export function prepareMGPSelects(dicts) {
  const { mgp_program, mgp_ksp, mgp_type, mgp_kind, mgp_target, mgp_operator } =
    dicts;
  return {
    mgpSelect:
      Object?.values(mgp_program)?.map((i) => ({
        label: i.name,
        value: i.id,
      })) ?? [],
    kspSelect:
      Object?.values(mgp_ksp)?.map((i) => ({ label: i.name, value: i.id })) ??
      [],
    typeSelect:
      Object?.values(mgp_type)?.map((i) => ({ label: i.name, value: i.id })) ??
      [],
    kindSelect:
      Object?.values(mgp_kind)?.map((i) => ({ label: i.name, value: i.id })) ??
      [],
    targetSelect:
      Object?.values(mgp_target)?.map((i) => ({
        label: i.name,
        value: i.id,
      })) ?? [],
    operatorsSelect:
      Object?.values(mgp_operator)?.map((i) => ({
        label: i.name,
        value: i.id,
      })) ?? [],
  };
}

export function prepareMGPMaps(dicts) {
  const { mgp_program, mgp_ksp, mgp_type, mgp_kind, mgp_target, mgp_operator } =
    dicts;
  return {
    mgpMap: Object?.fromEntries(
      Object?.values(mgp_program)?.map((i) => [i.id, i.name]) ?? []
    ),
    kspMap: Object?.fromEntries(
      Object?.values(mgp_ksp)?.map((i) => [i.id, i.name]) ?? []
    ),
    typeMap: Object?.fromEntries(
      Object?.values(mgp_type)?.map((i) => [i.id, i.name]) ?? []
    ),
    kindMap: Object?.fromEntries(
      Object?.values(mgp_kind)?.map((i) => [i.id, i.name]) ?? []
    ),
    targetMap: Object?.fromEntries(
      Object?.values(mgp_target)?.map((i) => [i.id, i.name]) ?? []
    ),
    operatorsMap: Object?.fromEntries(
      Object?.values(mgp_operator)?.map((i) => [i.id, i.name]) ?? []
    ),
  };
}
