import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Skeleton,
  Typography,
  Radio,
  Flex,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector.jsx';
import BizPollQuestions from './BizPollQuestions.jsx';
import {
  $binInfo,
  $submitStatus,
  BizPollGate,
  getBinInfoEv,
  getBizPollOptsFx,
  submitFormEv,
} from '../../models/bizPollsModel/index';
import WrongTypeModal from './WrongTypeModal.jsx';
import GovCantVoteModal from './GovCantVoteModal.jsx';
import style from './BizPolls.module.css';

const blocksDict = {
  small: {
    14: [15, 4],
  },
  medium: {},
};

const yearBlocksDict = {
  small: {
    '55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77': [
      [78, 79, 80, 81],
      4,
    ],
  },
  medium: {},
};

export default function BizPolls({ year }) {
  const { type } = useParams();
  const { t } = useTranslation();
  const getBin = useUnit(getBinInfoEv);
  const submit = useUnit(submitFormEv);
  const binInfo = useUnit($binInfo);
  const submitStatus = useUnit($submitStatus);
  const loading = useUnit(getBizPollOptsFx.pending);
  const [pollForm] = Form.useForm();
  const [binCorrect, setBinCorrect] = useState(false);

  const [blocked, setBlocked] = useState([]);
  const [blockedOptions, setBlockedOptions] = useState({});

  console.log('blockedOptions', blockedOptions);

  const blockManager = (changedValues, allValues) => {
    if (year && type === 'small') {
      const fifthQKey = Object.keys(yearBlocksDict[type])[0];
      const fifthQs = fifthQKey.split(',');
      if (!binCorrect) return;
      if (
        fifthQs.every(
          (id) => +allValues[id].value === yearBlocksDict[type][fifthQKey][1]
        )
      ) {
        setBlocked([...blocked, ...yearBlocksDict[type][fifthQKey][0]]);
      } else if (
        yearBlocksDict[type][fifthQKey][0].every((item) =>
          blocked.includes(item)
        )
      ) {
        setBlocked([
          ...blocked.filter(
            (item) => !yearBlocksDict[type][fifthQKey][0].includes(item)
          ),
        ]);
      }

      const sixthQ = [78, 79, 80];
      if (sixthQ.includes(+Object.keys(changedValues)[0])) {
        let neverCount = 0;
        const sixthQValues = Object.fromEntries(
          sixthQ.map((item) => {
            if (allValues[item].value === '4') neverCount += 1;
            return [item, allValues[item].value];
          })
        );
        if (neverCount === 2) {
          const nonNeverQ = sixthQ.filter((item) => sixthQValues[item] !== '4');
          setBlockedOptions({
            ...blockedOptions,
            [nonNeverQ]: 4,
          });
        } else if (Object.keys(blockedOptions).length > 0) {
          setBlockedOptions({});
        }
      }
    } else if (/\d+/g.test(Object.keys(changedValues)[0])) {
      const [id, { value }] = Object.entries(changedValues)[0];
      if (blocksDict[type][id] && blocksDict[type][id].includes(+value)) {
        setBlocked([...blocked, blocksDict[type][id][0]]);
      } else if (
        blocksDict[type][id] &&
        !blocksDict[type][id].includes(+value)
      ) {
        setBlocked([
          ...blocked.filter((item) => item !== blocksDict[type][id][0]),
        ]);
      }
    }
  };
  // FIXME hide for year too
  // if (!year) {
  return (
    <Card
      style={{
        position: 'fixed',
        top: '50%',
        right: '50%',
        transform: 'translate(50%, -50%)',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          fontSize: '28px',
        }}
      >
        Опрос завершен
      </div>
    </Card>
  );
  // }

  document.onkeydown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
    }
  };

  return (
    <Card
      extra={<LanguageSelector />}
      styles={{ body: { maxHeight: '90vh', overflow: 'auto' } }}
      title={type === 'small' ? t('biz-polls.titleSm') : t('biz-polls.titleMd')}
    >
      <WrongTypeModal year={year} />
      <GovCantVoteModal />
      {/* {binInfo &&  />} */}
      <BizPollGate type={type} />
      {/* <BizPollGate type={type}/> */}
      {submitStatus ? (
        <Typography.Paragraph>{t('biz-polls.success')}</Typography.Paragraph>
      ) : loading ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography.Paragraph>{t('biz-polls.desc1')}</Typography.Paragraph>
            <Typography.Paragraph>{t('biz-polls.desc2')}</Typography.Paragraph>
            <Typography.Paragraph>
              <strong>{t('biz-polls.desc3')}</strong>
              {/* FIXME Quarter desc3 */}
              {/* "desc3": "Целью опроса является оценка и сопоставления результатов работы центральных государственных и местных исполнительных органов по созданию условий ведения предпринимательской деятельности в приоритетных отраслях экономики каждого отдельного региона/района.", */}
              {/* "desc3": "Сауалнаманың мақсаты әрбір жекелеген өңір/аудан экономикасының басым салаларында кәсіпкерлік қызметті жүргізуге жағдай жасау бойынша орталық мемлекеттік және жергілікті атқарушы органдардың жұмыс нәтижелерін бағалау және салыстыру болып табылады", */}
            </Typography.Paragraph>
            <Typography.Paragraph>{t('biz-polls.desc4')}</Typography.Paragraph>
            <Typography.Paragraph>{t('biz-polls.desc5')}</Typography.Paragraph>
            <Typography.Paragraph>{t('biz-polls.desc6')}</Typography.Paragraph>
            {/* FIXME Quarter desc6 */}
            {/* "desc6": "Вашему вниманию предложен ряд вопросов и варианты ответов на них. Прочитайте, пожалуйста, их до конца и выберите устраивающий Вас ответ. В случае, если ни один из предложенных ответов Вас не удовлетворяет, укажите свой вариант в графе «затрудняюсь ответить/не сталкивался».", */}
            {/* "desc6": "Сіздердің назарларыңызға бірқатар сұрақтар мен оларға жауаптардың нұсқалары ұсынылды. Оларды соңына дейін оқып шығыңыз және өзіңізді қанағаттандыратын жауапты таңдаңыз. Егер ұсынылған жауаптардың ешқайсысы Сізді қанағаттандырмаса, «жауап беруге қиналамын/кездестірмедім» бағанында өзіңіздің нұсқаңызды көрсетіңіз.", */}
            <Typography.Paragraph>{t('biz-polls.desc7')}</Typography.Paragraph>
            <Typography.Paragraph>
              <strong>{t('biz-polls.desc8')}</strong>
            </Typography.Paragraph>
          </div>
          <Divider />
          <Form
            form={pollForm}
            name="pollForm"
            onFinish={(values) => {
              // debugger;
              // if (Object.keys(values).length < 2) {
              //   return;
              // }
              // if (values[14] && values[14].value === '4') {
              //   delete values[15];
              // }
              submit(values);
            }}
            layout="vertical"
            scrollToFirstError
            onValuesChange={blockManager}
          >
            <Row justify="space-between">
              <Col span={6}>
                <Form.Item
                  name="gov_id"
                  label={t('biz-polls.bin')}
                  validationTrigger={['onChange', 'onBlur']}
                  rules={[
                    { required: true, message: t('Обязательное поле') },
                    {
                      validator: async (rule, value) => {
                        if (value && value.length === 12) {
                          setBinCorrect(true);
                          return Promise.resolve();
                        }
                        if (!value || (value && value.length !== 12)) {
                          setBinCorrect(false);
                          return Promise.reject(
                            new Error('БИН/ИИН должен состоять из 12 цифр')
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    showCount
                    maxLength={12}
                    onPressEnter={() =>
                      getBin(pollForm.getFieldValue('gov_id'))
                    }
                  />
                </Form.Item>
                <Button
                  type="primary"
                  onClick={() => getBin(pollForm.getFieldValue('gov_id'))}
                  disabled={!binCorrect}
                >
                  {t('biz-polls.search')}
                </Button>
              </Col>
              {binInfo && (
                <>
                  <Col span={4}>
                    <Typography.Text>
                      <strong>{t('biz-polls.bin-info.name')}</strong>
                    </Typography.Text>
                    <Divider style={{ margin: 0 }} />
                    <Typography.Text>
                      {binInfo?.business_name_ru}
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text>
                      <strong>{t('biz-polls.bin-info.kato')}</strong>
                    </Typography.Text>
                    <Divider style={{ margin: 0 }} />
                    <Typography.Text>{binInfo?.kato_level_0}</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text>
                      <strong>{t('biz-polls.bin-info.oked')}</strong>
                    </Typography.Text>
                    <Divider style={{ margin: 0 }} />
                    <Typography.Text>{binInfo?.oked_level_4}</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text>
                      <strong>{t('biz-polls.bin-info.krp')}</strong>
                    </Typography.Text>
                    <Divider style={{ margin: 0 }} />
                    <Typography.Text>{binInfo?.krp_name_ru}</Typography.Text>
                  </Col>
                </>
              )}
            </Row>
            {binInfo && (
              <>
                <Divider />
                <BizPollQuestions
                  type={type}
                  pollForm={pollForm}
                  blocked={blocked}
                  blockedOptions={blockedOptions}
                  year={year}
                />
                <Button
                  type="primary"
                  onClick={() => pollForm.submit()}
                  disabled={!binInfo}
                >
                  {t('biz-polls.submit')}
                </Button>
              </>
            )}
          </Form>
        </>
      )}
    </Card>
  );
}
