import React from 'react';
import { Button, Col, Form, Row, Select, Tooltip, TreeSelect } from 'antd';
import { useUnit } from 'effector-react/effector-react.mjs';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import {
  $activitiesTreeSelect,
  $regionsSelect,
} from '../../../models/globalModel/index.js';
import { months_dictionary } from '../../../dictionaries/months_dictionary.js';
import {
  applyNMPFiltersEv,
  getNMPDataFx,
  resetNMPFiltersEv,
} from '../../../models/mgpModel/index.js';

const { SHOW_PARENT } = TreeSelect;

const yearOptions = [
  {
    label: 2021,
    value: 2021,
  },
  {
    label: 2022,
    value: 2022,
  },
  {
    label: 2023,
    value: 2023,
  },
];
const monthOptions = Object.keys(months_dictionary).map((key) => {
  return {
    label: months_dictionary[key],
    value: key,
  };
});
const servicesOptions = [
  {
    label: 'Правовые вопросы и получение разрешительных документов',
    value: 'Правовые вопросы и получение разрешительных документов',
  },
  {
    label:
      'Информирование и консультирование о мерах государственной поддержки',
    value:
      'Информирование и консультирование о мерах государственной поддержки',
  },
  {
    label:
      'Услуги, связанные с ведением бухгалтерского и налогового учета, а также составлением статистической отчетности',
    value:
      'Услуги, связанные с ведением бухгалтерского и налогового учета, а также составлением статистической отчетности',
  },
  {
    label: 'Услуги по юридическим вопросам и конкурсным тендерным процедурам',
    value: 'Услуги по юридическим вопросам и конкурсным тендерным процедурам',
  },
  {
    label: 'Услуги по разработке бизнес-плана',
    value: 'Услуги по разработке бизнес-плана',
  },
  {
    label:
      'Услуги по вопросам финансирования и получения мер государственной поддержки',
    value:
      'Услуги по вопросам финансирования и получения мер государственной поддержки',
  },
];
const regions = [
  {
    label: 'Акмолинская',
    value: 'Акмолинская',
  },
  {
    label: 'Актюбинская',
    value: 'Актюбинская',
  },
  {
    label: 'Алматинская',
    value: 'Алматинская',
  },
  {
    label: 'Атырауская',
    value: 'Атырауская',
  },
  {
    label: 'ВКО',
    value: 'ВКО',
  },
  {
    label: 'г. Астана',
    value: 'г. Астана',
  },
  {
    label: 'г. Шымкент',
    value: 'г. Шымкент',
  },
  {
    label: 'г. Алматы',
    value: 'г. Алматы',
  },
  {
    label: 'Карагандинская',
    value: 'Карагандинская',
  },
  {
    label: 'ЗКО',
    value: 'ЗКО',
  },
  {
    label: 'Жамбылская',
    value: 'Жамбылская',
  },
  {
    label: 'Костанайская',
    value: 'Костанайская',
  },
  {
    label: 'Кызылординская',
    value: 'Кызылординская',
  },
  {
    label: 'Мангистауская',
    value: 'Мангистауская',
  },
  {
    label: 'обл. Жетісу',
    value: 'обл. Жетісу',
  },
  {
    label: 'обл. Абай',
    value: 'обл. Абай',
  },
  {
    label: 'обл. Ұлытау',
    value: 'обл. Ұлытау',
  },
  {
    label: 'Область Жетісу',
    value: 'Область Жетісу',
  },
  {
    label: 'Область Абай',
    value: 'Область Абай',
  },
  {
    label: 'Область Ұлытау',
    value: 'Область Ұлытау',
  },
  {
    label: 'Туркестанская',
    value: 'Туркестанская',
  },
  {
    label: 'Павлодарская',
    value: 'Павлодарская',
  },
  {
    label: 'СКО',
    value: 'СКО',
  },
  {
    label: 'КЗО',
    value: 'КЗО',
  },
];

function NmpFilters() {
  const [filterForm] = Form.useForm();
  const okedTree = useUnit($activitiesTreeSelect);
  const apply = useUnit(applyNMPFiltersEv);
  const reset = useUnit(resetNMPFiltersEv);

  const onActivitySelect = (value, label, extra) => {
    const payloadValue = [];

    const getOkedIds = (node) => {
      if (!payloadValue.includes(node.node.props.value)) {
        payloadValue.push(node.node.props.value);
      }
      if (node.children.length > 0) {
        node.children.forEach((child) => getOkedIds(child));
      }
    };

    if (extra.allCheckedNodes.length > 0) {
      extra.allCheckedNodes.forEach((checkedNode) => {
        getOkedIds(checkedNode);
      });
    }
    filterForm.setFieldValue('activity_ids', payloadValue);
  };

  const onFinish = (values) => {
    apply(values);
  };

  const onCancel = () => {
    filterForm.resetFields();
    reset();
  };

  return (
    <Form form={filterForm} onFinish={onFinish} layout="vertical">
      <Row justify="space-between" style={{ alignItems: 'center' }}>
        <Col span={4}>
          <Form.Item name="year" label="Год">
            <Select
              options={yearOptions}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="month" label="Месяц">
            <Select
              options={monthOptions}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="region" label="Регион">
            <Select
              options={regions}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="service" label="Услуга">
            <Select
              options={servicesOptions}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="activity" label="ОКЭД">
            <TreeSelect
              treeData={okedTree}
              showSearch
              treeNodeFilterProp="title"
              allowClear
              style={{
                maxHeight: 32,
                overflow: 'auto',
              }}
              dropdownStyle={{ width: 500 }}
              listHeight={600}
              onChange={onActivitySelect}
              // treeCheckable
              showCheckedStrategy={SHOW_PARENT}
            />
          </Form.Item>
        </Col>
        <Button.Group>
          <Tooltip title="Применить фильтры">
            <Button
              onClick={() => filterForm.submit()}
              icon={<FilterOutlined />}
              type="primary"
              style={{ boxShadow: 'none' }}
            />
          </Tooltip>
          <Tooltip title="Сбросить фильтры">
            <Button onClick={onCancel} icon={<ClearOutlined />} danger />
          </Tooltip>
        </Button.Group>
      </Row>
    </Form>
  );
}

export default NmpFilters;
