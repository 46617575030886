import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useEvent } from 'effector-react';
import DownloadFilteredButton from './DownloadFilteredButton.jsx';

function filterOption(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

const getInput = (i) => {
  if (i.type === 'input') {
    return (
      <Form.Item name={i.name} label={i.label}>
        <Input />
      </Form.Item>
    );
  }
  if (i.type === 'switch') {
    return (
      <Form.Item name={i.name} label={i.label} valuePropName="checked">
        <Switch defaultChecked={false} className="form-checker" />
      </Form.Item>
    );
  }
  if (i.type === 'date') {
    return (
      <Form.Item name={i.name} label={i.label}>
        <DatePicker.YearPicker placeholder="Укажите год" />
      </Form.Item>
    );
  }
  if (i.type === 'select') {
    return (
      <Form.Item name={i.name} label={i.label}>
        <Select
          options={i?.selectOptions ?? []}
          style={{ width: '12.8rem' }}
          mode={i?.mode || null}
          filterOption={filterOption}
          allowClear
        />
      </Form.Item>
    );
  }
};

const prepareValues = (values) => {
  for (const [key, value] of Object.entries(values)) {
    if (key.includes('year_of_purchase')) {
      values[key] = parseInt(dayjs(value).format('YYYY'));
    }
    if (!value && value !== false) {
      delete values[key];
    }
  }
  return values;
};

function FilterBlock({ items, download }) {
  const [form] = Form.useForm();
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (search?.size > 0) {
      search.forEach((value, name) => {
        if (value === 'false') {
          form.setFieldValue(name, false);
        } else if (value === 'true') {
          form.setFieldValue(name, true);
        } else if (name.includes('year_of_purchase')) {
          form.setFieldValue(name, dayjs(value));
        } else form.setFieldValue(name, value);
      });
    }
  });

  const onReset = () => {
    form.resetFields();
    setSearch();
  };

  const prepareDownload = () => {
    const payload = prepareValues(form.getFieldsValue());

    // if (Object.keys(payload).length === 0) {
    //     message.error('Ни один фильтр не заполнен', 2).then(() => true)
    // } else {
    download(payload);
    // initDownload(payload)
    // }
  };

  return (
    <Card style={{ width: '100%' }} id="filter-block">
      <Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={(v) => setSearch(prepareValues(v))}
        >
          <Row style={{ gap: 16 }}>{items?.map((i) => getInput(i))}</Row>
        </Form>
      </Row>
      <Row style={{ alignItems: 'center', gap: 16 }}>
        <Button
          type="primary"
          className="btn-primary"
          onClick={() => form.submit()}
        >
          Применить фильтр
        </Button>
        <Button onClick={onReset} className="form-btn-secondary">
          Очистить фильтр
        </Button>
        {download ? (
          <DownloadFilteredButton onDownload={prepareDownload} />
        ) : null}
      </Row>
    </Card>
  );
}

export default FilterBlock;
