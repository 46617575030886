import { createGate } from 'effector-react';
import { createEvent } from 'effector';

export const MGPsGate = createGate();
export const EditMGPGate = createGate();

export const MGPsListGate = createGate();
export const applyMgpFiltersEv = createEvent();
export const resetMgpFiltersEv = createEvent();
export const setMgpTypeFilterEv = createEvent();

export const setMGPsModalEv = createEvent();

export const resetMGPsModalEv = createEvent();

export const createMGPEv = createEvent();
export const editMGPEv = createEvent();
export const deleteMGPEv = createEvent();

export const NMPGate = createGate();
export const applyNMPFiltersEv = createEvent();
export const resetNMPFiltersEv = createEvent();
