import { createEffect } from 'effector';
import queryString from 'query-string';
import { api } from '../../api/axiosInstance.js';

export const getOverviewDataFx = createEffect().use(async (activeFilters) => {
  let prepareQuery = { ...activeFilters };
  if (window.location.pathname.includes('public')) {
    prepareQuery = {
      region_id: 9,
    };
  }
  for (const [key, value] of Object.entries(prepareQuery)) {
    if (!value || value?.length === 0) {
      delete prepareQuery[key];
    }
  }
  delete prepareQuery.index;
  return (await api().post(`/dashboard/`, prepareQuery)).data;
});

export const getOtpDataFx = createEffect().use(async (activeFilters) => {
  const query = prepareQueryForOtp(activeFilters);

  const test = (
    await api().get(
      `/dashboard/manufacturing${query.length > 0 ? `?${query}` : ''}`
    )
  ).data;

  return new Promise((resolve, reject) =>
    setTimeout(() => {
      return resolve(test);
    }, 1000)
  );

  // return (
  //   await api().get(
  //     `/dashboard/manufacturing${query.length > 0 ? `?${query}` : ''}`
  //   )
  // ).data;
});

export const getOtpActivitiesDataFx = createEffect().use(
  async (activeFilters) => {
    const query = prepareQueryForOtp(activeFilters);
    return (
      await api().get(
        `/dashboard/manufacturing/activities${
          query.length > 0 ? `?${query}` : ''
        }`
      )
    ).data;
  }
);

export const getOtpOrgsDataFx = createEffect().use(async (activeFilters) => {
  const query = prepareQueryForOtp(activeFilters);
  return (
    await api().get(
      `/dashboard/manufacturing/organizations${
        query.length > 0 ? `?${query}` : ''
      }`
    )
  ).data;
});

export const getProblemProjectsFx = createEffect().use(async (query) => {
  const filters = query ? queryString.stringify(query) : '';
  return (
    await api().get(
      `/project/problems${filters.length > 0 ? `?${filters}` : ''}`
    )
  ).data;
});

export const getDashboardModalDataFx = createEffect().use(async (query) => {
  const filters = query ? queryString.stringify(query) : '';
  return (
    await api().get(
      `/dashboard/projects-start-date${filters.length > 0 ? `?${filters}` : ''}`
    )
  ).data;
});

export const getSmBizModalDataFx = createEffect().use(
  async (yearly) =>
    (
      await api().get(
        `/business-climate/small-climate${
          yearly ? '?business_climate_yearly=true' : ''
        }`
      )
    ).data
);

export const getMBizModalDataFx = createEffect().use(
  async (yearly) =>
    (
      await api().get(
        `/business-climate/medium-climate${
          yearly ? '?business_climate_yearly=true' : ''
        }`
      )
    ).data
);

export const getBizModalPollsStatsFx = createEffect().use(
  async ({ type, region_id, year, quarter }) =>
    (
      await api().get(
        `/business-climate/form-result?business_climate=${type}&region_id=${region_id}&year=${year}&quarter=${quarter}`
      )
    ).data
);

export const downloadProjectsFx = createEffect().use(async () => {
  const file = (await api().get(`/project/export`, { responseType: 'blob' }))
    .data;
  const link = document.createElement('a');
  const url = URL.createObjectURL(file);
  link.href = url;
  link.target = '_blank';
  link.download = `Реестр проектов ${new Date().toLocaleDateString(
    'ru-RU'
  )}.xlsx`;
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
});

function prepareQueryForOtp(activeFilters) {
  const prepareQuery = { ...activeFilters };
  if (!activeFilters.region_id) {
    delete prepareQuery.region_id;
  }
  if (!activeFilters.district_id) {
    delete prepareQuery.district_id;
  }
  if (!activeFilters.activity_id) {
    delete prepareQuery.activity_id;
  }
  if (Object.hasOwn(activeFilters, 'is_gov')) {
    delete prepareQuery.is_gov;
  }
  return queryString.stringify(prepareQuery);
}
