import { Col, Row, Skeleton, Table, Tooltip } from 'antd';
import { Line } from '@ant-design/charts';
import { useStore, useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';
import style from '../../components/ViewProjectComponents/ViewProjectComponents.module.css';
import {
  $organizationInfo,
  getOrganizationInfoFullFx,
  ViewOrganizationGate,
} from '../../models/viewOrganizationModel/index.js';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import { $userInfo } from '../../models/authModel/index.js';

const formatText = (v) => {
  return v.length > 30 ? (
    <Tooltip title={v}>{`${v.slice(0, 36)}...`}</Tooltip>
  ) : (
    v
  );
};

const keys_titles_dict = {
  installed_capacity: 'Установленная мощность',
  available_power: 'Располагаемая мощность',
  placement: 'Расположение',
  winter: 'Зима',
  summer: 'Лето',
  boilers: 'Котлоагрегаты',
  turbines: 'Турбины',
  generators: 'Генераторы',
};

const tempColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'temp_id',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'temp_type',
  },
  {
    title: 'N, МВт D, т/ч N, МВА',
    dataIndex: 'power',
    key: 'temp_power',
  },
  {
    title: 'Завод изготовит.',
    dataIndex: 'manufacturer',
    key: 'temp_manufacturer',
  },
  {
    title: 'Р атм',
    dataIndex: 'pressure',
    key: 'temp_pressure',
  },
  {
    title: 'Т °C',
    dataIndex: 'temperature',
    key: 'temp_temperature',
  },
  {
    title: 'Год ввода',
    dataIndex: 'year',
    key: 'temp_year',
  },
  {
    title: 'U кВ',
    dataIndex: 'voltage',
    key: 'temp_voltage',
  },
];

const tempData = {
  general_tech_data: {
    installed_capacity: '360 МВт',
    available_power: {
      winter: '325 МВт',
      summer: '325 МВт',
    },
    placement: 'г. Астана',
  },
  general_equipment: {
    boilers: [
      {
        id: 1,
        type: 'БКЗ-420-140',
        power: 420,
        manufacturer: 'БКЗ',
        pressure: 140,
        temperature: 560,
        year: 1979,
        voltage: null,
      },
      {
        id: 2,
        type: 'БКЗ-420-140',
        power: 420,
        manufacturer: 'БКЗ',
        pressure: 140,
        temperature: 560,
        year: 1981,
        voltage: null,
      },
      {
        id: 3,
        type: 'БКЗ-420-140',
        power: 420,
        manufacturer: 'БКЗ',
        pressure: 140,
        temperature: 560,
        year: 1983,
        voltage: null,
      },
      {
        id: 4,
        type: 'БКЗ-420-140',
        power: 420,
        manufacturer: 'БКЗ',
        pressure: 140,
        temperature: 560,
        year: 1985,
        voltage: null,
      },
      {
        id: 5,
        type: 'БКЗ-420-140',
        power: 420,
        manufacturer: 'БКЗ',
        pressure: 140,
        temperature: 560,
        year: 1992,
        voltage: null,
      },
    ],
    turbines: [
      {
        id: 1,
        type: 'ПТ-80/100',
        power: 80,
        manufacturer: 'ЛМЗ',
        pressure: 130,
        temperature: 555,
        year: 1979,
        voltage: null,
      },
      {
        id: 2,
        type: 'ПТ-80/100',
        power: 80,
        manufacturer: 'ЛМЗ',
        pressure: 130,
        temperature: 555,
        year: 1980,
        voltage: null,
      },
      {
        id: 3,
        type: 'ПТ-80/100',
        power: 80,
        manufacturer: 'ЛМЗ',
        pressure: 130,
        temperature: 555,
        year: 1983,
        voltage: null,
      },
      {
        id: 4,
        type: 'Т-120/130-130-8',
        power: 120,
        manufacturer: 'УТЗ',
        pressure: 130,
        temperature: 555,
        year: 2006,
        voltage: null,
      },
    ],
    generators: [
      {
        id: 1,
        type: 'ТВФ-120-2УЗ',
        power: 120,
        manufacturer: 'НТГЗ',
        pressure: null,
        temperature: null,
        year: 1979,
        voltage: 10.5,
      },
      {
        id: 2,
        type: 'ТВФ-120-2УЗ',
        power: 120,
        manufacturer: 'НТГЗ',
        pressure: null,
        temperature: null,
        year: 1980,
        voltage: 10.5,
      },
      {
        id: 3,
        type: 'ТВФ-120-2УЗ',
        power: 120,
        manufacturer: 'НТГЗ',
        pressure: null,
        temperature: null,
        year: 1983,
        voltage: 10.5,
      },
      {
        id: 4,
        type: 'ТФ-125-2УЗ',
        power: 125,
        manufacturer: 'ЛСИБ',
        pressure: null,
        temperature: null,
        year: 2006,
        voltage: 10.5,
      },
    ],
  },
};

export default function OrganizationInfo() {
  const organization = useStore($organizationInfo);
  const loading = useStore(getOrganizationInfoFullFx.pending);
  const { id } = useParams();
  const userInfo = useUnit($userInfo);

  const columns = [
    {
      title: 'Наименование товара',
      key: 'name',
      dataIndex: 'product_name',
    },
    {
      title: 'Код ТН ВЭД',
      key: 'tnved',
      dataIndex: 'tnved',
    },
    {
      title: 'Код КП ВЭД',
      key: 'kpved',
      dataIndex: 'kpved',
    },
    {
      title: 'Вид деятельности согласно ОКЭД (перв, вторич.)',
      dataIndex: 'oked',
      key: 'oked',
    },
    {
      title: 'Производственная мощность, кол-во единиц в год',
      dataIndex: 'power',
      key: 'power',
    },
    {
      title: 'единица измерения',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  return (
    <ContainerInner showTitle={false}>
      <ViewOrganizationGate gov_id={id} />
      <div className={style.block_title} style={{ marginBottom: 16 }}>
        О компании
      </div>
      <Row gutter={[20, 20]}>
        {loading && <Skeleton loading={loading} />}
        {organization?.blocks?.map((item, index) => {
          return (
            <Col span={6} key={`${item.title}-${index}`}>
              <div className={style.project_view_card}>
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <div
                  className={style.project_view_card_text}
                  style={{ marginTop: '8px' }}
                >
                  {formatText(item.value)}
                </div>
              </div>
            </Col>
          );
        })}
        {organization?.graphs?.map((item, index) => {
          return (
            <Col span={8} key={`${item.title}-${index}`}>
              <div
                className={style.project_view_card}
                style={{ maxHeight: '247px' }}
              >
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <LineGraph data={item.values} />
              </div>
            </Col>
          );
        })}
        {userInfo.role === 'admin' && id === '041140002811' ? (
          <>
            {Object.keys(tempData.general_tech_data).map((key) => {
              return (
                <Col span={8}>
                  <div className={style.project_view_card}>
                    <div className={style.project_view_card_title}>
                      {keys_titles_dict[key]}
                    </div>
                    <div
                      className={style.project_view_card_text}
                      style={{ marginTop: '8px' }}
                    >
                      {typeof tempData.general_tech_data[key] !== 'object'
                        ? formatText(tempData.general_tech_data[key])
                        : Object.keys(tempData.general_tech_data[key]).map(
                            (subKey) =>
                              `${keys_titles_dict[subKey]}: ${formatText(
                                tempData.general_tech_data[key][subKey]
                              )}\n`
                          )}
                    </div>
                  </div>
                </Col>
              );
            })}
            {Object.keys(tempData.general_equipment).map((key) => {
              return (
                <Col span={24}>
                  <div style={{ textAlign: 'center' }}>
                    {keys_titles_dict[key]}
                  </div>
                  <Table
                    dataSource={tempData.general_equipment[key]}
                    columns={tempColumns}
                  />
                </Col>
              );
            })}
          </>
        ) : (
          ''
        )}
      </Row>
      {/* <div className={style.block_title} style={{ margin: '16px 0' }}> */}
      {/*  Производимые товары */}
      {/* </div> */}
      {/* <Row gutter={[20, 20]}> */}
      {/*  <Col span={24}> */}
      {/*    <Table dataSource={productsData.organizations} columns={columns} /> */}
      {/*  </Col> */}
      {/* </Row> */}
    </ContainerInner>
  );
}

function LineGraph({ data }) {
  const format = (v) => {
    return typeof v === 'number'
      ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
      : v;
  };

  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#4318FF',
    // label: { position: 'right', formatter: (v) => format(v.value), autoHide: true },
    yAxis: { label: { formatter: (v) => formatYLabel(v), autoHide: true } },
    xAxis: {
      label: {
        autoHide: true,
        formatter: (v) => {
          return new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }).format(new Date(v));
        },
      },
    },
    meta: {
      date: { alias: 'Дата' },
      value: { alias: 'Сумма', formatter: (v) => format(parseInt(v)) },
    },
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {data?.length > 0 ? <Line {...config} /> : 'Нет данных'}
    </div>
  );
}
