import { Button, Col, Row } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useEvent, useStore, useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import MapWork from '../../pages/MapWork/MapWork.jsx';
import DashboardCharts from '../../components/DashboardCharts/DashboardCharts.jsx';
import {
  $activeFilters,
  changeActiveFilterEv,
  resetActiveFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import OverviewModal from './components/OverviewModal.jsx';
import {
  $isModalOpen,
  $otpStore,
  $overviewStore,
  getOverviewDataFx,
  ManufacturersGate,
  setIsModalOpenEv,
  setOtpModalEv,
} from '../../models/overviewModel/index.js';
import { months_dictionary } from '../../dictionaries/months_dictionary.js';
import { formatNumber } from '../../utils/number-manipulation.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import OverviewDashboardModal from './components/OverviewDashboardModal.jsx';
import ManufacturersModal from './components/otp/ManufacturersModal.jsx';
import OtpCharts from '../../components/OtpCharts/OtpCharts.jsx';
import OverviewCard from './components/OverviewCard.jsx';
import OverviewCardOtp from './components/otp/OverviewCardOtp.jsx';
import { initData as data } from './utils.js';
import GovSwitch from './components/GovSwitch.jsx';
import OverviewBizCard from './components/biz/OverviewBizCard.jsx';
import BizRegionModal from './components/biz/BizRegionModal.jsx';

function OverviewPublic() {
  const changeActiveFilters = useUnit(changeActiveFilterEv);
  const activeFilters = useUnit($activeFilters);
  const { visible, modalType } = useStore($isModalOpen);
  const changeIsModalOpen = useEvent(setIsModalOpenEv);
  const [cardsData, setCardsData] = useState(data);
  const overviewStore = useStore($overviewStore);
  const {
    medium_companies,
    people,
    taxes,
    mio_performance,
    business_inspections,
    problems,
    projects,
  } = overviewStore;
  const { companies, avg_workload } = useStore($otpStore);
  const setOtpModal = useEvent(setOtpModalEv);

  const resetActiveFilters = useEvent(resetActiveFiltersEv);

  useEffect(() => {
    if (hasActiveFilters(activeFilters)) {
      setTimeout(() => {
        resetActiveFilters();
      }, 500);
    }
  }, []);

  const cardClick = (e) => {
    let node = e.target;
    if (node.dataset.subcategory == activeFilters.subCategory) {
      return;
    }
    if (node.localName === 'svg' || node.localName === 'button') return;

    while (Object.keys(node.dataset).length === 0) {
      node = node.parentNode;
    }

    if (node.dataset.subcategory == activeFilters.subCategory) {
      return;
    }
    // if (workingIndexes.includes(node.dataset.subcategory)) {
    if (node.dataset.type === 'card') {
      if (activeFilters.subCategory !== node.dataset.subcategory) {
        // changeActiveFilters({
        //   field: 'category',
        //   value: node.dataset.category,
        // });
        changeActiveFilters({
          field: 'subCategory',
          value: node.dataset.subcategory,
        });
      }
      // else {
      //   // changeActiveFilters({
      //   //   field: 'category',
      //   //   value: node.dataset.category,
      //   // });
      //   changeActiveFilters({
      //     field: 'subCategory',
      //     value: activeFilters.subCategory,
      //   });
      // }
    } else {
      if (activeFilters.category !== node.dataset.category) {
        // changeActiveFilters({
        //   field: 'category',
        //   value: node.dataset.category,
        // });
      }
      changeActiveFilters({
        field: 'subCategory',
        value: node.dataset.subcategory,
      });
    }
    // }
    // if (node.dataset.subcategory === 'projectCount') {
    //   changeShowProjectDots(!showProjectDots);
    // } else if (showProjectDots) {
    //   changeShowProjectDots(false);
    // }
  };

  const prepareCardsData = () => {
    const reformatCard = (newData, card, plainValue, quarter) => {
      const formattedNumber = formatNumber(
        plainValue ? newData : newData.value
      );
      const [val, measure] = formattedNumber.split(' ');
      let type;
      if (plainValue) {
        type =
          newData === 0 ? 'neutral' : newData > 0 ? 'positive' : 'negative';
      } else {
        type =
          newData?.change === 0
            ? 'neutral'
            : newData?.change > 0
            ? 'positive'
            : 'negative';
      }

      return {
        ...card,
        value: val,
        measure: `${measure || ''} ${card?.measure}`,
        // date: `${quarter ? `${newData.month} квартал` : (months_dictionary[newData?.month] ?? 'Нет данных')} ${newData?.year ?? ''}`,
        date:
          card.subCategory === 'people'
            ? 'Ноябрь 2023'
            : // : card.subCategory === 'taxes' ||
              //   card.subCategory === 'medium_companies'
              // ? 'Май 2024'
              `${
                quarter
                  ? `${newData.month} квартал`
                  : months_dictionary[newData?.month + 1] ?? 'Нет данных'
              } ${new Date().getFullYear() ?? ''}`,
        percent: newData?.change?.toFixed(2)?.replace('-', ''),
        type,
      };
    };
    const mutatedData = {
      ...data,
      mal: {
        ...data.mal,
        cards: [
          {
            ...reformatCard(people.last_month_data, data.mal.cards[0]),
          },
          {
            ...reformatCard(taxes.last_month_data, data.mal.cards[1]),
          },
        ],
      },
      sred: {
        ...data.sred,
        cards: [
          {
            ...reformatCard(
              medium_companies.last_month_data,
              data.sred.cards[0]
            ),
          },
        ],
      },
      projectsRealisation: {
        ...data.projectsRealisation,
        cards: [
          {
            ...data.projectsRealisation.cards[0],
            value: projects.count,
            measure: `(${formatNumber(projects.budget * 1000000)} тенге)`,
          },
          {
            ...data.projectsRealisation.cards[1],
            value: problems.count,
            measure: `(${formatNumber(problems.budget * 1000000)} тенге)`,
          },
        ],
      },
      businessClimate: {
        ...data.businessClimate,
        cards: [
          {
            ...reformatCard(
              business_inspections.last_month_data,
              data.businessClimate.cards[0],
              false,
              true
            ),
          },
          {
            ...reformatCard(
              mio_performance.last_month_data,
              data.businessClimate.cards[1],
              false,
              true
            ),
          },
        ],
      },
      otp: {
        ...data.otp,
        cards: [
          {
            ...reformatCard(companies ?? 0, data.otp.cards[0], true),
          },
          {
            ...reformatCard(
              avg_workload ? avg_workload * 100 : 0,
              data.otp.cards[1],
              true
            ),
          },
        ],
      },
    };

    setCardsData(mutatedData);
  };

  useEffect(() => {
    getOverviewDataFx({
      index: 'people',
    });
  }, []);

  useEffect(() => {
    prepareCardsData();
  }, [overviewStore, companies]);

  const [carousel, setCarousel] = useState(0);

  return (
    <ContainerInner
      title="Ключевые показатели"
      extra={<GovSwitch changeActiveFilters={changeActiveFilters} />}
    >
      <ManufacturersGate />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <OverviewBizCard
                {...cardsData.businessClimate}
                cardClick={cardClick}
                field="businessClimate"
                activeCategory={activeFilters.category}
                activeSubCategory={activeFilters.subCategory}
                granularity={activeFilters.business_climate_yearly}
                changeFilters={changeActiveFilters}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col span={10}> */}
        {/*  <Row gutter={[0, 16]}> */}
        {/*    <Col */}
        {/*      span={24} */}
        {/*      style={{ */}
        {/*        height: '350px', */}
        {/*        borderRadius: '12px', */}
        {/*        overflow: 'hidden', */}
        {/*        // padding: '0', */}
        {/*        // border: '1px solid black', */}
        {/*      }} */}
        {/*    > */}
        {/*      <MapWork isOverview /> */}
        {/*    </Col> */}
        {/*    <Col */}
        {/*      span={24} */}
        {/*      style={ */}
        {/*        { */}
        {/*          // height: '267px', */}
        {/*          // width: '100%', */}
        {/*        } */}
        {/*      } */}
        {/*    > */}
        {/*      {activeFilters.subCategory !== 'otp_count' && */}
        {/*      activeFilters.subCategory !== 'otp_avg_workload' ? ( */}
        {/*        <DashboardCharts /> */}
        {/*      ) : ( */}
        {/*        '' */}
        {/*      )} */}
        {/*      {activeFilters.subCategory === 'otp_count' || */}
        {/*      activeFilters.subCategory === 'otp_avg_workload' ? ( */}
        {/*        <OtpCharts /> */}
        {/*      ) : null} */}
        {/*    </Col> */}
        {/*  </Row> */}
        {/* </Col> */}
      </Row>
      <OverviewDashboardModal />
      <OverviewModal
        isOpen={visible}
        setIsOpen={changeIsModalOpen}
        type={modalType}
      />
      <ManufacturersModal />
      <BizRegionModal />
    </ContainerInner>
  );
}

export default OverviewPublic;
