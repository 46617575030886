import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MapWork from '../pages/MapWork/MapWork.jsx';
import Overview from '../pages/Overview/Overview.jsx';
import ProjRegistry from '../pages/ProjRegistry/ProjRegistry.jsx';
import Curators from '../pages/Curators/Curators.jsx';
import Investments from '../pages/Investments/Investments.jsx';
import ViewSubject from '../pages/ViewSubject/ViewSubject.jsx';
import ViewProject from '../pages/ViewProject/ViewProject.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import EditProjectById from '../pages/EditProject/EditProjectById.jsx';
import EditList from '../components/EditList/EditList.jsx';
import EditSubjectById from '../pages/EditSubject/EditSubjectById.jsx';
import CreateSubject from '../pages/CreateSubject/CreateSubject.jsx';
import ForeignInvestments from '../pages/ForeignInvestments/ForeignInvestments.jsx';
import ProjectsMap from '../pages/ProjectsMap/ProjectsMap.jsx';
import CreateProject from '../pages/CreateProject/CreateProject.jsx';
import EditSubjectsList from '../components/EditList/EditSubjectsList.jsx';
import OrganizationsDashboard from '../pages/OrganizationsDashboard/OrganizationsDashboard.jsx';
import ManufacturerInfo from '../pages/ViewManufacturer/ViewManufacturer.jsx';
import OrganizationInfo from '../pages/ViewOrganization/ViewOrganization.jsx';
import OkedsPriority from '../pages/OkedsPriority/OkedsPriority.jsx';
import MGPEdit from '../pages/MGPEdit/MGPEdit.jsx';
import MGPRegistry from '../pages/MGPRegistry/MGPRegistry.jsx';
import RegRequest from '../pages/RegRequest/RegRequest.jsx';
import InvestRequests from '../pages/InvestRequests/InvestRequests.jsx';
import EmailVerification from '../pages/EmailVerification/EmailVerification.jsx';
import BizPolls from '../pages/BizPolls/BizPolls.jsx';
import BizPollsResults from '../pages/BizPollsResults/BizPollsResults.jsx';
import Profile from '../pages/Profile/Profile.jsx';
import UnpublishedProjects from '../pages/UnpublishedProjects/UnpublishedProjects.jsx';
import OverviewPublic from '../publicPages/Overview/Overview.jsx';
import MGPRegistryPublic from '../publicPages/MGPRegistry/MGPRegistry.jsx';
import CreateProjectPublic from '../publicPages/CreateProject/CreateProject.jsx';
import Sectors from '../gmkParts/pages/Sectors/Sectors.jsx';

const routes = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      children: [
        { path: 'dashboard', element: <Overview /> },
        { path: 'projects-registry', element: <ProjRegistry /> },
        { path: 'curators', element: <Curators /> },
        { path: 'investments', element: <Investments /> },
        { path: 'view-subject/:id', element: <ViewSubject /> },
        { path: 'view-project/:id', element: <ViewProject /> },
        { path: 'map', element: <MapWork /> },
        { path: 'edit-project', element: <EditList /> },
        { path: 'edit-subject', element: <EditSubjectsList /> },
        { path: 'edit-project/:id', element: <EditProjectById /> },
        { path: 'edit-subject/:id', element: <EditSubjectById /> },
        { path: 'create-project', element: <CreateProject /> },
        { path: 'create-subject', element: <CreateSubject /> },
        { path: 'foreign-investments', element: <ForeignInvestments /> },
        { path: 'projects-map', element: <ProjectsMap /> },
        { path: 'organizations', element: <OrganizationsDashboard /> },
        { path: 'view-manufacturer/:id', element: <ManufacturerInfo /> },
        { path: 'view-organization/:id', element: <OrganizationInfo /> },
        { path: 'okeds-priority', element: <OkedsPriority /> },
        { path: 'mgp', element: <MGPEdit /> },
        { path: 'mgp-registry', element: <MGPRegistry /> },
        { path: 'invest-requests', element: <InvestRequests /> },
        { path: 'polls-results', element: <BizPollsResults /> },
        { path: 'my-profile', element: <Profile /> },
        { path: 'project-requests', element: <UnpublishedProjects /> },
        { path: 'dashboard-public', element: <OverviewPublic /> },
        { path: 'mgp-registry-public', element: <MGPRegistryPublic /> },
        { path: 'create-project-public', element: <CreateProjectPublic /> },
        { path: '/sectors', element: <Sectors /> },
        { path: '/sectors/:sector', element: <Sectors /> },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    { path: '/reg-request', element: <RegRequest /> },
    { path: '/users/verify/:token', element: <EmailVerification /> },
    { path: '/polls/:type', element: <BizPolls /> },
    { path: '/polls-year/:type', element: <BizPolls year /> },
  ],
  { basename: '/' }
);

export default routes;
