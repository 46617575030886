import { Form, Modal, Select, Skeleton } from 'antd';
import { useStore, useUnit } from 'effector-react';
import { useEffect, useMemo } from 'react';
import {
  $okedsModalState,
  $okedsSelect,
  $selectedOked,
  createOkedEv,
  editOkedEv,
  EditOkedGate,
  getOkedByIdFx,
  resetOkedsModalEv,
  $regionsSelectByUser,
  $districtsSelectByUser,
  $republicanCities,
} from '../../models/okedsPriorityModel/index.js';

export default function OkedPriorityModal() {
  const { open, type, id } = useUnit($okedsModalState);
  const edit = useUnit($selectedOked);
  const loading = useUnit(getOkedByIdFx.pending);

  const submitCreate = useUnit(createOkedEv);
  const submitEdit = useUnit(editOkedEv);
  const close = useUnit(resetOkedsModalEv);

  const [okedForm] = Form.useForm();

  useEffect(() => {
    if (type === 'edit' && edit) {
      okedForm.setFieldsValue(edit);
    }
  }, [type, edit]);

  const onClose = () => {
    okedForm.resetFields();
    close();
  };

  const regionsSelect = useStore($regionsSelectByUser);
  const republicanCities = useStore($republicanCities);
  const region_id = Form.useWatch('region_id', okedForm);
  const isRepublican = republicanCities?.includes(region_id);

  useEffect(() => {
    if (isRepublican) {
      okedForm.setFieldsValue({ district_id: null });
    }
  }, [isRepublican]);

  const districts = useStore($districtsSelectByUser);
  const districtsSelect = useMemo(() => {
    if (!region_id) {
      return districts ?? [];
    }
    return districts?.filter((i) => i.region_id === region_id) ?? [];
  }, [districts, region_id]);

  const okedsSelect = useUnit($okedsSelect);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFinish = (values) => {
    const payload = isRepublican
      ? { ...values, district_id: districtsSelect[0]?.value }
      : values;
    if (type === 'create') {
      submitCreate(payload);
    } else if (type === 'edit') {
      submitEdit({ id, payload });
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      okText="Сохранить"
      cancelText="Отмена"
      onOk={() => okedForm.submit()}
      onCancel={onClose}
      title={
        type === 'create'
          ? 'Добавить приоритет ОКЭД'
          : 'Изменить приоритет ОКЭД'
      }
    >
      {type === 'edit' && id && <EditOkedGate id={id} />}
      {type === 'edit' && loading ? (
        <Skeleton />
      ) : (
        <Form form={okedForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="region_id"
            label="Регион"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={regionsSelect}
              filterOption={filterOption}
              allowClear
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="district_id"
            label="Район"
            rules={[{ required: !isRepublican, message: 'Обязательное поле' }]}
          >
            <Select
              options={districtsSelect}
              filterOption={filterOption}
              allowClear
              showSearch
              disabled={isRepublican}
            />
          </Form.Item>
          <Form.Item
            name="activity_id"
            label="ОКЭД"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={okedsSelect}
              filterOption={filterOption}
              allowClear
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="note"
            label="Приоритет"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={[
                { label: 'Сильная отрасль', value: 'Сильная отрасль' },
                {
                  label: 'Перспективная отрасль',
                  value: 'Перспективная отрасль',
                },
              ]}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
