import React, { useMemo } from 'react';
import { Col, Row, Space } from 'antd';
import { useUnit } from 'effector-react/effector-react.mjs';
import { Column, Pie } from '@ant-design/charts';
import style from './MGPRegistry.module.css';
import { $nmpData, NMPGate } from '../../models/mgpModel/index.js';
import NMPFilters from './components/NMPFilters.jsx';
import { formatNumber } from '../../utils/number-manipulation.js';

function NmpRegistry() {
  const nmpData = useUnit($nmpData);

  const [consultation_count, consultation_measure] = useMemo(() => {
    return nmpData?.consultation_count
      ? formatNumber(nmpData.consultation_count).split(' ')
      : [0, ''];
  }, [nmpData]);
  const [client_count, client_measure] = useMemo(() => {
    return nmpData?.client_count
      ? formatNumber(nmpData.client_count).split(' ')
      : [0, ''];
  }, [nmpData]);

  const pieData = useMemo(() => {
    return nmpData?.opf
      ? Object.keys(nmpData.opf).map((item) => {
          return {
            type: item,
            value: nmpData.opf[item],
          };
        })
      : [];
  }, [nmpData]);

  const taxesColumnData = useMemo(() => {
    return nmpData?.tax
      ? Object.keys(nmpData.tax).map((item) => {
          return {
            type:
              item === 'before'
                ? 'Сумма уплаченных налогов до получения МГП'
                : 'Сумма уплаченных налогов после получения МГП',
            value: +nmpData.tax[item],
          };
        })
      : [];
  }, [nmpData]);

  const peopleColumnData = useMemo(() => {
    return nmpData?.people
      ? Object.keys(nmpData.people).map((item) => {
          return {
            type:
              item === 'before'
                ? 'Кол-во сотрудников до получения МГП'
                : 'Кол-во сотрудников после получения МГП',
            value: nmpData.people[item],
          };
        })
      : [];
  }, [nmpData]);

  const taxPercent = useMemo(() => {
    if (!nmpData?.tax) return 0;
    const { before, after } = nmpData.tax || {};

    return (((after - before) / before) * 100).toFixed(2);
  }, [nmpData]);

  const peoplePercent = useMemo(() => {
    if (!nmpData?.people) return 0;
    const { before, after } = nmpData.people || {};

    return (((after - before) / before) * 100).toFixed(2);
  }, [nmpData]);

  return (
    <>
      <NMPGate />
      <div className={style.nmp_title}>
        Первый уровень данных{' '}
        <div className={style.text_recolor_blue}>
          Сведения о получателях МГП
        </div>
      </div>
      <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={8}>
          <div className={style.info_text}>
            <div className={style.text_recolor_yellow}>
              {consultation_count}
            </div>{' '}
            {consultation_measure}
            услуг и консультаций для
          </div>
          <div className={style.info_text}>
            <div className={style.text_recolor_yellow}>{client_count}</div>{' '}
            {client_measure}
            клиентов
          </div>

          <div
            className={[style.info_text, style.text_recolor_blue].join(' ')}
            style={{
              textAlign: 'center',
              display: 'block',
              marginTop: 20,
              fontWeight: 600,
            }}
          >
            Составе МСБ по ОПФ
          </div>
          <div className={style.pie_title}>ОПФ Клиентов</div>
          <Pie
            angleField="value"
            colorField="type"
            data={pieData}
            label={{
              type: 'inner',
            }}
          />
        </Col>
        <Col span={16}>
          <NMPFilters />
          <div className={style.columns_container}>
            <div style={{ width: '45%' }}>
              <div
                className={[style.text_recolor_blue, style.column_title].join(
                  ' '
                )}
              >
                Динамика налоговых отчислений
              </div>
              <Column xField="type" yField="value" data={taxesColumnData} />
              <div
                className={[
                  style.column_percent,
                  taxPercent > 0 ? style.percent_pos : style.percent_neg,
                ].join(' ')}
              >
                {taxPercent}%
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div
                className={[style.text_recolor_blue, style.column_title].join(
                  ' '
                )}
              >
                Динамика трудовых ресурсов
              </div>
              <Column xField="type" yField="value" data={peopleColumnData} />
              <div
                className={[
                  style.column_percent,
                  peoplePercent > 0 ? style.percent_pos : style.percent_neg,
                ].join(' ')}
              >
                {peoplePercent}%
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={style.footer}>
        <div className={[style.text_recolor_blue, style.footer_text].join(' ')}>
          Физические лица зарегистрировавшие субъект МСБ после получения МГП
        </div>
        <div className={style.footer_nums}>
          <div className={style.phys_count}>{nmpData?.ip_grow?.count}</div>
          <div className={style.phys_percent}>
            {formatNumber(nmpData?.ip_grow?.percent)}%
          </div>
        </div>
      </div>
    </>
  );
}

export default NmpRegistry;
