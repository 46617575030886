export const publicOkedTree = [
	{
		"title": "Сельское, лесное и рыбное хозяйство",
		"value": 19,
		"level": 1,
		"children": [
			{
				"title": "Растениеводство и животноводство, охота и предоставление услуг в этих областях",
				"value": 20,
				"level": 2,
				"children": [
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 21,
						"level": 3,
						"children": [
							{
								"title": "Выращивание зерновых (кроме риса), бобовых и масличных культур",
								"value": 22,
								"level": 4,
								"children": [
									{
										"title": "Выращивание зерновых и зернобобовых культур, включая семеноводство",
										"value": 23,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание масличных культур и их семян",
										"value": 24,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание риса",
								"value": 25,
								"level": 4,
								"children": [
									{
										"title": "Выращивание риса",
										"value": 26,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание овощей, бахчевых, корнеплодов и клубнеплодов",
								"value": 27,
								"level": 4,
								"children": [
									{
										"title": "Выращивание картофеля и посадочного материала",
										"value": 28,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание овощей, их семян и рассады",
										"value": 29,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание сахарной свеклы и ее семян",
										"value": 30,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание сахарного тростника",
								"value": 31,
								"level": 4,
								"children": [
									{
										"title": "Выращивание сахарного тростника",
										"value": 32,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание табака",
								"value": 33,
								"level": 4,
								"children": [
									{
										"title": "Выращивание табака",
										"value": 34,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание прядильных культур",
								"value": 35,
								"level": 4,
								"children": [
									{
										"title": "Выращивание хлопка-сырца",
										"value": 36,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание прядильных культур и их семян",
										"value": 37,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание прочих одно- или двухлетних культур",
								"value": 38,
								"level": 4,
								"children": [
									{
										"title": "Выращивание кормовых культур и их семян",
										"value": 39,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание цветов, семеноводство цветочных культур",
										"value": 40,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выращивание прочих одно- или двухлетних культур, не включенных в другие группировки",
										"value": 41,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 42,
						"level": 3,
						"children": [
							{
								"title": "Выращивание винограда",
								"value": 43,
								"level": 4,
								"children": [
									{
										"title": "Выращивание винограда",
										"value": 44,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание тропических и субтропических плодов",
								"value": 45,
								"level": 4,
								"children": [
									{
										"title": "Выращивание тропических и субтропических плодов",
										"value": 46,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание цитрусовых плодов",
								"value": 47,
								"level": 4,
								"children": [
									{
										"title": "Выращивание цитрусовых плодов",
										"value": 48,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание семечковых и косточковых плодов",
								"value": 49,
								"level": 4,
								"children": [
									{
										"title": "Выращивание семечковых и косточковых плодов",
										"value": 50,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание прочих плодов, ягод и орехов",
								"value": 51,
								"level": 4,
								"children": [
									{
										"title": "Выращивание прочих плодов, ягод и орехов",
										"value": 52,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание маслосодержащих плодов",
								"value": 53,
								"level": 4,
								"children": [
									{
										"title": "Выращивание маслосодержащих плодов",
										"value": 54,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание культур для производства напитков",
								"value": 55,
								"level": 4,
								"children": [
									{
										"title": "Выращивание культур для производства напитков",
										"value": 56,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание специй, пряностей, лекарственных и используемых в парфюмерии растений",
								"value": 57,
								"level": 4,
								"children": [
									{
										"title": "Выращивание специй, пряностей, лекарственных и используемых в парфюмерии растений",
										"value": 58,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Выращивание прочих многолетних культур",
								"value": 59,
								"level": 4,
								"children": [
									{
										"title": "Выращивание прочих многолетних культур",
										"value": 60,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 61,
						"level": 3,
						"children": [
							{
								"title": "Воспроизводство растений",
								"value": 62,
								"level": 4,
								"children": [
									{
										"title": "Воспроизводство растений",
										"value": 63,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 64,
						"level": 3,
						"children": [
							{
								"title": "Разведение крупного рогатого скота молочного направления",
								"value": 65,
								"level": 4,
								"children": [
									{
										"title": "Разведение крупного рогатого скота молочного направления",
										"value": 66,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение прочего крупного рогатого скота и буйволов",
								"value": 67,
								"level": 4,
								"children": [
									{
										"title": "Разведение прочего крупного рогатого скота и буйволов",
										"value": 68,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение лошадей и прочих животных семейства лошадиных ",
								"value": 69,
								"level": 4,
								"children": [
									{
										"title": "Разведение лошадей",
										"value": 70,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Разведение и выращивание ослов, мулов или лошаков",
										"value": 71,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение верблюдов и прочих животных семейства верблюжьих",
								"value": 72,
								"level": 4,
								"children": [
									{
										"title": "Разведение верблюдов и прочих животных семейства верблюжьих",
										"value": 73,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение овец и коз",
								"value": 74,
								"level": 4,
								"children": [
									{
										"title": "Разведение овец и коз",
										"value": 75,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение свиней",
								"value": 76,
								"level": 4,
								"children": [
									{
										"title": "Разведение свиней",
										"value": 77,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение сельскохозяйственной птицы",
								"value": 78,
								"level": 4,
								"children": [
									{
										"title": "Разведение птицы на мясо, племенной птицы и молодняка",
										"value": 79,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство яиц",
										"value": 80,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство смешанное, мясояичное",
										"value": 81,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность инкубаторно-птицеводческих станций",
										"value": 82,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разведение прочих видов животных",
								"value": 83,
								"level": 4,
								"children": [
									{
										"title": "Разведение прочих видов животных",
										"value": 84,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 85,
						"level": 3,
						"children": [
							{
								"title": "Смешанное сельское хозяйство",
								"value": 86,
								"level": 4,
								"children": [
									{
										"title": "Смешанное сельское хозяйство",
										"value": 87,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 88,
						"level": 3,
						"children": [
							{
								"title": "Деятельность, способствующая растениеводству",
								"value": 89,
								"level": 4,
								"children": [
									{
										"title": "Деятельность, способствующая растениеводству, кроме эксплуатации оросительных систем",
										"value": 90,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Эксплуатация оросительных систем",
										"value": 91,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность, способствующая животноводству",
								"value": 92,
								"level": 4,
								"children": [
									{
										"title": "Деятельность, способствующая животноводству",
										"value": 93,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по обработке урожая",
								"value": 94,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по обработке урожая",
										"value": 95,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по обработке семян для посадки",
								"value": 96,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по обработке семян для посадки",
										"value": 97,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Выращивание одно- или двухлетних культур",
						"value": 98,
						"level": 3,
						"children": [
							{
								"title": "Охота и отлов, включая предоставление услуг в этих областях",
								"value": 99,
								"level": 4,
								"children": [
									{
										"title": "Охота и отлов, включая предоставление услуг в этих областях",
										"value": 100,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Лесоводство и лесозаготовки",
				"value": 101,
				"level": 2,
				"children": [
					{
						"title": "Лесоводство и прочая лесохозяйственная деятельность",
						"value": 102,
						"level": 3,
						"children": [
							{
								"title": "Лесоводство и прочая лесохозяйственная деятельность",
								"value": 103,
								"level": 4,
								"children": [
									{
										"title": "Лесоводство и прочая лесохозяйственная деятельность",
										"value": 104,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Лесоводство и прочая лесохозяйственная деятельность",
						"value": 105,
						"level": 3,
						"children": [
							{
								"title": "Лесозаготовки",
								"value": 106,
								"level": 4,
								"children": [
									{
										"title": "Лесозаготовки",
										"value": 107,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Лесоводство и прочая лесохозяйственная деятельность",
						"value": 108,
						"level": 3,
						"children": [
							{
								"title": "Сбор дикорастущей недревесной продукции",
								"value": 109,
								"level": 4,
								"children": [
									{
										"title": "Сбор дикорастущей недревесной продукции",
										"value": 110,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Лесоводство и прочая лесохозяйственная деятельность",
						"value": 111,
						"level": 3,
						"children": [
							{
								"title": "Услуги, связанные с лесоводство и лесозаготовками",
								"value": 112,
								"level": 4,
								"children": [
									{
										"title": "Услуги, связанные с лесоводство и лесозаготовками",
										"value": 113,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Рыболовство и рыбоводство",
				"value": 114,
				"level": 2,
				"children": [
					{
						"title": "Рыболовство",
						"value": 115,
						"level": 3,
						"children": [
							{
								"title": "Морское рыболовство",
								"value": 116,
								"level": 4,
								"children": [
									{
										"title": "Морское рыболовство",
										"value": 117,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Пресноводное рыболовство",
								"value": 118,
								"level": 4,
								"children": [
									{
										"title": "Пресноводное рыболовство",
										"value": 119,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Рыболовство",
						"value": 120,
						"level": 3,
						"children": [
							{
								"title": "Морское рыбоводство",
								"value": 121,
								"level": 4,
								"children": [
									{
										"title": "Морское рыбоводство",
										"value": 122,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Пресноводное рыбоводство",
								"value": 123,
								"level": 4,
								"children": [
									{
										"title": "Пресноводное рыбоводство",
										"value": 124,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Горнодобывающая промышленность и разработка карьеров",
		"value": 125,
		"level": 1,
		"children": [
			{
				"title": "Добыча угля",
				"value": 126,
				"level": 2,
				"children": [
					{
						"title": "Добыча каменного угля",
						"value": 127,
						"level": 3,
						"children": [
							{
								"title": "Добыча каменного угля",
								"value": 128,
								"level": 4,
								"children": [
									{
										"title": "Добыча каменного угля открытым способом",
										"value": 129,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча каменного угля подземным способом",
										"value": 130,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обогащение каменного угля",
										"value": 131,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Добыча каменного угля",
						"value": 132,
						"level": 3,
						"children": [
							{
								"title": "Добыча лигнита (бурого угля)",
								"value": 133,
								"level": 4,
								"children": [
									{
										"title": "Добыча лигнита (бурого угля) открытым способом",
										"value": 134,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча лигнита (бурого угля) подземным способом",
										"value": 135,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обогащение лигнита (бурого угля)",
										"value": 136,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Добыча сырой нефти и природного газа",
				"value": 137,
				"level": 2,
				"children": [
					{
						"title": "Добыча сырой нефти",
						"value": 138,
						"level": 3,
						"children": [
							{
								"title": "Добыча сырой нефти",
								"value": 139,
								"level": 4,
								"children": [
									{
										"title": "Добыча сырой нефти и попутного газа",
										"value": 140,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Добыча сырой нефти",
						"value": 141,
						"level": 3,
						"children": [
							{
								"title": "Добыча природного газа",
								"value": 142,
								"level": 4,
								"children": [
									{
										"title": "Добыча  природного газа, кроме метана",
										"value": 143,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча метана  из угольных месторождений",
										"value": 144,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Добыча металлических руд",
				"value": 145,
				"level": 2,
				"children": [
					{
						"title": "Добыча железных руд",
						"value": 146,
						"level": 3,
						"children": [
							{
								"title": "Добыча железных руд",
								"value": 147,
								"level": 4,
								"children": [
									{
										"title": "Добыча железных руд подземным способом",
										"value": 148,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча железных руд открытым способом",
										"value": 149,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обогащение и агломерация железных руд",
										"value": 150,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Добыча железных руд",
						"value": 151,
						"level": 3,
						"children": [
							{
								"title": "Добыча урановой и ториевой руд",
								"value": 152,
								"level": 4,
								"children": [
									{
										"title": "Добыча урановой и ториевой руд",
										"value": 153,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Добыча прочих металлических руд",
								"value": 154,
								"level": 4,
								"children": [
									{
										"title": "Добыча и обогащение алюминийсодержащего сырья",
										"value": 155,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение медной руды",
										"value": 156,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение свинцово-цинковой руды",
										"value": 157,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение никель-кобальтовых руд",
										"value": 158,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение титаномагниевого сырья (руды)",
										"value": 159,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение оловянной руды",
										"value": 160,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение сурьмяно-ртутных руд",
										"value": 161,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча драгоценных металлов и руд редких металлов",
										"value": 162,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение прочих металлических руд, не включенных в другие группировки",
										"value": 163,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Добыча прочих полезных ископаемых",
				"value": 164,
				"level": 2,
				"children": [
					{
						"title": "Добыча камня, песка и глины",
						"value": 165,
						"level": 3,
						"children": [
							{
								"title": "Добыча отделочного и строительного камня, известняка, гипса, мела и сланцев",
								"value": 166,
								"level": 4,
								"children": [
									{
										"title": "Добыча отделочного и строительного камня",
										"value": 167,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча известняка, гипса и мела",
										"value": 168,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча сланцев",
										"value": 169,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разработка гравийных и песчаных карьеров, добыча глины и каолина",
								"value": 170,
								"level": 4,
								"children": [
									{
										"title": "Разработка гравийных и песчаных карьеров",
										"value": 171,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча глины и каолина",
										"value": 172,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Добыча камня, песка и глины",
						"value": 173,
						"level": 3,
						"children": [
							{
								"title": "Добыча минерального сырья для химической промышленности и производства удобрений",
								"value": 174,
								"level": 4,
								"children": [
									{
										"title": "Добыча минерального сырья для химической промышленности и производства удобрений",
										"value": 175,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Добыча торфа",
								"value": 176,
								"level": 4,
								"children": [
									{
										"title": "Добыча торфа",
										"value": 177,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Добыча соли",
								"value": 178,
								"level": 4,
								"children": [
									{
										"title": "Добыча соли",
										"value": 179,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Добыча прочих полезных ископаемых, не включенных в другие группировки",
								"value": 180,
								"level": 4,
								"children": [
									{
										"title": "Добыча асбестовой руды",
										"value": 181,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча и обогащение неметаллических руд",
										"value": 182,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча сырья для естественных и искусственных пористых заполнителей",
										"value": 183,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча исходного сырья для стекольной промышленности",
										"value": 184,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча драгоценных камней (кроме алмазов) и полудрагоценных камней, самоцветов и янтаря",
										"value": 185,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Добыча алмазов",
										"value": 186,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Предоставление услуг в горнодобывающей промышленности",
				"value": 187,
				"level": 2,
				"children": [
					{
						"title": "Предоставление услуг, способствующих добыче нефти и природного газа",
						"value": 188,
						"level": 3,
						"children": [
							{
								"title": "Предоставление услуг, способствующих добыче нефти и природного газа",
								"value": 189,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг, способствующих добыче нефти и природного газа",
										"value": 190,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление услуг, способствующих добыче нефти и природного газа",
						"value": 191,
						"level": 3,
						"children": [
							{
								"title": "Предоставление услуг, способствующих добыче других полезных ископаемых",
								"value": 192,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг, способствующих добыче других полезных ископаемых",
										"value": 193,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Обрабатывающая промышленность",
		"value": 194,
		"level": 1,
		"children": [
			{
				"title": "Производство продуктов питания",
				"value": 195,
				"level": 2,
				"children": [
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 196,
						"level": 3,
						"children": [
							{
								"title": "Переработка и консервирование мяса",
								"value": 197,
								"level": 4,
								"children": [
									{
										"title": "Переработка и консервирование мяса",
										"value": 198,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Переработка и консервирование мяса сельскохозяйственной птицы",
								"value": 199,
								"level": 4,
								"children": [
									{
										"title": "Переработка и консервирование мяса сельскохозяйственной птицы",
										"value": 200,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство продуктов из мяса и мяса сельскохозяйственной птицы",
								"value": 201,
								"level": 4,
								"children": [
									{
										"title": "Производство продуктов из мяса и мяса сельскохозяйственной птицы",
										"value": 202,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 203,
						"level": 3,
						"children": [
							{
								"title": "Переработка и консервирование рыбы, ракообразных и моллюсков",
								"value": 204,
								"level": 4,
								"children": [
									{
										"title": "Переработка и консервирование рыбы, ракообразных и моллюсков",
										"value": 205,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 206,
						"level": 3,
						"children": [
							{
								"title": "Переработка и консервирование картофеля",
								"value": 207,
								"level": 4,
								"children": [
									{
										"title": "Переработка и консервирование картофеля",
										"value": 208,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство фруктовых и овощных соков",
								"value": 209,
								"level": 4,
								"children": [
									{
										"title": "Производство фруктовых и овощных соков",
										"value": 210,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие виды переработки и консервирования фруктов и овощей",
								"value": 211,
								"level": 4,
								"children": [
									{
										"title": "Прочие виды переработки и консервирования фруктов и овощей",
										"value": 212,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 213,
						"level": 3,
						"children": [
							{
								"title": "Производство масел и жиров",
								"value": 214,
								"level": 4,
								"children": [
									{
										"title": "Производство неочищенных масел и жиров",
										"value": 215,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство рафинированных масел и жиров",
										"value": 216,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство маргарина и аналогичных пищевых жиров",
								"value": 217,
								"level": 4,
								"children": [
									{
										"title": "Производство маргарина и аналогичных пищевых жиров",
										"value": 218,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 219,
						"level": 3,
						"children": [
							{
								"title": "Переработка молока и производство сыров",
								"value": 220,
								"level": 4,
								"children": [
									{
										"title": "Переработка молока, кроме консервирования, и производство сыров",
										"value": 221,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство молочных консервов",
										"value": 222,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство мороженого",
								"value": 223,
								"level": 4,
								"children": [
									{
										"title": "Производство мороженого",
										"value": 224,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 225,
						"level": 3,
						"children": [
							{
								"title": "Производство  мукомольно-крупяных продуктов",
								"value": 226,
								"level": 4,
								"children": [
									{
										"title": "Производство муки",
										"value": 227,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство круп",
										"value": 228,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство пищевых концентратов",
										"value": 229,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство крахмала и продукции из крахмала",
								"value": 230,
								"level": 4,
								"children": [
									{
										"title": "Производство крахмала и продукции из крахмала",
										"value": 231,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 232,
						"level": 3,
						"children": [
							{
								"title": "Производство хлебобулочных и мучных кондитерских изделий недлительного хранения",
								"value": 233,
								"level": 4,
								"children": [
									{
										"title": "Производство хлебобулочных и мучных кондитерских изделий недлительного хранения",
										"value": 234,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство сухарей и печенья, мучных кондитерских изделий длительного хранения",
								"value": 235,
								"level": 4,
								"children": [
									{
										"title": "Производство сухарей и печенья, мучных кондитерских изделий длительного хранения",
										"value": 236,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство макаронных изделий",
								"value": 237,
								"level": 4,
								"children": [
									{
										"title": "Производство макаронных изделий",
										"value": 238,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 239,
						"level": 3,
						"children": [
							{
								"title": "Производство сахара",
								"value": 240,
								"level": 4,
								"children": [
									{
										"title": "Производство сахара",
										"value": 241,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство какао, шоколада и сахаристых кондитерских изделий",
								"value": 242,
								"level": 4,
								"children": [
									{
										"title": "Производство какао, шоколада и сахаристых кондитерских изделий",
										"value": 243,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство чая и кофе",
								"value": 244,
								"level": 4,
								"children": [
									{
										"title": "Производство чая и кофе",
										"value": 245,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство пряностей и приправ",
								"value": 246,
								"level": 4,
								"children": [
									{
										"title": "Производство пряностей и приправ",
										"value": 247,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство готовых пищевых продуктов",
								"value": 248,
								"level": 4,
								"children": [
									{
										"title": "Производство готовых пищевых продуктов",
										"value": 249,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство детского питания и диетических пищевых продуктов",
								"value": 250,
								"level": 4,
								"children": [
									{
										"title": "Производство детского питания и диетических пищевых продуктов",
										"value": 251,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих продуктов питания, не включенных в другие группировки",
								"value": 252,
								"level": 4,
								"children": [
									{
										"title": "Производство супов, бульонов и яйцепродуктов",
										"value": 253,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство дрожжей",
										"value": 254,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих пищевых продуктов, не включенных в другие группировки",
										"value": 255,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Переработка и консервирование мяса и производство мясной продукции",
						"value": 256,
						"level": 3,
						"children": [
							{
								"title": "Производство готовых кормов для сельскохозяйственных животных",
								"value": 257,
								"level": 4,
								"children": [
									{
										"title": "Производство готовых кормов для сельскохозяйственных животных",
										"value": 258,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство готовых кормов для домашних животных",
								"value": 259,
								"level": 4,
								"children": [
									{
										"title": "Производство готовых кормов для домашних животных",
										"value": 260,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство напитков",
				"value": 261,
				"level": 2,
				"children": [
					{
						"title": "Производство напитков",
						"value": 262,
						"level": 3,
						"children": [
							{
								"title": "Дистилляция, ректификация и смешивание спиртных напитков",
								"value": 263,
								"level": 4,
								"children": [
									{
										"title": "Дистилляция, ректификация и смешивание спиртных напитков",
										"value": 264,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство вина из винограда",
								"value": 265,
								"level": 4,
								"children": [
									{
										"title": "Производство вина из винограда собственного производства",
										"value": 266,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство вина из винограда несобственного производства",
										"value": 267,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство сидра и прочих плодовых вин",
								"value": 268,
								"level": 4,
								"children": [
									{
										"title": "Производство сидра и прочих плодовых вин",
										"value": 269,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих недистиллированных напитков из сброженного материала",
								"value": 270,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих недистиллированных напитков из сброженного материала",
										"value": 271,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство пива",
								"value": 272,
								"level": 4,
								"children": [
									{
										"title": "Производство пива",
										"value": 273,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство солода",
								"value": 274,
								"level": 4,
								"children": [
									{
										"title": "Производство солода",
										"value": 275,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство безалкогольных напитков, минеральных вод и других вод в бутылках",
								"value": 276,
								"level": 4,
								"children": [
									{
										"title": "Производство безалкогольных напитков, минеральных вод и других вод в бутылках",
										"value": 277,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство табачных изделий",
				"value": 278,
				"level": 2,
				"children": [
					{
						"title": "Производство табачных изделий",
						"value": 279,
						"level": 3,
						"children": [
							{
								"title": "Производство табачных изделий",
								"value": 280,
								"level": 4,
								"children": [
									{
										"title": "Ферментация табака",
										"value": 281,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство изделий из табака",
										"value": 282,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство текстильных изделий",
				"value": 283,
				"level": 2,
				"children": [
					{
						"title": "Подготовка и прядение текстильных волокон",
						"value": 284,
						"level": 3,
						"children": [
							{
								"title": "Подготовка и прядение текстильных волокон",
								"value": 285,
								"level": 4,
								"children": [
									{
										"title": "Подготовка хлопчатобумажного волокна, хлопка-волокна",
										"value": 286,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прядение хлопчатобумажного волокна, хлопка-волокна",
										"value": 287,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Подготовка шерстяного волокна",
										"value": 288,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прядение шерстяного волокна",
										"value": 289,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Подготовка льняного и прочих растительных волокон",
										"value": 290,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прядение льняного и прочих растительных волокон",
										"value": 291,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Подготовка шелкового волокна",
										"value": 292,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство шелковой пряжи",
										"value": 293,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство швейных ниток",
										"value": 294,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Подготовка и прядение текстильных волокон",
						"value": 295,
						"level": 3,
						"children": [
							{
								"title": "Ткацкое производство",
								"value": 296,
								"level": 4,
								"children": [
									{
										"title": "Производство хлопчатобумажных тканей",
										"value": 297,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство шерстяных тканей",
										"value": 298,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство шелковых тканей",
										"value": 299,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство льняных тканей",
										"value": 300,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих тканей",
										"value": 301,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Подготовка и прядение текстильных волокон",
						"value": 302,
						"level": 3,
						"children": [
							{
								"title": "Отделка тканей и текстильных изделий",
								"value": 303,
								"level": 4,
								"children": [
									{
										"title": "Отделка тканей и текстильных изделий",
										"value": 304,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Подготовка и прядение текстильных волокон",
						"value": 305,
						"level": 3,
						"children": [
							{
								"title": "Производство вязаного и трикотажного полотна",
								"value": 306,
								"level": 4,
								"children": [
									{
										"title": "Производство искусственного меха",
										"value": 308,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство  и обработка вязаного и трикотажного полотна",
										"value": 307,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство готовых текстильных изделий, кроме одежды",
								"value": 309,
								"level": 4,
								"children": [
									{
										"title": "Производство готовых текстильных изделий, кроме одежды",
										"value": 310,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство ковров и ковровых изделий",
								"value": 311,
								"level": 4,
								"children": [
									{
										"title": "Производство ковров и ковровых изделий",
										"value": 312,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство веревок, канатов, бечевок, шпагата и сетей",
								"value": 313,
								"level": 4,
								"children": [
									{
										"title": "Производство веревок, канатов, бечевок, шпагата",
										"value": 314,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сетей и изделий из веревок ",
										"value": 315,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство нетканых текстильных изделий, кроме одежды",
								"value": 316,
								"level": 4,
								"children": [
									{
										"title": "Производство нетканых текстильных изделий, кроме одежды",
										"value": 317,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих текстильных изделий технического и производственного назначения",
								"value": 318,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих текстильных изделий технического и производственного назначения",
										"value": 319,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих текстильных изделий, не включенных в другие группировки",
								"value": 320,
								"level": 4,
								"children": [
									{
										"title": "Производство текстильной галантереи",
										"value": 321,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство валяльно-войлочных изделий",
										"value": 322,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство других текстильных изделий, не включенных в другие группировки",
										"value": 323,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство одежды",
				"value": 324,
				"level": 2,
				"children": [
					{
						"title": "Производство одежды, кроме одежды из меха",
						"value": 325,
						"level": 3,
						"children": [
							{
								"title": "Производство одежды из кожи",
								"value": 326,
								"level": 4,
								"children": [
									{
										"title": "Производство одежды из кожи",
										"value": 327,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство спецодежды",
								"value": 328,
								"level": 4,
								"children": [
									{
										"title": "Производство спецодежды",
										"value": 329,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочей верхней одежды",
								"value": 330,
								"level": 4,
								"children": [
									{
										"title": "Производство прочей верхней одежды",
										"value": 331,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство нижнего белья",
								"value": 332,
								"level": 4,
								"children": [
									{
										"title": "Производство нижнего белья",
										"value": 333,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих видов одежды и аксессуаров",
								"value": 334,
								"level": 4,
								"children": [
									{
										"title": "Производство головных уборов",
										"value": 335,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих видов одежды и аксессуаров, не включенных в другие группировки",
										"value": 336,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство одежды, кроме одежды из меха",
						"value": 337,
						"level": 3,
						"children": [
							{
								"title": "Производство меховых изделий",
								"value": 338,
								"level": 4,
								"children": [
									{
										"title": "Производство меховых изделий",
										"value": 339,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство одежды, кроме одежды из меха",
						"value": 340,
						"level": 3,
						"children": [
							{
								"title": "Производство вязаных и трикотажных чулочно-носочных  изделий",
								"value": 341,
								"level": 4,
								"children": [
									{
										"title": "Производство вязаных и трикотажных чулочно-носочных  изделий",
										"value": 342,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочей вязаной и трикотажной одежды",
								"value": 343,
								"level": 4,
								"children": [
									{
										"title": "Производство прочей вязаной и трикотажной одежды",
										"value": 344,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство кожаной и относящейся к ней продукции",
				"value": 345,
				"level": 2,
				"children": [
					{
						"title": "Дубление и выделка кожи; производство дорожных принадлежностей, шорно-седельных изделий; выделка и крашение меха",
						"value": 346,
						"level": 3,
						"children": [
							{
								"title": "Дубление и выделка кожи; выделка и крашение меха",
								"value": 347,
								"level": 4,
								"children": [
									{
										"title": "Дубление и выделка кожи",
										"value": 348,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство искусственных кож и пленочных материалов",
										"value": 349,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Выделка и крашение меха",
										"value": 350,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство дорожных принадлежностей, шорно-седельных изделий",
								"value": 351,
								"level": 4,
								"children": [
									{
										"title": "Производство дорожных принадлежностей и аналогичных изделий",
										"value": 352,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство шорно-седельных изделий",
										"value": 353,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дубление и выделка кожи; производство дорожных принадлежностей, шорно-седельных изделий; выделка и крашение меха",
						"value": 354,
						"level": 3,
						"children": [
							{
								"title": "Производство обуви",
								"value": 355,
								"level": 4,
								"children": [
									{
										"title": "Производство обуви",
										"value": 356,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство деревянных и пробковых изделий, кроме мебели; производство изделий из соломки и материалов для плетения",
				"value": 357,
				"level": 2,
				"children": [
					{
						"title": "Лесопильное и строгальное производство",
						"value": 358,
						"level": 3,
						"children": [
							{
								"title": "Лесопильное и строгальное производство",
								"value": 359,
								"level": 4,
								"children": [
									{
										"title": "Производство сборных деревянных покрытий",
										"value": 362,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Распиловка и строгание древесины",
										"value": 360,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Антисептирование древесины",
										"value": 361,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Лесопильное и строгальное производство",
						"value": 363,
						"level": 3,
						"children": [
							{
								"title": "Производство шпона, фанеры, плит и панелей из древесины",
								"value": 364,
								"level": 4,
								"children": [
									{
										"title": "Производство шпона, фанеры, плит и панелей из древесины",
										"value": 365,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство сборных паркетных покрытий",
								"value": 366,
								"level": 4,
								"children": [
									{
										"title": "Производство сборных паркетных покрытий",
										"value": 367,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих деревянных строительных конструкций и столярных  изделий",
								"value": 368,
								"level": 4,
								"children": [
									{
										"title": "Производство деревянных изделий для строительства",
										"value": 369,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сборных домов, преимущественно из дерева",
										"value": 370,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство деревянных контейнерных, цельноперевозных и сборно-разборных зданий и помещений",
										"value": 371,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство деревянной тары",
								"value": 372,
								"level": 4,
								"children": [
									{
										"title": "Производство деревянной тары",
										"value": 373,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих деревянных изделий; производство изделий из пробки, соломки и материалов для плетения",
								"value": 374,
								"level": 4,
								"children": [
									{
										"title": "Производство разных деревянных изделий",
										"value": 375,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство декоративных изделий из дерева",
										"value": 376,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство изделий из пробки, соломки и материалов для плетения",
										"value": 377,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство бумаги и бумажной продукции",
				"value": 378,
				"level": 2,
				"children": [
					{
						"title": "Производство древесной массы и целлюлозы, бумаги и картона",
						"value": 379,
						"level": 3,
						"children": [
							{
								"title": "Производство древесной массы и целлюлозы",
								"value": 380,
								"level": 4,
								"children": [
									{
										"title": "Производство древесной массы и целлюлозы",
										"value": 381,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство бумаги и картона",
								"value": 382,
								"level": 4,
								"children": [
									{
										"title": "Производство бумаги и картона",
										"value": 383,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство древесной массы и целлюлозы, бумаги и картона",
						"value": 384,
						"level": 3,
						"children": [
							{
								"title": "Производство гофрированной бумаги и картона, бумажной и картонной тары",
								"value": 385,
								"level": 4,
								"children": [
									{
										"title": "Производство гофрированной бумаги и картона",
										"value": 386,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство бумажной и картонной тары",
										"value": 387,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство бумажных изделий хозяйственно-бытового и санитарно-гигиенического назначения",
								"value": 388,
								"level": 4,
								"children": [
									{
										"title": "Производство бумажных изделий хозяйственно-бытового и санитарно-гигиенического назначения",
										"value": 389,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство писчебумажных изделий",
								"value": 390,
								"level": 4,
								"children": [
									{
										"title": "Производство писчебумажных изделий",
										"value": 391,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство обоев",
								"value": 392,
								"level": 4,
								"children": [
									{
										"title": "Производство обоев",
										"value": 393,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих изделий из бумаги и картона",
								"value": 394,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих изделий из бумаги и картона",
										"value": 395,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Полиграфическая деятельность и воспроизведение записанных носителей информации",
				"value": 396,
				"level": 2,
				"children": [
					{
						"title": "Полиграфическая деятельность и предоставление услуг в этой области",
						"value": 397,
						"level": 3,
						"children": [
							{
								"title": "Печатание газет",
								"value": 398,
								"level": 4,
								"children": [
									{
										"title": "Печатание газет",
										"value": 399,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие виды печатного производства",
								"value": 400,
								"level": 4,
								"children": [
									{
										"title": "Прочие виды печатного производства",
										"value": 401,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по подготовке материалов к печати и рапространению",
								"value": 402,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по подготовке материалов к печати и рапространению",
										"value": 403,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Брошюровочно-переплетная, отделочная деятельность и сопутствующие услуги",
								"value": 404,
								"level": 4,
								"children": [
									{
										"title": "Брошюровочно-переплетная, отделочная деятельность и сопутствующие услуги",
										"value": 405,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Полиграфическая деятельность и предоставление услуг в этой области",
						"value": 406,
						"level": 3,
						"children": [
							{
								"title": "Воспроизведение записанных носителей информации",
								"value": 407,
								"level": 4,
								"children": [
									{
										"title": "Воспроизведение звукозаписей",
										"value": 408,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Воспроизведение видеозаписей",
										"value": 409,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Воспроизведение программного обеспечения и баз данных",
										"value": 410,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство кокса и продуктов нефтепереработки",
				"value": 411,
				"level": 2,
				"children": [
					{
						"title": "Производство продукции коксовых печей",
						"value": 412,
						"level": 3,
						"children": [
							{
								"title": "Производство продукции коксовых печей",
								"value": 413,
								"level": 4,
								"children": [
									{
										"title": "Производство продукции коксовых печей",
										"value": 414,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство продукции коксовых печей",
						"value": 415,
						"level": 3,
						"children": [
							{
								"title": "Производство продуктов нефтепереработки,брикетов из торфа и угля",
								"value": 416,
								"level": 4,
								"children": [
									{
										"title": "Производство продуктов нефтепереработки",
										"value": 417,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Агломерация торфа и производство торфяных брикетов",
										"value": 418,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Агломерация и производство брикетов из каменного угля",
										"value": 419,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Агломерация и производство брикетов из лигнита",
										"value": 420,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство продуктов химической промышленности",
				"value": 421,
				"level": 2,
				"children": [
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 422,
						"level": 3,
						"children": [
							{
								"title": "Производство промышленных газов",
								"value": 423,
								"level": 4,
								"children": [
									{
										"title": "Производство промышленных газов",
										"value": 424,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство красителей и пигментов",
								"value": 425,
								"level": 4,
								"children": [
									{
										"title": "Производство красителей и пигментов",
										"value": 426,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих основных неорганических химических веществ",
								"value": 427,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих основных неорганических химических веществ",
										"value": 428,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих основных органических химических веществ",
								"value": 429,
								"level": 4,
								"children": [
									{
										"title": "Производство дубильных веществ",
										"value": 430,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство биоэтанола",
										"value": 431,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство ациклических и циклических углеводородов из углеводородного сырья",
										"value": 432,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство простых эфиров из углеводородного сырья",
										"value": 433,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих основных органических химических веществ, не включенных в другие группировки",
										"value": 434,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство удобрений и азотосодержащих соединений",
								"value": 435,
								"level": 4,
								"children": [
									{
										"title": "Производство удобрений",
										"value": 436,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство азотосодержащих соединений",
										"value": 437,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство пластмасс в первичных формах",
								"value": 438,
								"level": 4,
								"children": [
									{
										"title": "Производство пластмасс в первичных формах, кроме полимеров из углеводородного сырья",
										"value": 439,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство полимеров в первичных формах из углеводородного сырья",
										"value": 440,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство синтетического каучука в первичных формах",
								"value": 441,
								"level": 4,
								"children": [
									{
										"title": "Производство синтетического каучука в первичных формах",
										"value": 442,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 443,
						"level": 3,
						"children": [
							{
								"title": "Производство пестицидов и прочей агрохимической продукции",
								"value": 444,
								"level": 4,
								"children": [
									{
										"title": "Производство пестицидов и прочей агрохимической продукции",
										"value": 445,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 446,
						"level": 3,
						"children": [
							{
								"title": "Производство красок, лаков и аналогичных покрытий, типографской краски и мастик",
								"value": 447,
								"level": 4,
								"children": [
									{
										"title": "Производство красок, лаков, эмалей и минеральных пигментов для них",
										"value": 448,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство готовых растворителей и разбавителей красок и лаков",
										"value": 449,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 450,
						"level": 3,
						"children": [
							{
								"title": "Производство мыла и моющих, чистящих и полирующих средств",
								"value": 451,
								"level": 4,
								"children": [
									{
										"title": "Производство мыла и моющих, чистящих и полирующих средств",
										"value": 452,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство парфюмерных и косметических средств",
								"value": 453,
								"level": 4,
								"children": [
									{
										"title": "Производство парфюмерных и косметических средств",
										"value": 454,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 455,
						"level": 3,
						"children": [
							{
								"title": "Производство взрывчатых веществ",
								"value": 456,
								"level": 4,
								"children": [
									{
										"title": "Производство взрывчатых веществ и прочих пиротехнических изделий",
										"value": 457,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство спичек",
										"value": 458,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство клея",
								"value": 459,
								"level": 4,
								"children": [
									{
										"title": "Производство клея",
										"value": 460,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство эфирных масел",
								"value": 461,
								"level": 4,
								"children": [
									{
										"title": "Производство эфирных масел",
										"value": 462,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих химических продуктов, не включенных в другие группировки",
								"value": 463,
								"level": 4,
								"children": [
									{
										"title": "Производство фотоматериалов",
										"value": 464,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство желатина",
										"value": 465,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство материалов, используемых в отделке текстильных изделий",
										"value": 466,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство технического углерода (сажи), углеродных нанотрубок, нановолокон и других углеродных наноматериалов",
										"value": 467,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство других химических продуктов",
										"value": 468,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
						"value": 469,
						"level": 3,
						"children": [
							{
								"title": "Производство искусственных и синтетических волокон",
								"value": 470,
								"level": 4,
								"children": [
									{
										"title": "Производство искусственных и синтетических волокон",
										"value": 471,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство основных фармацевтических продуктов и фармацевтических препаратов",
				"value": 472,
				"level": 2,
				"children": [
					{
						"title": "Производство основных фармацевтических продуктов",
						"value": 473,
						"level": 3,
						"children": [
							{
								"title": "Производство основных фармацевтических продуктов",
								"value": 474,
								"level": 4,
								"children": [
									{
										"title": "Производство основных фармацевтических продуктов",
										"value": 475,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство основных фармацевтических продуктов",
						"value": 476,
						"level": 3,
						"children": [
							{
								"title": "Производство фармацевтических препаратов и медицинских материалов",
								"value": 477,
								"level": 4,
								"children": [
									{
										"title": "Производство фармацевтических препаратов",
										"value": 478,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство лекарственных препаратов для ветеринарии",
										"value": 479,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство резиновых и пластмассовых изделий",
				"value": 480,
				"level": 2,
				"children": [
					{
						"title": "Производство резиновых изделий",
						"value": 481,
						"level": 3,
						"children": [
							{
								"title": "Производство резиновых шин, покрышек и камер; восстановление резиновых шин и покрышек",
								"value": 482,
								"level": 4,
								"children": [
									{
										"title": "Производство резиновых шин, покрышек и камер",
										"value": 483,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Восстановление резиновых шин и покрышек",
										"value": 484,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих резиновых изделий",
								"value": 485,
								"level": 4,
								"children": [
									{
										"title": "Производство резинотехнических изделий",
										"value": 486,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство резиновых санитарно-гигиенических и медицинских изделий",
										"value": 487,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство резиновых изделий",
						"value": 488,
						"level": 3,
						"children": [
							{
								"title": "Производство пластмассовых плит, листов, труб и профилей",
								"value": 489,
								"level": 4,
								"children": [
									{
										"title": "Производство пластмассовых плит, листов, труб и профилей",
										"value": 490,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство пластмассовых упаковок для товаров",
								"value": 491,
								"level": 4,
								"children": [
									{
										"title": "Производство пластмассовых упаковок для товаров",
										"value": 492,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство строительных пластмассовых изделий",
								"value": 493,
								"level": 4,
								"children": [
									{
										"title": "Производство пластмассовых изделий, используемых в строительстве",
										"value": 494,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство линолеума и прочих эластичных покрытий для пола",
										"value": 495,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих пластмассовых изделий",
								"value": 496,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих пластмассовых изделий",
										"value": 497,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство прочей не металлической минеральной продукции",
				"value": 498,
				"level": 2,
				"children": [
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 499,
						"level": 3,
						"children": [
							{
								"title": "Производство листового стекла",
								"value": 500,
								"level": 4,
								"children": [
									{
										"title": "Производство листового стекла",
										"value": 501,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Формирование и обработка листового стекла",
								"value": 502,
								"level": 4,
								"children": [
									{
										"title": "Формирование и обработка листового стекла",
										"value": 503,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство полых стеклянных изделий",
								"value": 504,
								"level": 4,
								"children": [
									{
										"title": "Производство стеклянной тары",
										"value": 505,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство хозяйственно-бытовых изделий из стекла и стеклянной посуды",
										"value": 506,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство стекловолокна",
								"value": 507,
								"level": 4,
								"children": [
									{
										"title": "Производство стекловолокна",
										"value": 508,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство и обработка прочих стеклянных изделий",
								"value": 509,
								"level": 4,
								"children": [
									{
										"title": "Производство электротехнического и электровакуумного стекла",
										"value": 510,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство химико-лабораторной посуды из стекла",
										"value": 511,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство стеклянных изделий, не включенных в другие группировки",
										"value": 512,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 513,
						"level": 3,
						"children": [
							{
								"title": "Производство огнеупорных изделий",
								"value": 514,
								"level": 4,
								"children": [
									{
										"title": "Производство огнеупорных изделий",
										"value": 515,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 516,
						"level": 3,
						"children": [
							{
								"title": "Производство керамических покрытий и плит",
								"value": 517,
								"level": 4,
								"children": [
									{
										"title": "Производство керамических покрытий и плит",
										"value": 518,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство кирпича, черепицы и прочих строительных изделий из обожженной глины",
								"value": 519,
								"level": 4,
								"children": [
									{
										"title": "Производство кирпича, черепицы и прочих строительных изделий из обожженной глины",
										"value": 520,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 521,
						"level": 3,
						"children": [
							{
								"title": "Производство керамических хозяйственных и декоративных изделий",
								"value": 522,
								"level": 4,
								"children": [
									{
										"title": "Производство хозяйственно-бытовых изделий из фарфора и фаянса",
										"value": 523,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство хозяйственно-бытовых гончарных изделий",
										"value": 524,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство керамических санитарно-технических изделий",
								"value": 525,
								"level": 4,
								"children": [
									{
										"title": "Производство керамических санитарно-технических изделий",
										"value": 526,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство керамических электрических изоляторов и изолирующей арматуры",
								"value": 527,
								"level": 4,
								"children": [
									{
										"title": "Производство керамических электрических изоляторов и изолирующей арматуры",
										"value": 528,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих технических керамических изделий",
								"value": 529,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих технических керамических изделий",
										"value": 530,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих керамических изделий",
								"value": 531,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих керамических изделий",
										"value": 532,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 533,
						"level": 3,
						"children": [
							{
								"title": "Производство цемента",
								"value": 534,
								"level": 4,
								"children": [
									{
										"title": "Производство цемента",
										"value": 535,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство извести и строительного гипса",
								"value": 536,
								"level": 4,
								"children": [
									{
										"title": "Производство извести",
										"value": 537,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство известняковой и доломитовой муки",
										"value": 538,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство строительного гипса",
										"value": 539,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 540,
						"level": 3,
						"children": [
							{
								"title": "Производство строительных изделий из бетона",
								"value": 541,
								"level": 4,
								"children": [
									{
										"title": "Производство сборных железобетонных и бетонных конструкций и изделий",
										"value": 542,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство стеновых блоков",
										"value": 543,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство силикатного кирпича",
										"value": 544,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство изделий из гипса для строительных целей",
								"value": 545,
								"level": 4,
								"children": [
									{
										"title": "Производство изделий из гипса для строительных целей",
										"value": 546,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство товарного бетона",
								"value": 547,
								"level": 4,
								"children": [
									{
										"title": "Производство товарного бетона",
										"value": 548,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство асфальтобетона",
										"value": 2114,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство строительных растворов",
								"value": 549,
								"level": 4,
								"children": [
									{
										"title": "Производство строительных растворов",
										"value": 550,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство изделий из асбестоцемента и волокнистого цемента",
								"value": 551,
								"level": 4,
								"children": [
									{
										"title": "Производство изделий из асбестоцемента и волокнистого цемента",
										"value": 552,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих изделий из бетона, строительного гипса и цемента",
								"value": 553,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих изделий из бетона, строительного гипса и цемента",
										"value": 554,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 555,
						"level": 3,
						"children": [
							{
								"title": "Резка, обработка и отделка камня",
								"value": 556,
								"level": 4,
								"children": [
									{
										"title": "Резка, обработка и отделка камня",
										"value": 557,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство стекла и изделий из стекла",
						"value": 558,
						"level": 3,
						"children": [
							{
								"title": "Производство абразивных изделий",
								"value": 559,
								"level": 4,
								"children": [
									{
										"title": "Производство абразивных изделий",
										"value": 560,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочей неметаллической минеральной продукции, не включенной в другие группировки",
								"value": 561,
								"level": 4,
								"children": [
									{
										"title": "Производство изделий из неметаллических руд",
										"value": 567,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство асбестотехнических изделий",
										"value": 562,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство минеральных изоляционных материалов",
										"value": 563,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство мягких кровельных и гидроизоляционных материалов",
										"value": 564,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство искусственных и переработка естественных пористых заполнителей",
										"value": 565,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство изделий из битума и аналогичных материалов",
										"value": 566,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Металлургическое производство",
				"value": 568,
				"level": 2,
				"children": [
					{
						"title": "Производство чугуна, стали и ферросплавов",
						"value": 569,
						"level": 3,
						"children": [
							{
								"title": "Производство чугуна, стали и ферросплавов",
								"value": 570,
								"level": 4,
								"children": [
									{
										"title": "Производство чугуна, стали и ферросплавов",
										"value": 571,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство чугуна, стали и ферросплавов",
						"value": 572,
						"level": 3,
						"children": [
							{
								"title": "Производство труб, трубок, полых профилей, фитингов из стали",
								"value": 573,
								"level": 4,
								"children": [
									{
										"title": "Производство труб, трубок, полых профилей, фитингов из стали",
										"value": 574,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство чугуна, стали и ферросплавов",
						"value": 575,
						"level": 3,
						"children": [
							{
								"title": "Холодное волочение",
								"value": 576,
								"level": 4,
								"children": [
									{
										"title": "Холодное волочение",
										"value": 577,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Холодная прокатка лент и узких полос",
								"value": 578,
								"level": 4,
								"children": [
									{
										"title": "Холодная прокатка лент и узких полос",
										"value": 579,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Холодная штамповка или гибка",
								"value": 580,
								"level": 4,
								"children": [
									{
										"title": "Холодная штамповка или гибка",
										"value": 581,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство проволоки путем холодного волочения",
								"value": 582,
								"level": 4,
								"children": [
									{
										"title": "Производство проволоки путем холодного волочения",
										"value": 583,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство чугуна, стали и ферросплавов",
						"value": 584,
						"level": 3,
						"children": [
							{
								"title": "Переработка ядерного топлива",
								"value": 599,
								"level": 4,
								"children": [
									{
										"title": "Переработка ядерного топлива",
										"value": 600,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство благородных (драгоценных) металлов",
								"value": 585,
								"level": 4,
								"children": [
									{
										"title": "Производство благородных (драгоценных) металлов",
										"value": 586,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство алюминия",
								"value": 587,
								"level": 4,
								"children": [
									{
										"title": "Производство алюминия",
										"value": 588,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство свинца, цинка и олова",
								"value": 589,
								"level": 4,
								"children": [
									{
										"title": "Производство свинца, цинка и олова",
										"value": 590,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство меди",
								"value": 591,
								"level": 4,
								"children": [
									{
										"title": "Производство меди",
										"value": 592,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих цветных металлов",
								"value": 593,
								"level": 4,
								"children": [
									{
										"title": "Производство никеля и кобальта",
										"value": 594,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство титана, порошка из титана, магния, вольфрама и молибдена",
										"value": 595,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сурьмы и ртути",
										"value": 596,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обработка цветных металлов и сплавов",
										"value": 597,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство редких, редкоземельных металлов и полупроводниковых материалов",
										"value": 598,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство чугуна, стали и ферросплавов",
						"value": 601,
						"level": 3,
						"children": [
							{
								"title": "Литье чугуна",
								"value": 602,
								"level": 4,
								"children": [
									{
										"title": "Литье чугуна, кроме производства труб",
										"value": 603,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство труб из чугуна",
										"value": 604,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Литье стали",
								"value": 605,
								"level": 4,
								"children": [
									{
										"title": "Литье стали",
										"value": 606,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Литье легких металлов",
								"value": 607,
								"level": 4,
								"children": [
									{
										"title": "Литье легких металлов",
										"value": 608,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Литье прочих цветных металлов",
								"value": 609,
								"level": 4,
								"children": [
									{
										"title": "Литье прочих цветных металлов",
										"value": 610,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство готовых металлических изделий, кроме машин и оборудования",
				"value": 611,
				"level": 2,
				"children": [
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 612,
						"level": 3,
						"children": [
							{
								"title": "Производство строительных металлических конструкций и их частей",
								"value": 613,
								"level": 4,
								"children": [
									{
										"title": "Производство строительных стальных конструкций",
										"value": 614,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство легких металлических конструкций",
										"value": 615,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство строительных конструкций и изделий из алюминия и алюминиевых сплавов",
										"value": 616,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство контейнерных и сборно-разборных зданий и помещений",
										"value": 617,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство металлических дверей и окон",
								"value": 618,
								"level": 4,
								"children": [
									{
										"title": "Производство металлических дверей и окон",
										"value": 619,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 620,
						"level": 3,
						"children": [
							{
								"title": "Производство радиаторов и котлов центрального отопления",
								"value": 621,
								"level": 4,
								"children": [
									{
										"title": "Производство радиаторов и котлов центрального отопления",
										"value": 622,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих металлических цистерн, резервуаров и контейнеров",
								"value": 623,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих металлических цистерн, резервуаров и контейнеров",
										"value": 624,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 625,
						"level": 3,
						"children": [
							{
								"title": "Производство паровых котлов, кроме котлов центрального отопления",
								"value": 626,
								"level": 4,
								"children": [
									{
										"title": "Производство паровых котлов, кроме котлов центрального отопления",
										"value": 627,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 628,
						"level": 3,
						"children": [
							{
								"title": "Производство оружия и боеприпасов",
								"value": 629,
								"level": 4,
								"children": [
									{
										"title": "Производство оружия и боеприпасов",
										"value": 630,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 631,
						"level": 3,
						"children": [
							{
								"title": "Ковка, прессование, штамповка, профилирование металла; порошковая металлургия",
								"value": 632,
								"level": 4,
								"children": [
									{
										"title": "Производство готовых металлических изделий или полуфабрикатов путем ковки, прессования, штамповки и профилирования",
										"value": 633,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство металлических изделий методом порошковой металлургии",
										"value": 634,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 635,
						"level": 3,
						"children": [
							{
								"title": "Обработка металлов и нанесение покрытий на металлы",
								"value": 636,
								"level": 4,
								"children": [
									{
										"title": "Обработка металлов и нанесение покрытий на металлы",
										"value": 637,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Основные технологические процессы машиностроения",
								"value": 638,
								"level": 4,
								"children": [
									{
										"title": "Основные технологические процессы машиностроения",
										"value": 639,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 640,
						"level": 3,
						"children": [
							{
								"title": "Производство ножевых изделий",
								"value": 641,
								"level": 4,
								"children": [
									{
										"title": "Производство ножевых изделий",
										"value": 642,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство замков, петель и шарниров",
								"value": 643,
								"level": 4,
								"children": [
									{
										"title": "Производство замков, петель и шарниров",
										"value": 644,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство инструментов",
								"value": 645,
								"level": 4,
								"children": [
									{
										"title": "Производство металло- и деревообрабатывающего инструмента",
										"value": 646,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сельскохозяйственного и садово-огородного инвентаря",
										"value": 647,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство строительных металлических конструкций и изделий",
						"value": 648,
						"level": 3,
						"children": [
							{
								"title": "Производство металлических бочек и аналогичных емкостей",
								"value": 649,
								"level": 4,
								"children": [
									{
										"title": "Производство металлических бочек и аналогичных емкостей",
										"value": 650,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство упаковки из легких металлов",
								"value": 651,
								"level": 4,
								"children": [
									{
										"title": "Производство упаковки из легких металлов",
										"value": 652,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство изделий из проволоки, цепей и пружин",
								"value": 653,
								"level": 4,
								"children": [
									{
										"title": "Производство изделий из проволоки",
										"value": 654,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство цепей и пружин",
										"value": 655,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство крепежных и резьбовых изделий",
								"value": 656,
								"level": 4,
								"children": [
									{
										"title": "Производство крепежных и резьбовых изделий",
										"value": 657,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих готовых металлических изделий, не включенных в другие группировки",
								"value": 658,
								"level": 4,
								"children": [
									{
										"title": "Производство металлического санитарно-технического оборудования",
										"value": 659,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство хозяйственной посуды и кухонного инвентаря из металла",
										"value": 660,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих готовых металлических изделий",
										"value": 661,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство компьютеров, электронного и оптического оборудования",
				"value": 662,
				"level": 2,
				"children": [
					{
						"title": "Производство электронных элементов и плат",
						"value": 663,
						"level": 3,
						"children": [
							{
								"title": "Производство электронных элементов",
								"value": 664,
								"level": 4,
								"children": [
									{
										"title": "Производство электронных элементов",
										"value": 665,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство электронных плат",
								"value": 666,
								"level": 4,
								"children": [
									{
										"title": "Производство электронных плат",
										"value": 667,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 668,
						"level": 3,
						"children": [
							{
								"title": "Производство компьютеров и периферийного оборудования",
								"value": 669,
								"level": 4,
								"children": [
									{
										"title": "Производство компьютеров и периферийного оборудования",
										"value": 670,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 671,
						"level": 3,
						"children": [
							{
								"title": "Производство коммуникационного оборудования",
								"value": 672,
								"level": 4,
								"children": [
									{
										"title": "Производство теле- и радиоаппаратуры производственного назначения",
										"value": 673,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство аппаратуры для кабельной телефонной и телеграфной связи",
										"value": 674,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 675,
						"level": 3,
						"children": [
							{
								"title": "Производство электронной бытовой техники",
								"value": 676,
								"level": 4,
								"children": [
									{
										"title": "Производство электронной бытовой техники",
										"value": 677,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 678,
						"level": 3,
						"children": [
							{
								"title": "Производство инструментов и приборов для измерения, тестирования и навигации",
								"value": 679,
								"level": 4,
								"children": [
									{
										"title": "Производство приборов для измерения механических величин",
										"value": 680,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроизмерительных приборов",
										"value": 681,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство радиоизмерительных приборов",
										"value": 682,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оптических и оптико-механических приборов и оборудования",
										"value": 683,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство приборов для физических исследований",
										"value": 684,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство медико-хирургических инструментов",
										"value": 685,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для систем позиционирования и мониторинга ответственных сооружений",
										"value": 686,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство часов",
								"value": 687,
								"level": 4,
								"children": [
									{
										"title": "Производство часов всех видов",
										"value": 688,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство приборов для регистрации времени",
										"value": 689,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство деталей и принадлежностей для часов",
										"value": 690,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 691,
						"level": 3,
						"children": [
							{
								"title": "Производство облучающего, электромедицинского и электротерапевтического оборудования",
								"value": 692,
								"level": 4,
								"children": [
									{
										"title": "Производство облучающего, электромедицинского и электротерапевтического оборудования",
										"value": 693,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 694,
						"level": 3,
						"children": [
							{
								"title": "Производство оптических приборов, фото- и кинооборудования",
								"value": 695,
								"level": 4,
								"children": [
									{
										"title": "Производство оптических приборов",
										"value": 696,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство фото- и кинооборудования",
										"value": 697,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электронных элементов и плат",
						"value": 698,
						"level": 3,
						"children": [
							{
								"title": "Производство магнитных и оптических носителей информации",
								"value": 699,
								"level": 4,
								"children": [
									{
										"title": "Производство магнитных и оптических носителей информации",
										"value": 700,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство электрического оборудования",
				"value": 701,
				"level": 2,
				"children": [
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 702,
						"level": 3,
						"children": [
							{
								"title": "Производство электродвигателей, генераторов и трансформаторов",
								"value": 703,
								"level": 4,
								"children": [
									{
										"title": "Производство электродвигателей, генераторов и трансформаторов ",
										"value": 704,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство электрораспределительной и регулирующей аппаратуры",
								"value": 705,
								"level": 4,
								"children": [
									{
										"title": "Производство электрораспределительной и регулирующей аппаратуры ",
										"value": 706,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 707,
						"level": 3,
						"children": [
							{
								"title": "Производство батарей и аккумуляторов",
								"value": 708,
								"level": 4,
								"children": [
									{
										"title": "Производство батарей и аккумуляторов",
										"value": 709,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 710,
						"level": 3,
						"children": [
							{
								"title": "Производство волоконно-оптических кабелей",
								"value": 711,
								"level": 4,
								"children": [
									{
										"title": "Производство волоконно-оптических кабелей",
										"value": 712,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих электрических проводов и кабелей",
								"value": 713,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих электрических проводов и кабелей",
										"value": 714,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство электромонтажных устройств",
								"value": 715,
								"level": 4,
								"children": [
									{
										"title": "Производство электромонтажных устройств",
										"value": 716,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 717,
						"level": 3,
						"children": [
							{
								"title": "Производство электроосветительного оборудования",
								"value": 718,
								"level": 4,
								"children": [
									{
										"title": "Производство электроламп",
										"value": 719,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство осветительных приборов",
										"value": 720,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 721,
						"level": 3,
						"children": [
							{
								"title": "Производство электрических бытовых приборов",
								"value": 722,
								"level": 4,
								"children": [
									{
										"title": "Производство электрических бытовых приборов, кроме холодильников и морозильников",
										"value": 723,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство бытовых холодильников и морозильников",
										"value": 724,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство неэлектрических бытовых приборов",
								"value": 725,
								"level": 4,
								"children": [
									{
										"title": "Производство неэлектрических бытовых приборов",
										"value": 726,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
						"value": 727,
						"level": 3,
						"children": [
							{
								"title": "Производство прочего электрического оборудования",
								"value": 728,
								"level": 4,
								"children": [
									{
										"title": "Производство электродной продукции",
										"value": 729,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроизоляционных изделий",
										"value": 730,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электрического сигнального оборудования",
										"value": 731,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочего электрического оборудования, не включенного в другие группировки",
										"value": 732,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство машин и оборудования, не включенных в другие группировки",
				"value": 733,
				"level": 2,
				"children": [
					{
						"title": "Производство оборудования общего назначения",
						"value": 734,
						"level": 3,
						"children": [
							{
								"title": "Производство двигателей и турбин, кроме авиационных, автомобильных и мотоциклетных двигателей",
								"value": 735,
								"level": 4,
								"children": [
									{
										"title": "Производство двигателей",
										"value": 736,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство турбин",
										"value": 737,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство гидравлического и пневматического оборудования",
								"value": 738,
								"level": 4,
								"children": [
									{
										"title": "Производство гидравлического и пневматического оборудования",
										"value": 739,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих насосов и компрессоров",
								"value": 740,
								"level": 4,
								"children": [
									{
										"title": "Производство вакуумных и воздушных насосов",
										"value": 741,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство компрессоров",
										"value": 742,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих кранов, клапанов и вентилей",
								"value": 743,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих кранов, клапанов и вентилей",
										"value": 744,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство подшипников, зубчатых передач, элементов зубчатых передач и приводов",
								"value": 745,
								"level": 4,
								"children": [
									{
										"title": "Производство подшипников",
										"value": 746,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство зубчатых передач, элементов зубчатых передач и приводов",
										"value": 747,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство оборудования общего назначения",
						"value": 748,
						"level": 3,
						"children": [
							{
								"title": "Производство плит, печей и печных горелок",
								"value": 749,
								"level": 4,
								"children": [
									{
										"title": "Производство электрических печей",
										"value": 751,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство неэлектрических печей, горелок и устройств для печей",
										"value": 750,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство подъемно-транспортного оборудования",
								"value": 752,
								"level": 4,
								"children": [
									{
										"title": "Производство кранов (без строительных)",
										"value": 753,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство кранов для строительства",
										"value": 754,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования непрерывного транспорта",
										"value": 755,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство лифтов",
										"value": 756,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство авто- и электропогрузчиков",
										"value": 757,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочего подъемно-транспортного оборудования",
										"value": 758,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство офисной техники и оборудования (за исключением компьютеров и периферийного оборудования)",
								"value": 759,
								"level": 4,
								"children": [
									{
										"title": "Производство офисной техники и оборудования (за исключением компьютеров и периферийного оборудования)",
										"value": 760,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство ручных механизированных  инструментов",
								"value": 761,
								"level": 4,
								"children": [
									{
										"title": "Производство ручных механизированных  инструментов",
										"value": 762,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство промышленного холодильного и вентиляционного оборудования",
								"value": 763,
								"level": 4,
								"children": [
									{
										"title": "Производство холодильного или морозильного оборудования",
										"value": 764,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство кондиционеров воздуха, вентиляторов",
										"value": 765,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих машин и оборудования общего назначения, не включенных в другие группировки",
								"value": 766,
								"level": 4,
								"children": [
									{
										"title": "Производство весоизмерительного оборудования (кроме высокоточного лабораторного оборудования)",
										"value": 767,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для фильтрования и очистки",
										"value": 768,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для распыления и разбрызгивания жидкостей или порошков",
										"value": 769,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство упаковочных и оберточных машин",
										"value": 770,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования и аппаратуры для химических процессов",
										"value": 771,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство чертежных, разметочных и измерительных инструментов",
										"value": 772,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих машин и оборудования, деталей  и узлов",
										"value": 773,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство оборудования общего назначения",
						"value": 774,
						"level": 3,
						"children": [
							{
								"title": "Производство сельскохозяйственной и лесохозяйственной техники",
								"value": 775,
								"level": 4,
								"children": [
									{
										"title": "Производство сельскохозяйственных и лесохозяйственных тракторов ",
										"value": 776,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сельскохозяйственных машин",
										"value": 777,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для животноводства и кормопроизводства",
										"value": 778,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство лесозаготовительного и мелиоративного оборудования",
										"value": 779,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство оборудования общего назначения",
						"value": 780,
						"level": 3,
						"children": [
							{
								"title": "Производство прочих станков",
								"value": 786,
								"level": 4,
								"children": [
									{
										"title": "Производство станков для обработки камня, дерева и материалов твердых аналогичных",
										"value": 787,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство частей и приспособлений прочих станков",
										"value": 788,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих станков, не включенных в другие группировки",
										"value": 789,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство станков для обработки металлов ",
								"value": 781,
								"level": 4,
								"children": [
									{
										"title": "Производство станков для обработки металлов лазером и станков аналогичных",
										"value": 782,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство токарных, расточных, сверлильных и фрезерных станков",
										"value": 783,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих металлорежущих станков",
										"value": 784,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство частей и приспособлений к металлообрабатывающим станкам",
										"value": 785,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство оборудования общего назначения",
						"value": 790,
						"level": 3,
						"children": [
							{
								"title": "Производство машин и оборудования  для металлургии",
								"value": 791,
								"level": 4,
								"children": [
									{
										"title": "Производство машин и оборудования  для металлургии",
										"value": 792,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство машин и оборудования для горнодобывающей промышленности, подземной разработки и строительства",
								"value": 793,
								"level": 4,
								"children": [
									{
										"title": "Производство горношахтного и горнорудного оборудования",
										"value": 794,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство дорожных и землеройных машин",
										"value": 795,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство строительных машин",
										"value": 796,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для обработки строительных материалов",
										"value": 797,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для торфяной промышленности",
										"value": 798,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство машин и оборудования для производства и переработки продуктов питания, напитков и табачных изделий",
								"value": 799,
								"level": 4,
								"children": [
									{
										"title": "Производство  машин и оборудования для производства и переработки продуктов питания, напитков и табачных изделий  ",
										"value": 800,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство машин и  оборудования для изготовления текстильных, швейных, меховых и кожаных изделий",
								"value": 801,
								"level": 4,
								"children": [
									{
										"title": "Производство машин и оборудования для текстильной промышленности",
										"value": 802,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство машин и оборудования для швейной и трикотажной промышленности",
										"value": 803,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство швейных машин ",
										"value": 804,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство машин и оборудования для обувной, меховой, кожевенной и кожгалантерейной промышленности",
										"value": 805,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство специального оборудования для предприятий бытового обслуживания",
										"value": 806,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство оборудования для получения химического волокна",
										"value": 807,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство машин и оборудования для изготовления бумаги и картона",
								"value": 808,
								"level": 4,
								"children": [
									{
										"title": "Производство машин и оборудования для изготовления бумаги и картона",
										"value": 809,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство машин и оборудования для обработки пластмасс и резины",
								"value": 810,
								"level": 4,
								"children": [
									{
										"title": "Производство машин и оборудования для обработки пластмасс и резины",
										"value": 811,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих машин и оборудования специального назначения, не включенных в другие группировки",
								"value": 812,
								"level": 4,
								"children": [
									{
										"title": "Производство нефтепромыслового и бурового геологоразведочного оборудования",
										"value": 813,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство нефтегазоперерабатывающего оборудования",
										"value": 814,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство специального технологического оборудования для электронной промышленности",
										"value": 815,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство технологического оборудования для стекольной промышленности",
										"value": 816,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство технологического оборудования для полиграфической промышленности",
										"value": 817,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство каруселей, качелей, тиров и других аттракционов",
										"value": 818,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство других машин специального назначения",
										"value": 819,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство автомобилей, прицепов и полуприцепов",
				"value": 820,
				"level": 2,
				"children": [
					{
						"title": "Производство автомобилей",
						"value": 821,
						"level": 3,
						"children": [
							{
								"title": "Производство автомобилей",
								"value": 822,
								"level": 4,
								"children": [
									{
										"title": "Производство автомобилей, кроме двигателей для автомобилей",
										"value": 823,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство двигателей",
										"value": 824,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство автомобилей",
						"value": 825,
						"level": 3,
						"children": [
							{
								"title": "Производство кузовов для автомобилей; производство прицепов и полуприцепов",
								"value": 826,
								"level": 4,
								"children": [
									{
										"title": "Производство кузовов для автомобилей",
										"value": 827,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прицепов и полуприцепов",
										"value": 828,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство автомобилей",
						"value": 829,
						"level": 3,
						"children": [
							{
								"title": "Производство электрического и электронного оборудования для автомобилей",
								"value": 830,
								"level": 4,
								"children": [
									{
										"title": "Производство электрического и электронного оборудования для автомобилей",
										"value": 831,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих частей и принадлежностей автомобилей",
								"value": 832,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих частей и принадлежностей автомобилей",
										"value": 833,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство гаражного и автозаправочного оборудования",
										"value": 2115,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих частей и принадлежностей автотранспортных средств и их двигателей",
										"value": 2116,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство прочих транспортных средств",
				"value": 834,
				"level": 2,
				"children": [
					{
						"title": "Строительство судов и лодок",
						"value": 835,
						"level": 3,
						"children": [
							{
								"title": "Строительство судов и плавучих средств",
								"value": 836,
								"level": 4,
								"children": [
									{
										"title": "Строительство судов и плавучих средств",
										"value": 837,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Строительство прогулочных и спортивных лодок",
								"value": 838,
								"level": 4,
								"children": [
									{
										"title": "Строительство прогулочных и спортивных лодок",
										"value": 839,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство судов и лодок",
						"value": 840,
						"level": 3,
						"children": [
							{
								"title": "Производство железнодорожных локомотивов и подвижного состава",
								"value": 841,
								"level": 4,
								"children": [
									{
										"title": "Производство железнодорожных локомотивов и подвижного состава",
										"value": 842,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство судов и лодок",
						"value": 843,
						"level": 3,
						"children": [
							{
								"title": "Производство воздушных и космических летательных аппаратов и связанного с ними оборудования",
								"value": 844,
								"level": 4,
								"children": [
									{
										"title": "Производство воздушных летательных аппаратов",
										"value": 845,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство космических летательных аппаратов",
										"value": 846,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство судов и лодок",
						"value": 847,
						"level": 3,
						"children": [
							{
								"title": "Производство военных боевых транспортных средств",
								"value": 848,
								"level": 4,
								"children": [
									{
										"title": "Производство военных боевых транспортных средств",
										"value": 849,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство судов и лодок",
						"value": 850,
						"level": 3,
						"children": [
							{
								"title": "Производство мотоциклов",
								"value": 851,
								"level": 4,
								"children": [
									{
										"title": "Производство мотоциклов",
										"value": 852,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство велосипедов, детских  и инвалидных колясок/кресел",
								"value": 853,
								"level": 4,
								"children": [
									{
										"title": "Производство велосипедов",
										"value": 854,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство инвалидных колясок/кресел",
										"value": 855,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство детских колясок",
										"value": 856,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочих транспортных средств и оборудования, не включенных в другие группировки",
								"value": 857,
								"level": 4,
								"children": [
									{
										"title": "Производство прочих транспортных средств и оборудования, не включенных в другие группировки",
										"value": 858,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство мебели",
				"value": 859,
				"level": 2,
				"children": [
					{
						"title": "Производство мебели",
						"value": 860,
						"level": 3,
						"children": [
							{
								"title": "Производство мебели для офисов и предприятий торговли",
								"value": 861,
								"level": 4,
								"children": [
									{
										"title": "Производство стульев и другой мебели для сидения",
										"value": 862,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство мебели для офисов и предприятий торговли, кроме стульев и другой мебели для сидения",
										"value": 863,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство кухонной мебели",
								"value": 864,
								"level": 4,
								"children": [
									{
										"title": "Производство кухонной мебели",
										"value": 865,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство матрасов",
								"value": 866,
								"level": 4,
								"children": [
									{
										"title": "Производство матрасов",
										"value": 867,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочей мебели",
								"value": 868,
								"level": 4,
								"children": [
									{
										"title": "Производство прочей мебели",
										"value": 869,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство прочих готовых изделий",
				"value": 870,
				"level": 2,
				"children": [
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 871,
						"level": 3,
						"children": [
							{
								"title": "Чеканка/выпуск монет и медалей",
								"value": 872,
								"level": 4,
								"children": [
									{
										"title": "Чеканка/выпуск монет и медалей",
										"value": 873,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство ювелирных и аналогичных изделий",
								"value": 874,
								"level": 4,
								"children": [
									{
										"title": "Производство ювелирных и аналогичных изделий",
										"value": 875,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство бижутерии и аналогичных изделий",
								"value": 876,
								"level": 4,
								"children": [
									{
										"title": "Производство бижутерии и аналогичных изделий",
										"value": 877,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 878,
						"level": 3,
						"children": [
							{
								"title": "Производство музыкальных инструментов",
								"value": 879,
								"level": 4,
								"children": [
									{
										"title": "Производство музыкальных инструментов ",
										"value": 880,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 881,
						"level": 3,
						"children": [
							{
								"title": "Производство спортивных товаров",
								"value": 882,
								"level": 4,
								"children": [
									{
										"title": "Производство спортивных товаров",
										"value": 883,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 884,
						"level": 3,
						"children": [
							{
								"title": "Производство игр и игрушек",
								"value": 885,
								"level": 4,
								"children": [
									{
										"title": "Производство игр и игрушек",
										"value": 886,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 887,
						"level": 3,
						"children": [
							{
								"title": "Производство медицинских и стоматологических инструментов и принадлежностей",
								"value": 888,
								"level": 4,
								"children": [
									{
										"title": "Производство цементов, используемых в медицине",
										"value": 889,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство медицинских инструментов, аппаратов  и оборудования",
										"value": 890,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство хирургических и ортопедических приспособлений",
										"value": 891,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство медицинской, хирургической, стоматологической и ветеринарной мебели",
										"value": 892,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
						"value": 893,
						"level": 3,
						"children": [
							{
								"title": "Производство метел и щеток",
								"value": 894,
								"level": 4,
								"children": [
									{
										"title": "Производство метел и щеток",
										"value": 895,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Производство прочей продукции, не включенной в другие группировки",
								"value": 896,
								"level": 4,
								"children": [
									{
										"title": "Производство канцелярских изделий",
										"value": 897,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство изделий металлической галантереи",
										"value": 898,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство сувенирных изделий",
										"value": 899,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство прочих изделий",
										"value": 900,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Ремонт и установка машин и оборудования",
				"value": 901,
				"level": 2,
				"children": [
					{
						"title": "Ремонт готовых металлических изделий, машин и оборудования",
						"value": 902,
						"level": 3,
						"children": [
							{
								"title": "Ремонт готовых металлических изделий",
								"value": 903,
								"level": 4,
								"children": [
									{
										"title": "Ремонт металлических цистерн, резервуаров и контейнеров",
										"value": 904,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт радиаторов и котлов центрального отопления",
										"value": 905,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт паровых котлов, кроме котлов центрального отопления",
										"value": 906,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт огнестрельного оружия и артиллерийских орудий",
										"value": 907,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт прочих металлических изделий",
										"value": 908,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт металлических бочек и аналогичных емкостей",
										"value": 2118,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт машин и оборудования",
								"value": 909,
								"level": 4,
								"children": [
									{
										"title": "Ремонт и техническое обслуживание механического оборудования",
										"value": 910,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание прочего оборудования общего назначения",
										"value": 911,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание машин и оборудования для сельского и лесного хозяйства",
										"value": 912,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание станков",
										"value": 913,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание прочих машин и оборудования специального назначения",
										"value": 914,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание офисных машин и вычислительной техники",
										"value": 915,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт электронного и оптического оборудования",
								"value": 916,
								"level": 4,
								"children": [
									{
										"title": "Ремонт и техническое обслуживание инструментов и приборов для измерения, тестирования и навигации",
										"value": 917,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт контрольного оборудования",
										"value": 918,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание облучающего, электромедицинского и электротерапевтического оборудования",
										"value": 919,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание оптических приборов и фотографического оборудования",
										"value": 920,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт электрического оборудования",
								"value": 921,
								"level": 4,
								"children": [
									{
										"title": "Ремонт и техническое обслуживание электродвигателей, генераторов и трансформаторов",
										"value": 922,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание электрораспределительной и регулирующей аппаратуры",
										"value": 923,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание электроосветительного оборудования",
										"value": 924,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание прочего электрооборудования, не включенного в другие группировки",
										"value": 925,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт и техническое обслуживание судов и лодок",
								"value": 926,
								"level": 4,
								"children": [
									{
										"title": "Ремонт и техническое обслуживание судов",
										"value": 927,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт и техническое обслуживание спортивных и прогулочных лодок",
										"value": 928,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт и техническое обслуживание воздушных и космических летательных аппаратов",
								"value": 929,
								"level": 4,
								"children": [
									{
										"title": "Ремонт и техническое обслуживание воздушных и космических летательных аппаратов",
										"value": 930,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт и техническое обслуживание прочих транспортных средств и оборудования",
								"value": 931,
								"level": 4,
								"children": [
									{
										"title": "Ремонт подвижного состава железных дорог",
										"value": 932,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт трамваев, вагонов метро и троллейбусов",
										"value": 933,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт прочих транспортных средств и оборудования, не включенных в другие группировки",
										"value": 934,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт прочего оборудования",
								"value": 935,
								"level": 4,
								"children": [
									{
										"title": "Ремонт прочего оборудования",
										"value": 936,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Ремонт готовых металлических изделий, машин и оборудования",
						"value": 937,
						"level": 3,
						"children": [
							{
								"title": "Монтаж, установка промышленных машин и оборудования",
								"value": 938,
								"level": 4,
								"children": [
									{
										"title": "Монтаж, установка промышленных машин и оборудования",
										"value": 939,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Снабжение электроэнергией, газом, паром, горячей водой и  кондиционированным воздухом",
		"value": 940,
		"level": 1,
		"children": [
			{
				"title": "Снабжение электроэнергией, газом, паром, горячей водой и  кондиционированным воздухом",
				"value": 941,
				"level": 2,
				"children": [
					{
						"title": "Производство, передача и распределение электроэнергии",
						"value": 942,
						"level": 3,
						"children": [
							{
								"title": "Производство электроэнергии",
								"value": 943,
								"level": 4,
								"children": [
									{
										"title": "Производство электроэнергии тепловыми электростанциями",
										"value": 944,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроэнергии гидроэлектростанциями",
										"value": 945,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроэнергии ядерными (атомными) электростанциями",
										"value": 946,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроэнергии ветровыми электростанциями",
										"value": 947,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроэнергии солнечными электростанциями",
										"value": 948,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство электроэнергии прочими электростанциями",
										"value": 949,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Передача электроэнергии",
								"value": 950,
								"level": 4,
								"children": [
									{
										"title": "Передача электроэнергии",
										"value": 951,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Техническая диспетчеризация отпуска в сеть и потребления электрической энергии, организация балансирования производства-потребления электрической энергии",
										"value": 952,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Эксплуатационное обслуживание оборудования Национальной электрической сети",
										"value": 953,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обеспечение готовности электрической мощности к несению нагрузки, регулирование и резервирование электрической мощности",
										"value": 954,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Распределение электроэнергии",
								"value": 955,
								"level": 4,
								"children": [
									{
										"title": "Распределение электроэнергии",
										"value": 956,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Продажа электроэнергии ",
								"value": 957,
								"level": 4,
								"children": [
									{
										"title": "Продажа электроэнергии ",
										"value": 958,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство, передача и распределение электроэнергии",
						"value": 959,
						"level": 3,
						"children": [
							{
								"title": "Производство газообразного топлива",
								"value": 960,
								"level": 4,
								"children": [
									{
										"title": "Производство газообразного топлива",
										"value": 961,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Распределение газообразного топлива по трубопроводам",
								"value": 962,
								"level": 4,
								"children": [
									{
										"title": "Распределение газообразного топлива по трубопроводам",
										"value": 963,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Продажа газообразного топлива по трубопроводам",
								"value": 964,
								"level": 4,
								"children": [
									{
										"title": "Продажа газообразного топлива по трубопроводам",
										"value": 965,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Производство, передача и распределение электроэнергии",
						"value": 966,
						"level": 3,
						"children": [
							{
								"title": "Снабжение паром, горячей водой и кондиционированным воздухом",
								"value": 967,
								"level": 4,
								"children": [
									{
										"title": "Производство тепловой энергии тепловыми сетями",
										"value": 968,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство тепловой энергии самостоятельными котельными",
										"value": 969,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Передача тепловой энергии",
										"value": 970,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Кондиционирование воздуха",
										"value": 971,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство тепловой энергии тепловыми электростанциями",
										"value": 972,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Распределение тепловой энергии",
										"value": 973,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Производство тепловой энергии возобновляемыми и альтернативными источниками энергии",
										"value": 2117,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Водоснабжение; сбор, обработка и удаление отходов, деятельность по ликвидации загрязнений",
		"value": 974,
		"level": 1,
		"children": [
			{
				"title": "Сбор, обработка и распределение воды",
				"value": 975,
				"level": 2,
				"children": [
					{
						"title": "Сбор, обработка и распределение воды",
						"value": 976,
						"level": 3,
						"children": [
							{
								"title": "Сбор, обработка и распределение воды",
								"value": 977,
								"level": 4,
								"children": [
									{
										"title": "Сбор, обработка и распределение воды",
										"value": 978,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Сбор и обработка сточных вод",
				"value": 979,
				"level": 2,
				"children": [
					{
						"title": "Сбор и обработка сточных вод",
						"value": 980,
						"level": 3,
						"children": [
							{
								"title": "Сбор и обработка сточных вод",
								"value": 981,
								"level": 4,
								"children": [
									{
										"title": "Сбор и обработка сточных вод",
										"value": 982,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ассенизаторская деятельность",
										"value": 2119,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Эксплуатация сетей и сооружений систем водоотведения (канализации)",
										"value": 2107,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Сбор, обработка и удаление отходов; утилизация (восстановление) материалов",
				"value": 983,
				"level": 2,
				"children": [
					{
						"title": "Сбор отходов",
						"value": 984,
						"level": 3,
						"children": [
							{
								"title": "Сбор неопасных отходов",
								"value": 985,
								"level": 4,
								"children": [
									{
										"title": "Сбор неопасных отходов",
										"value": 986,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Сбор опасных отходов",
								"value": 987,
								"level": 4,
								"children": [
									{
										"title": "Сбор опасных отходов",
										"value": 988,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Сбор отходов",
						"value": 989,
						"level": 3,
						"children": [
							{
								"title": "Обработка и удаление неопасных отходов",
								"value": 990,
								"level": 4,
								"children": [
									{
										"title": "Обработка и удаление неопасных отходов",
										"value": 991,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Обработка и удаление опасных отходов",
								"value": 992,
								"level": 4,
								"children": [
									{
										"title": "Обработка и удаление опасных отходов",
										"value": 993,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Сбор отходов",
						"value": 994,
						"level": 3,
						"children": [
							{
								"title": "Разборка машин и оборудования, не подлежащих восстановлению",
								"value": 995,
								"level": 4,
								"children": [
									{
										"title": "Разборка машин и оборудования, не подлежащих восстановлению",
										"value": 996,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Переработка отсортированных материалов",
								"value": 997,
								"level": 4,
								"children": [
									{
										"title": "Переработка отходов и лома черных металлов",
										"value": 998,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Переработка отходов и лома цветных металлов",
										"value": 999,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Переработка неметаллических отходов",
										"value": 1000,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
				"value": 1001,
				"level": 2,
				"children": [
					{
						"title": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
						"value": 1002,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
								"value": 1003,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
										"value": 1004,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Строительство",
		"value": 1005,
		"level": 1,
		"children": [
			{
				"title": "Строительство зданий",
				"value": 1006,
				"level": 2,
				"children": [
					{
						"title": "Разработка строительных проектов",
						"value": 1007,
						"level": 3,
						"children": [
							{
								"title": "Разработка строительных проектов",
								"value": 1008,
								"level": 4,
								"children": [
									{
										"title": "Разработка строительных проектов",
										"value": 1009,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Разработка строительных проектов",
						"value": 1010,
						"level": 3,
						"children": [
							{
								"title": "Строительство жилых и нежилых зданий",
								"value": 1011,
								"level": 4,
								"children": [
									{
										"title": "Строительство жилых зданий",
										"value": 1012,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Строительство нежилых зданий, за исключением стационарных торговых объектов категорий 1, 2",
										"value": 1013,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Строительство стационарных торговых объектов категории 1",
										"value": 1014,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Строительство стационарных торговых объектов категории 2",
										"value": 1015,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Гражданское строительство",
				"value": 1016,
				"level": 2,
				"children": [
					{
						"title": "Строительство автомобильных и железных дорог",
						"value": 1017,
						"level": 3,
						"children": [
							{
								"title": "Строительство автомобильных дорог",
								"value": 1018,
								"level": 4,
								"children": [
									{
										"title": "Строительство дорог и автомагистралей",
										"value": 1019,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по организации строительства, реконструкции, ремонта, платного движения и содержания автомобильных дорог (участков) общего пользования международного и республиканского значения",
										"value": 1020,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Строительство железных дорог и метро",
								"value": 1021,
								"level": 4,
								"children": [
									{
										"title": "Строительство железных дорог и метро",
										"value": 1022,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Строительство мостов и туннелей",
								"value": 1023,
								"level": 4,
								"children": [
									{
										"title": "Строительство мостов и туннелей",
										"value": 1024,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство автомобильных и железных дорог",
						"value": 1025,
						"level": 3,
						"children": [
							{
								"title": "Строительство трубопроводов",
								"value": 1026,
								"level": 4,
								"children": [
									{
										"title": "Строительство нефтяных и газовых магистральных трубопроводов",
										"value": 1027,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Строительство трубопроводов для систем водоснабжения и канализации",
										"value": 1028,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Строительство прочих трубопроводов",
										"value": 1029,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Строительство линий электропередач и телекоммуникаций",
								"value": 1030,
								"level": 4,
								"children": [
									{
										"title": "Строительство линий электропередач и телекоммуникаций",
										"value": 1031,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Строительство автомобильных и железных дорог",
						"value": 1032,
						"level": 3,
						"children": [
							{
								"title": "Строительство водных сооружений",
								"value": 1033,
								"level": 4,
								"children": [
									{
										"title": "Строительство водных сооружений",
										"value": 1034,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Строительство прочих инженерных сооружений, не включенных в другие группировки",
								"value": 1035,
								"level": 4,
								"children": [
									{
										"title": "Строительство прочих инженерных сооружений, не включенных в другие группировки",
										"value": 1036,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Специализированные строительные работы",
				"value": 1037,
				"level": 2,
				"children": [
					{
						"title": "Снос  зданий и сооружений, подготовка строительного участка",
						"value": 1038,
						"level": 3,
						"children": [
							{
								"title": "Разведочное бурение",
								"value": 1045,
								"level": 4,
								"children": [
									{
										"title": "Разведочное бурение",
										"value": 1046,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Разборка и снос зданий и сооружений",
								"value": 1039,
								"level": 4,
								"children": [
									{
										"title": "Разборка и снос зданий и сооружений",
										"value": 1040,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Подготовка строительного участка",
								"value": 1041,
								"level": 4,
								"children": [
									{
										"title": "Земляные работы",
										"value": 1042,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Взрывные работы",
										"value": 1043,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Специальные работы в грунтах",
										"value": 1044,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Снос  зданий и сооружений, подготовка строительного участка",
						"value": 1047,
						"level": 3,
						"children": [
							{
								"title": "Электромонтажные работы",
								"value": 1048,
								"level": 4,
								"children": [
									{
										"title": "Электромонтажные работы по прокладке  телекоммуникационных, компьютерных и телевизионных сетей",
										"value": 1049,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие электромонтажные работы",
										"value": 1050,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Монтаж систем водоснабжения, отопления и кондиционирования воздуха",
								"value": 1051,
								"level": 4,
								"children": [
									{
										"title": "Монтаж систем водоснабжения, отопления и кондиционирования воздуха",
										"value": 1052,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие строительно-монтажные работы",
								"value": 1053,
								"level": 4,
								"children": [
									{
										"title": "Пуск и наладка смонтированного оборудования",
										"value": 1056,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Изоляционные работы",
										"value": 1054,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие строительно-монтажные работы, не включенные в другие группировки",
										"value": 1055,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Снос  зданий и сооружений, подготовка строительного участка",
						"value": 1057,
						"level": 3,
						"children": [
							{
								"title": "Штукатурные работы",
								"value": 1058,
								"level": 4,
								"children": [
									{
										"title": "Штукатурные работы",
										"value": 1059,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Столярные и плотницкие работы",
								"value": 1060,
								"level": 4,
								"children": [
									{
										"title": "Столярные и плотницкие работы",
										"value": 1061,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Покрытие полов и облицовка стен",
								"value": 1062,
								"level": 4,
								"children": [
									{
										"title": "Покрытие полов и облицовка стен",
										"value": 1063,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Малярные и стекольные работы",
								"value": 1064,
								"level": 4,
								"children": [
									{
										"title": "Малярные и стекольные работы",
										"value": 1065,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие отделочные работы",
								"value": 1066,
								"level": 4,
								"children": [
									{
										"title": "Прочие отделочные работы",
										"value": 1067,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Снос  зданий и сооружений, подготовка строительного участка",
						"value": 1068,
						"level": 3,
						"children": [
							{
								"title": "Кровельные работы",
								"value": 1069,
								"level": 4,
								"children": [
									{
										"title": "Кровельные работы",
										"value": 1070,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие специализированные строительные работы, не включенные в другие группировки",
								"value": 1071,
								"level": 4,
								"children": [
									{
										"title": "Строительство шахт",
										"value": 1072,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Гидроизоляционные работы",
										"value": 1073,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Аренда строительного оборудования с оператором",
										"value": 1074,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие строительные работы, требующие специальной квалификации",
										"value": 1075,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Оптовая и розничная торговля; ремонт автомобилей и мотоциклов",
		"value": 1076,
		"level": 1,
		"children": [
			{
				"title": "Оптовая и розничная торговля автомобилями и мотоциклами и их ремонт",
				"value": 1077,
				"level": 2,
				"children": [
					{
						"title": "Торговля автомобилями",
						"value": 1078,
						"level": 3,
						"children": [
							{
								"title": "Торговля автомобилями и легкими автотранспортными средствами",
								"value": 1079,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля автомобилями и легкими автотранспортными средствами",
										"value": 1080,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля автомобилями и легкими автотранспортными средствами в торговых объектах с торговой площадью менее 2000 кв.м",
										"value": 1081,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля автомобилями и легкими автотранспортными средствами в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1082,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Торговля прочими автотранспортными средствами",
								"value": 1083,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля прочими автотранспортными средствами",
										"value": 1084,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля прочими автотранспортными средствами в торговых объектах с торговой площадью менее 2000 кв.м",
										"value": 1085,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля прочими автотранспортными средствами в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1086,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Торговля автомобилями",
						"value": 1087,
						"level": 3,
						"children": [
							{
								"title": "Техническое обслуживание и ремонт автомобилей",
								"value": 1088,
								"level": 4,
								"children": [
									{
										"title": "Техническое обслуживание и ремонт автомобилей, за исключением произведенных станциями технического обслуживания, находящимися на придорожной полосе",
										"value": 1089,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Техническое обслуживание и ремонт автомобилей станциями технического обслуживания, находящимися на придорожной полосе",
										"value": 1090,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по зарядке аккумуляторных батарей транспортных средств с электродвигателями",
										"value": 2105,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Торговля автомобилями",
						"value": 1091,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля автомобильными деталями, узлами и  принадлежностями ",
								"value": 1092,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля автомобильными деталями, узлами и  принадлежностями ",
										"value": 1093,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля автомобильными деталями, узлами и  принадлежностями ",
								"value": 1094,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля автомобильными деталями, узлами и  принадлежностями в торговых объектах с торговой площадью менее 2000 кв.м",
										"value": 1095,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля автомобильными деталями, узлами и  принадлежностями  в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1096,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Торговля автомобилями",
						"value": 1097,
						"level": 3,
						"children": [
							{
								"title": "Торговля мотоциклами, их деталями, узлами и принадлежностями; техническое обслуживание и ремонт мотоциклов",
								"value": 1098,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля мотоциклами, мотороллерами, деталями и принадлежностями к ним",
										"value": 1099,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля мотоциклами, мотороллерами, деталями и принадлежностями к ним",
										"value": 1100,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Техническое обслуживание и ремонт мотоциклов  и мотороллеров",
										"value": 1101,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Оптовая торговля, за исключением торговли автомобилями и мотоциклами",
				"value": 1102,
				"level": 2,
				"children": [
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1103,
						"level": 3,
						"children": [
							{
								"title": "Деятельность агентов по оптовой  торговле сельскохозяйственным сырьем, живыми животными, текстильным сырьем и полуфабрикатами",
								"value": 1104,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой  торговле сельскохозяйственным сырьем, живыми животными, текстильным сырьем и полуфабрикатами",
										"value": 1105,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле топливом, рудами, металлами и химическими веществами",
								"value": 1106,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле топливом, рудами, металлами и химическими веществами",
										"value": 1107,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле древесиной и строительными материалами",
								"value": 1108,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле древесиной и строительными материалами",
										"value": 1109,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой  торговле машинами, оборудованием, судами и летательными аппаратами",
								"value": 1110,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой  торговле машинами, оборудованием, судами и летательными аппаратами",
										"value": 1111,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле мебелью, бытовыми товарами, скобяными и прочими металлическими изделиями",
								"value": 1112,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле мебелью, бытовыми товарами, скобяными и прочими металлическими изделиями",
										"value": 1113,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле текстильными изделиями, одеждой, обувью, изделиями из кожи и меха",
								"value": 1114,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле текстильными изделиями, одеждой, обувью, изделиями из кожи и меха",
										"value": 1115,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле пищевыми продуктами, напитками и табачными изделиями",
								"value": 1116,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле пищевыми продуктами, напитками и табачными изделиями",
										"value": 1117,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов, специализирующихся на оптовой торговле отдельными видами товаров или группами товаров, не включенными в другие группировки",
								"value": 1118,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов, специализирующихся на оптовой торговле отдельными видами товаров или группами товаров, не включенными в другие группировки",
										"value": 1119,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность агентов по оптовой торговле товарами широкого ассортимента",
								"value": 1120,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентов по оптовой торговле товарами широкого ассортимента",
										"value": 1121,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1122,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля зерном, необработанным табаком, семенами и кормами для животных",
								"value": 1123,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля зерном, семенами и кормами для животных",
										"value": 1124,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля необработанным табаком",
										"value": 1125,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля масличными культурами",
										"value": 1126,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля цветами и другими растениями",
								"value": 1127,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля цветами и другими растениями",
										"value": 1128,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля живыми животными",
								"value": 1129,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля живыми животными",
										"value": 1130,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля шкурами и кожей",
								"value": 1131,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля шкурами и кожей",
										"value": 1132,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1133,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля фруктами и овощами",
								"value": 1134,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля фруктами и овощами",
										"value": 1135,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля мясом и мясными продуктами",
								"value": 1136,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля мясом и мясными продуктами",
										"value": 1137,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля молочными продуктами, яйцами и пищевыми маслами и жирами",
								"value": 1138,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля молочными продуктами, яйцами и пищевыми маслами и жирами",
										"value": 1139,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля напитками",
								"value": 1140,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля напитками в магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1141,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля напитками в магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше), включая оптово-продовольственные распределительные центры",
										"value": 1142,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля табачными изделиями",
								"value": 1143,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля табачными изделиями",
										"value": 1144,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля сахаром, шоколадом и сахаристыми кондитерскими изделиями",
								"value": 1145,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля сахаром, шоколадом и сахаристыми кондитерскими изделиями",
										"value": 1146,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля кофе, чаем, какао и специями",
								"value": 1147,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля кофе, чаем, какао и специями",
										"value": 1148,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля прочими продуктами питания, включая рыбу, ракообразных и моллюсков",
								"value": 1149,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля рыбой и рыбными продуктами",
										"value": 1150,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля прочими продуктами питания",
										"value": 1151,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Неспециализированная оптовая торговля продуктами питания, напитками и табачными изделиями",
								"value": 1152,
								"level": 4,
								"children": [
									{
										"title": "Неспециализированная оптовая торговля продуктами питания, напитками и табачными изделиями",
										"value": 1153,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1154,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля текстильными изделиями",
								"value": 1155,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля текстильными изделиями",
										"value": 1156,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля одеждой и обувью",
								"value": 1157,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля обувью",
										"value": 1160,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля трикотажными и чулочно-носочными изделиями",
										"value": 1158,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля одеждой, кроме трикотажных и чулочно-носочных изделий",
										"value": 1159,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля бытовыми электротоварами, радио- и  телевизионным оборудованием",
								"value": 1161,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля бытовыми электротоварами",
										"value": 1162,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля радио- и телевизионным оборудованием",
										"value": 1163,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля изделиями из керамики и стекла, чистящими средствами",
								"value": 1164,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля изделиями из керамики и стекла, чистящими средствами",
										"value": 1165,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля парфюмерными и косметическими средствами",
								"value": 1166,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля парфюмерными и косметическими средствами",
										"value": 1167,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля фармацевтическими товарами",
								"value": 1168,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля фармацевтическими товарами, кроме торговли медицинской техникой и ортопедическими изделиями",
										"value": 1169,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая реализация фармацевтических и медицинских товаров в рамках гарантированного объема бесплатной медицинской помощи",
										"value": 1170,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля медицинской техникой и ортопедическими  изделиями",
										"value": 1171,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля мебелью, коврами и осветительным оборудованием",
								"value": 1172,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля мебелью, коврами и осветительным оборудованием",
										"value": 1173,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля часами и ювелирными изделиями",
								"value": 1174,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля часами и ювелирными изделиями",
										"value": 1175,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля прочими непродовольственными товарами потребительского назначения",
								"value": 1176,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля канцелярскими принадлежностями",
										"value": 1177,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля музыкальными инструментами ",
										"value": 1178,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля прочими непродовольственными товарами потребительского назначения, не включенными в другие группировки",
										"value": 1179,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1180,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля компьютерами, периферийным компьютерным оборудованием и программным обеспечением",
								"value": 1181,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля компьютерами, периферийным компьютерным оборудованием и программным обеспечением",
										"value": 1182,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля электронным и телекоммуникационным оборудованием и их частями ",
								"value": 1183,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля электронным и телекоммуникационным оборудованием и их частями ",
										"value": 1184,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1185,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля сельскохозяйственной техникой, оборудованием, деталями и принадлежностями к ним",
								"value": 1186,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля сельскохозяйственной техникой, оборудованием, деталями и принадлежностями к ним",
										"value": 1187,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля  станками",
								"value": 1188,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля  станками",
										"value": 1189,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля машинами и оборудованием для горнодобывающей промышленности и  строительства",
								"value": 1190,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля машинами и оборудованием для горнодобывающей промышленности и  строительства",
										"value": 1191,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля машинами и оборудованием для текстильной промышленности и швейными и вязальными машинами",
								"value": 1192,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля машинами и оборудованием для текстильной промышленности и швейными и вязальными машинами",
										"value": 1193,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля офисной мебелью",
								"value": 1194,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля офисной мебелью",
										"value": 1195,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля прочими офисными машинами и оборудованием",
								"value": 1196,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля прочими офисными машинами и оборудованием",
										"value": 1197,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля прочими машинами и оборудованием",
								"value": 1198,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля прочими машинами и оборудованием",
										"value": 1199,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1200,
						"level": 3,
						"children": [
							{
								"title": "Оптовая торговля твердым, жидким и газообразным топливом и подобными продуктами",
								"value": 1201,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля прочим топливом",
										"value": 1210,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля сырой нефтью и попутным газом",
										"value": 1202,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля природным (горючим) газом",
										"value": 1203,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля каменным углем",
										"value": 1204,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля лигнитом (бурым углем)",
										"value": 1205,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля авиационным бензином и керосином",
										"value": 1206,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля автомобильным бензином",
										"value": 1207,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля дизельным топливом",
										"value": 1208,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля мазутом топочным",
										"value": 1209,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля металлами и металлическими рудами",
								"value": 1211,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля рудами черных и цветных металлов",
										"value": 1212,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля чугуном, сталью и их литьем",
										"value": 1213,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля редкими, редкоземельными и цветными металлами и их литьем",
										"value": 1214,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля драгоценными металлами",
										"value": 1215,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля лесоматериалами, строительными материалами и санитарно-техническим оборудованием",
								"value": 1216,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля листовым стеклом ",
										"value": 1217,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля цементом, песком и гравием",
										"value": 1218,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля изделиями из бетона, цемента, гипса и аналогичных материалов",
										"value": 1219,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля лакокрасочной продукцией, обоями и напольными покрытиями",
										"value": 1220,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля древесиной и продукцией обработки древесины",
										"value": 1221,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля строительными металлическими конструкциями",
										"value": 1222,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля санитарно-техническим оборудованием",
										"value": 1223,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля стеновыми блоками",
										"value": 1224,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля скобяными изделиями, водопроводным и отопительным оборудованием и  инвентарем",
								"value": 1225,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля скобяными изделиями, водопроводным и отопительным оборудованием и  инвентарем",
										"value": 1226,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля химической продукцией",
								"value": 1227,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля химическими веществами и химическими продуктами",
										"value": 1228,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля резинотехническими изделиями",
										"value": 1229,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля минеральными удобрениями",
										"value": 1230,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля пестицидами и прочими агрохимическими продуктами",
										"value": 1231,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля прочими промежуточными продуктами",
								"value": 1232,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля пластмассами в первичных формах и синтетическим каучуком",
										"value": 1233,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля прочими промежуточными продуктами",
										"value": 1234,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля драгоценными камнями",
										"value": 1235,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оптовая торговля отходами и ломом",
								"value": 1236,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля ломом и отходами черных и цветных металлов",
										"value": 1237,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля ломом драгоценных металлов и драгоценных камней",
										"value": 1238,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля прочими неметаллическими отходами и неметаллическим ломом",
										"value": 1239,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Оптовая торговля за вознаграждение или на договорной основе",
						"value": 1240,
						"level": 3,
						"children": [
							{
								"title": "Неспециализированная оптовая торговля",
								"value": 1241,
								"level": 4,
								"children": [
									{
										"title": "Оптовая торговля полудрагоценными камнями",
										"value": 1242,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля мореным дубом",
										"value": 1243,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля широким ассортиментом товаров без какой-либо конкретизации в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше), включая оптово-продовольственные распределительные центры",
										"value": 1244,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля оборудованием и материалами, бывшими в употреблении",
										"value": 1245,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Специализированная оптовая торговля товарами, не включенными в другие группировки",
										"value": 1246,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Оптовая торговля широким ассортиментом товаров без какой-либо конкретизации",
										"value": 1247,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Розничная торговля, кроме торговли автомобилями и мотоциклами",
				"value": 1248,
				"level": 2,
				"children": [
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1249,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля в неспециализированных магазинах преимущественно продуктами питания, напитками и табачными изделиями",
								"value": 1250,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля преимущественно продуктами питания, напитками и табачными изделиями в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1251,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля преимущественно продуктами питания,  напитками и табачными изделиями, в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1252,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая розничная торговля в неспециализированных магазинах",
								"value": 1253,
								"level": 4,
								"children": [
									{
										"title": "Прочая розничная торговля в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1254,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1255,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1256,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля фруктами и овощами в специализированных магазинах",
								"value": 1257,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля фруктами и овощами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1258,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля фруктами и овощами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1259,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля мясом и мясными продуктами в специализированных магазинах",
								"value": 1260,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля домашней птицей, дичью и изделиями из них в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1261,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля домашней птицей, дичью и изделиями из них в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1262,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля мясом и мясными продуктами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1263,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля мясом и мясными продуктами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1264,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах",
								"value": 1265,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1266,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1267,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах",
								"value": 1268,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1269,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1270,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля напитками в специализированных магазинах",
								"value": 1271,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля напитками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1272,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля напитками в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1273,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля табачными изделиями в специализированных магазинах",
								"value": 1274,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля табачными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1275,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля табачными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1276,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая розничная торговля продуктами питания в специализированных магазинах",
								"value": 1277,
								"level": 4,
								"children": [
									{
										"title": "Прочая розничная торговля  продуктами питания в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1278,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля продуктами питания в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1279,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1280,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля топливом в специализированных магазинах",
								"value": 1281,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля моторным топливом в специализированных магазинах, за исключением находящихся на придорожной полосе",
										"value": 1282,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля смазочными материалами в специализированных магазинах",
										"value": 1283,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля моторным топливом в специализированных магазинах, находящихся на придорожной полосе",
										"value": 1284,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1285,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах",
								"value": 1286,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1287,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1288,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах",
								"value": 1289,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1290,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1291,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах",
								"value": 1292,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1293,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1294,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1331,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля обувью и кожаными изделиями в специализированных магазинах",
								"value": 1337,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля обувью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1338,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля кожаными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1339,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля обувью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1340,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля кожаными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1341,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля одеждой в специализированных магазинах",
								"value": 1332,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля трикотажными и чулочно-носочными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1333,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля одеждой, кроме трикотажных и чулочно-носочных изделий, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1334,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля трикотажными и чулочно-носочными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1335,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля одеждой, кроме трикотажных и чулочно-носочных изделий, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1336,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля фармацевтическими товарами в специализированных магазинах",
								"value": 1342,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля фармацевтическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1343,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля фармацевтическими товарами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1344,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах",
								"value": 1345,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1346,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1347,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах",
								"value": 1348,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1349,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1350,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля цветами, комнатными растениями, семенами, удобрениями, домашними животными и кормами для домашних животных в специализированных магазинах",
								"value": 1351,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля цветами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1352,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля семенами и удобрениями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1353,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля домашними животными и кормами для домашних животных в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1354,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля цветами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1355,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля семенами и удобрениями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1356,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля домашними животными и кормами для домашних животных в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1357,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах",
								"value": 1358,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1359,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1360,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая розничная торговля новыми товарами в специализированных магазинах",
								"value": 1361,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля фотографическим, оптическим и точным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1362,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля велосипедами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1363,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля фотографическим, оптическим и точным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1364,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля велосипедами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1365,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1366,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1367,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля подержанными товарами в магазинах",
								"value": 1368,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля подержанными товарами в магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1369,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля подержанными товарами в  магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1370,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1295,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля текстильными изделиями в специализированных магазинах",
								"value": 1296,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля текстильными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1297,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля текстильными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1298,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах",
								"value": 1299,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1300,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1301,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах",
								"value": 1302,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
										"value": 1303,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше) ",
										"value": 1304,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах",
								"value": 1305,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м  ",
										"value": 1306,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1307,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля мебелью, осветительным оборудованием и прочими бытовыми товарами в специализированных магазинах",
								"value": 1308,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля мебелью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1309,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля музыкальными инструментами и партитурами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1310,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля мебелью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1311,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля музыкальными инструментами и партитурами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1312,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля осветительными приборами и бытовыми товарами, не включенными в другие группировки, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1313,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля осветительными приборами и бытовыми товарами, не включенными в другие группировки, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1314,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1315,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля книгами в специализированных магазинах",
								"value": 1316,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля книгами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1317,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля книгами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1318,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах",
								"value": 1319,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1320,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1321,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля аудио и видеозаписями в специализированных магазинах",
								"value": 1322,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля аудио и видеозаписями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1323,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля аудио и видеозаписями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1324,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля спортивным оборудованием в специализированных магазинах",
								"value": 1325,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля спортивным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1326,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля спортивным оборудованием в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1327,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля играми и игрушками в специализированных магазинах",
								"value": 1328,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля играми и игрушками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
										"value": 1329,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля играми и игрушками в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
										"value": 1330,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1371,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля продуктами питания, напитками и табачными изделиями в торговых палатках, киосках и на рынках",
								"value": 1372,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля продуктами питания, напитками и табачными изделиями в торговых палатках, ларьках и киосках",
										"value": 1373,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля продуктами питания, напитками и табачными изделиями на рынках",
										"value": 1374,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля одеждой, обувью и текстильными изделиями  в торговых палатках, киосках и на рынках",
								"value": 1375,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля одеждой, обувью и текстильными изделиями в торговых палатках, ларьках и киосках",
										"value": 1376,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля одеждой, обувью и текстильными изделиями на рынках",
										"value": 1377,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Розничная торговля прочими товарами в палатках, киосках и  на рынках",
								"value": 1378,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля прочими товарами в торговых палатках, ларьках и киосках",
										"value": 1379,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля прочими товарами на рынках",
										"value": 1380,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Розничная торговля в неспециализированных магазинах",
						"value": 1381,
						"level": 3,
						"children": [
							{
								"title": "Розничная торговля путем заказа товаров по почте или через сеть Интернет",
								"value": 1382,
								"level": 4,
								"children": [
									{
										"title": "Розничная торговля путем заказа товаров по почте или через сеть Интернет",
										"value": 1383,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая розничная торговля вне магазинов, палаток, киосков или рынков",
								"value": 1384,
								"level": 4,
								"children": [
									{
										"title": "Развозная и разносная розничная торговля",
										"value": 1385,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Розничная торговля через сетевой маркетинг",
										"value": 1386,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая розничная торговля вне магазинов",
										"value": 1387,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Транспорт и складирование",
		"value": 1388,
		"level": 1,
		"children": [
			{
				"title": "Деятельность водного транспорта",
				"value": 1415,
				"level": 2,
				"children": [
					{
						"title": "Деятельность морского и прибрежного пассажирского транспорта",
						"value": 1416,
						"level": 3,
						"children": [
							{
								"title": "Деятельность морского и прибрежного пассажирского транспорта",
								"value": 1417,
								"level": 4,
								"children": [
									{
										"title": "Деятельность морского и прибрежного пассажирского транспорта",
										"value": 1418,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность морского и прибрежного пассажирского транспорта",
						"value": 1419,
						"level": 3,
						"children": [
							{
								"title": "Деятельность морского и прибрежного грузового транспорта",
								"value": 1420,
								"level": 4,
								"children": [
									{
										"title": "Деятельность морского и прибрежного грузового транспорта",
										"value": 1421,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность морского и прибрежного пассажирского транспорта",
						"value": 1422,
						"level": 3,
						"children": [
							{
								"title": "Деятельность речного пассажирского транспорта",
								"value": 1423,
								"level": 4,
								"children": [
									{
										"title": "Деятельность речного пассажирского транспорта",
										"value": 1424,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность морского и прибрежного пассажирского транспорта",
						"value": 1425,
						"level": 3,
						"children": [
							{
								"title": "Деятельность речного  грузового транспорта",
								"value": 1426,
								"level": 4,
								"children": [
									{
										"title": "Деятельность речного  грузового транспорта, кроме лесосплава",
										"value": 1427,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лесосплав",
										"value": 1428,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность сухопутного и трубопроводного транспорта",
				"value": 1389,
				"level": 2,
				"children": [
					{
						"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
						"value": 1390,
						"level": 3,
						"children": [
							{
								"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
								"value": 1391,
								"level": 4,
								"children": [
									{
										"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
										"value": 1392,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
						"value": 1393,
						"level": 3,
						"children": [
							{
								"title": "Деятельность грузового железнодорожного транспорта",
								"value": 1394,
								"level": 4,
								"children": [
									{
										"title": "Деятельность грузового железнодорожного транспорта",
										"value": 1395,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
						"value": 1396,
						"level": 3,
						"children": [
							{
								"title": "Деятельность сухопутного транспорта по городским и пригородным пассажирским перевозкам",
								"value": 1397,
								"level": 4,
								"children": [
									{
										"title": "Перевозки автобусами",
										"value": 1398,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Перевозки трамваями",
										"value": 1399,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Перевозки троллейбусами",
										"value": 1400,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Перевозки метрополитеном",
										"value": 1401,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Перевозки прочими видами транспорта, подчиняющегося расписанию",
										"value": 1402,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность такси",
								"value": 1403,
								"level": 4,
								"children": [
									{
										"title": "Деятельность такси",
										"value": 1404,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность  прочего пассажирского сухопутного транспорта, не включенного в другие группировки",
								"value": 1405,
								"level": 4,
								"children": [
									{
										"title": "Деятельность  прочего пассажирского сухопутного транспорта, не включенного в другие группировки",
										"value": 1406,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
						"value": 1407,
						"level": 3,
						"children": [
							{
								"title": "Деятельность грузового автомобильного транспорта",
								"value": 1408,
								"level": 4,
								"children": [
									{
										"title": "Деятельность грузового автомобильного транспорта",
										"value": 1409,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Предоставление услуг по переезду",
								"value": 1410,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг по переезду",
										"value": 1411,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
						"value": 1412,
						"level": 3,
						"children": [
							{
								"title": "Деятельность трубопроводного транспорта",
								"value": 1413,
								"level": 4,
								"children": [
									{
										"title": "Деятельность трубопроводного транспорта",
										"value": 1414,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность воздушного транспорта",
				"value": 1429,
				"level": 2,
				"children": [
					{
						"title": "Деятельность воздушного пассажирского транспорта",
						"value": 1434,
						"level": 3,
						"children": [
							{
								"title": "Деятельность грузового воздушного транспорта",
								"value": 1435,
								"level": 4,
								"children": [
									{
										"title": "Деятельность грузового воздушного транспорта, подчиняющего расписанию",
										"value": 1436,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность грузового воздушного транспорта, не подчиняющего расписанию",
										"value": 1437,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность космического транспорта",
								"value": 1438,
								"level": 4,
								"children": [
									{
										"title": "Деятельность космического транспорта",
										"value": 1439,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность воздушного пассажирского транспорта",
						"value": 1430,
						"level": 3,
						"children": [
							{
								"title": "Деятельность воздушного пассажирского транспорта",
								"value": 1431,
								"level": 4,
								"children": [
									{
										"title": "Деятельность  воздушного пассажирского транспорта, подчиняющегося расписанию",
										"value": 1432,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность  воздушного пассажирского транспорта, не подчиняющегося расписанию",
										"value": 1433,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Складирование грузов и вспомогательная транспортная деятельность",
				"value": 1440,
				"level": 2,
				"children": [
					{
						"title": "Складирование и хранение грузов",
						"value": 1441,
						"level": 3,
						"children": [
							{
								"title": "Складирование и хранение грузов",
								"value": 1442,
								"level": 4,
								"children": [
									{
										"title": "Складирование и хранение зерна",
										"value": 1443,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Складирование и хранение непродовольственных товаров, кроме зерна и нефти",
										"value": 1444,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Складирование и хранение продовольственных товаров, кроме овощей и фруктов",
										"value": 1445,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Хранение нефти",
										"value": 1446,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Складирование и хранение овощей и фруктов",
										"value": 1447,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Складирование и хранение грузов",
						"value": 1448,
						"level": 3,
						"children": [
							{
								"title": "Вспомогательная деятельность сухопутного транспорта",
								"value": 1449,
								"level": 4,
								"children": [
									{
										"title": "Эксплуатация железных дорог",
										"value": 1450,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Эксплуатация автомобильных дорог",
										"value": 1451,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность терминалов",
										"value": 1452,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность автомобильных стоянок",
										"value": 1453,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Эксплуатация магистральных и иных трубопроводов, в том числе водоводов",
										"value": 1454,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по подсоединению (подключению) трубопровода к действующим магистральным и иным трубопроводам",
										"value": 1455,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая вспомогательная деятельность  сухопутного транспорта",
										"value": 1456,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Вспомогательная деятельность водного транспорта",
								"value": 1457,
								"level": 4,
								"children": [
									{
										"title": "Вспомогательная деятельность водного транспорта",
										"value": 1458,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Вспомогательная деятельность воздушного транспорта",
								"value": 1459,
								"level": 4,
								"children": [
									{
										"title": "Регулирование использования воздушного пространства",
										"value": 1460,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность аэропортов",
										"value": 1461,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая деятельность, относящаяся к пассажирским и грузовым перевозкам воздушным транспортом",
										"value": 1462,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Транспортная обработка грузов",
								"value": 1463,
								"level": 4,
								"children": [
									{
										"title": "Транспортная обработка грузов",
										"value": 1464,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая вспомогательная транспортная деятельность",
								"value": 1465,
								"level": 4,
								"children": [
									{
										"title": "Транспортно-экспедиционные  услуги",
										"value": 1466,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Технический надзор на транспорте",
										"value": 1467,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая транспортно-экспедиционная деятельность",
										"value": 1468,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Почтовая и курьерская деятельность",
				"value": 1469,
				"level": 2,
				"children": [
					{
						"title": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
						"value": 1470,
						"level": 3,
						"children": [
							{
								"title": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
								"value": 1471,
								"level": 4,
								"children": [
									{
										"title": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
										"value": 1472,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
						"value": 1473,
						"level": 3,
						"children": [
							{
								"title": "Прочая почтовая и курьерская деятельность",
								"value": 1474,
								"level": 4,
								"children": [
									{
										"title": "Прочая почтовая и курьерская деятельность",
										"value": 1475,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Предоставление услуг по проживанию и питанию",
		"value": 1476,
		"level": 1,
		"children": [
			{
				"title": "Предоставление услуг по временному проживанию",
				"value": 1477,
				"level": 2,
				"children": [
					{
						"title": "Предоставление услуг гостиницами и аналогичными местами для проживания",
						"value": 1478,
						"level": 3,
						"children": [
							{
								"title": "Предоставление услуг гостиницами и аналогичными местами для проживания",
								"value": 1479,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг гостиницами с ресторанами, за исключением гостиниц, находящихся на придорожной полосе",
										"value": 1480,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление услуг гостиницами без ресторанов, за исключением гостиниц, находящихся на придорожной полосе",
										"value": 1481,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление гостиничных услуг с ресторанами для официальных мероприятий",
										"value": 1482,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление услуг гостиницами, находящимися на придорожной полосе",
										"value": 1483,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление услуг гостиницами и аналогичными местами для проживания",
						"value": 1484,
						"level": 3,
						"children": [
							{
								"title": "Предоставление жилья на выходные дни и прочие периоды краткосрочного проживания",
								"value": 1485,
								"level": 4,
								"children": [
									{
										"title": "Предоставление жилья на выходные дни и прочие периоды краткосрочного проживания",
										"value": 1486,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление услуг гостиницами и аналогичными местами для проживания",
						"value": 1487,
						"level": 3,
						"children": [
							{
								"title": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья",
								"value": 1488,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья, за исключением находящихся на придорожной полосе",
										"value": 1489,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья, находящимися на придорожной полосе",
										"value": 1490,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление услуг гостиницами и аналогичными местами для проживания",
						"value": 1491,
						"level": 3,
						"children": [
							{
								"title": "Предоставление услуг прочими местами для проживания",
								"value": 1492,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг общежитиями при школах-интернатах",
										"value": 1493,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление услуг студенческими общежитиями ",
										"value": 1494,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Предоставление услуг прочими местами для проживания, не включенными в другие категории",
										"value": 1495,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Предоставление услуг по обеспечению  питанием и напитками",
				"value": 1496,
				"level": 2,
				"children": [
					{
						"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
						"value": 1497,
						"level": 3,
						"children": [
							{
								"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
								"value": 1498,
								"level": 4,
								"children": [
									{
										"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания, за исключением деятельности объектов, находящихся на придорожной полосе",
										"value": 1499,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания объектами, находящимися на придорожной полосе",
										"value": 1500,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
						"value": 1501,
						"level": 3,
						"children": [
							{
								"title": "Доставка готовой пищи на заказ",
								"value": 1502,
								"level": 4,
								"children": [
									{
										"title": "Доставка готовой пищи на заказ",
										"value": 1503,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность по обеспечению питанием",
								"value": 1504,
								"level": 4,
								"children": [
									{
										"title": "Прочие виды организации питания вне населенных пунктов",
										"value": 1505,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие виды организации питания в пассажирских поездах",
										"value": 1506,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая деятельность по обеспечению питанием, не включенная в другие группировки",
										"value": 1507,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
						"value": 1508,
						"level": 3,
						"children": [
							{
								"title": "Подача напитков",
								"value": 1509,
								"level": 4,
								"children": [
									{
										"title": "Подача напитков",
										"value": 1510,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Информация и связь",
		"value": 1511,
		"level": 1,
		"children": [
			{
				"title": "Издательская деятельность",
				"value": 1512,
				"level": 2,
				"children": [
					{
						"title": "Издание книг, периодических публикаций и другие виды издательской деятельности",
						"value": 1513,
						"level": 3,
						"children": [
							{
								"title": "Издание книг",
								"value": 1514,
								"level": 4,
								"children": [
									{
										"title": "Издание книг",
										"value": 1515,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Издание справочников и адресных списков",
								"value": 1516,
								"level": 4,
								"children": [
									{
										"title": "Издание справочников и адресных списков",
										"value": 1517,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Издание газет",
								"value": 1518,
								"level": 4,
								"children": [
									{
										"title": "Издание газет",
										"value": 1519,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Издание журналов и периодических публикаций",
								"value": 1520,
								"level": 4,
								"children": [
									{
										"title": "Издание журналов и периодических публикаций",
										"value": 1521,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие виды издательской деятельности",
								"value": 1522,
								"level": 4,
								"children": [
									{
										"title": "Прочие виды издательской деятельности",
										"value": 1523,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Издание книг, периодических публикаций и другие виды издательской деятельности",
						"value": 1524,
						"level": 3,
						"children": [
							{
								"title": "Издание компьютерных игр",
								"value": 1525,
								"level": 4,
								"children": [
									{
										"title": "Издание компьютерных игр",
										"value": 1526,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Издание прочего программного обеспечения",
								"value": 1527,
								"level": 4,
								"children": [
									{
										"title": "Издание прочего программного обеспечения",
										"value": 1528,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Производство кино-, видеофильмов и телевизионных программ, деятельность в сфере звукозаписи и издания музыкальных произведений ",
				"value": 1529,
				"level": 2,
				"children": [
					{
						"title": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
						"value": 1530,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
								"value": 1531,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
										"value": 1532,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по завершению создания кино-, видеофильмов  и телевизионных программ",
								"value": 1533,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по завершению создания кино-, видеофильмов  и телевизионных программ",
										"value": 1534,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по распространению кино-, видеофильмов и телевизионных программ",
								"value": 1535,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по распространению кино-, видеофильмов и телевизионных программ",
										"value": 1536,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по показу кинофильмов",
								"value": 1537,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по показу кинофильмов",
										"value": 1538,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
						"value": 1539,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в сфере звукозаписи и издания музыкальных произведений ",
								"value": 1540,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в сфере звукозаписи и издания музыкальных произведений ",
										"value": 1541,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность по созданию программ и телерадиовещание",
				"value": 1542,
				"level": 2,
				"children": [
					{
						"title": "Радиовещание",
						"value": 1543,
						"level": 3,
						"children": [
							{
								"title": "Радиовещание",
								"value": 1544,
								"level": 4,
								"children": [
									{
										"title": "Радиовещание",
										"value": 1545,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Радиовещание",
						"value": 1546,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по созданию и трансляции телевизионных программ",
								"value": 1547,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по созданию и трансляции телевизионных программ",
										"value": 1548,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Телекоммуникации",
				"value": 1549,
				"level": 2,
				"children": [
					{
						"title": "Проводная телекоммуникационная связь",
						"value": 1550,
						"level": 3,
						"children": [
							{
								"title": "Проводная телекоммуникационная связь",
								"value": 1551,
								"level": 4,
								"children": [
									{
										"title": "Проводная телекоммуникационная связь для государственных органов посредством единой транспортной среды",
										"value": 1552,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая проводная телекоммуникационная связь",
										"value": 1553,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Проводная телекоммуникационная связь",
						"value": 1554,
						"level": 3,
						"children": [
							{
								"title": "Беспроводная телекоммуникационная связь",
								"value": 1555,
								"level": 4,
								"children": [
									{
										"title": "Беспроводная телекоммуникационная связь посредством единой транспортной среды",
										"value": 1556,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Организация вещания теле-, радиопрограмм посредством сети национального оператора телерадиовещания",
										"value": 1557,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая беспроводная телекоммуникационная связь",
										"value": 1558,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Проводная телекоммуникационная связь",
						"value": 1559,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области спутниковых телекоммуникаций",
								"value": 1560,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области спутниковых телекоммуникаций для государственных органов посредством единой транспортной среды",
										"value": 1561,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области спутниковых телекоммуникаций для целей телерадиовещания",
										"value": 1562,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области спутниковых телекоммуникаций для организации связи",
										"value": 1563,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Проводная телекоммуникационная связь",
						"value": 1564,
						"level": 3,
						"children": [
							{
								"title": "Прочая деятельность в области телекоммуникаций",
								"value": 1565,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по распространению телерадиопрограмм  посредством  сети Интернет",
										"value": 1566,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая деятельность в области телекоммуникаций, не включенная в другие группировки",
										"value": 1567,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Компьютерное программирование, консультационные и другие сопутствующие услуги",
				"value": 1568,
				"level": 2,
				"children": [
					{
						"title": "Компьютерное программирование, консультационные и другие сопутствующие услуги",
						"value": 1569,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области компьютерного программирования",
								"value": 1570,
								"level": 4,
								"children": [
									{
										"title": "Разработка программного обеспечения",
										"value": 1571,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Сопровождение программного обеспечения",
										"value": 1572,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Консультационные услуги в области информационных технологий",
								"value": 1573,
								"level": 4,
								"children": [
									{
										"title": "Консультационные  и практические услуги в области информационных технологий",
										"value": 1574,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Планирование и проектирование коммерческих информационных систем",
										"value": 1575,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по управлению компьютерным оборудованием",
								"value": 1576,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по управлению информационно-коммуникационной инфраструктурой  в рамках формирования и развития государственных электронных информационных ресурсов и систем",
										"value": 1577,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по управлению информационно-коммуникационным оборудованием",
										"value": 1578,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Другие виды деятельности в области информационных технологий и информационных систем",
								"value": 1579,
								"level": 4,
								"children": [
									{
										"title": "Другие виды деятельности в области информационных технологий и информационных систем в рамках автоматизации функций государственных органов, учреждений и организаций",
										"value": 1580,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области кибербезопасности",
										"value": 1581,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Другие виды деятельности в области информационных технологий и информационных систем, не включенные в другие группировки",
										"value": 1582,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность в области информационного обслуживания",
				"value": 1583,
				"level": 2,
				"children": [
					{
						"title": "Обработка данных, размещение приложений (прикладных программ) и связанная с этим деятельность; деятельность веб-порталов",
						"value": 1584,
						"level": 3,
						"children": [
							{
								"title": "Обработка данных, размещение приложений (прикладных программ) и связанная с этим деятельность",
								"value": 1585,
								"level": 4,
								"children": [
									{
										"title": "Размещений приложений (прикладных программ) и связанная с этим деятельность",
										"value": 1586,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Информационно-методологическое обеспечение с сопровождением информационных систем и баз данных",
										"value": 1587,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по осуществлению цифрового майнинга",
										"value": 2109,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность веб-порталов",
								"value": 1588,
								"level": 4,
								"children": [
									{
										"title": "Деятельность веб-порталов",
										"value": 1589,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Обработка данных, размещение приложений (прикладных программ) и связанная с этим деятельность; деятельность веб-порталов",
						"value": 1590,
						"level": 3,
						"children": [
							{
								"title": "Деятельность информационных агентств",
								"value": 1591,
								"level": 4,
								"children": [
									{
										"title": "Деятельность информационных агентств",
										"value": 1592,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность в области информационного обслуживания, не включенная в другие группировки",
								"value": 1593,
								"level": 4,
								"children": [
									{
										"title": "Прочие виды деятельности, связанные с предоставлением компьютеризированных услуг телефонной связи",
										"value": 1594,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие виды деятельности в области информационного обслуживания",
										"value": 1595,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Финансовая и страховая деятельность",
		"value": 1596,
		"level": 1,
		"children": [
			{
				"title": "Финансовое посредничество, кроме страхования и пенсионного обеспечения",
				"value": 1597,
				"level": 2,
				"children": [
					{
						"title": "Денежное посредничество",
						"value": 1606,
						"level": 3,
						"children": [
							{
								"title": "Деятельность холдинговых компаний",
								"value": 1607,
								"level": 4,
								"children": [
									{
										"title": "Деятельность холдинговых компаний",
										"value": 1608,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Денежное посредничество",
						"value": 1598,
						"level": 3,
						"children": [
							{
								"title": "Деятельность национального банка",
								"value": 1599,
								"level": 4,
								"children": [
									{
										"title": "Деятельность национального банка",
										"value": 1600,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочее денежное посредничество",
								"value": 1601,
								"level": 4,
								"children": [
									{
										"title": "Деятельность банков, за исключением, банка, являющегося национальным институтом развития, и его дочерней организации-лизингодателя",
										"value": 1602,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность сберегательных банков",
										"value": 1603,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность банка, являющегося национальным институтом развития, и его дочерней организации-лизингодателя",
										"value": 1604,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Денежное посредничество прочих финансовых учреждений",
										"value": 1605,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Денежное посредничество",
						"value": 1609,
						"level": 3,
						"children": [
							{
								"title": "Деятельность трастовых компаний, инвестиционных фондов и аналогичных финансовых организаций",
								"value": 1610,
								"level": 4,
								"children": [
									{
										"title": "Деятельность трастовых компаний, инвестиционных фондов и аналогичных финансовых организаций",
										"value": 1611,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Денежное посредничество",
						"value": 1612,
						"level": 3,
						"children": [
							{
								"title": "Финансовый лизинг",
								"value": 1613,
								"level": 4,
								"children": [
									{
										"title": "Финансовый лизинг, кроме финансового лизинга медицинского оборудования и техники",
										"value": 1614,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Финансовый лизинг медицинского оборудования и техники",
										"value": 1615,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие виды кредитования",
								"value": 1616,
								"level": 4,
								"children": [
									{
										"title": "Деятельность ломбардов",
										"value": 1617,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность специальных фондов финансовой поддержки субъектов частного предпринимательства",
										"value": 1618,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие виды кредитования, не включенные в другие группировки",
										"value": 1619,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочее финансовое посредничество, кроме страхования и пенсионного обеспечения, не включенное в другие группировки",
								"value": 1620,
								"level": 4,
								"children": [
									{
										"title": "Финансово-экономические услуги, оказываемые субъектам индустриально-инновационной деятельности и агропромышленного комплекса",
										"value": 1621,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Другие финансовые услуги, финансирование в различных отраслях экономики, инвестиционная деятельность",
										"value": 1622,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Другие виды финансовых услуг, кроме страхования и пенсионного обеспечения, не включенные в другие группировки",
										"value": 1623,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Страхование, перестрахование и пенсионное обеспечение, кроме обязательного социального обеспечения",
				"value": 1624,
				"level": 2,
				"children": [
					{
						"title": "Страхование",
						"value": 1625,
						"level": 3,
						"children": [
							{
								"title": "Страхование жизни",
								"value": 1626,
								"level": 4,
								"children": [
									{
										"title": "Государственное страхование жизни",
										"value": 1627,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Негосударственное страхование жизни",
										"value": 1628,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Страхование ущерба",
								"value": 1629,
								"level": 4,
								"children": [
									{
										"title": "Государственное страхование ущерба",
										"value": 1630,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Негосударственное страхование ущерба",
										"value": 1631,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Страхование",
						"value": 1632,
						"level": 3,
						"children": [
							{
								"title": "Перестрахование",
								"value": 1633,
								"level": 4,
								"children": [
									{
										"title": "Перестрахование",
										"value": 1634,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Страхование",
						"value": 1635,
						"level": 3,
						"children": [
							{
								"title": "Пенсионное обеспечение",
								"value": 1636,
								"level": 4,
								"children": [
									{
										"title": "Государственное пенсионное обеспечение",
										"value": 1637,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Негосударственное пенсионное обеспечение",
										"value": 1638,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Вспомогательная деятельность в сфере финансовых услуг и страхования",
				"value": 1639,
				"level": 2,
				"children": [
					{
						"title": "Вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
						"value": 1640,
						"level": 3,
						"children": [
							{
								"title": "Управление финансовыми рынками",
								"value": 1641,
								"level": 4,
								"children": [
									{
										"title": "Деятельность товарных бирж",
										"value": 1642,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность, связанная с управлением финансовыми рынками",
										"value": 1643,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по ведению системы реестров держателей ценных бумаг и участников хозяйственных товариществ",
										"value": 1644,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Брокерская деятельность по сделкам с ценными бумагами и товарами",
								"value": 1645,
								"level": 4,
								"children": [
									{
										"title": "Брокерская и дилерская деятельность, связанная с управлением активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
										"value": 1646,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Брокерская деятельность по сделкам с ценными бумагами и товарами, за исключением деятельности, связанной с управлением активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
										"value": 1647,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
								"value": 1648,
								"level": 4,
								"children": [
									{
										"title": "Прочая вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
										"value": 1649,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
						"value": 1650,
						"level": 3,
						"children": [
							{
								"title": "Оценка страховых рисков и убытков",
								"value": 1651,
								"level": 4,
								"children": [
									{
										"title": "Оценка страховых рисков и убытков",
										"value": 1652,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность страховых агентов и брокеров",
								"value": 1653,
								"level": 4,
								"children": [
									{
										"title": "Деятельность страховых агентов и брокеров",
										"value": 1654,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая вспомогательная деятельность по страхованию и пенсионному обеспечению",
								"value": 1655,
								"level": 4,
								"children": [
									{
										"title": "Прочая вспомогательная деятельность по страхованию и пенсионному обеспечению",
										"value": 1656,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
						"value": 1657,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по управлению фондами",
								"value": 1658,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по управлению активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
										"value": 1659,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по доверительному управлению портфелем активов фондов",
										"value": 1660,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Операции с недвижимым имуществом",
		"value": 1661,
		"level": 1,
		"children": [
			{
				"title": "Операции с недвижимым имуществом",
				"value": 1662,
				"level": 2,
				"children": [
					{
						"title": "Покупка и продажа собственного недвижимого имущества",
						"value": 1663,
						"level": 3,
						"children": [
							{
								"title": "Покупка и продажа собственного недвижимого имущества",
								"value": 1664,
								"level": 4,
								"children": [
									{
										"title": "Покупка и продажа многоквартирных и жилых домов (особняков)",
										"value": 1665,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Покупка и продажа прочей недвижимости",
										"value": 1666,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Покупка и продажа собственного недвижимого имущества",
						"value": 1667,
						"level": 3,
						"children": [
							{
								"title": "Аренда и управление собственной или арендуемой недвижимостью",
								"value": 1668,
								"level": 4,
								"children": [
									{
										"title": "Аренда и управление собственной недвижимостью",
										"value": 1669,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Аренда (субаренда) и эксплуатация арендуемой недвижимости",
										"value": 1670,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Аренда и эксплуатация торгового рынка",
										"value": 1671,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Покупка и продажа собственного недвижимого имущества",
						"value": 1672,
						"level": 3,
						"children": [
							{
								"title": "Деятельность агентств по операциям с недвижимым имуществом",
								"value": 1673,
								"level": 4,
								"children": [
									{
										"title": "Посреднические услуги при купле-продаже и сдаче внаем недвижимого имущества производственно-технического назначения",
										"value": 1674,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Посреднические услуги при купле-продаже и сдаче внаем жилья и другого недвижимого имущества непроизводственного назначения",
										"value": 1675,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Управление недвижимым имуществом за вознаграждение или на договорной основе",
								"value": 1676,
								"level": 4,
								"children": [
									{
										"title": "Управление недвижимостью за вознаграждение или на договорной основе",
										"value": 1677,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Обеспечение мер при эксплуатаций «Эталонного центра»",
										"value": 1678,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Профессиональная, научная и техническая деятельность",
		"value": 1679,
		"level": 1,
		"children": [
			{
				"title": "Деятельность в области права и бухгалтерского учета",
				"value": 1680,
				"level": 2,
				"children": [
					{
						"title": "Деятельность в области права",
						"value": 1681,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области права",
								"value": 1682,
								"level": 4,
								"children": [
									{
										"title": "Адвокатская деятельность",
										"value": 1683,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Нотариальная деятельность",
										"value": 1684,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая деятельность в области права",
										"value": 1685,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области права",
						"value": 1686,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области бухгалтерского учета и аудита; консультирование по налогообложению",
								"value": 1687,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по проведению финансовой ревизии (аудита)",
										"value": 1688,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области составления счетов и бухгалтерского учета",
										"value": 1689,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Консультрование в области налогообложения",
										"value": 1690,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность, связанная с неплатежеспособностью и взысканием задолженности",
										"value": 1691,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области бухгалтерского, налогового учета и казначейских операций для национального управляющего холдинга",
										"value": 1692,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность головных компаний; консультирование по вопросам управления",
				"value": 1693,
				"level": 2,
				"children": [
					{
						"title": "Деятельность головных компаний",
						"value": 1694,
						"level": 3,
						"children": [
							{
								"title": "Деятельность головных компаний",
								"value": 1695,
								"level": 4,
								"children": [
									{
										"title": "Деятельность головных компаний, связанная с реализацией государственной политики индустриально-инновационного развития",
										"value": 1696,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность прочих головных компаний",
										"value": 1697,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность головных компаний",
						"value": 1698,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по взаимоотношениям и связью с общественностью",
								"value": 1699,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по взаимоотношениям и связью с общественностью",
										"value": 1700,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Консультирование по вопросам коммерческой деятельности и прочее консультирование по вопросам управления",
								"value": 1701,
								"level": 4,
								"children": [
									{
										"title": "Консультирование по вопросам коммерческой деятельности и управления",
										"value": 1702,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Консультативное сопровождение концессионных проектов и проектов государственно-частного партнерства",
										"value": 1703,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность в области архитектуры, инженерных изысканий, технических испытаний и анализа",
				"value": 1704,
				"level": 2,
				"children": [
					{
						"title": "Деятельность в области архитектуры, инженерных изысканий и предоставление технических консультаций в этих областях",
						"value": 1705,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области архитектуры",
								"value": 1706,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области архитектуры для объектов атомной промышленности и атомной энергетики",
										"value": 1707,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области архитектуры, за исключением объектов атомной промышленности и атомной энергетики  ",
										"value": 1708,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность в области инженерных изысканий и предоставление технических консультаций в этой области",
								"value": 1709,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области инженерно-технического проектирования, за исключением объектов атомной промышленности и атомной энергетики",
										"value": 1710,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по проведению геологической разведки и изысканий (без научных исследований и разработок)",
										"value": 1711,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Геодезическая деятельность",
										"value": 1712,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Землеустройство",
										"value": 1713,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области картографии",
										"value": 1714,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области инженерных изысканий и предоставление технических консультаций в этой области для объектов атомной промышленности и атомной энергетики",
										"value": 1715,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по предоставлению инженерно-технических консультаций",
										"value": 1716,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области технического регулирования, стандартизации, метрологии и оценки соответствия",
										"value": 2108,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области архитектуры, инженерных изысканий и предоставление технических консультаций в этих областях",
						"value": 1717,
						"level": 3,
						"children": [
							{
								"title": "Технические испытания и анализы",
								"value": 1718,
								"level": 4,
								"children": [
									{
										"title": "Деятельность санитарно-эпидемиологических организаций",
										"value": 1719,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность организаций санитарного просвещения",
										"value": 1720,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность нефтеперерабатывающих заводов по осуществлению лабораторных испытаний и анализов",
										"value": 1721,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лабораторно-аналитические исследования в геологической отрасли",
										"value": 1722,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность прочих учреждений, осуществляющих технические испытания и анализы",
										"value": 1723,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Научные исследования и разработки",
				"value": 1724,
				"level": 2,
				"children": [
					{
						"title": "Научные исследования и экспериментальные разработки в области естественных и технических наук ",
						"value": 1725,
						"level": 3,
						"children": [
							{
								"title": "Научные исследования и экспериментальные разработки в области биотехнологий",
								"value": 1726,
								"level": 4,
								"children": [
									{
										"title": "Научные исследования и экспериментальные разработки в области биотехнологий",
										"value": 1727,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие научные исследования и экспериментальные разработки в области естественных и технических наук ",
								"value": 1728,
								"level": 4,
								"children": [
									{
										"title": "Научные исследования и экспериментальные разработки в области проектирования, строительства, ремонта, содержания и диагностики автомобильных дорог и мостовых сооружений",
										"value": 1729,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Научно-исследовательские, опытно-методические и опытно-конструкторские работы в геологической отрасли",
										"value": 1730,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Научные исследования и разработки в области космической деятельности",
										"value": 1731,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Исследования и экспериментальные разработки в области мирного использования атомной энергии",
										"value": 1732,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Исследования и разработки в области противоинфекционных препаратов",
										"value": 1733,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие исследования и разработки в области естественных и технических наук ",
										"value": 1734,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Научные исследования и экспериментальные разработки в области естественных и технических наук ",
						"value": 1735,
						"level": 3,
						"children": [
							{
								"title": "Исследования и экспериментальные разработки в области общественных и гуманитарных наук",
								"value": 1736,
								"level": 4,
								"children": [
									{
										"title": "Прикладные исследования в области общественных и гуманитарных наук, направленные на содействие развитию отечественных производств",
										"value": 1737,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прикладные исследования в области общественных и гуманитарных наук, направленных на развитие индустрии",
										"value": 1738,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Исследования и экспериментальные разработки в области обороны и национальной безопасности",
										"value": 1739,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Междисциплинарные исследования и разработки в области экономического, бюджетного и стратегического планирования",
										"value": 1740,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Исследования и экспериментальные разработки в области общественных и гуманитарных наук, не включенные в другие группировки",
										"value": 1741,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Рекламная деятельность и исследование конъюнктуры рынка",
				"value": 1742,
				"level": 2,
				"children": [
					{
						"title": "Рекламная деятельность",
						"value": 1743,
						"level": 3,
						"children": [
							{
								"title": "Деятельность рекламных агентств",
								"value": 1744,
								"level": 4,
								"children": [
									{
										"title": "Деятельность рекламных агентств",
										"value": 1745,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Размещение рекламы в средствах массовой информации",
								"value": 1746,
								"level": 4,
								"children": [
									{
										"title": "Размещение рекламы в средствах массовой информации",
										"value": 1747,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Рекламная деятельность",
						"value": 1748,
						"level": 3,
						"children": [
							{
								"title": "Исследование конъюнктуры рынка и изучение общественного мнения",
								"value": 1749,
								"level": 4,
								"children": [
									{
										"title": "Исследование конъюнктуры рынка и изучение общественного мнения",
										"value": 1750,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Прочая профессиональная, научная и техническая деятельность",
				"value": 1751,
				"level": 2,
				"children": [
					{
						"title": "Специализированная дизайнерская деятельность",
						"value": 1752,
						"level": 3,
						"children": [
							{
								"title": "Специализированная дизайнерская деятельность",
								"value": 1753,
								"level": 4,
								"children": [
									{
										"title": "Специализированная дизайнерская деятельность",
										"value": 1754,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Специализированная дизайнерская деятельность",
						"value": 1755,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области фотографии",
								"value": 1756,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области фотографии",
										"value": 1757,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Специализированная дизайнерская деятельность",
						"value": 1758,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по устному и письменному переводу",
								"value": 1759,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по устному и письменному переводу",
										"value": 1760,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Специализированная дизайнерская деятельность",
						"value": 1761,
						"level": 3,
						"children": [
							{
								"title": "Прочая профессиональная, научная и техническая деятельность, не включенная в другие группировки",
								"value": 1762,
								"level": 4,
								"children": [
									{
										"title": "Деятельность гидрометеорологической службы",
										"value": 1763,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Аккредитация в области оценки соответствия",
										"value": 1764,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность ведомственных служб, занимающихся инновационными технологиями (медицинские, образовательные, консультационные и др.)",
										"value": 1765,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Иная профессиональная, научная и техническая деятельность, не включенная в другие группировки",
										"value": 1766,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Ветеринарная деятельность",
				"value": 1767,
				"level": 2,
				"children": [
					{
						"title": "Ветеринарная деятельность",
						"value": 1768,
						"level": 3,
						"children": [
							{
								"title": "Ветеринарная деятельность",
								"value": 1769,
								"level": 4,
								"children": [
									{
										"title": "Ветеринарная деятельность",
										"value": 1770,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Деятельность в области административного и вспомогательного обслуживания",
		"value": 1771,
		"level": 1,
		"children": [
			{
				"title": "Аренда, прокат и лизинг",
				"value": 1772,
				"level": 2,
				"children": [
					{
						"title": "Аренда и лизинг автомобилей",
						"value": 1773,
						"level": 3,
						"children": [
							{
								"title": "Аренда и лизинг легковых автомобилей и легких автотранспортных средств",
								"value": 1774,
								"level": 4,
								"children": [
									{
										"title": "Аренда легковых автомобилей и легких автотранспортных средств",
										"value": 1775,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг легковых автомобилей и легких автотранспортных средств",
										"value": 1776,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг грузовых автомобилей",
								"value": 1777,
								"level": 4,
								"children": [
									{
										"title": "Аренда грузовых автомобилей",
										"value": 1778,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг грузовых автомобилей",
										"value": 1779,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Аренда и лизинг автомобилей",
						"value": 1780,
						"level": 3,
						"children": [
							{
								"title": "Аренда и лизинг развлекательного и спортивного оборудования",
								"value": 1781,
								"level": 4,
								"children": [
									{
										"title": "Аренда и лизинг развлекательного и спортивного оборудования",
										"value": 1782,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прокат видеокассет и дисков",
								"value": 1783,
								"level": 4,
								"children": [
									{
										"title": "Прокат видеокассет и дисков",
										"value": 1784,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг прочих предметов личного потребления и бытовых товаров",
								"value": 1785,
								"level": 4,
								"children": [
									{
										"title": "Аренда и лизинг прочих предметов личного потребления и бытовых товаров",
										"value": 1786,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Аренда и лизинг автомобилей",
						"value": 1787,
						"level": 3,
						"children": [
							{
								"title": "Аренда и лизинг сельскохозяйственных машин и оборудования",
								"value": 1788,
								"level": 4,
								"children": [
									{
										"title": "Аренда сельскохозяйственных машин и оборудования",
										"value": 1789,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг сельскохозяйственных машин  и оборудования",
										"value": 1790,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг строительных машин и оборудования ",
								"value": 1791,
								"level": 4,
								"children": [
									{
										"title": "Аренда строительных машин и оборудования ",
										"value": 1792,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг строительных машин и оборудования ",
										"value": 1793,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг офисных машин и оборудования, включая вычислительную технику",
								"value": 1794,
								"level": 4,
								"children": [
									{
										"title": "Аренда компьютерного и периферийного оборудования для инфотелекоммуникационной системы",
										"value": 1795,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг компьютерного и периферийного оборудования для инфотелекоммуникационной системы",
										"value": 1796,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Аренда прочих офисных машин и оборудования",
										"value": 1797,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг прочих офисных машин и оборудования",
										"value": 1798,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг водных транспортных средств и оборудования",
								"value": 1799,
								"level": 4,
								"children": [
									{
										"title": "Аренда водных транспортных средств и оборудования",
										"value": 1800,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг водных транспортных средств и оборудования",
										"value": 1801,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг воздушных транспортных средств и оборудования",
								"value": 1802,
								"level": 4,
								"children": [
									{
										"title": "Аренда воздушных транспортных средств и оборудования",
										"value": 1803,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг воздушных транспортных средств и оборудования",
										"value": 1804,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Аренда и лизинг прочих машин, оборудования и материальных активов, не включенных в другие группировки",
								"value": 1805,
								"level": 4,
								"children": [
									{
										"title": "Аренда прочих машин, оборудования и материальных активов, не включенных в другие группировки",
										"value": 1806,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Лизинг прочих машин, оборудования и материальных активов, не включенных в другие группировки",
										"value": 1807,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Аренда и лизинг автомобилей",
						"value": 1808,
						"level": 3,
						"children": [
							{
								"title": "Лизинг продуктов интеллектуальной собственности и аналогичных  продуктов, кроме работ, защищенным авторским правом",
								"value": 1809,
								"level": 4,
								"children": [
									{
										"title": "Лизинг продуктов интеллектуальной собственности и аналогичных  продуктов, кроме работ, защищенным авторским правом",
										"value": 1810,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность в области трудоустройства",
				"value": 1811,
				"level": 2,
				"children": [
					{
						"title": "Деятельность агентств по трудоустройству",
						"value": 1812,
						"level": 3,
						"children": [
							{
								"title": "Деятельность агентств по трудоустройству",
								"value": 1813,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентств по трудоустройству",
										"value": 1814,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность агентств по трудоустройству",
						"value": 1815,
						"level": 3,
						"children": [
							{
								"title": "Деятельность агентств по временному трудоустройству",
								"value": 1816,
								"level": 4,
								"children": [
									{
										"title": "Деятельность агентств по временному трудоустройству",
										"value": 1817,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность агентств по трудоустройству",
						"value": 1818,
						"level": 3,
						"children": [
							{
								"title": "Прочая деятельность по обеспечению трудовыми ресурсами (персоналом)",
								"value": 1819,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность по обеспечению трудовыми ресурсами (персоналом), кроме деятельности организаций, учрежденных национальными компаниями",
										"value": 1820,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность организаций, учрежденных национальными компаниями, по предоставлению технического и вспомогательного персонала для обслуживания стратегических объектов",
										"value": 1821,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность по кадровому делопроизводству для национального управляющего холдинга",
										"value": 1822,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность туристских агентств и операторов, бронирование и сопутствующая деятельность",
				"value": 1823,
				"level": 2,
				"children": [
					{
						"title": "Деятельность туристских агентств и операторов",
						"value": 1824,
						"level": 3,
						"children": [
							{
								"title": "Деятельность туристских агентств",
								"value": 1825,
								"level": 4,
								"children": [
									{
										"title": "Деятельность туристских агентств",
										"value": 1826,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность туристских операторов",
								"value": 1827,
								"level": 4,
								"children": [
									{
										"title": "Деятельность туристских операторов",
										"value": 1828,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность туристских агентств и операторов",
						"value": 1829,
						"level": 3,
						"children": [
							{
								"title": "Прочие услуги по бронированию и сопутствующая деятельность",
								"value": 1830,
								"level": 4,
								"children": [
									{
										"title": "Прочие услуги по бронированию и сопутствующая деятельность",
										"value": 1831,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность по обеспечению безопасности и проведению расследований",
				"value": 1832,
				"level": 2,
				"children": [
					{
						"title": "Деятельность по обеспечению безопасности частных лиц и имущества",
						"value": 1833,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по обеспечению безопасности частных лиц и имущества",
								"value": 1834,
								"level": 4,
								"children": [
									{
										"title": "Деятельность частных охранных служб",
										"value": 1835,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность охранных организаций, учрежденных национальными компаниями",
										"value": 1836,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность по обеспечению безопасности частных лиц и имущества",
						"value": 1837,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области систем обеспечения безопасности",
								"value": 1838,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области систем обеспечения безопасности",
										"value": 1839,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность по обеспечению безопасности частных лиц и имущества",
						"value": 1840,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по проведению расследований",
								"value": 1841,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по проведению расследований",
										"value": 1842,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность по обслуживанию зданий и благоустройству территорий",
				"value": 1843,
				"level": 2,
				"children": [
					{
						"title": "Комплексное обслуживание объектов",
						"value": 1844,
						"level": 3,
						"children": [
							{
								"title": "Комплексное обслуживание объектов",
								"value": 1845,
								"level": 4,
								"children": [
									{
										"title": "Комплексное обслуживание объектов",
										"value": 1846,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Комплексное обслуживание жилых объектов",
										"value": 2110,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Комплексное обслуживание коммерческих объектов",
										"value": 2111,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Комплексное обслуживание объектов",
						"value": 1847,
						"level": 3,
						"children": [
							{
								"title": "Общая уборка зданий",
								"value": 1848,
								"level": 4,
								"children": [
									{
										"title": "Общая уборка зданий",
										"value": 1849,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Общая уборка жилых домов",
										"value": 2112,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Общая уборка коммерческих  зданий",
										"value": 2113,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность (специализированная) по уборке зданий, чистке промышленных машин и оборудования",
								"value": 1850,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность (специализированная) по уборке зданий, чистке промышленных машин и оборудования",
										"value": 1851,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность по уборке",
								"value": 1852,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность по уборке",
										"value": 1853,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Комплексное обслуживание объектов",
						"value": 1854,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по благоустройству территорий",
								"value": 1855,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по благоустройству территорий",
										"value": 1856,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность в области офисного административного и вспомогательного обслуживания, направленная на поддержание коммерческой деятельности",
				"value": 1857,
				"level": 2,
				"children": [
					{
						"title": "Деятельность в области офисного административного и вспомогательного обслуживания",
						"value": 1858,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по предоставлению комплексных офисных административных услуг",
								"value": 1859,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по предоставлению комплексных офисных административных услуг",
										"value": 1860,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по копированию, подготовке документов и прочая специализированная офисная вспомогательная деятельность",
								"value": 1861,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по копированию, подготовке документов и прочая специализированная офисная вспомогательная деятельность",
										"value": 1862,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области офисного административного и вспомогательного обслуживания",
						"value": 1863,
						"level": 3,
						"children": [
							{
								"title": "Деятельность телефонных справочно-информационных служб",
								"value": 1864,
								"level": 4,
								"children": [
									{
										"title": "Деятельность телефонных справочно-информационных служб",
										"value": 1865,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области офисного административного и вспомогательного обслуживания",
						"value": 1866,
						"level": 3,
						"children": [
							{
								"title": "Организация конференций и торговых выставок",
								"value": 1867,
								"level": 4,
								"children": [
									{
										"title": "Организация конференций и торговых выставок",
										"value": 1868,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области офисного административного и вспомогательного обслуживания",
						"value": 1869,
						"level": 3,
						"children": [
							{
								"title": "Деятельность агентств по сбору платежей и кредитных бюро",
								"value": 1870,
								"level": 4,
								"children": [
									{
										"title": "Деятельность государственного кредитного бюро",
										"value": 1871,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность агентств по сбору платежей и кредитных бюро, за исключением государственного",
										"value": 1872,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Упаковывание",
								"value": 1873,
								"level": 4,
								"children": [
									{
										"title": "Упаковывание",
										"value": 1874,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность по предоставлению вспомогательных коммерческих услуг, не включенная в другие группировки",
								"value": 1875,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность по предоставлению вспомогательных коммерческих услуг, не включенная в другие группировки",
										"value": 1876,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Государственное управление и оборона; обязательное  социальное обеспечение",
		"value": 1877,
		"level": 1,
		"children": [
			{
				"title": "Государственное управление и оборона; обязательное социальное обеспечение",
				"value": 1878,
				"level": 2,
				"children": [
					{
						"title": "Государственное управление общего характера и управление социально-экономической политикой общества",
						"value": 1879,
						"level": 3,
						"children": [
							{
								"title": "Государственное управление общего характера",
								"value": 1880,
								"level": 4,
								"children": [
									{
										"title": "Деятельность республиканских органов управления",
										"value": 1881,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность региональных органов управления",
										"value": 1882,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность местных органов управления",
										"value": 1883,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность сельских и поселковых органов управления",
										"value": 1884,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность, связанная с налогообложением",
										"value": 1885,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность таможни",
										"value": 1886,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность в области статистики и социологии",
										"value": 1887,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Регулирование деятельности учреждений здравоохранения, образования, культуры и других социальных услуг, кроме социального обеспечения",
								"value": 1888,
								"level": 4,
								"children": [
									{
										"title": "Регулирование деятельности учреждений здравоохранения, образования, культуры и других социальных услуг, кроме социального обеспечения",
										"value": 1889,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Регулирование и содействие эффективному ведению экономической деятельности",
								"value": 1890,
								"level": 4,
								"children": [
									{
										"title": "Регулирование и содействие эффективному ведению экономической деятельности",
										"value": 1891,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Государственное управление общего характера и управление социально-экономической политикой общества",
						"value": 1892,
						"level": 3,
						"children": [
							{
								"title": "Международная деятельность",
								"value": 1893,
								"level": 4,
								"children": [
									{
										"title": "Международная деятельность",
										"value": 1894,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Оборонная деятельность",
								"value": 1895,
								"level": 4,
								"children": [
									{
										"title": "Оборонная деятельность",
										"value": 1896,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность в области юстиции и правосудия",
								"value": 1897,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области юстиции и правосудия",
										"value": 1898,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по обеспечению общественного порядка и безопасности",
								"value": 1899,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по обеспечению общественного порядка и безопасности",
										"value": 1900,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях",
								"value": 1901,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях",
										"value": 1902,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Государственное управление общего характера и управление социально-экономической политикой общества",
						"value": 1903,
						"level": 3,
						"children": [
							{
								"title": "Деятельность в области обязательного социального страхования",
								"value": 1904,
								"level": 4,
								"children": [
									{
										"title": "Деятельность в области обязательного социального страхования",
										"value": 1905,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Образование",
		"value": 1906,
		"level": 1,
		"children": [
			{
				"title": "Образование",
				"value": 1907,
				"level": 2,
				"children": [
					{
						"title": "Дошкольное образование",
						"value": 1914,
						"level": 3,
						"children": [
							{
								"title": "Основное и общее среднее образование",
								"value": 1915,
								"level": 4,
								"children": [
									{
										"title": "Основное и общее среднее образование",
										"value": 1916,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Техническое и профессиональное среднее образование",
								"value": 1917,
								"level": 4,
								"children": [
									{
										"title": "Профессионально-техническое образование",
										"value": 1918,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Среднее специальное образование",
										"value": 1919,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дошкольное образование",
						"value": 1908,
						"level": 3,
						"children": [
							{
								"title": "Дошкольное образование",
								"value": 1909,
								"level": 4,
								"children": [
									{
										"title": "Дошкольное образование",
										"value": 1910,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дошкольное образование",
						"value": 1911,
						"level": 3,
						"children": [
							{
								"title": "Начальное образование (1-й уровень)",
								"value": 1912,
								"level": 4,
								"children": [
									{
										"title": "Начальное образование (1-й уровень)",
										"value": 1913,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дошкольное образование",
						"value": 1920,
						"level": 3,
						"children": [
							{
								"title": "Послесреднее образование",
								"value": 1921,
								"level": 4,
								"children": [
									{
										"title": "Послесреднее образование",
										"value": 1922,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Высшее и послевузовское образование",
								"value": 1923,
								"level": 4,
								"children": [
									{
										"title": "Высшее образование",
										"value": 1924,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Послевузовское образование",
										"value": 1925,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дошкольное образование",
						"value": 1926,
						"level": 3,
						"children": [
							{
								"title": "Образование в области спорта и отдыха",
								"value": 1927,
								"level": 4,
								"children": [
									{
										"title": "Образование в области спорта и отдыха",
										"value": 1928,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Образование в области культуры",
								"value": 1929,
								"level": 4,
								"children": [
									{
										"title": "Образование в области культуры",
										"value": 1930,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность школ подготовки водителей транспортных средств",
								"value": 1931,
								"level": 4,
								"children": [
									{
										"title": "Деятельность школ подготовки водителей транспортных средств",
										"value": 1932,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочие виды образования, не включенные в другие группировки",
								"value": 1933,
								"level": 4,
								"children": [
									{
										"title": "Деятельность вечерних общеобразовательных школ",
										"value": 1934,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Виды образования, предоставляемые национальными компаниями и их дочерними организациями",
										"value": 1935,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая деятельность в области образования, не включенная в другие группировки",
										"value": 1936,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Дошкольное образование",
						"value": 1937,
						"level": 3,
						"children": [
							{
								"title": "Вспомогательная деятельность в области образования",
								"value": 1938,
								"level": 4,
								"children": [
									{
										"title": "Вспомогательная деятельность в области образования, предоставляемая национальными компаниями и их дочерними организациями",
										"value": 1939,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочая вспомогательная деятельность в области образования",
										"value": 1940,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Здравоохранение и социальное обслуживание населения",
		"value": 1941,
		"level": 1,
		"children": [
			{
				"title": "Деятельность в области здравоохранения",
				"value": 1942,
				"level": 2,
				"children": [
					{
						"title": "Деятельность больничных организаций",
						"value": 1943,
						"level": 3,
						"children": [
							{
								"title": "Деятельность больничных организаций",
								"value": 1944,
								"level": 4,
								"children": [
									{
										"title": "Деятельность больниц широкого профиля и специализированных больниц",
										"value": 1945,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность родильных домов",
										"value": 1946,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность санаторно-курортных организаций",
										"value": 1947,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность лепрозориев",
										"value": 1948,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность других лечебных учреждений, имеющих стационары",
										"value": 1949,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность больничных организаций",
						"value": 1950,
						"level": 3,
						"children": [
							{
								"title": "Общая врачебная практика",
								"value": 1951,
								"level": 4,
								"children": [
									{
										"title": "Общая врачебная практика",
										"value": 1952,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Специальная врачебная практика",
								"value": 1953,
								"level": 4,
								"children": [
									{
										"title": "Специальная врачебная практика",
										"value": 1954,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Стоматологическая деятельность",
								"value": 1955,
								"level": 4,
								"children": [
									{
										"title": "Стоматологическая деятельность",
										"value": 1956,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность больничных организаций",
						"value": 1957,
						"level": 3,
						"children": [
							{
								"title": "Прочая деятельность в области здравоохранения",
								"value": 1958,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность в области здравоохранения",
										"value": 1959,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Предоставление социальных услуг с обеспечением проживания",
				"value": 1960,
				"level": 2,
				"children": [
					{
						"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
						"value": 1961,
						"level": 3,
						"children": [
							{
								"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
								"value": 1962,
								"level": 4,
								"children": [
									{
										"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
										"value": 1963,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
						"value": 1964,
						"level": 3,
						"children": [
							{
								"title": "Предоставление социальных услуг с обеспечением проживания лицам, страдающим  психическими расстройствами (заболеваниями), с умственными и физическими недостатками, алкогольной или наркотической зависимостью",
								"value": 1965,
								"level": 4,
								"children": [
									{
										"title": "Предоставление социальных услуг с обеспечением проживания лицам, страдающим  психическими расстройствами (заболеваниями), с умственными и физическими недостатками, алкогольной или наркотической зависимостью",
										"value": 1966,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
						"value": 1967,
						"level": 3,
						"children": [
							{
								"title": "Предоставление социальных услуг  пожилым гражданам и инвалидам с обеспечением проживания",
								"value": 1968,
								"level": 4,
								"children": [
									{
										"title": "Предоставление социальных услуг  пожилым гражданам и инвалидам с обеспечением проживания",
										"value": 1969,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление социальных услуг с услугами средних медицинских работников с обеспечением проживания",
						"value": 1970,
						"level": 3,
						"children": [
							{
								"title": "Ппредоставление прочих социальных услуг  с обеспечением проживания",
								"value": 1971,
								"level": 4,
								"children": [
									{
										"title": "Ппредоставление прочих социальных услуг  с обеспечением проживания",
										"value": 1972,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Предоставление социальных услуг без обеспечения проживания",
				"value": 1973,
				"level": 2,
				"children": [
					{
						"title": "Предоставление социальных услуг без обеспечения проживания пожилым гражданам и инвалидам",
						"value": 1974,
						"level": 3,
						"children": [
							{
								"title": "Предоставление социальных услуг без обеспечения проживания пожилым гражданам и инвалидам",
								"value": 1975,
								"level": 4,
								"children": [
									{
										"title": "Предоставление социальных услуг без обеспечения проживания пожилым гражданам и инвалидам",
										"value": 1976,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Предоставление социальных услуг без обеспечения проживания пожилым гражданам и инвалидам",
						"value": 1977,
						"level": 3,
						"children": [
							{
								"title": "Дневной уход за детьми",
								"value": 1978,
								"level": 4,
								"children": [
									{
										"title": "Дневной уход за детьми",
										"value": 1979,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Предоставление прочих социальных услуг без обеспечения проживания, не включенные в другие группировки",
								"value": 1980,
								"level": 4,
								"children": [
									{
										"title": "Предоставление прочих социальных услуг без обеспечения проживания, не включенные в другие группировки",
										"value": 1981,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Искусство, развлечения и отдых",
		"value": 1982,
		"level": 1,
		"children": [
			{
				"title": "Деятельность в области творчества, искусства и развлечений",
				"value": 1983,
				"level": 2,
				"children": [
					{
						"title": "Деятельность в области творчества, искусства и развлечений",
						"value": 1984,
						"level": 3,
						"children": [
							{
								"title": "Артистическая деятельность",
								"value": 1985,
								"level": 4,
								"children": [
									{
										"title": "Театральная деятельность",
										"value": 1986,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Концертная деятельность",
										"value": 1987,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность цирков",
										"value": 1988,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность, способствующая проведению культурно-зрелищных мероприятий",
								"value": 1989,
								"level": 4,
								"children": [
									{
										"title": "Деятельность, способствующая проведению культурно-зрелищных мероприятий",
										"value": 1990,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Художественное и литературное творчество",
								"value": 1991,
								"level": 4,
								"children": [
									{
										"title": "Художественное и литературное творчество",
										"value": 1992,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность концертных и театральных залов",
								"value": 1993,
								"level": 4,
								"children": [
									{
										"title": "Деятельность концертных и театральных залов",
										"value": 1994,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность библиотек, архивов, музеев и прочая деятельность в области культуры",
				"value": 1995,
				"level": 2,
				"children": [
					{
						"title": "Деятельность библиотек, архивов, музеев и прочая деятельность в области культуры",
						"value": 1996,
						"level": 3,
						"children": [
							{
								"title": "Деятельность библиотек и архивов",
								"value": 1997,
								"level": 4,
								"children": [
									{
										"title": "Деятельность фильмо- и фонотек",
										"value": 1998,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Библиотечная деятельность, включая деятельность читальных залов, лекториев, демонстрационных залов",
										"value": 1999,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность архивов",
										"value": 2000,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность книжных палат",
										"value": 2001,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность музеев",
								"value": 2002,
								"level": 4,
								"children": [
									{
										"title": "Деятельность музеев",
										"value": 2003,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность исторических мест и зданий и аналогичных туристических достопримечательностей",
								"value": 2004,
								"level": 4,
								"children": [
									{
										"title": "Деятельность исторических мест и зданий и аналогичных туристических достопримечательностей",
										"value": 2005,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность ботанических садов, зоопарков и природных заповедников",
								"value": 2006,
								"level": 4,
								"children": [
									{
										"title": "Деятельность ботанических садов и зоопарков",
										"value": 2007,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность природных заповедников, охрана дикой природы",
										"value": 2008,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность по организации азартных игр и заключению пари",
				"value": 2009,
				"level": 2,
				"children": [
					{
						"title": "Деятельность по организации азартных игр и заключению пари",
						"value": 2010,
						"level": 3,
						"children": [
							{
								"title": "Деятельность по организации азартных игр и заключению пари",
								"value": 2011,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по организации азартных игр и заключению пари",
										"value": 2012,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность в области спорта, организации отдыха и развлечений",
				"value": 2013,
				"level": 2,
				"children": [
					{
						"title": "Деятельность в области спорта",
						"value": 2014,
						"level": 3,
						"children": [
							{
								"title": "Эксплуатация спортивных сооружений",
								"value": 2015,
								"level": 4,
								"children": [
									{
										"title": "Эксплуатация спортивных сооружений",
										"value": 2016,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность спортивных клубов",
								"value": 2017,
								"level": 4,
								"children": [
									{
										"title": "Деятельность спортивных клубов",
										"value": 2018,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность фитнес-клубов",
								"value": 2019,
								"level": 4,
								"children": [
									{
										"title": "Деятельность фитнес-клубов",
										"value": 2020,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Прочая деятельность в области спорта",
								"value": 2021,
								"level": 4,
								"children": [
									{
										"title": "Прочая деятельность в области спорта",
										"value": 2022,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность в области спорта",
						"value": 2023,
						"level": 3,
						"children": [
							{
								"title": "Прочая деятельность по организации отдыха и развлечений",
								"value": 2026,
								"level": 4,
								"children": [
									{
										"title": "Деятельность танцевальных залов, дискотек",
										"value": 2027,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность родео, тиров",
										"value": 2028,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Деятельность кукольных театров",
										"value": 2029,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Прочие виды деятельности по организации отдыха и развлечений",
										"value": 2030,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность развлекательных и тематических парков",
								"value": 2024,
								"level": 4,
								"children": [
									{
										"title": "Деятельность развлекательных и тематических парков",
										"value": 2025,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Предоставление прочих видов услуг",
		"value": 2031,
		"level": 1,
		"children": [
			{
				"title": "Деятельность общественных объединений (организаций)",
				"value": 2032,
				"level": 2,
				"children": [
					{
						"title": "Деятельность коммерческих, предпринимательских и профессиональных общественных организаций",
						"value": 2033,
						"level": 3,
						"children": [
							{
								"title": "Деятельность коммерческих и предпринимательских общественных организаций",
								"value": 2034,
								"level": 4,
								"children": [
									{
										"title": "Деятельность коммерческих и предпринимательских общественных организаций",
										"value": 2035,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность профессиональных общественных организаций",
								"value": 2036,
								"level": 4,
								"children": [
									{
										"title": "Деятельность профессиональных общественных организаций",
										"value": 2037,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность коммерческих, предпринимательских и профессиональных общественных организаций",
						"value": 2038,
						"level": 3,
						"children": [
							{
								"title": "Деятельность профессиональных союзов",
								"value": 2039,
								"level": 4,
								"children": [
									{
										"title": "Деятельность профессиональных союзов",
										"value": 2040,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность коммерческих, предпринимательских и профессиональных общественных организаций",
						"value": 2041,
						"level": 3,
						"children": [
							{
								"title": "Деятельность религиозных организаций",
								"value": 2042,
								"level": 4,
								"children": [
									{
										"title": "Деятельность религиозных организаций",
										"value": 2043,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность политических организаций",
								"value": 2044,
								"level": 4,
								"children": [
									{
										"title": "Деятельность политических организаций",
										"value": 2045,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность прочих общественных организаций, не включенных в другие группировки",
								"value": 2046,
								"level": 4,
								"children": [
									{
										"title": "Деятельность прочих общественных организаций, не включенных в другие группировки",
										"value": 2047,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Ремонт компьютеров, предметов личного потребления и бытовых товаров",
				"value": 2048,
				"level": 2,
				"children": [
					{
						"title": "Ремонт компьютеров и коммуникационного оборудования",
						"value": 2049,
						"level": 3,
						"children": [
							{
								"title": "Ремонт компьютеров и периферийного оборудования",
								"value": 2050,
								"level": 4,
								"children": [
									{
										"title": "Ремонт компьютеров и периферийного оборудования",
										"value": 2051,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт коммуникационного оборудования",
								"value": 2052,
								"level": 4,
								"children": [
									{
										"title": "Ремонт коммуникационного оборудования",
										"value": 2053,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Ремонт компьютеров и коммуникационного оборудования",
						"value": 2054,
						"level": 3,
						"children": [
							{
								"title": "Ремонт  электронной бытовой техники",
								"value": 2055,
								"level": 4,
								"children": [
									{
										"title": "Ремонт  электронной бытовой техники",
										"value": 2056,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт бытовых приборов, домашнего и садового оборудования",
								"value": 2057,
								"level": 4,
								"children": [
									{
										"title": "Ремонт бытовых приборов, домашнего и садового оборудования",
										"value": 2058,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт обуви и изделий из кожи",
								"value": 2059,
								"level": 4,
								"children": [
									{
										"title": "Ремонт обуви",
										"value": 2060,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт дорожных и галантерейных изделий из натуральной и искусственной кожи",
										"value": 2061,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт мебели и предметов интерьера",
								"value": 2062,
								"level": 4,
								"children": [
									{
										"title": "Ремонт мебели и предметов интерьера",
										"value": 2063,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт часов и ювелирных изделий",
								"value": 2064,
								"level": 4,
								"children": [
									{
										"title": "Ремонт наручных и прочих часов",
										"value": 2065,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт ювелирных изделий",
										"value": 2066,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Ремонт прочих предметов личного потребления и бытовых товаров",
								"value": 2067,
								"level": 4,
								"children": [
									{
										"title": "Ремонт трикотажных и вязаных изделий",
										"value": 2068,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт швейных изделий, головных уборов и изделий текстильной галантереи",
										"value": 2069,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт меховых и кожаных изделий и головных уборов",
										"value": 2070,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт музыкальных инструментов",
										"value": 2071,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт ковров и ковровых изделий",
										"value": 2072,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт велосипедов",
										"value": 2073,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Ремонт прочих предметов личного потребления и бытовых товаров, не включенных в другие группировки",
										"value": 2074,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Предоставление прочих индивидуальных услуг",
				"value": 2075,
				"level": 2,
				"children": [
					{
						"title": "Предоставление прочих индивидуальных услуг",
						"value": 2076,
						"level": 3,
						"children": [
							{
								"title": "Стирка и (химическая) чистка текстильных и меховых изделий ",
								"value": 2077,
								"level": 4,
								"children": [
									{
										"title": "Химическая чистка и крашение",
										"value": 2079,
										"level": 5,
										"children": [],
										"disabled": false
									},
									{
										"title": "Стирка и обработка белья",
										"value": 2078,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Предоставление услуг парикмахерскими и салонами красоты",
								"value": 2080,
								"level": 4,
								"children": [
									{
										"title": "Предоставление услуг парикмахерскими и салонами красоты",
										"value": 2081,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Организация похорон и связанная с этим деятельность",
								"value": 2082,
								"level": 4,
								"children": [
									{
										"title": "Организация похорон и связанная с этим деятельность",
										"value": 2083,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Деятельность по обеспечению физического комфорта",
								"value": 2084,
								"level": 4,
								"children": [
									{
										"title": "Деятельность по обеспечению физического комфорта",
										"value": 2085,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							},
							{
								"title": "Предоставление прочих индивидуальных услуг, не включенных в другие группировки",
								"value": 2086,
								"level": 4,
								"children": [
									{
										"title": "Предоставление прочих индивидуальных услуг, не включенных в другие группировки",
										"value": 2087,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу; деятельность домашних хозяйств по производству товаров и услуг для собственного потребления",
		"value": 2088,
		"level": 1,
		"children": [
			{
				"title": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
				"value": 2089,
				"level": 2,
				"children": [
					{
						"title": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
						"value": 2090,
						"level": 3,
						"children": [
							{
								"title": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
								"value": 2091,
								"level": 4,
								"children": [
									{
										"title": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
										"value": 2092,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			},
			{
				"title": "Деятельность домашних хозяйств по производству товаров и услуг для собственного потребления",
				"value": 2093,
				"level": 2,
				"children": [
					{
						"title": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
						"value": 2094,
						"level": 3,
						"children": [
							{
								"title": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
								"value": 2095,
								"level": 4,
								"children": [
									{
										"title": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
										"value": 2096,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					},
					{
						"title": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
						"value": 2097,
						"level": 3,
						"children": [
							{
								"title": "Деятельность домашних хозяйств по предоставлению услуг для собственного потребления",
								"value": 2098,
								"level": 4,
								"children": [
									{
										"title": "Деятельность домашних хозяйств по предоставлению услуг для собственного потребления",
										"value": 2099,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	},
	{
		"title": "Деятельность экстерриториальных организаций и органов",
		"value": 2100,
		"level": 1,
		"children": [
			{
				"title": "Деятельность экстерриториальных организаций и органов",
				"value": 2101,
				"level": 2,
				"children": [
					{
						"title": "Деятельность экстерриториальных организаций и органов",
						"value": 2102,
						"level": 3,
						"children": [
							{
								"title": "Деятельность экстерриториальных организаций и органов",
								"value": 2103,
								"level": 4,
								"children": [
									{
										"title": "Деятельность экстерриториальных организаций и органов",
										"value": 2104,
										"level": 5,
										"children": [],
										"disabled": false
									}
								],
								"disabled": false
							}
						],
						"disabled": false
					}
				],
				"disabled": false
			}
		],
		"disabled": false
	}
]

export const public_activity_select = [
	{
		"label": "Сельское, лесное и рыбное хозяйство",
		"value": 19
	},
	{
		"label": "Разведение прочего крупного рогатого скота и буйволов",
		"value": 67
	},
	{
		"label": "Рыболовство и рыбоводство",
		"value": 114
	},
	{
		"label": "Добыча сланцев",
		"value": 169
	},
	{
		"label": "Добыча глины и каолина",
		"value": 172
	},
	{
		"label": "Производство масел и жиров",
		"value": 214
	},
	{
		"label": "Производство готовых кормов для сельскохозяйственных животных",
		"value": 257
	},
	{
		"label": "Производство готовых кормов для сельскохозяйственных животных",
		"value": 258
	},
	{
		"label": "Производство готовых кормов для домашних животных",
		"value": 259
	},
	{
		"label": "Производство готовых кормов для домашних животных",
		"value": 260
	},
	{
		"label": "Производство вязаного и трикотажного полотна",
		"value": 306
	},
	{
		"label": "Производство готовых текстильных изделий, кроме одежды",
		"value": 309
	},
	{
		"label": "Производство ковров и ковровых изделий",
		"value": 311
	},
	{
		"label": "Производство ковров и ковровых изделий",
		"value": 312
	},
	{
		"label": "Производство веревок, канатов, бечевок, шпагата и сетей",
		"value": 313
	},
	{
		"label": "Производство веревок, канатов, бечевок, шпагата",
		"value": 314
	},
	{
		"label": "Производство дорожных принадлежностей, шорно-седельных изделий",
		"value": 351
	},
	{
		"label": "Производство дорожных принадлежностей и аналогичных изделий",
		"value": 352
	},
	{
		"label": "Производство прочих изделий из бумаги и картона",
		"value": 395
	},
	{
		"label": "Полиграфическая деятельность и предоставление услуг в этой области",
		"value": 397
	},
	{
		"label": "Выращивание многолетних культур",
		"value": 42
	},
	{
		"label": "Смешанное сельское хозяйство",
		"value": 85
	},
	{
		"label": "Деятельность, способствующая выращиванию сельскохозяйственных культур и разведению животных и деятельность по обработке урожая",
		"value": 88
	},
	{
		"label": "Охота и отлов, включая предоставление услуг в этих областях",
		"value": 98
	},
	{
		"label": "Лесозаготовки",
		"value": 105
	},
	{
		"label": "Сбор дикорастущей недревесной продукции",
		"value": 108
	},
	{
		"label": "Услуги, связанные с лесоводство и лесозаготовками",
		"value": 111
	},
	{
		"label": "Рыбоводство",
		"value": 120
	},
	{
		"label": "Добыча лигнита (бурого угля)",
		"value": 132
	},
	{
		"label": "Добыча природного газа",
		"value": 141
	},
	{
		"label": "Добыча руд, кроме железных",
		"value": 151
	},
	{
		"label": "Добыча полезных ископаемых, не включенных в другие группировки",
		"value": 173
	},
	{
		"label": "Предоставление услуг, способствующих добыче других полезных ископаемых",
		"value": 191
	},
	{
		"label": "Переработка и консервирование рыбы, ракообразных и моллюсков",
		"value": 203
	},
	{
		"label": "Переработка и консервирование фруктов и овощей",
		"value": 206
	},
	{
		"label": "Производство растительных и животных масел и жиров",
		"value": 213
	},
	{
		"label": "Производство молочных продуктов",
		"value": 219
	},
	{
		"label": "Производство  мукомольно-крупяных продуктов, крахмалов и крахмальных продуктов",
		"value": 225
	},
	{
		"label": "Производство хлебобулочных, макаронных  и мучных кондитерских изделий",
		"value": 232
	},
	{
		"label": "Производство прочих продуктов питания",
		"value": 239
	},
	{
		"label": "Производство готовых кормов для животных",
		"value": 256
	},
	{
		"label": "Ткацкое производство",
		"value": 295
	},
	{
		"label": "Отделка тканей и текстильных изделий",
		"value": 302
	},
	{
		"label": "Производство прочих текстильных изделий",
		"value": 305
	},
	{
		"label": "Производство меховых изделий",
		"value": 337
	},
	{
		"label": "Производство вязаной и трикотажной одежды",
		"value": 340
	},
	{
		"label": "Производство обуви",
		"value": 354
	},
	{
		"label": "Производство изделий из древесины, пробки, соломки и материалов для плетения",
		"value": 363
	},
	{
		"label": "Производство изделий из бумаги и картона",
		"value": 384
	},
	{
		"label": "Производство пластмасс в первичных формах, кроме полимеров из углеводородного сырья",
		"value": 439
	},
	{
		"label": "Производство полимеров в первичных формах из углеводородного сырья",
		"value": 440
	},
	{
		"label": "Производство парфюмерных и косметических средств",
		"value": 453
	},
	{
		"label": "Воспроизведение записанных носителей информации",
		"value": 406
	},
	{
		"label": "Производство продуктов нефтепереработки,брикетов из торфа и угля",
		"value": 415
	},
	{
		"label": "Производство пестицидов и прочей агрохимической продукции",
		"value": 443
	},
	{
		"label": "Производство красок, лаков и аналогичных покрытий, типографской краски и мастик",
		"value": 446
	},
	{
		"label": "Производство мыла, моющих, чистящих, полирующих,   парфюмерных и косметических средств",
		"value": 450
	},
	{
		"label": "Производство прочих химических продуктов",
		"value": 455
	},
	{
		"label": "Производство искусственных и синтетических волокон",
		"value": 469
	},
	{
		"label": "Производство фармацевтических препаратов и медицинских материалов",
		"value": 476
	},
	{
		"label": "Производство пластмассовых изделий",
		"value": 488
	},
	{
		"label": "Производство огнеупорных изделий",
		"value": 513
	},
	{
		"label": "Производство строительных материалов из глины",
		"value": 516
	},
	{
		"label": "Производство прочих фарфоровых и керамических изделий",
		"value": 521
	},
	{
		"label": "Производство цемента, извести и строительного гипса",
		"value": 533
	},
	{
		"label": "Производство изделий из бетона, цемента и строительного гипса",
		"value": 540
	},
	{
		"label": "Резка, обработка и отделка камня",
		"value": 555
	},
	{
		"label": "Производство абразивных изделий и прочей неметаллической минеральной продукции",
		"value": 558
	},
	{
		"label": "Производство труб, трубок, полых профилей, фитингов из стали",
		"value": 572
	},
	{
		"label": "Производство прочих стальных изделий путем первичной обработки",
		"value": 575
	},
	{
		"label": "Производство основных благородных и цветных металлов",
		"value": 584
	},
	{
		"label": "Литье металлов",
		"value": 601
	},
	{
		"label": "Производство металлических цистерн, резервуаров и контейнеров",
		"value": 620
	},
	{
		"label": "Производство паровых котлов, кроме котлов центрального отопления",
		"value": 625
	},
	{
		"label": "Производство оружия и боеприпасов",
		"value": 628
	},
	{
		"label": "Ковка, прессование, штамповка, профилирование металла; порошковая металлургия",
		"value": 631
	},
	{
		"label": "Обработка металлов и нанесение покрытий на металлы; основные технологические процессы машиностроения",
		"value": 635
	},
	{
		"label": "Производство ножевых изделий, инструментов и скобяных изделий",
		"value": 640
	},
	{
		"label": "Производство фармацевтических препаратов и медицинских материалов",
		"value": 477
	},
	{
		"label": "Производство фармацевтических препаратов",
		"value": 478
	},
	{
		"label": "Производство инструментов, не включенных в другие группировки",
		"value": 2129
	},
	{
		"label": "Производство прочих готовых металлических изделий",
		"value": 648
	},
	{
		"label": "Производство компьютеров и периферийного оборудования",
		"value": 668
	},
	{
		"label": "Производство коммуникационного оборудования",
		"value": 671
	},
	{
		"label": "Производство электронной бытовой техники",
		"value": 675
	},
	{
		"label": "Производство инструментов и приборов для измерения, тестирования и навигации; производство часов ",
		"value": 678
	},
	{
		"label": "Производство облучающего, электромедицинского и электротерапевтического оборудования",
		"value": 691
	},
	{
		"label": "Производство оптических приборов, фото- и кинооборудования",
		"value": 694
	},
	{
		"label": "Производство магнитных и оптических носителей информации",
		"value": 698
	},
	{
		"label": "Производство батарей и аккумуляторов",
		"value": 707
	},
	{
		"label": "Производство электропроводки и электромонтажных устройств",
		"value": 710
	},
	{
		"label": "Производство электроосветительного оборудования",
		"value": 717
	},
	{
		"label": "Производство бытовых приборов",
		"value": 721
	},
	{
		"label": "Производство прочего электрического оборудования",
		"value": 727
	},
	{
		"label": "Производство прочих насосов, не включенных в другие группировки",
		"value": 2130
	},
	{
		"label": "Производство прочих машин и оборудования общего назначения",
		"value": 748
	},
	{
		"label": "Производство сельскохозяйственной и лесохозяйственной техники",
		"value": 774
	},
	{
		"label": "Производство станков для обработки металлов  и прочих станков",
		"value": 780
	},
	{
		"label": "Производство прочих машин и оборудования специального назначения",
		"value": 790
	},
	{
		"label": "Производство кузовов для автомобилей; производство прицепов и полуприцепов",
		"value": 825
	},
	{
		"label": "Производство частей и принадлежностей автомобилей",
		"value": 829
	},
	{
		"label": "Производство железнодорожных локомотивов и подвижного состава",
		"value": 840
	},
	{
		"label": "Производство воздушных и космических летательных аппаратов и связанного с ними оборудования",
		"value": 843
	},
	{
		"label": "Производство военных боевых транспортных средств",
		"value": 847
	},
	{
		"label": "Производство транспортных средств, не включенных в другие группировки",
		"value": 850
	},
	{
		"label": "Производство керамических хозяйственных и декоративных изделий",
		"value": 522
	},
	{
		"label": "Производство хозяйственно-бытовых изделий из фарфора и фаянса",
		"value": 523
	},
	{
		"label": "Производство велосипедов, детских  колясок и кресло-колясок для лиц с инвалидностью",
		"value": 853
	},
	{
		"label": "Производство кресло-колясок для лиц с инвалидностью",
		"value": 855
	},
	{
		"label": "Производство музыкальных инструментов",
		"value": 878
	},
	{
		"label": "Производство спортивных товаров",
		"value": 881
	},
	{
		"label": "Производство игр и игрушек",
		"value": 884
	},
	{
		"label": "Производство медицинских и стоматологических инструментов и принадлежностей",
		"value": 887
	},
	{
		"label": "Производство медицинских и ветеринарных инструментов, аппаратов  и оборудования",
		"value": 890
	},
	{
		"label": "Производство прочих готовых изделий, не включенных в другие группировки",
		"value": 893
	},
	{
		"label": "Монтаж, установка промышленных машин и оборудования",
		"value": 937
	},
	{
		"label": "Производство и распределение газообразного топлива по трубопроводам",
		"value": 959
	},
	{
		"label": "Снабжение паром, горячей водой и кондиционированным воздухом",
		"value": 966
	},
	{
		"label": "Передача и распределение тепловой энергии",
		"value": 970
	},
	{
		"label": "Производство тепловой энергии тепловыми электростанциями (включая ТЭЦ)",
		"value": 972
	},
	{
		"label": "Продажа тепловой энергии",
		"value": 2131
	},
	{
		"label": "Водоснабжение; водоотведение; сбор, обработка и удаление отходов, деятельность по ликвидации загрязнений",
		"value": 974
	},
	{
		"label": "Забор, обработка и распределение воды",
		"value": 975
	},
	{
		"label": "Забор, обработка и распределение воды",
		"value": 976
	},
	{
		"label": "Забор, обработка и распределение воды",
		"value": 977
	},
	{
		"label": "Забор, обработка и распределение воды",
		"value": 978
	},
	{
		"label": "Обработка и удаление отходов",
		"value": 989
	},
	{
		"label": "Утилизация (восстановление) материалов",
		"value": 994
	},
	{
		"label": "Строительство жилых и нежилых зданий",
		"value": 1010
	},
	{
		"label": "Строительство распределительных инженерных сооружений",
		"value": 1025
	},
	{
		"label": "Строительство прочих инженерных сооружений",
		"value": 1032
	},
	{
		"label": "Монтаж и установка электрического, водопроводного и прочего оборудования",
		"value": 1047
	},
	{
		"label": "Прочие специализированные строительные работы",
		"value": 1068
	},
	{
		"label": "Техническое обслуживание и ремонт автомобилей",
		"value": 1087
	},
	{
		"label": "Торговля автомобильными деталями, узлами и принадлежностями",
		"value": 1091
	},
	{
		"label": "Производство чугуна, стали и ферросплавов",
		"value": 569
	},
	{
		"label": "Торговля мотоциклами, их деталями, узлами и принадлежностями; техническое обслуживание и ремонт мотоциклов",
		"value": 1097
	},
	{
		"label": "Оптовая торговля сельскохозяйственным сырьем и живыми животными",
		"value": 1122
	},
	{
		"label": "Оптовая торговля продуктами питания, напитками и табачными изделиями",
		"value": 1133
	},
	{
		"label": "Оптовая торговля непродовольственными товарами потребительского назначения",
		"value": 1154
	},
	{
		"label": "Оптовая торговля компьютерами и коммуникационным оборудованием",
		"value": 1180
	},
	{
		"label": "Оптовая торговля прочими машинами, оборудованием и деталями и принадлежностями к ним",
		"value": 1185
	},
	{
		"label": "Прочая специализированная оптовая торговля",
		"value": 1200
	},
	{
		"label": "Неспециализированная оптовая торговля",
		"value": 1240
	},
	{
		"label": "Оптовая торговля углеродными единицами",
		"value": 2132
	},
	{
		"label": "Розничная торговля продуктами питания, напитками и табачными изделиями в специализированных магазинах",
		"value": 1256
	},
	{
		"label": "Розничная торговля топливом в специализированных магазинах",
		"value": 1280
	},
	{
		"label": "Розничная торговля компьютерами и коммуникационным оборудованием в специализированных магазинах",
		"value": 1285
	},
	{
		"label": "Розничная торговля прочими бытовыми товарами в специализированных магазинах",
		"value": 1295
	},
	{
		"label": "Розничная торговля товарами культурно-развлекательного характера в специализированных магазинах",
		"value": 1315
	},
	{
		"label": "Розничная торговля прочими товарами в специализированных магазинах",
		"value": 1331
	},
	{
		"label": "Розничная торговля цветами, комнатными растениями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1352
	},
	{
		"label": "Производство радиаторов и котлов центрального отопления",
		"value": 621
	},
	{
		"label": "Производство радиаторов и котлов центрального отопления",
		"value": 622
	},
	{
		"label": "Производство прочих металлических цистерн, резервуаров и контейнеров",
		"value": 623
	},
	{
		"label": "Производство прочих металлических цистерн, резервуаров и контейнеров",
		"value": 624
	},
	{
		"label": "Производство паровых котлов, кроме котлов центрального отопления",
		"value": 626
	},
	{
		"label": "Издательская деятельность",
		"value": 1512
	},
	{
		"label": "Производство паровых котлов, кроме котлов центрального отопления",
		"value": 627
	},
	{
		"label": "Розничная торговля цветами, комнатными растениями  в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1355
	},
	{
		"label": "Розничная торговля в палатках, киосках и  на рынках",
		"value": 1371
	},
	{
		"label": "Розничная торговля вне магазинов, палаток, киосков или рынков",
		"value": 1381
	},
	{
		"label": "Деятельность грузового железнодорожного транспорта",
		"value": 1393
	},
	{
		"label": "Деятельность прочего пассажирского сухопутного транспорта",
		"value": 1396
	},
	{
		"label": "Перевозки легкорельсовым транспортом",
		"value": 2133
	},
	{
		"label": "Деятельность грузового  автомобильного транспорта и предоставление услуг по переезду",
		"value": 1407
	},
	{
		"label": "Деятельность трубопроводного транспорта",
		"value": 1412
	},
	{
		"label": "Деятельность морского и прибрежного грузового транспорта",
		"value": 1419
	},
	{
		"label": "Деятельность речного пассажирского транспорта",
		"value": 1422
	},
	{
		"label": "Деятельность речного  грузового транспорта",
		"value": 1425
	},
	{
		"label": "Деятельность воздушного грузового транспорта и космического транспорта",
		"value": 1434
	},
	{
		"label": "Складирование и хранение непродовольственных товаров, кроме зерна, масличных культур и нефти",
		"value": 1444
	},
	{
		"label": "Складирование и хранение масличных культур",
		"value": 2134
	},
	{
		"label": "Вспомогательная транспортная деятельность",
		"value": 1448
	},
	{
		"label": "Прочая почтовая и курьерская деятельность",
		"value": 1473
	},
	{
		"label": "Предоставление жилья на выходные дни и прочие периоды краткосрочного проживания",
		"value": 1484
	},
	{
		"label": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья",
		"value": 1487
	},
	{
		"label": "Предоставление услуг прочими местами для проживания",
		"value": 1491
	},
	{
		"label": "Доставка готовой пищи на заказ и прочая деятельность по обеспечению питанием",
		"value": 1501
	},
	{
		"label": "Подача напитков",
		"value": 1508
	},
	{
		"label": "Издание справочников и списков рассылок",
		"value": 1516
	},
	{
		"label": "Производство прочих готовых металлических изделий",
		"value": 661
	},
	{
		"label": "Производство компьютеров, электронного и оптического оборудования",
		"value": 662
	},
	{
		"label": "Производство электронных элементов и плат",
		"value": 663
	},
	{
		"label": "Производство электронных элементов",
		"value": 664
	},
	{
		"label": "Производство электронных элементов",
		"value": 665
	},
	{
		"label": "Производство электронных плат",
		"value": 666
	},
	{
		"label": "Издание справочников и списков рассылок",
		"value": 1517
	},
	{
		"label": "Издание программного обеспечения",
		"value": 1524
	},
	{
		"label": "Деятельность в сфере звукозаписи и издания музыкальных произведений ",
		"value": 1539
	},
	{
		"label": "Деятельность по созданию и трансляции телевизионных программ",
		"value": 1546
	},
	{
		"label": "Беспроводная телекоммуникационная связь",
		"value": 1554
	},
	{
		"label": "Деятельность в области спутниковых телекоммуникаций",
		"value": 1559
	},
	{
		"label": "Прочая деятельность в области спутниковых телекоммуникаций",
		"value": 2135
	},
	{
		"label": "Прочая деятельность в области телекоммуникаций",
		"value": 1564
	},
	{
		"label": "Деятельность в области разработки и тестирования программного кода",
		"value": 1571
	},
	{
		"label": "Сопровождение программного обеспечения, программных продуктов, баз данных, интернет-ресурсов (сайтов), информационных систем",
		"value": 1572
	},
	{
		"label": "Деятельность по управлению проектом в области информационных технологий",
		"value": 2136
	},
	{
		"label": "Деятельность в области разработки технической документации и системной архитектуры",
		"value": 2137
	},
	{
		"label": "Системно-техническое обслуживание аппаратно-программных комплексов",
		"value": 1577
	},
	{
		"label": "Деятельность в области монтажа и настройки аппаратно-программных комплексов",
		"value": 1578
	},
	{
		"label": "Другие виды деятельности в области информационных технологий ",
		"value": 1582
	},
	{
		"label": "Деятельность по предоставлению в пользование серверных помещений (центров обработки данных)",
		"value": 1586
	},
	{
		"label": "Деятельность в области обработки данных",
		"value": 2138
	},
	{
		"label": "Предоставление в пользование программных продуктов, электронных информационных ресурсов, мобильных и иных приложений",
		"value": 2139
	},
	{
		"label": "Прочая деятельность в области информационного обслуживания",
		"value": 1590
	},
	{
		"label": "Производство электродвигателей, генераторов и трансформаторов",
		"value": 703
	},
	{
		"label": "Производство электродвигателей, генераторов и трансформаторов ",
		"value": 704
	},
	{
		"label": "Производство электрораспределительной и регулирующей аппаратуры",
		"value": 705
	},
	{
		"label": "Производство электрораспределительной и регулирующей аппаратуры ",
		"value": 706
	},
	{
		"label": "Производство батарей и аккумуляторов",
		"value": 708
	},
	{
		"label": "Производство батарей и аккумуляторов",
		"value": 709
	},
	{
		"label": "Производство волоконно-оптических кабелей",
		"value": 711
	},
	{
		"label": "Производство волоконно-оптических кабелей",
		"value": 712
	},
	{
		"label": "Деятельность холдинговых компаний",
		"value": 1606
	},
	{
		"label": "Деятельность трастовых компаний, инвестиционных фондов и аналогичных финансовых организаций",
		"value": 1609
	},
	{
		"label": "Прочее финансовое посредничество, кроме страхования и пенсионного обеспечения",
		"value": 1612
	},
	{
		"label": "Перестрахование",
		"value": 1632
	},
	{
		"label": "Пенсионное обеспечение",
		"value": 1635
	},
	{
		"label": "Вспомогательная деятельность по страхованию и пенсионному обеспечению",
		"value": 1650
	},
	{
		"label": "Деятельность по управлению фондами",
		"value": 1657
	},
	{
		"label": "Аренда и управление собственной или арендуемой недвижимостью",
		"value": 1667
	},
	{
		"label": "Аренда (субаренда) и управление собственной или арендованной жилой недвижимостью",
		"value": 1669
	},
	{
		"label": "Аренда (субаренда) и управление собственными или арендованными бизнес-центром, офисными помещениями, административным зданием",
		"value": 1670
	},
	{
		"label": "Аренда (субаренда) и управление собственным или арендованным торговым рынком",
		"value": 1671
	},
	{
		"label": "Аренда и управление собственной торговой недвижимостью и многофункциональными комплексами в торговой деятельности",
		"value": 2140
	},
	{
		"label": "Аренда (субаренда) и управление арендуемой торговой недвижимостью и арендованными многофункциональными комплексами в торговой деятельности",
		"value": 2141
	},
	{
		"label": "Аренда (субаренда) и управление собственными или арендованными встроенными, пристроенными нежилыми помещениями в жилых домах",
		"value": 2142
	},
	{
		"label": "Аренда (субаренда) и управление собственными или арендованными выставочным залом, конференц-залами",
		"value": 2143
	},
	{
		"label": "Аренда (субаренда) и управление собственными или арендованными складскими помещениями ",
		"value": 2144
	},
	{
		"label": "Аренда (субаренда) и управление собственной или арендованной прочей недвижимостью, не включенной в другие группировки",
		"value": 2145
	},
	{
		"label": "Операции с недвижимым имуществом за вознаграждение или на договорной основе",
		"value": 1672
	},
	{
		"label": "Деятельность в области бухгалтерского учета и аудита; консультирование по налогообложению",
		"value": 1686
	},
	{
		"label": "Производство подшипников, зубчатых передач, элементов зубчатых передач и приводов",
		"value": 745
	},
	{
		"label": "Производство подшипников",
		"value": 746
	},
	{
		"label": "Производство лифтов",
		"value": 756
	},
	{
		"label": "Деятельность по консультированию по вопросам управления",
		"value": 1698
	},
	{
		"label": "Технические испытания и анализы",
		"value": 1717
	},
	{
		"label": "Деятельность по производству судебных экспертиз и исследований, кроме медицинских",
		"value": 2146
	},
	{
		"label": "Исследования и экспериментальные разработки в области общественных и гуманитарных наук",
		"value": 1735
	},
	{
		"label": "Исследование конъюнктуры рынка и изучение общественного мнения",
		"value": 1748
	},
	{
		"label": "Деятельность в области фотографии",
		"value": 1755
	},
	{
		"label": "Деятельность по устному и письменному переводу",
		"value": 1758
	},
	{
		"label": "Прочая профессиональная, научная и техническая деятельность, не включенная в другие группировки",
		"value": 1761
	},
	{
		"label": "Аренда и лизинг предметов личного потребления и бытовых товаров",
		"value": 1780
	},
	{
		"label": "Аренда и лизинг прочих машин, оборудования и материальных активов",
		"value": 1787
	},
	{
		"label": "Лизинг продуктов интеллектуальной собственности и аналогичных  продуктов, кроме работ, защищенным авторским правом",
		"value": 1808
	},
	{
		"label": "Деятельность агентств по временному трудоустройству",
		"value": 1815
	},
	{
		"label": "Прочая деятельность по обеспечению трудовыми ресурсами (персоналом)",
		"value": 1818
	},
	{
		"label": "Прочие услуги по бронированию и сопутствующая деятельность",
		"value": 1829
	},
	{
		"label": "Деятельность в области систем обеспечения безопасности",
		"value": 1837
	},
	{
		"label": "Деятельность по проведению расследований",
		"value": 1840
	},
	{
		"label": "Деятельность по уборке",
		"value": 1847
	},
	{
		"label": "Деятельность по благоустройству территорий",
		"value": 1854
	},
	{
		"label": "Деятельность телефонных справочно-информационных служб",
		"value": 1863
	},
	{
		"label": "Организация конференций и торговых выставок",
		"value": 1866
	},
	{
		"label": "Деятельность по предоставлению вспомогательных коммерческих услуг, не включенная в другие группировки",
		"value": 1869
	},
	{
		"label": "Предоставление государством услуг обществу в целом",
		"value": 1892
	},
	{
		"label": "Деятельность в области обязательного социального страхования",
		"value": 1903
	},
	{
		"label": "Начальное образование (1-й уровень)",
		"value": 1911
	},
	{
		"label": "Среднее образование (2-й и 3-й уровни)",
		"value": 1914
	},
	{
		"label": "Производство строительных машин",
		"value": 796
	},
	{
		"label": "Высшее образование (5-й, 6-й, 7-й, 8-й уровни)",
		"value": 1920
	},
	{
		"label": "Прочие виды образования",
		"value": 1926
	},
	{
		"label": "Вспомогательная деятельность в области образования",
		"value": 1937
	},
	{
		"label": "Деятельности в области врачебной практики и стоматологии",
		"value": 1950
	},
	{
		"label": "Прочая деятельность в области здравоохранения",
		"value": 1957
	},
	{
		"label": "Деятельность по производству судебно-медицинских экспертиз и исследований",
		"value": 2147
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания лицам, страдающим психическими расстройствами (заболеваниями), задержкой умственного развития и физическими недостатками, алкогольной или наркотической зависимостью",
		"value": 1964
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания лицам, страдающим психическими расстройствами (заболеваниями), задержкой умственного развития и физическими недостатками, алкогольной или наркотической зависимостью",
		"value": 1965
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания лицам, страдающим психическими расстройствами (заболеваниями), задержкой умственного развития и физическими недостатками, алкогольной или наркотической зависимостью",
		"value": 1966
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1968
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1969
	},
	{
		"label": "Ппредоставление прочих социальных услуг  с обеспечением проживания",
		"value": 1970
	},
	{
		"label": "Деятельность в области социального обслуживания населения без обеспечения проживания",
		"value": 1973
	},
	{
		"label": "Предоставление специальных социальных услуг без обеспечения проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1974
	},
	{
		"label": "Производство двигателей",
		"value": 824
	},
	{
		"label": "Производство кузовов для автомобилей; производство прицепов и полуприцепов",
		"value": 826
	},
	{
		"label": "Производство кузовов для автомобилей",
		"value": 827
	},
	{
		"label": "Производство прицепов и полуприцепов",
		"value": 828
	},
	{
		"label": "Производство электрического и электронного оборудования для автомобилей",
		"value": 830
	},
	{
		"label": "Производство электрического и электронного оборудования для автомобилей",
		"value": 831
	},
	{
		"label": "Предоставление специальных социальных услуг без обеспечения проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1975
	},
	{
		"label": "Предоставление специальных социальных услуг без обеспечения проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1976
	},
	{
		"label": "Предоставление прочих социальных услуг  без обеспечения проживания",
		"value": 1977
	},
	{
		"label": "Деятельность профессиональных союзов",
		"value": 2038
	},
	{
		"label": "Деятельность прочих общественных  объединений",
		"value": 2041
	},
	{
		"label": "Ремонт предметов личного потребления и бытовых товаров",
		"value": 2054
	},
	{
		"label": "Деятельность домашних хозяйств по предоставлению услуг для собственного потребления",
		"value": 2097
	},
	{
		"label": "Чеканка/выпуск монет и медалей",
		"value": 872
	},
	{
		"label": "Чеканка/выпуск монет и медалей",
		"value": 873
	},
	{
		"label": "Производство ювелирных и аналогичных изделий",
		"value": 874
	},
	{
		"label": "Производство ювелирных и аналогичных изделий",
		"value": 875
	},
	{
		"label": "Производство бижутерии и аналогичных изделий",
		"value": 876
	},
	{
		"label": "Производство бижутерии и аналогичных изделий",
		"value": 877
	},
	{
		"label": "Производство музыкальных инструментов",
		"value": 879
	},
	{
		"label": "Производство музыкальных инструментов ",
		"value": 880
	},
	{
		"label": "Ремонт электронного и оптического оборудования",
		"value": 916
	},
	{
		"label": "Обеспечение готовности электрической мощности к несению нагрузки, регулирование и резервирование электрической мощности",
		"value": 954
	},
	{
		"label": "Распределение электроэнергии",
		"value": 955
	},
	{
		"label": "Распределение электроэнергии",
		"value": 956
	},
	{
		"label": "Продажа электроэнергии ",
		"value": 957
	},
	{
		"label": "Продажа электроэнергии ",
		"value": 958
	},
	{
		"label": "Производство газообразного топлива",
		"value": 960
	},
	{
		"label": "Производство газообразного топлива",
		"value": 961
	},
	{
		"label": "Распределение газообразного топлива по трубопроводам",
		"value": 962
	},
	{
		"label": "Распределение газообразного топлива по трубопроводам",
		"value": 963
	},
	{
		"label": "Продажа газообразного топлива по трубопроводам",
		"value": 964
	},
	{
		"label": "Сбор отходов",
		"value": 984
	},
	{
		"label": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
		"value": 1001
	},
	{
		"label": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
		"value": 1002
	},
	{
		"label": "Строительство",
		"value": 1005
	},
	{
		"label": "Электромонтажные работы",
		"value": 1048
	},
	{
		"label": "Электромонтажные работы по прокладке  телекоммуникационных, компьютерных и телевизионных сетей",
		"value": 1049
	},
	{
		"label": "Прочие электромонтажные работы",
		"value": 1050
	},
	{
		"label": "Прочие строительно-монтажные работы",
		"value": 1053
	},
	{
		"label": "Оптовая торговля автомобильными деталями, узлами и  принадлежностями ",
		"value": 1092
	},
	{
		"label": "Оптовая торговля автомобильными деталями, узлами и  принадлежностями ",
		"value": 1093
	},
	{
		"label": "Техническое обслуживание и ремонт мотоциклов  и мотороллеров",
		"value": 1101
	},
	{
		"label": "Деятельность агентов по оптовой торговле товарами широкого ассортимента",
		"value": 1120
	},
	{
		"label": "Деятельность агентов по оптовой торговле товарами широкого ассортимента",
		"value": 1121
	},
	{
		"label": "Оптовая торговля зерном, необработанным табаком, семенами и кормами для животных",
		"value": 1123
	},
	{
		"label": "Оптовая торговля бытовыми электротоварами, радио- и  телевизионным оборудованием",
		"value": 1161
	},
	{
		"label": "Оптовая торговля бытовыми электротоварами",
		"value": 1162
	},
	{
		"label": "Оптовая торговля прочими офисными машинами и оборудованием",
		"value": 1197
	},
	{
		"label": "Оптовая торговля прочими неметаллическими отходами и неметаллическим ломом",
		"value": 1239
	},
	{
		"label": "Неспециализированная оптовая торговля",
		"value": 1241
	},
	{
		"label": "Оптовая торговля полудрагоценными камнями",
		"value": 1242
	},
	{
		"label": "Оптовая торговля мореным дубом",
		"value": 1243
	},
	{
		"label": "Оптовая торговля широким ассортиментом товаров без какой-либо конкретизации в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше), включая оптово-продовольственные распределительные центры",
		"value": 1244
	},
	{
		"label": "Оптовая торговля оборудованием и материалами, бывшими в употреблении",
		"value": 1245
	},
	{
		"label": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1266
	},
	{
		"label": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1291
	},
	{
		"label": "Розничная торговля книгами в специализированных магазинах",
		"value": 1316
	},
	{
		"label": "Деятельность трубопроводного транспорта",
		"value": 1414
	},
	{
		"label": "Розничная торговля обувью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1338
	},
	{
		"label": "Розничная торговля кожаными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1339
	},
	{
		"label": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1360
	},
	{
		"label": "Прочая розничная торговля новыми товарами в специализированных магазинах",
		"value": 1361
	},
	{
		"label": "Розничная торговля велосипедами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1363
	},
	{
		"label": "Транспорт и складирование",
		"value": 1388
	},
	{
		"label": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
		"value": 1390
	},
	{
		"label": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
		"value": 1391
	},
	{
		"label": "Деятельность пассажирского железнодорожного транспорта в междугородном сообщении",
		"value": 1392
	},
	{
		"label": "Деятельность грузового железнодорожного транспорта",
		"value": 1394
	},
	{
		"label": "Деятельность грузового железнодорожного транспорта",
		"value": 1395
	},
	{
		"label": "Деятельность сухопутного транспорта по городским и пригородным пассажирским перевозкам",
		"value": 1397
	},
	{
		"label": "Перевозки автобусами",
		"value": 1398
	},
	{
		"label": "Добыча каменного угля",
		"value": 128
	},
	{
		"label": "Добыча каменного угля открытым способом",
		"value": 129
	},
	{
		"label": "Добыча каменного угля подземным способом",
		"value": 130
	},
	{
		"label": "Обогащение каменного угля",
		"value": 131
	},
	{
		"label": "Добыча лигнита (бурого угля)",
		"value": 133
	},
	{
		"label": "Предоставление услуг гостиницами, находящимися на придорожной полосе",
		"value": 1483
	},
	{
		"label": "Предоставление жилья на выходные дни и прочие периоды краткосрочного проживания",
		"value": 1485
	},
	{
		"label": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья",
		"value": 1488
	},
	{
		"label": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья, за исключением находящихся на придорожной полосе",
		"value": 1489
	},
	{
		"label": "Предоставление услуг кемпингами, стоянками для автофургонов  и автоприцепов для жилья, находящимися на придорожной полосе",
		"value": 1490
	},
	{
		"label": "Предоставление услуг прочими местами для проживания",
		"value": 1492
	},
	{
		"label": "Издание журналов и периодических публикаций",
		"value": 1520
	},
	{
		"label": "Издание журналов и периодических публикаций",
		"value": 1521
	},
	{
		"label": "Прочие виды издательской деятельности",
		"value": 1522
	},
	{
		"label": "Прочие виды издательской деятельности",
		"value": 1523
	},
	{
		"label": "Издание компьютерных игр",
		"value": 1525
	},
	{
		"label": "Деятельность в области спутниковых телекоммуникаций для целей телерадиовещания",
		"value": 1562
	},
	{
		"label": "Деятельность в области спутниковых телекоммуникаций для организации связи",
		"value": 1563
	},
	{
		"label": "Прочая деятельность в области телекоммуникаций",
		"value": 1565
	},
	{
		"label": "Деятельность по распространению телерадиопрограмм  посредством  сети Интернет",
		"value": 1566
	},
	{
		"label": "Прочая деятельность в области телекоммуникаций, не включенная в другие группировки",
		"value": 1567
	},
	{
		"label": "Компьютерное программирование, консультационные и другие сопутствующие услуги",
		"value": 1568
	},
	{
		"label": "Компьютерное программирование, консультационные и другие сопутствующие услуги",
		"value": 1569
	},
	{
		"label": "Деятельность в области компьютерного программирования",
		"value": 1570
	},
	{
		"label": "Консультационные услуги в области информационных технологий",
		"value": 1573
	},
	{
		"label": "Финансовое посредничество, кроме страхования и пенсионного обеспечения",
		"value": 1597
	},
	{
		"label": "Денежное посредничество",
		"value": 1598
	},
	{
		"label": "Финансовый лизинг",
		"value": 1613
	},
	{
		"label": "Деятельность, связанная с управлением финансовыми рынками",
		"value": 1643
	},
	{
		"label": "Деятельность по ведению системы реестров держателей ценных бумаг и участников хозяйственных товариществ",
		"value": 1644
	},
	{
		"label": "Брокерская деятельность по сделкам с ценными бумагами и товарами",
		"value": 1645
	},
	{
		"label": "Брокерская и дилерская деятельность, связанная с управлением активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
		"value": 1646
	},
	{
		"label": "Прочая вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
		"value": 1648
	},
	{
		"label": "Производство сахара",
		"value": 240
	},
	{
		"label": "Производство сахара",
		"value": 241
	},
	{
		"label": "Лесопильное и строгальное производство",
		"value": 359
	},
	{
		"label": "Деятельность в области инженерных изысканий и предоставление технических консультаций в этой области для объектов атомной промышленности и атомной энергетики",
		"value": 1715
	},
	{
		"label": "Деятельность по предоставлению инженерно-технических консультаций",
		"value": 1716
	},
	{
		"label": "Исследование конъюнктуры рынка и изучение общественного мнения",
		"value": 1750
	},
	{
		"label": "Прочая профессиональная, научная и техническая деятельность",
		"value": 1751
	},
	{
		"label": "Специализированная дизайнерская деятельность",
		"value": 1752
	},
	{
		"label": "Специализированная дизайнерская деятельность",
		"value": 1753
	},
	{
		"label": "Аренда и лизинг офисных машин и оборудования, включая вычислительную технику",
		"value": 1794
	},
	{
		"label": "Аренда компьютерного и периферийного оборудования для инфотелекоммуникационной системы",
		"value": 1795
	},
	{
		"label": "Аренда прочих офисных машин и оборудования",
		"value": 1797
	},
	{
		"label": "Деятельность по обеспечению безопасности и проведению расследований",
		"value": 1832
	},
	{
		"label": "Деятельность по обеспечению безопасности частных лиц и имущества",
		"value": 1833
	},
	{
		"label": "Деятельность по обеспечению безопасности частных лиц и имущества",
		"value": 1834
	},
	{
		"label": "Деятельность частных охранных служб",
		"value": 1835
	},
	{
		"label": "Деятельность охранных организаций, учрежденных национальными компаниями",
		"value": 1836
	},
	{
		"label": "Деятельность в области систем обеспечения безопасности",
		"value": 1838
	},
	{
		"label": "Деятельность в области систем обеспечения безопасности",
		"value": 1839
	},
	{
		"label": "Деятельность агентств по сбору платежей и кредитных бюро, за исключением государственного",
		"value": 1872
	},
	{
		"label": "Упаковывание",
		"value": 1873
	},
	{
		"label": "Упаковывание",
		"value": 1874
	},
	{
		"label": "Государственное управление общего характера",
		"value": 1880
	},
	{
		"label": "Основное и общее среднее образование",
		"value": 1916
	},
	{
		"label": "Техническое и профессиональное среднее образование",
		"value": 1917
	},
	{
		"label": "Профессионально-техническое образование",
		"value": 1918
	},
	{
		"label": "Среднее специальное образование",
		"value": 1919
	},
	{
		"label": "Послесреднее образование",
		"value": 1921
	},
	{
		"label": "Послесреднее образование",
		"value": 1922
	},
	{
		"label": "Высшее и послевузовское образование",
		"value": 1923
	},
	{
		"label": "Ппредоставление прочих социальных услуг  с обеспечением проживания",
		"value": 1971
	},
	{
		"label": "Ппредоставление прочих социальных услуг  с обеспечением проживания",
		"value": 1972
	},
	{
		"label": "Прочая деятельность по организации отдыха и развлечений",
		"value": 2026
	},
	{
		"label": "Дневной уход за детьми",
		"value": 1978
	},
	{
		"label": "Дневной уход за детьми",
		"value": 1979
	},
	{
		"label": "Предоставление прочих социальных услуг без обеспечения проживания, не включенные в другие группировки",
		"value": 1980
	},
	{
		"label": "Предоставление прочих социальных услуг без обеспечения проживания, не включенные в другие группировки",
		"value": 1981
	},
	{
		"label": "Искусство, развлечения и отдых",
		"value": 1982
	},
	{
		"label": "Деятельность в области творчества, искусства и развлечений",
		"value": 1983
	},
	{
		"label": "Деятельность в области творчества, искусства и развлечений",
		"value": 1984
	},
	{
		"label": "Артистическая деятельность",
		"value": 1985
	},
	{
		"label": "Театральная деятельность",
		"value": 1986
	},
	{
		"label": "Концертная деятельность",
		"value": 1987
	},
	{
		"label": "Деятельность цирков",
		"value": 1988
	},
	{
		"label": "Деятельность, способствующая проведению культурно-зрелищных мероприятий",
		"value": 1989
	},
	{
		"label": "Деятельность, способствующая проведению культурно-зрелищных мероприятий",
		"value": 1990
	},
	{
		"label": "Художественное и литературное творчество",
		"value": 1991
	},
	{
		"label": "Художественное и литературное творчество",
		"value": 1992
	},
	{
		"label": "Деятельность концертных и театральных залов",
		"value": 1993
	},
	{
		"label": "Деятельность концертных и театральных залов",
		"value": 1994
	},
	{
		"label": "Деятельность библиотек, архивов, музеев и прочая деятельность в области культуры",
		"value": 1995
	},
	{
		"label": "Деятельность библиотек, архивов, музеев и прочая деятельность в области культуры",
		"value": 1996
	},
	{
		"label": "Деятельность библиотек и архивов",
		"value": 1997
	},
	{
		"label": "Деятельность религиозных организаций",
		"value": 2042
	},
	{
		"label": "Деятельность религиозных организаций",
		"value": 2043
	},
	{
		"label": "Деятельность политических организаций",
		"value": 2044
	},
	{
		"label": "Ремонт обуви",
		"value": 2060
	},
	{
		"label": "Предоставление прочих индивидуальных услуг, не включенных в другие группировки",
		"value": 2086
	},
	{
		"label": "Предоставление прочих индивидуальных услуг, не включенных в другие группировки",
		"value": 2087
	},
	{
		"label": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
		"value": 2089
	},
	{
		"label": "Производство прочей верхней одежды",
		"value": 331
	},
	{
		"label": "Производство прочих видов одежды и аксессуаров, не включенных в другие группировки",
		"value": 336
	},
	{
		"label": "Техобслуживание и ремонт автотранспортных средств",
		"value": 2120
	},
	{
		"label": "Оптовая торговля фруктами и овощами",
		"value": 1135
	},
	{
		"label": "Производство обуви",
		"value": 355
	},
	{
		"label": "Производство обуви",
		"value": 356
	},
	{
		"label": "Производство деревянных и пробковых изделий, кроме мебели; производство изделий из соломки и материалов для плетения",
		"value": 357
	},
	{
		"label": "Лесопильное и строгальное производство",
		"value": 358
	},
	{
		"label": "Животноводство",
		"value": 64
	},
	{
		"label": "Производство макаронных изделий",
		"value": 237
	},
	{
		"label": "Разведение прочего крупного рогатого скота и буйволов",
		"value": 68
	},
	{
		"label": "Разведение лошадей и прочих животных семейства лошадиных ",
		"value": 69
	},
	{
		"label": "Разведение лошадей",
		"value": 70
	},
	{
		"label": "Разведение и выращивание ослов, мулов или лошаков",
		"value": 71
	},
	{
		"label": "Разведение верблюдов и прочих животных семейства верблюжьих",
		"value": 72
	},
	{
		"label": "Воспроизводство растений",
		"value": 61
	},
	{
		"label": "Растениеводство и животноводство, охота и предоставление услуг в этих областях",
		"value": 20
	},
	{
		"label": "Выращивание одно- или двухлетних культур",
		"value": 21
	},
	{
		"label": "Выращивание зерновых (кроме риса), бобовых и масличных культур",
		"value": 22
	},
	{
		"label": "Выращивание зерновых и зернобобовых культур, включая семеноводство",
		"value": 23
	},
	{
		"label": "Выращивание масличных культур и их семян",
		"value": 24
	},
	{
		"label": "Выращивание риса",
		"value": 25
	},
	{
		"label": "Выращивание риса",
		"value": 26
	},
	{
		"label": "Выращивание овощей, бахчевых, корнеплодов и клубнеплодов",
		"value": 27
	},
	{
		"label": "Выращивание картофеля и посадочного материала",
		"value": 28
	},
	{
		"label": "Выращивание овощей, их семян и рассады",
		"value": 29
	},
	{
		"label": "Выращивание сахарной свеклы и ее семян",
		"value": 30
	},
	{
		"label": "Выращивание сахарного тростника",
		"value": 31
	},
	{
		"label": "Выращивание сахарного тростника",
		"value": 32
	},
	{
		"label": "Выращивание табака",
		"value": 33
	},
	{
		"label": "Выращивание табака",
		"value": 34
	},
	{
		"label": "Выращивание прядильных культур",
		"value": 35
	},
	{
		"label": "Выращивание хлопка-сырца",
		"value": 36
	},
	{
		"label": "Выращивание прядильных культур и их семян",
		"value": 37
	},
	{
		"label": "Выращивание прочих одно- или двухлетних культур",
		"value": 38
	},
	{
		"label": "Выращивание кормовых культур и их семян",
		"value": 39
	},
	{
		"label": "Выращивание цветов, семеноводство цветочных культур",
		"value": 40
	},
	{
		"label": "Выращивание прочих одно- или двухлетних культур, не включенных в другие группировки",
		"value": 41
	},
	{
		"label": "Выращивание винограда",
		"value": 43
	},
	{
		"label": "Выращивание винограда",
		"value": 44
	},
	{
		"label": "Выращивание тропических и субтропических плодов",
		"value": 45
	},
	{
		"label": "Выращивание тропических и субтропических плодов",
		"value": 46
	},
	{
		"label": "Выращивание цитрусовых плодов",
		"value": 47
	},
	{
		"label": "Выращивание цитрусовых плодов",
		"value": 48
	},
	{
		"label": "Выращивание семечковых и косточковых плодов",
		"value": 49
	},
	{
		"label": "Выращивание семечковых и косточковых плодов",
		"value": 50
	},
	{
		"label": "Выращивание прочих плодов, ягод и орехов",
		"value": 51
	},
	{
		"label": "Выращивание прочих плодов, ягод и орехов",
		"value": 52
	},
	{
		"label": "Выращивание маслосодержащих плодов",
		"value": 53
	},
	{
		"label": "Выращивание маслосодержащих плодов",
		"value": 54
	},
	{
		"label": "Выращивание культур для производства напитков",
		"value": 55
	},
	{
		"label": "Выращивание культур для производства напитков",
		"value": 56
	},
	{
		"label": "Выращивание специй, пряностей, лекарственных и используемых в парфюмерии растений",
		"value": 57
	},
	{
		"label": "Выращивание специй, пряностей, лекарственных и используемых в парфюмерии растений",
		"value": 58
	},
	{
		"label": "Выращивание прочих многолетних культур",
		"value": 59
	},
	{
		"label": "Выращивание прочих многолетних культур",
		"value": 60
	},
	{
		"label": "Воспроизводство растений",
		"value": 62
	},
	{
		"label": "Воспроизводство растений",
		"value": 63
	},
	{
		"label": "Разведение крупного рогатого скота молочного направления",
		"value": 65
	},
	{
		"label": "Разведение крупного рогатого скота молочного направления",
		"value": 66
	},
	{
		"label": "Разведение верблюдов и прочих животных семейства верблюжьих",
		"value": 73
	},
	{
		"label": "Разведение овец и коз",
		"value": 74
	},
	{
		"label": "Разведение овец и коз",
		"value": 75
	},
	{
		"label": "Разведение свиней",
		"value": 76
	},
	{
		"label": "Разведение свиней",
		"value": 77
	},
	{
		"label": "Разведение сельскохозяйственной птицы",
		"value": 78
	},
	{
		"label": "Разведение птицы на мясо, племенной птицы и молодняка",
		"value": 79
	},
	{
		"label": "Производство яиц",
		"value": 80
	},
	{
		"label": "Производство смешанное, мясояичное",
		"value": 81
	},
	{
		"label": "Деятельность инкубаторно-птицеводческих станций",
		"value": 82
	},
	{
		"label": "Разведение прочих видов животных",
		"value": 83
	},
	{
		"label": "Разведение прочих видов животных",
		"value": 84
	},
	{
		"label": "Смешанное сельское хозяйство",
		"value": 86
	},
	{
		"label": "Смешанное сельское хозяйство",
		"value": 87
	},
	{
		"label": "Деятельность, способствующая растениеводству",
		"value": 89
	},
	{
		"label": "Деятельность, способствующая растениеводству, кроме эксплуатации оросительных систем",
		"value": 90
	},
	{
		"label": "Эксплуатация оросительных систем",
		"value": 91
	},
	{
		"label": "Деятельность, способствующая животноводству",
		"value": 92
	},
	{
		"label": "Деятельность, способствующая животноводству",
		"value": 93
	},
	{
		"label": "Деятельность по обработке урожая",
		"value": 94
	},
	{
		"label": "Деятельность по обработке урожая",
		"value": 95
	},
	{
		"label": "Деятельность по обработке семян для посадки",
		"value": 96
	},
	{
		"label": "Деятельность по обработке семян для посадки",
		"value": 97
	},
	{
		"label": "Охота и отлов, включая предоставление услуг в этих областях",
		"value": 99
	},
	{
		"label": "Охота и отлов, включая предоставление услуг в этих областях",
		"value": 100
	},
	{
		"label": "Лесоводство и лесозаготовки",
		"value": 101
	},
	{
		"label": "Лесоводство и прочая лесохозяйственная деятельность",
		"value": 102
	},
	{
		"label": "Лесоводство и прочая лесохозяйственная деятельность",
		"value": 103
	},
	{
		"label": "Лесоводство и прочая лесохозяйственная деятельность",
		"value": 104
	},
	{
		"label": "Добыча сырой нефти",
		"value": 139
	},
	{
		"label": "Производство мороженого",
		"value": 224
	},
	{
		"label": "Лесозаготовки",
		"value": 106
	},
	{
		"label": "Лесозаготовки",
		"value": 107
	},
	{
		"label": "Сбор дикорастущей недревесной продукции",
		"value": 109
	},
	{
		"label": "Сбор дикорастущей недревесной продукции",
		"value": 110
	},
	{
		"label": "Услуги, связанные с лесоводство и лесозаготовками",
		"value": 112
	},
	{
		"label": "Услуги, связанные с лесоводство и лесозаготовками",
		"value": 113
	},
	{
		"label": "Рыболовство",
		"value": 115
	},
	{
		"label": "Морское рыболовство",
		"value": 116
	},
	{
		"label": "Морское рыболовство",
		"value": 117
	},
	{
		"label": "Пресноводное рыболовство",
		"value": 118
	},
	{
		"label": "Пресноводное рыболовство",
		"value": 119
	},
	{
		"label": "Морское рыбоводство",
		"value": 121
	},
	{
		"label": "Морское рыбоводство",
		"value": 122
	},
	{
		"label": "Пресноводное рыбоводство",
		"value": 123
	},
	{
		"label": "Пресноводное рыбоводство",
		"value": 124
	},
	{
		"label": "Горнодобывающая промышленность и разработка карьеров",
		"value": 125
	},
	{
		"label": "Добыча угля",
		"value": 126
	},
	{
		"label": "Добыча каменного угля",
		"value": 127
	},
	{
		"label": "Добыча лигнита (бурого угля) открытым способом",
		"value": 134
	},
	{
		"label": "Добыча лигнита (бурого угля) подземным способом",
		"value": 135
	},
	{
		"label": "Обогащение лигнита (бурого угля)",
		"value": 136
	},
	{
		"label": "Добыча сырой нефти и природного газа",
		"value": 137
	},
	{
		"label": "Добыча сырой нефти",
		"value": 138
	},
	{
		"label": "Добыча сырой нефти и попутного газа",
		"value": 140
	},
	{
		"label": "Добыча природного газа",
		"value": 142
	},
	{
		"label": "Добыча  природного газа, кроме метана",
		"value": 143
	},
	{
		"label": "Добыча метана  из угольных месторождений",
		"value": 144
	},
	{
		"label": "Добыча металлических руд",
		"value": 145
	},
	{
		"label": "Добыча железных руд",
		"value": 146
	},
	{
		"label": "Добыча железных руд",
		"value": 147
	},
	{
		"label": "Добыча железных руд подземным способом",
		"value": 148
	},
	{
		"label": "Добыча железных руд открытым способом",
		"value": 149
	},
	{
		"label": "Обогащение и агломерация железных руд",
		"value": 150
	},
	{
		"label": "Добыча урановой и ториевой руд",
		"value": 152
	},
	{
		"label": "Добыча урановой и ториевой руд",
		"value": 153
	},
	{
		"label": "Добыча прочих металлических руд",
		"value": 154
	},
	{
		"label": "Добыча и обогащение алюминийсодержащего сырья",
		"value": 155
	},
	{
		"label": "Добыча и обогащение медной руды",
		"value": 156
	},
	{
		"label": "Добыча и обогащение свинцово-цинковой руды",
		"value": 157
	},
	{
		"label": "Добыча и обогащение никель-кобальтовых руд",
		"value": 158
	},
	{
		"label": "Добыча и обогащение титаномагниевого сырья (руды)",
		"value": 159
	},
	{
		"label": "Добыча и обогащение оловянной руды",
		"value": 160
	},
	{
		"label": "Добыча и обогащение сурьмяно-ртутных руд",
		"value": 161
	},
	{
		"label": "Добыча драгоценных металлов и руд редких металлов",
		"value": 162
	},
	{
		"label": "Добыча и обогащение прочих металлических руд, не включенных в другие группировки",
		"value": 163
	},
	{
		"label": "Добыча прочих полезных ископаемых",
		"value": 164
	},
	{
		"label": "Добыча камня, песка и глины",
		"value": 165
	},
	{
		"label": "Добыча отделочного и строительного камня, известняка, гипса, мела и сланцев",
		"value": 166
	},
	{
		"label": "Добыча отделочного и строительного камня",
		"value": 167
	},
	{
		"label": "Добыча известняка, гипса и мела",
		"value": 168
	},
	{
		"label": "Разработка гравийных и песчаных карьеров, добыча глины и каолина",
		"value": 170
	},
	{
		"label": "Разработка гравийных и песчаных карьеров",
		"value": 171
	},
	{
		"label": "Добыча минерального сырья для химической промышленности и производства удобрений",
		"value": 174
	},
	{
		"label": "Добыча минерального сырья для химической промышленности и производства удобрений",
		"value": 175
	},
	{
		"label": "Добыча торфа",
		"value": 176
	},
	{
		"label": "Добыча торфа",
		"value": 177
	},
	{
		"label": "Добыча соли",
		"value": 178
	},
	{
		"label": "Добыча соли",
		"value": 179
	},
	{
		"label": "Добыча прочих полезных ископаемых, не включенных в другие группировки",
		"value": 180
	},
	{
		"label": "Добыча асбестовой руды",
		"value": 181
	},
	{
		"label": "Добыча и обогащение неметаллических руд",
		"value": 182
	},
	{
		"label": "Добыча сырья для естественных и искусственных пористых заполнителей",
		"value": 183
	},
	{
		"label": "Добыча исходного сырья для стекольной промышленности",
		"value": 184
	},
	{
		"label": "Добыча драгоценных камней (кроме алмазов) и полудрагоценных камней, самоцветов и янтаря",
		"value": 185
	},
	{
		"label": "Добыча алмазов",
		"value": 186
	},
	{
		"label": "Производство макаронных изделий",
		"value": 238
	},
	{
		"label": "Предоставление услуг в горнодобывающей промышленности",
		"value": 187
	},
	{
		"label": "Предоставление услуг, способствующих добыче нефти и природного газа",
		"value": 188
	},
	{
		"label": "Предоставление услуг, способствующих добыче нефти и природного газа",
		"value": 189
	},
	{
		"label": "Предоставление услуг, способствующих добыче нефти и природного газа",
		"value": 190
	},
	{
		"label": "Предоставление услуг, способствующих добыче других полезных ископаемых",
		"value": 192
	},
	{
		"label": "Предоставление услуг, способствующих добыче других полезных ископаемых",
		"value": 193
	},
	{
		"label": "Обрабатывающая промышленность",
		"value": 194
	},
	{
		"label": "Производство продуктов питания",
		"value": 195
	},
	{
		"label": "Переработка и консервирование мяса и производство мясной продукции",
		"value": 196
	},
	{
		"label": "Переработка и консервирование мяса",
		"value": 197
	},
	{
		"label": "Переработка и консервирование мяса",
		"value": 198
	},
	{
		"label": "Переработка и консервирование мяса сельскохозяйственной птицы",
		"value": 199
	},
	{
		"label": "Переработка и консервирование мяса сельскохозяйственной птицы",
		"value": 200
	},
	{
		"label": "Производство продуктов из мяса и мяса сельскохозяйственной птицы",
		"value": 201
	},
	{
		"label": "Производство продуктов из мяса и мяса сельскохозяйственной птицы",
		"value": 202
	},
	{
		"label": "Переработка и консервирование рыбы, ракообразных и моллюсков",
		"value": 204
	},
	{
		"label": "Переработка и консервирование рыбы, ракообразных и моллюсков",
		"value": 205
	},
	{
		"label": "Переработка и консервирование картофеля",
		"value": 207
	},
	{
		"label": "Переработка и консервирование картофеля",
		"value": 208
	},
	{
		"label": "Производство фруктовых и овощных соков",
		"value": 209
	},
	{
		"label": "Производство фруктовых и овощных соков",
		"value": 210
	},
	{
		"label": "Прочие виды переработки и консервирования фруктов и овощей",
		"value": 211
	},
	{
		"label": "Прочие виды переработки и консервирования фруктов и овощей",
		"value": 212
	},
	{
		"label": "Производство неочищенных масел и жиров",
		"value": 215
	},
	{
		"label": "Производство рафинированных масел и жиров",
		"value": 216
	},
	{
		"label": "Производство маргарина и аналогичных пищевых жиров",
		"value": 217
	},
	{
		"label": "Производство маргарина и аналогичных пищевых жиров",
		"value": 218
	},
	{
		"label": "Переработка молока и производство сыров",
		"value": 220
	},
	{
		"label": "Переработка молока, кроме консервирования, и производство сыров",
		"value": 221
	},
	{
		"label": "Производство молочных консервов",
		"value": 222
	},
	{
		"label": "Производство мороженого",
		"value": 223
	},
	{
		"label": "Производство  мукомольно-крупяных продуктов",
		"value": 226
	},
	{
		"label": "Производство муки",
		"value": 227
	},
	{
		"label": "Производство круп",
		"value": 228
	},
	{
		"label": "Производство пищевых концентратов",
		"value": 229
	},
	{
		"label": "Производство крахмала и продукции из крахмала",
		"value": 230
	},
	{
		"label": "Производство крахмала и продукции из крахмала",
		"value": 231
	},
	{
		"label": "Производство хлебобулочных и мучных кондитерских изделий недлительного хранения",
		"value": 233
	},
	{
		"label": "Производство хлебобулочных и мучных кондитерских изделий недлительного хранения",
		"value": 234
	},
	{
		"label": "Производство сухарей и печенья, мучных кондитерских изделий длительного хранения",
		"value": 235
	},
	{
		"label": "Производство сухарей и печенья, мучных кондитерских изделий длительного хранения",
		"value": 236
	},
	{
		"label": "Производство какао, шоколада и сахаристых кондитерских изделий",
		"value": 242
	},
	{
		"label": "Производство какао, шоколада и сахаристых кондитерских изделий",
		"value": 243
	},
	{
		"label": "Производство чая и кофе",
		"value": 244
	},
	{
		"label": "Производство чая и кофе",
		"value": 245
	},
	{
		"label": "Производство пряностей и приправ",
		"value": 246
	},
	{
		"label": "Производство пряностей и приправ",
		"value": 247
	},
	{
		"label": "Производство готовых пищевых продуктов",
		"value": 248
	},
	{
		"label": "Производство готовых пищевых продуктов",
		"value": 249
	},
	{
		"label": "Производство детского питания и диетических пищевых продуктов",
		"value": 250
	},
	{
		"label": "Переработка ядерного топлива",
		"value": 599
	},
	{
		"label": "Переработка ядерного топлива",
		"value": 600
	},
	{
		"label": "Производство детского питания и диетических пищевых продуктов",
		"value": 251
	},
	{
		"label": "Производство прочих продуктов питания, не включенных в другие группировки",
		"value": 252
	},
	{
		"label": "Производство супов, бульонов и яйцепродуктов",
		"value": 253
	},
	{
		"label": "Производство дрожжей",
		"value": 254
	},
	{
		"label": "Производство прочих пищевых продуктов, не включенных в другие группировки",
		"value": 255
	},
	{
		"label": "Производство напитков",
		"value": 261
	},
	{
		"label": "Производство напитков",
		"value": 262
	},
	{
		"label": "Дистилляция, ректификация и смешивание спиртных напитков",
		"value": 263
	},
	{
		"label": "Дистилляция, ректификация и смешивание спиртных напитков",
		"value": 264
	},
	{
		"label": "Производство вина из винограда",
		"value": 265
	},
	{
		"label": "Производство вина из винограда собственного производства",
		"value": 266
	},
	{
		"label": "Производство вина из винограда несобственного производства",
		"value": 267
	},
	{
		"label": "Производство сидра и прочих плодовых вин",
		"value": 268
	},
	{
		"label": "Производство сидра и прочих плодовых вин",
		"value": 269
	},
	{
		"label": "Производство прочих недистиллированных напитков из сброженного материала",
		"value": 270
	},
	{
		"label": "Производство прочих недистиллированных напитков из сброженного материала",
		"value": 271
	},
	{
		"label": "Производство пива",
		"value": 272
	},
	{
		"label": "Производство пива",
		"value": 273
	},
	{
		"label": "Производство солода",
		"value": 274
	},
	{
		"label": "Производство солода",
		"value": 275
	},
	{
		"label": "Производство безалкогольных напитков, минеральных вод и других вод в бутылках",
		"value": 276
	},
	{
		"label": "Производство искусственного меха",
		"value": 308
	},
	{
		"label": "Производство безалкогольных напитков, минеральных вод и других вод в бутылках",
		"value": 277
	},
	{
		"label": "Производство табачных изделий",
		"value": 278
	},
	{
		"label": "Производство табачных изделий",
		"value": 279
	},
	{
		"label": "Производство табачных изделий",
		"value": 280
	},
	{
		"label": "Ферментация табака",
		"value": 281
	},
	{
		"label": "Производство изделий из табака",
		"value": 282
	},
	{
		"label": "Производство текстильных изделий",
		"value": 283
	},
	{
		"label": "Подготовка и прядение текстильных волокон",
		"value": 284
	},
	{
		"label": "Подготовка и прядение текстильных волокон",
		"value": 285
	},
	{
		"label": "Прядение хлопчатобумажного волокна, хлопка-волокна",
		"value": 287
	},
	{
		"label": "Прядение шерстяного волокна",
		"value": 289
	},
	{
		"label": "Прядение льняного и прочих растительных волокон",
		"value": 291
	},
	{
		"label": "Подготовка шелкового волокна",
		"value": 292
	},
	{
		"label": "Производство шелковой пряжи",
		"value": 293
	},
	{
		"label": "Производство швейных ниток",
		"value": 294
	},
	{
		"label": "Ткацкое производство",
		"value": 296
	},
	{
		"label": "Производство хлопчатобумажных тканей",
		"value": 297
	},
	{
		"label": "Производство шерстяных тканей",
		"value": 298
	},
	{
		"label": "Производство шелковых тканей",
		"value": 299
	},
	{
		"label": "Производство льняных тканей",
		"value": 300
	},
	{
		"label": "Отделка тканей и текстильных изделий",
		"value": 303
	},
	{
		"label": "Производство сетей и изделий из веревок ",
		"value": 315
	},
	{
		"label": "Производство нетканых текстильных изделий, кроме одежды",
		"value": 316
	},
	{
		"label": "Производство нетканых текстильных изделий, кроме одежды",
		"value": 317
	},
	{
		"label": "Производство прочих текстильных изделий технического и производственного назначения",
		"value": 318
	},
	{
		"label": "Производство прочих текстильных изделий, не включенных в другие группировки",
		"value": 320
	},
	{
		"label": "Производство валяльно-войлочных изделий",
		"value": 322
	},
	{
		"label": "Производство других текстильных изделий, не включенных в другие группировки",
		"value": 323
	},
	{
		"label": "Производство одежды",
		"value": 324
	},
	{
		"label": "Производство одежды, кроме одежды из меха",
		"value": 325
	},
	{
		"label": "Производство одежды из кожи",
		"value": 326
	},
	{
		"label": "Производство спецодежды",
		"value": 328
	},
	{
		"label": "Производство спецодежды",
		"value": 329
	},
	{
		"label": "Производство прочей верхней одежды",
		"value": 330
	},
	{
		"label": "Производство нижнего белья",
		"value": 332
	},
	{
		"label": "Производство нижнего белья",
		"value": 333
	},
	{
		"label": "Производство прочих видов одежды и аксессуаров",
		"value": 334
	},
	{
		"label": "Производство головных уборов",
		"value": 335
	},
	{
		"label": "Производство сборных деревянных покрытий",
		"value": 362
	},
	{
		"label": "Производство меховых изделий",
		"value": 338
	},
	{
		"label": "Производство меховых изделий",
		"value": 339
	},
	{
		"label": "Производство вязаных и трикотажных чулочно-носочных  изделий",
		"value": 341
	},
	{
		"label": "Производство вязаных и трикотажных чулочно-носочных  изделий",
		"value": 342
	},
	{
		"label": "Производство прочей вязаной и трикотажной одежды",
		"value": 343
	},
	{
		"label": "Производство прочей вязаной и трикотажной одежды",
		"value": 344
	},
	{
		"label": "Производство кожаной и относящейся к ней продукции",
		"value": 345
	},
	{
		"label": "Дубление и выделка кожи; производство дорожных принадлежностей, шорно-седельных изделий; выделка и крашение меха",
		"value": 346
	},
	{
		"label": "Дубление и выделка кожи; выделка и крашение меха",
		"value": 347
	},
	{
		"label": "Дубление и выделка кожи",
		"value": 348
	},
	{
		"label": "Производство искусственных кож и пленочных материалов",
		"value": 349
	},
	{
		"label": "Выделка и крашение меха",
		"value": 350
	},
	{
		"label": "Производство шорно-седельных изделий",
		"value": 353
	},
	{
		"label": "Распиловка и строгание древесины",
		"value": 360
	},
	{
		"label": "Антисептирование древесины",
		"value": 361
	},
	{
		"label": "Производство шпона, фанеры, плит и панелей из древесины",
		"value": 364
	},
	{
		"label": "Производство шпона, фанеры, плит и панелей из древесины",
		"value": 365
	},
	{
		"label": "Производство сборных паркетных покрытий",
		"value": 366
	},
	{
		"label": "Производство сборных паркетных покрытий",
		"value": 367
	},
	{
		"label": "Производство прочих деревянных строительных конструкций и столярных  изделий",
		"value": 368
	},
	{
		"label": "Производство деревянных изделий для строительства",
		"value": 369
	},
	{
		"label": "Производство сборных домов, преимущественно из дерева",
		"value": 370
	},
	{
		"label": "Производство деревянных контейнерных, цельноперевозных и сборно-разборных зданий и помещений",
		"value": 371
	},
	{
		"label": "Производство деревянной тары",
		"value": 372
	},
	{
		"label": "Производство деревянной тары",
		"value": 373
	},
	{
		"label": "Производство прочих деревянных изделий; производство изделий из пробки, соломки и материалов для плетения",
		"value": 374
	},
	{
		"label": "Производство разных деревянных изделий",
		"value": 375
	},
	{
		"label": "Производство декоративных изделий из дерева",
		"value": 376
	},
	{
		"label": "Производство изделий из пробки, соломки и материалов для плетения",
		"value": 377
	},
	{
		"label": "Производство бумаги и бумажной продукции",
		"value": 378
	},
	{
		"label": "Производство древесной массы и целлюлозы, бумаги и картона",
		"value": 379
	},
	{
		"label": "Производство древесной массы и целлюлозы",
		"value": 380
	},
	{
		"label": "Производство древесной массы и целлюлозы",
		"value": 381
	},
	{
		"label": "Производство бумаги и картона",
		"value": 382
	},
	{
		"label": "Производство бумаги и картона",
		"value": 383
	},
	{
		"label": "Производство гофрированной бумаги и картона, бумажной и картонной тары",
		"value": 385
	},
	{
		"label": "Производство гофрированной бумаги и картона",
		"value": 386
	},
	{
		"label": "Производство бумажной и картонной тары",
		"value": 387
	},
	{
		"label": "Производство продукции коксовых печей",
		"value": 414
	},
	{
		"label": "Производство бумажных изделий хозяйственно-бытового и санитарно-гигиенического назначения",
		"value": 388
	},
	{
		"label": "Производство бумажных изделий хозяйственно-бытового и санитарно-гигиенического назначения",
		"value": 389
	},
	{
		"label": "Производство писчебумажных изделий",
		"value": 390
	},
	{
		"label": "Производство писчебумажных изделий",
		"value": 391
	},
	{
		"label": "Производство обоев",
		"value": 392
	},
	{
		"label": "Производство обоев",
		"value": 393
	},
	{
		"label": "Производство прочих изделий из бумаги и картона",
		"value": 394
	},
	{
		"label": "Полиграфическая деятельность и воспроизведение записанных носителей информации",
		"value": 396
	},
	{
		"label": "Печатание газет",
		"value": 398
	},
	{
		"label": "Печатание газет",
		"value": 399
	},
	{
		"label": "Прочие виды печатного производства",
		"value": 400
	},
	{
		"label": "Прочие виды печатного производства",
		"value": 401
	},
	{
		"label": "Деятельность по подготовке материалов к печати и рапространению",
		"value": 402
	},
	{
		"label": "Деятельность по подготовке материалов к печати и рапространению",
		"value": 403
	},
	{
		"label": "Брошюровочно-переплетная, отделочная деятельность и сопутствующие услуги",
		"value": 404
	},
	{
		"label": "Брошюровочно-переплетная, отделочная деятельность и сопутствующие услуги",
		"value": 405
	},
	{
		"label": "Воспроизведение записанных носителей информации",
		"value": 407
	},
	{
		"label": "Воспроизведение звукозаписей",
		"value": 408
	},
	{
		"label": "Воспроизведение видеозаписей",
		"value": 409
	},
	{
		"label": "Воспроизведение программного обеспечения и баз данных",
		"value": 410
	},
	{
		"label": "Производство кокса и продуктов нефтепереработки",
		"value": 411
	},
	{
		"label": "Производство продукции коксовых печей",
		"value": 412
	},
	{
		"label": "Производство продукции коксовых печей",
		"value": 413
	},
	{
		"label": "Производство продуктов нефтепереработки,брикетов из торфа и угля",
		"value": 416
	},
	{
		"label": "Производство продуктов нефтепереработки",
		"value": 417
	},
	{
		"label": "Агломерация торфа и производство торфяных брикетов",
		"value": 418
	},
	{
		"label": "Агломерация и производство брикетов из каменного угля",
		"value": 419
	},
	{
		"label": "Агломерация и производство брикетов из лигнита",
		"value": 420
	},
	{
		"label": "Производство продуктов химической промышленности",
		"value": 421
	},
	{
		"label": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах",
		"value": 422
	},
	{
		"label": "Производство промышленных газов",
		"value": 423
	},
	{
		"label": "Производство промышленных газов",
		"value": 424
	},
	{
		"label": "Производство красителей и пигментов",
		"value": 425
	},
	{
		"label": "Производство красителей и пигментов",
		"value": 426
	},
	{
		"label": "Производство прочих основных неорганических химических веществ",
		"value": 427
	},
	{
		"label": "Производство прочих основных неорганических химических веществ",
		"value": 428
	},
	{
		"label": "Производство прочих основных органических химических веществ",
		"value": 429
	},
	{
		"label": "Производство дубильных веществ",
		"value": 430
	},
	{
		"label": "Производство биоэтанола",
		"value": 431
	},
	{
		"label": "Производство ациклических и циклических углеводородов из углеводородного сырья",
		"value": 432
	},
	{
		"label": "Производство простых эфиров из углеводородного сырья",
		"value": 433
	},
	{
		"label": "Производство прочих основных органических химических веществ, не включенных в другие группировки",
		"value": 434
	},
	{
		"label": "Производство удобрений и азотосодержащих соединений",
		"value": 435
	},
	{
		"label": "Производство удобрений",
		"value": 436
	},
	{
		"label": "Производство азотосодержащих соединений",
		"value": 437
	},
	{
		"label": "Производство пластмасс в первичных формах",
		"value": 438
	},
	{
		"label": "Производство изделий из неметаллических руд",
		"value": 567
	},
	{
		"label": "Производство синтетического каучука в первичных формах",
		"value": 441
	},
	{
		"label": "Производство синтетического каучука в первичных формах",
		"value": 442
	},
	{
		"label": "Производство пестицидов и прочей агрохимической продукции",
		"value": 444
	},
	{
		"label": "Производство пестицидов и прочей агрохимической продукции",
		"value": 445
	},
	{
		"label": "Производство красок, лаков и аналогичных покрытий, типографской краски и мастик",
		"value": 447
	},
	{
		"label": "Производство красок, лаков, эмалей и минеральных пигментов для них",
		"value": 448
	},
	{
		"label": "Производство готовых растворителей и разбавителей красок и лаков",
		"value": 449
	},
	{
		"label": "Производство мыла и моющих, чистящих и полирующих средств",
		"value": 451
	},
	{
		"label": "Производство мыла и моющих, чистящих и полирующих средств",
		"value": 452
	},
	{
		"label": "Производство парфюмерных и косметических средств",
		"value": 454
	},
	{
		"label": "Производство взрывчатых веществ",
		"value": 456
	},
	{
		"label": "Производство взрывчатых веществ и прочих пиротехнических изделий",
		"value": 457
	},
	{
		"label": "Производство спичек",
		"value": 458
	},
	{
		"label": "Производство клея",
		"value": 459
	},
	{
		"label": "Производство клея",
		"value": 460
	},
	{
		"label": "Производство эфирных масел",
		"value": 461
	},
	{
		"label": "Производство эфирных масел",
		"value": 462
	},
	{
		"label": "Производство огнеупорных изделий",
		"value": 514
	},
	{
		"label": "Производство прочих химических продуктов, не включенных в другие группировки",
		"value": 463
	},
	{
		"label": "Производство фотоматериалов",
		"value": 464
	},
	{
		"label": "Производство желатина",
		"value": 465
	},
	{
		"label": "Производство материалов, используемых в отделке текстильных изделий",
		"value": 466
	},
	{
		"label": "Производство технического углерода (сажи), углеродных нанотрубок, нановолокон и других углеродных наноматериалов",
		"value": 467
	},
	{
		"label": "Производство других химических продуктов",
		"value": 468
	},
	{
		"label": "Производство искусственных и синтетических волокон",
		"value": 470
	},
	{
		"label": "Производство искусственных и синтетических волокон",
		"value": 471
	},
	{
		"label": "Производство основных фармацевтических продуктов и фармацевтических препаратов",
		"value": 472
	},
	{
		"label": "Производство основных фармацевтических продуктов",
		"value": 473
	},
	{
		"label": "Производство основных фармацевтических продуктов",
		"value": 474
	},
	{
		"label": "Производство основных фармацевтических продуктов",
		"value": 475
	},
	{
		"label": "Производство лекарственных препаратов для ветеринарии",
		"value": 479
	},
	{
		"label": "Производство резиновых и пластмассовых изделий",
		"value": 480
	},
	{
		"label": "Производство резиновых изделий",
		"value": 481
	},
	{
		"label": "Производство резиновых шин, покрышек и камер; восстановление резиновых шин и покрышек",
		"value": 482
	},
	{
		"label": "Производство резиновых шин, покрышек и камер",
		"value": 483
	},
	{
		"label": "Восстановление резиновых шин и покрышек",
		"value": 484
	},
	{
		"label": "Производство прочих резиновых изделий",
		"value": 485
	},
	{
		"label": "Производство резинотехнических изделий",
		"value": 486
	},
	{
		"label": "Производство резиновых санитарно-гигиенических и медицинских изделий",
		"value": 487
	},
	{
		"label": "Производство пластмассовых плит, листов, труб и профилей",
		"value": 489
	},
	{
		"label": "Производство пластмассовых плит, листов, труб и профилей",
		"value": 490
	},
	{
		"label": "Производство пластмассовых упаковок для товаров",
		"value": 491
	},
	{
		"label": "Производство пластмассовых упаковок для товаров",
		"value": 492
	},
	{
		"label": "Производство строительных пластмассовых изделий",
		"value": 493
	},
	{
		"label": "Производство пластмассовых изделий, используемых в строительстве",
		"value": 494
	},
	{
		"label": "Производство линолеума и прочих эластичных покрытий для пола",
		"value": 495
	},
	{
		"label": "Производство прочих пластмассовых изделий",
		"value": 496
	},
	{
		"label": "Производство прочих пластмассовых изделий",
		"value": 497
	},
	{
		"label": "Производство прочей не металлической минеральной продукции",
		"value": 498
	},
	{
		"label": "Производство стекла и изделий из стекла",
		"value": 499
	},
	{
		"label": "Производство листового стекла",
		"value": 500
	},
	{
		"label": "Производство листового стекла",
		"value": 501
	},
	{
		"label": "Формирование и обработка листового стекла",
		"value": 502
	},
	{
		"label": "Формирование и обработка листового стекла",
		"value": 503
	},
	{
		"label": "Производство полых стеклянных изделий",
		"value": 504
	},
	{
		"label": "Производство стеклянной тары",
		"value": 505
	},
	{
		"label": "Производство хозяйственно-бытовых изделий из стекла и стеклянной посуды",
		"value": 506
	},
	{
		"label": "Производство стекловолокна",
		"value": 507
	},
	{
		"label": "Производство стекловолокна",
		"value": 508
	},
	{
		"label": "Производство и обработка прочих стеклянных изделий",
		"value": 509
	},
	{
		"label": "Производство электротехнического и электровакуумного стекла",
		"value": 510
	},
	{
		"label": "Производство химико-лабораторной посуды из стекла",
		"value": 511
	},
	{
		"label": "Производство стеклянных изделий, не включенных в другие группировки",
		"value": 512
	},
	{
		"label": "Производство огнеупорных изделий",
		"value": 515
	},
	{
		"label": "Производство керамических покрытий и плит",
		"value": 517
	},
	{
		"label": "Производство керамических покрытий и плит",
		"value": 518
	},
	{
		"label": "Производство кирпича, черепицы и прочих строительных изделий из обожженной глины",
		"value": 519
	},
	{
		"label": "Производство кирпича, черепицы и прочих строительных изделий из обожженной глины",
		"value": 520
	},
	{
		"label": "Металлургическое производство",
		"value": 568
	},
	{
		"label": "Производство хозяйственно-бытовых гончарных изделий",
		"value": 524
	},
	{
		"label": "Производство керамических санитарно-технических изделий",
		"value": 525
	},
	{
		"label": "Производство керамических санитарно-технических изделий",
		"value": 526
	},
	{
		"label": "Производство керамических электрических изоляторов и изолирующей арматуры",
		"value": 527
	},
	{
		"label": "Производство керамических электрических изоляторов и изолирующей арматуры",
		"value": 528
	},
	{
		"label": "Производство прочих технических керамических изделий",
		"value": 529
	},
	{
		"label": "Производство прочих технических керамических изделий",
		"value": 530
	},
	{
		"label": "Производство прочих керамических изделий",
		"value": 531
	},
	{
		"label": "Производство прочих керамических изделий",
		"value": 532
	},
	{
		"label": "Производство цемента",
		"value": 534
	},
	{
		"label": "Производство цемента",
		"value": 535
	},
	{
		"label": "Производство извести и строительного гипса",
		"value": 536
	},
	{
		"label": "Производство извести",
		"value": 537
	},
	{
		"label": "Производство известняковой и доломитовой муки",
		"value": 538
	},
	{
		"label": "Производство строительного гипса",
		"value": 539
	},
	{
		"label": "Производство строительных изделий из бетона",
		"value": 541
	},
	{
		"label": "Производство сборных железобетонных и бетонных конструкций и изделий",
		"value": 542
	},
	{
		"label": "Производство стеновых блоков",
		"value": 543
	},
	{
		"label": "Производство силикатного кирпича",
		"value": 544
	},
	{
		"label": "Производство изделий из гипса для строительных целей",
		"value": 545
	},
	{
		"label": "Производство изделий из гипса для строительных целей",
		"value": 546
	},
	{
		"label": "Производство товарного бетона",
		"value": 547
	},
	{
		"label": "Производство товарного бетона",
		"value": 548
	},
	{
		"label": "Производство строительных растворов",
		"value": 549
	},
	{
		"label": "Производство строительных растворов",
		"value": 550
	},
	{
		"label": "Производство изделий из асбестоцемента и волокнистого цемента",
		"value": 551
	},
	{
		"label": "Производство изделий из асбестоцемента и волокнистого цемента",
		"value": 552
	},
	{
		"label": "Деятельность таможни",
		"value": 1886
	},
	{
		"label": "Производство прочих изделий из бетона, строительного гипса и цемента",
		"value": 553
	},
	{
		"label": "Производство прочих изделий из бетона, строительного гипса и цемента",
		"value": 554
	},
	{
		"label": "Резка, обработка и отделка камня",
		"value": 556
	},
	{
		"label": "Резка, обработка и отделка камня",
		"value": 557
	},
	{
		"label": "Производство абразивных изделий",
		"value": 559
	},
	{
		"label": "Производство абразивных изделий",
		"value": 560
	},
	{
		"label": "Производство прочей неметаллической минеральной продукции, не включенной в другие группировки",
		"value": 561
	},
	{
		"label": "Производство асбестотехнических изделий",
		"value": 562
	},
	{
		"label": "Производство минеральных изоляционных материалов",
		"value": 563
	},
	{
		"label": "Производство мягких кровельных и гидроизоляционных материалов",
		"value": 564
	},
	{
		"label": "Производство искусственных и переработка естественных пористых заполнителей",
		"value": 565
	},
	{
		"label": "Производство изделий из битума и аналогичных материалов",
		"value": 566
	},
	{
		"label": "Производство чугуна, стали и ферросплавов",
		"value": 570
	},
	{
		"label": "Производство чугуна, стали и ферросплавов",
		"value": 571
	},
	{
		"label": "Производство труб, трубок, полых профилей, фитингов из стали",
		"value": 573
	},
	{
		"label": "Производство труб, трубок, полых профилей, фитингов из стали",
		"value": 574
	},
	{
		"label": "Холодное волочение",
		"value": 576
	},
	{
		"label": "Холодное волочение",
		"value": 577
	},
	{
		"label": "Холодная прокатка лент и узких полос",
		"value": 578
	},
	{
		"label": "Холодная прокатка лент и узких полос",
		"value": 579
	},
	{
		"label": "Холодная штамповка или гибка",
		"value": 580
	},
	{
		"label": "Холодная штамповка или гибка",
		"value": 581
	},
	{
		"label": "Производство проволоки путем холодного волочения",
		"value": 582
	},
	{
		"label": "Производство проволоки путем холодного волочения",
		"value": 583
	},
	{
		"label": "Производство благородных (драгоценных) металлов",
		"value": 585
	},
	{
		"label": "Производство благородных (драгоценных) металлов",
		"value": 586
	},
	{
		"label": "Производство алюминия",
		"value": 587
	},
	{
		"label": "Производство алюминия",
		"value": 588
	},
	{
		"label": "Производство свинца, цинка и олова",
		"value": 589
	},
	{
		"label": "Производство свинца, цинка и олова",
		"value": 590
	},
	{
		"label": "Производство меди",
		"value": 591
	},
	{
		"label": "Производство меди",
		"value": 592
	},
	{
		"label": "Производство прочих цветных металлов",
		"value": 593
	},
	{
		"label": "Производство никеля и кобальта",
		"value": 594
	},
	{
		"label": "Производство титана, порошка из титана, магния, вольфрама и молибдена",
		"value": 595
	},
	{
		"label": "Производство сурьмы и ртути",
		"value": 596
	},
	{
		"label": "Обработка цветных металлов и сплавов",
		"value": 597
	},
	{
		"label": "Производство редких, редкоземельных металлов и полупроводниковых материалов",
		"value": 598
	},
	{
		"label": "Литье чугуна",
		"value": 602
	},
	{
		"label": "Литье чугуна, кроме производства труб",
		"value": 603
	},
	{
		"label": "Производство труб из чугуна",
		"value": 604
	},
	{
		"label": "Литье стали",
		"value": 605
	},
	{
		"label": "Литье стали",
		"value": 606
	},
	{
		"label": "Литье легких металлов",
		"value": 607
	},
	{
		"label": "Литье легких металлов",
		"value": 608
	},
	{
		"label": "Литье прочих цветных металлов",
		"value": 609
	},
	{
		"label": "Литье прочих цветных металлов",
		"value": 610
	},
	{
		"label": "Производство готовых металлических изделий, кроме машин и оборудования",
		"value": 611
	},
	{
		"label": "Производство строительных металлических конструкций и изделий",
		"value": 612
	},
	{
		"label": "Производство строительных металлических конструкций и их частей",
		"value": 613
	},
	{
		"label": "Производство строительных стальных конструкций",
		"value": 614
	},
	{
		"label": "Производство легких металлических конструкций",
		"value": 615
	},
	{
		"label": "Производство строительных конструкций и изделий из алюминия и алюминиевых сплавов",
		"value": 616
	},
	{
		"label": "Производство контейнерных и сборно-разборных зданий и помещений",
		"value": 617
	},
	{
		"label": "Производство металлических дверей и окон",
		"value": 618
	},
	{
		"label": "Производство металлических дверей и окон",
		"value": 619
	},
	{
		"label": "Производство прочих станков",
		"value": 786
	},
	{
		"label": "Производство оружия и боеприпасов",
		"value": 629
	},
	{
		"label": "Производство оружия и боеприпасов",
		"value": 630
	},
	{
		"label": "Ковка, прессование, штамповка, профилирование металла; порошковая металлургия",
		"value": 632
	},
	{
		"label": "Производство готовых металлических изделий или полуфабрикатов путем ковки, прессования, штамповки и профилирования",
		"value": 633
	},
	{
		"label": "Производство металлических изделий методом порошковой металлургии",
		"value": 634
	},
	{
		"label": "Обработка металлов и нанесение покрытий на металлы",
		"value": 636
	},
	{
		"label": "Обработка металлов и нанесение покрытий на металлы",
		"value": 637
	},
	{
		"label": "Основные технологические процессы машиностроения",
		"value": 638
	},
	{
		"label": "Основные технологические процессы машиностроения",
		"value": 639
	},
	{
		"label": "Производство ножевых изделий",
		"value": 641
	},
	{
		"label": "Производство ножевых изделий",
		"value": 642
	},
	{
		"label": "Производство замков, петель и шарниров",
		"value": 643
	},
	{
		"label": "Производство замков, петель и шарниров",
		"value": 644
	},
	{
		"label": "Производство инструментов",
		"value": 645
	},
	{
		"label": "Производство металло- и деревообрабатывающего инструмента",
		"value": 646
	},
	{
		"label": "Производство сельскохозяйственного и садово-огородного инвентаря",
		"value": 647
	},
	{
		"label": "Производство металлических бочек и аналогичных емкостей",
		"value": 649
	},
	{
		"label": "Производство металлических бочек и аналогичных емкостей",
		"value": 650
	},
	{
		"label": "Производство упаковки из легких металлов",
		"value": 651
	},
	{
		"label": "Производство упаковки из легких металлов",
		"value": 652
	},
	{
		"label": "Производство изделий из проволоки, цепей и пружин",
		"value": 653
	},
	{
		"label": "Производство изделий из проволоки",
		"value": 654
	},
	{
		"label": "Производство цепей и пружин",
		"value": 655
	},
	{
		"label": "Производство крепежных и резьбовых изделий",
		"value": 656
	},
	{
		"label": "Производство крепежных и резьбовых изделий",
		"value": 657
	},
	{
		"label": "Производство прочих готовых металлических изделий, не включенных в другие группировки",
		"value": 658
	},
	{
		"label": "Производство металлического санитарно-технического оборудования",
		"value": 659
	},
	{
		"label": "Производство хозяйственной посуды и кухонного инвентаря из металла",
		"value": 660
	},
	{
		"label": "Производство электронных плат",
		"value": 667
	},
	{
		"label": "Производство компьютеров и периферийного оборудования",
		"value": 669
	},
	{
		"label": "Производство компьютеров и периферийного оборудования",
		"value": 670
	},
	{
		"label": "Производство коммуникационного оборудования",
		"value": 672
	},
	{
		"label": "Ремонт трамваев, вагонов метро и троллейбусов",
		"value": 933
	},
	{
		"label": "Производство теле- и радиоаппаратуры производственного назначения",
		"value": 673
	},
	{
		"label": "Производство аппаратуры для кабельной телефонной и телеграфной связи",
		"value": 674
	},
	{
		"label": "Производство электронной бытовой техники",
		"value": 676
	},
	{
		"label": "Производство электронной бытовой техники",
		"value": 677
	},
	{
		"label": "Производство инструментов и приборов для измерения, тестирования и навигации",
		"value": 679
	},
	{
		"label": "Производство приборов для измерения механических величин",
		"value": 680
	},
	{
		"label": "Производство электроизмерительных приборов",
		"value": 681
	},
	{
		"label": "Производство радиоизмерительных приборов",
		"value": 682
	},
	{
		"label": "Производство оптических и оптико-механических приборов и оборудования",
		"value": 683
	},
	{
		"label": "Производство приборов для физических исследований",
		"value": 684
	},
	{
		"label": "Производство медико-хирургических инструментов",
		"value": 685
	},
	{
		"label": "Производство оборудования для систем позиционирования и мониторинга ответственных сооружений",
		"value": 686
	},
	{
		"label": "Производство часов",
		"value": 687
	},
	{
		"label": "Производство часов всех видов",
		"value": 688
	},
	{
		"label": "Производство приборов для регистрации времени",
		"value": 689
	},
	{
		"label": "Производство деталей и принадлежностей для часов",
		"value": 690
	},
	{
		"label": "Производство облучающего, электромедицинского и электротерапевтического оборудования",
		"value": 692
	},
	{
		"label": "Производство облучающего, электромедицинского и электротерапевтического оборудования",
		"value": 693
	},
	{
		"label": "Производство оптических приборов, фото- и кинооборудования",
		"value": 695
	},
	{
		"label": "Производство оптических приборов",
		"value": 696
	},
	{
		"label": "Производство фото- и кинооборудования",
		"value": 697
	},
	{
		"label": "Производство магнитных и оптических носителей информации",
		"value": 699
	},
	{
		"label": "Производство магнитных и оптических носителей информации",
		"value": 700
	},
	{
		"label": "Производство электрического оборудования",
		"value": 701
	},
	{
		"label": "Производство электродвигателей, генераторов, трансформаторов и электрораспределительной и регулирующей аппаратуры",
		"value": 702
	},
	{
		"label": "Производство электрических печей",
		"value": 751
	},
	{
		"label": "Производство прочих кранов, клапанов и вентилей",
		"value": 744
	},
	{
		"label": "Производство прочих электрических проводов и кабелей",
		"value": 713
	},
	{
		"label": "Производство прочих электрических проводов и кабелей",
		"value": 714
	},
	{
		"label": "Производство электромонтажных устройств",
		"value": 715
	},
	{
		"label": "Производство электромонтажных устройств",
		"value": 716
	},
	{
		"label": "Производство электроосветительного оборудования",
		"value": 718
	},
	{
		"label": "Производство электроламп",
		"value": 719
	},
	{
		"label": "Производство осветительных приборов",
		"value": 720
	},
	{
		"label": "Производство электрических бытовых приборов",
		"value": 722
	},
	{
		"label": "Производство электрических бытовых приборов, кроме холодильников и морозильников",
		"value": 723
	},
	{
		"label": "Производство бытовых холодильников и морозильников",
		"value": 724
	},
	{
		"label": "Производство неэлектрических бытовых приборов",
		"value": 725
	},
	{
		"label": "Производство неэлектрических бытовых приборов",
		"value": 726
	},
	{
		"label": "Производство прочего электрического оборудования",
		"value": 728
	},
	{
		"label": "Производство электродной продукции",
		"value": 729
	},
	{
		"label": "Производство электроизоляционных изделий",
		"value": 730
	},
	{
		"label": "Производство электрического сигнального оборудования",
		"value": 731
	},
	{
		"label": "Производство прочего электрического оборудования, не включенного в другие группировки",
		"value": 732
	},
	{
		"label": "Производство машин и оборудования, не включенных в другие группировки",
		"value": 733
	},
	{
		"label": "Производство оборудования общего назначения",
		"value": 734
	},
	{
		"label": "Производство двигателей и турбин, кроме авиационных, автомобильных и мотоциклетных двигателей",
		"value": 735
	},
	{
		"label": "Производство двигателей",
		"value": 736
	},
	{
		"label": "Производство турбин",
		"value": 737
	},
	{
		"label": "Производство гидравлического и пневматического оборудования",
		"value": 738
	},
	{
		"label": "Производство гидравлического и пневматического оборудования",
		"value": 739
	},
	{
		"label": "Производство прочих насосов и компрессоров",
		"value": 740
	},
	{
		"label": "Производство вакуумных и воздушных насосов",
		"value": 741
	},
	{
		"label": "Производство компрессоров",
		"value": 742
	},
	{
		"label": "Производство прочих кранов, клапанов и вентилей",
		"value": 743
	},
	{
		"label": "Производство зубчатых передач, элементов зубчатых передач и приводов",
		"value": 747
	},
	{
		"label": "Производство плит, печей и печных горелок",
		"value": 749
	},
	{
		"label": "Производство неэлектрических печей, горелок и устройств для печей",
		"value": 750
	},
	{
		"label": "Производство подъемно-транспортного оборудования",
		"value": 752
	},
	{
		"label": "Производство кранов (без строительных)",
		"value": 753
	},
	{
		"label": "Производство кранов для строительства",
		"value": 754
	},
	{
		"label": "Производство оборудования непрерывного транспорта",
		"value": 755
	},
	{
		"label": "Производство авто- и электропогрузчиков",
		"value": 757
	},
	{
		"label": "Производство прочего подъемно-транспортного оборудования",
		"value": 758
	},
	{
		"label": "Производство офисной техники и оборудования (за исключением компьютеров и периферийного оборудования)",
		"value": 759
	},
	{
		"label": "Производство офисной техники и оборудования (за исключением компьютеров и периферийного оборудования)",
		"value": 760
	},
	{
		"label": "Производство ручных механизированных  инструментов",
		"value": 761
	},
	{
		"label": "Производство ручных механизированных  инструментов",
		"value": 762
	},
	{
		"label": "Производство промышленного холодильного и вентиляционного оборудования",
		"value": 763
	},
	{
		"label": "Производство холодильного или морозильного оборудования",
		"value": 764
	},
	{
		"label": "Производство кондиционеров воздуха, вентиляторов",
		"value": 765
	},
	{
		"label": "Производство прочих машин и оборудования общего назначения, не включенных в другие группировки",
		"value": 766
	},
	{
		"label": "Производство весоизмерительного оборудования (кроме высокоточного лабораторного оборудования)",
		"value": 767
	},
	{
		"label": "Производство оборудования для фильтрования и очистки",
		"value": 768
	},
	{
		"label": "Производство оборудования для распыления и разбрызгивания жидкостей или порошков",
		"value": 769
	},
	{
		"label": "Производство упаковочных и оберточных машин",
		"value": 770
	},
	{
		"label": "Производство оборудования и аппаратуры для химических процессов",
		"value": 771
	},
	{
		"label": "Производство чертежных, разметочных и измерительных инструментов",
		"value": 772
	},
	{
		"label": "Производство прочих машин и оборудования, деталей  и узлов",
		"value": 773
	},
	{
		"label": "Производство сельскохозяйственной и лесохозяйственной техники",
		"value": 775
	},
	{
		"label": "Производство сельскохозяйственных и лесохозяйственных тракторов ",
		"value": 776
	},
	{
		"label": "Производство сельскохозяйственных машин",
		"value": 777
	},
	{
		"label": "Производство оборудования для животноводства и кормопроизводства",
		"value": 778
	},
	{
		"label": "Разведочное бурение",
		"value": 1046
	},
	{
		"label": "Производство лесозаготовительного и мелиоративного оборудования",
		"value": 779
	},
	{
		"label": "Производство станков для обработки металлов ",
		"value": 781
	},
	{
		"label": "Производство станков для обработки металлов лазером и станков аналогичных",
		"value": 782
	},
	{
		"label": "Производство токарных, расточных, сверлильных и фрезерных станков",
		"value": 783
	},
	{
		"label": "Производство прочих металлорежущих станков",
		"value": 784
	},
	{
		"label": "Производство частей и приспособлений к металлообрабатывающим станкам",
		"value": 785
	},
	{
		"label": "Разведочное бурение",
		"value": 1045
	},
	{
		"label": "Производство станков для обработки камня, дерева и материалов твердых аналогичных",
		"value": 787
	},
	{
		"label": "Производство частей и приспособлений прочих станков",
		"value": 788
	},
	{
		"label": "Производство прочих станков, не включенных в другие группировки",
		"value": 789
	},
	{
		"label": "Производство машин и оборудования  для металлургии",
		"value": 791
	},
	{
		"label": "Производство машин и оборудования  для металлургии",
		"value": 792
	},
	{
		"label": "Производство машин и оборудования для горнодобывающей промышленности, подземной разработки и строительства",
		"value": 793
	},
	{
		"label": "Производство горношахтного и горнорудного оборудования",
		"value": 794
	},
	{
		"label": "Производство дорожных и землеройных машин",
		"value": 795
	},
	{
		"label": "Производство оборудования для обработки строительных материалов",
		"value": 797
	},
	{
		"label": "Производство оборудования для торфяной промышленности",
		"value": 798
	},
	{
		"label": "Производство машин и оборудования для производства и переработки продуктов питания, напитков и табачных изделий",
		"value": 799
	},
	{
		"label": "Производство  машин и оборудования для производства и переработки продуктов питания, напитков и табачных изделий  ",
		"value": 800
	},
	{
		"label": "Производство машин и  оборудования для изготовления текстильных, швейных, меховых и кожаных изделий",
		"value": 801
	},
	{
		"label": "Производство машин и оборудования для текстильной промышленности",
		"value": 802
	},
	{
		"label": "Производство машин и оборудования для швейной и трикотажной промышленности",
		"value": 803
	},
	{
		"label": "Производство швейных машин ",
		"value": 804
	},
	{
		"label": "Производство машин и оборудования для обувной, меховой, кожевенной и кожгалантерейной промышленности",
		"value": 805
	},
	{
		"label": "Производство специального оборудования для предприятий бытового обслуживания",
		"value": 806
	},
	{
		"label": "Производство оборудования для получения химического волокна",
		"value": 807
	},
	{
		"label": "Производство машин и оборудования для изготовления бумаги и картона",
		"value": 808
	},
	{
		"label": "Производство машин и оборудования для изготовления бумаги и картона",
		"value": 809
	},
	{
		"label": "Производство машин и оборудования для обработки пластмасс и резины",
		"value": 810
	},
	{
		"label": "Производство машин и оборудования для обработки пластмасс и резины",
		"value": 811
	},
	{
		"label": "Производство прочих машин и оборудования специального назначения, не включенных в другие группировки",
		"value": 812
	},
	{
		"label": "Производство нефтепромыслового и бурового геологоразведочного оборудования",
		"value": 813
	},
	{
		"label": "Производство нефтегазоперерабатывающего оборудования",
		"value": 814
	},
	{
		"label": "Производство специального технологического оборудования для электронной промышленности",
		"value": 815
	},
	{
		"label": "Производство технологического оборудования для стекольной промышленности",
		"value": 816
	},
	{
		"label": "Производство технологического оборудования для полиграфической промышленности",
		"value": 817
	},
	{
		"label": "Производство воздушных летательных аппаратов",
		"value": 845
	},
	{
		"label": "Производство каруселей, качелей, тиров и других аттракционов",
		"value": 818
	},
	{
		"label": "Производство других машин специального назначения",
		"value": 819
	},
	{
		"label": "Производство автомобилей, прицепов и полуприцепов",
		"value": 820
	},
	{
		"label": "Производство автомобилей",
		"value": 821
	},
	{
		"label": "Производство автомобилей",
		"value": 822
	},
	{
		"label": "Производство автомобилей, кроме двигателей для автомобилей",
		"value": 823
	},
	{
		"label": "Производство прочих частей и принадлежностей автомобилей",
		"value": 832
	},
	{
		"label": "Производство прочих частей и принадлежностей автомобилей",
		"value": 833
	},
	{
		"label": "Производство прочих транспортных средств",
		"value": 834
	},
	{
		"label": "Строительство судов и лодок",
		"value": 835
	},
	{
		"label": "Строительство судов и плавучих средств",
		"value": 836
	},
	{
		"label": "Строительство судов и плавучих средств",
		"value": 837
	},
	{
		"label": "Строительство прогулочных и спортивных лодок",
		"value": 838
	},
	{
		"label": "Строительство прогулочных и спортивных лодок",
		"value": 839
	},
	{
		"label": "Производство железнодорожных локомотивов и подвижного состава",
		"value": 841
	},
	{
		"label": "Производство железнодорожных локомотивов и подвижного состава",
		"value": 842
	},
	{
		"label": "Производство воздушных и космических летательных аппаратов и связанного с ними оборудования",
		"value": 844
	},
	{
		"label": "Производство космических летательных аппаратов",
		"value": 846
	},
	{
		"label": "Производство военных боевых транспортных средств",
		"value": 848
	},
	{
		"label": "Производство военных боевых транспортных средств",
		"value": 849
	},
	{
		"label": "Производство мотоциклов",
		"value": 851
	},
	{
		"label": "Производство мотоциклов",
		"value": 852
	},
	{
		"label": "Производство велосипедов",
		"value": 854
	},
	{
		"label": "Производство детских колясок",
		"value": 856
	},
	{
		"label": "Производство прочих транспортных средств и оборудования, не включенных в другие группировки",
		"value": 857
	},
	{
		"label": "Производство прочих транспортных средств и оборудования, не включенных в другие группировки",
		"value": 858
	},
	{
		"label": "Производство мебели",
		"value": 859
	},
	{
		"label": "Производство мебели",
		"value": 860
	},
	{
		"label": "Производство мебели для офисов и предприятий торговли",
		"value": 861
	},
	{
		"label": "Производство стульев и другой мебели для сидения",
		"value": 862
	},
	{
		"label": "Производство мебели для офисов и предприятий торговли, кроме стульев и другой мебели для сидения",
		"value": 863
	},
	{
		"label": "Производство кухонной мебели",
		"value": 864
	},
	{
		"label": "Производство кухонной мебели",
		"value": 865
	},
	{
		"label": "Производство матрасов",
		"value": 866
	},
	{
		"label": "Производство матрасов",
		"value": 867
	},
	{
		"label": "Производство прочей мебели",
		"value": 868
	},
	{
		"label": "Производство прочей мебели",
		"value": 869
	},
	{
		"label": "Производство прочих готовых изделий",
		"value": 870
	},
	{
		"label": "Производство ювелирных изделий, бижутерии и аналогичных изделий",
		"value": 871
	},
	{
		"label": "Производство спортивных товаров",
		"value": 882
	},
	{
		"label": "Производство спортивных товаров",
		"value": 883
	},
	{
		"label": "Производство игр и игрушек",
		"value": 885
	},
	{
		"label": "Производство игр и игрушек",
		"value": 886
	},
	{
		"label": "Производство медицинских и стоматологических инструментов и принадлежностей",
		"value": 888
	},
	{
		"label": "Производство цементов, используемых в медицине",
		"value": 889
	},
	{
		"label": "Производство хирургических и ортопедических приспособлений",
		"value": 891
	},
	{
		"label": "Производство медицинской, хирургической, стоматологической и ветеринарной мебели",
		"value": 892
	},
	{
		"label": "Производство метел и щеток",
		"value": 894
	},
	{
		"label": "Производство метел и щеток",
		"value": 895
	},
	{
		"label": "Производство прочей продукции, не включенной в другие группировки",
		"value": 896
	},
	{
		"label": "Производство канцелярских изделий",
		"value": 897
	},
	{
		"label": "Производство изделий металлической галантереи",
		"value": 898
	},
	{
		"label": "Производство сувенирных изделий",
		"value": 899
	},
	{
		"label": "Производство прочих изделий",
		"value": 900
	},
	{
		"label": "Ремонт и установка машин и оборудования",
		"value": 901
	},
	{
		"label": "Ремонт готовых металлических изделий, машин и оборудования",
		"value": 902
	},
	{
		"label": "Ремонт готовых металлических изделий",
		"value": 903
	},
	{
		"label": "Ремонт металлических цистерн, резервуаров и контейнеров",
		"value": 904
	},
	{
		"label": "Ремонт радиаторов и котлов центрального отопления",
		"value": 905
	},
	{
		"label": "Ремонт паровых котлов, кроме котлов центрального отопления",
		"value": 906
	},
	{
		"label": "Ремонт огнестрельного оружия и артиллерийских орудий",
		"value": 907
	},
	{
		"label": "Ремонт прочих металлических изделий",
		"value": 908
	},
	{
		"label": "Ремонт машин и оборудования",
		"value": 909
	},
	{
		"label": "Ремонт и техническое обслуживание механического оборудования",
		"value": 910
	},
	{
		"label": "Ремонт и техническое обслуживание прочего оборудования общего назначения",
		"value": 911
	},
	{
		"label": "Ремонт и техническое обслуживание машин и оборудования для сельского и лесного хозяйства",
		"value": 912
	},
	{
		"label": "Ремонт и техническое обслуживание станков",
		"value": 913
	},
	{
		"label": "Ремонт и техническое обслуживание прочих машин и оборудования специального назначения",
		"value": 914
	},
	{
		"label": "Ремонт и техническое обслуживание офисных машин и вычислительной техники",
		"value": 915
	},
	{
		"label": "Ремонт и техническое обслуживание инструментов и приборов для измерения, тестирования и навигации",
		"value": 917
	},
	{
		"label": "Ремонт контрольного оборудования",
		"value": 918
	},
	{
		"label": "Ремонт и техническое обслуживание облучающего, электромедицинского и электротерапевтического оборудования",
		"value": 919
	},
	{
		"label": "Ремонт и техническое обслуживание оптических приборов и фотографического оборудования",
		"value": 920
	},
	{
		"label": "Ремонт электрического оборудования",
		"value": 921
	},
	{
		"label": "Ремонт и техническое обслуживание электродвигателей, генераторов и трансформаторов",
		"value": 922
	},
	{
		"label": "Ремонт и техническое обслуживание электрораспределительной и регулирующей аппаратуры",
		"value": 923
	},
	{
		"label": "Оптовая торговля прочим топливом",
		"value": 1210
	},
	{
		"label": "Ремонт и техническое обслуживание электроосветительного оборудования",
		"value": 924
	},
	{
		"label": "Ремонт и техническое обслуживание прочего электрооборудования, не включенного в другие группировки",
		"value": 925
	},
	{
		"label": "Ремонт и техническое обслуживание судов и лодок",
		"value": 926
	},
	{
		"label": "Ремонт и техническое обслуживание судов",
		"value": 927
	},
	{
		"label": "Ремонт и техническое обслуживание спортивных и прогулочных лодок",
		"value": 928
	},
	{
		"label": "Ремонт и техническое обслуживание воздушных и космических летательных аппаратов",
		"value": 929
	},
	{
		"label": "Ремонт и техническое обслуживание воздушных и космических летательных аппаратов",
		"value": 930
	},
	{
		"label": "Ремонт и техническое обслуживание прочих транспортных средств и оборудования",
		"value": 931
	},
	{
		"label": "Ремонт подвижного состава железных дорог",
		"value": 932
	},
	{
		"label": "Ремонт прочих транспортных средств и оборудования, не включенных в другие группировки",
		"value": 934
	},
	{
		"label": "Ремонт прочего оборудования",
		"value": 935
	},
	{
		"label": "Ремонт прочего оборудования",
		"value": 936
	},
	{
		"label": "Монтаж, установка промышленных машин и оборудования",
		"value": 938
	},
	{
		"label": "Монтаж, установка промышленных машин и оборудования",
		"value": 939
	},
	{
		"label": "Снабжение электроэнергией, газом, паром, горячей водой и  кондиционированным воздухом",
		"value": 940
	},
	{
		"label": "Снабжение электроэнергией, газом, паром, горячей водой и  кондиционированным воздухом",
		"value": 941
	},
	{
		"label": "Производство, передача и распределение электроэнергии",
		"value": 942
	},
	{
		"label": "Производство электроэнергии",
		"value": 943
	},
	{
		"label": "Производство электроэнергии тепловыми электростанциями",
		"value": 944
	},
	{
		"label": "Производство электроэнергии гидроэлектростанциями",
		"value": 945
	},
	{
		"label": "Производство электроэнергии ядерными (атомными) электростанциями",
		"value": 946
	},
	{
		"label": "Производство электроэнергии ветровыми электростанциями",
		"value": 947
	},
	{
		"label": "Производство электроэнергии солнечными электростанциями",
		"value": 948
	},
	{
		"label": "Производство электроэнергии прочими электростанциями",
		"value": 949
	},
	{
		"label": "Передача электроэнергии",
		"value": 950
	},
	{
		"label": "Передача электроэнергии",
		"value": 951
	},
	{
		"label": "Техническая диспетчеризация отпуска в сеть и потребления электрической энергии, организация балансирования производства-потребления электрической энергии",
		"value": 952
	},
	{
		"label": "Эксплуатационное обслуживание оборудования Национальной электрической сети",
		"value": 953
	},
	{
		"label": "Продажа газообразного топлива по трубопроводам",
		"value": 965
	},
	{
		"label": "Снабжение паром, горячей водой и кондиционированным воздухом",
		"value": 967
	},
	{
		"label": "Производство тепловой энергии тепловыми сетями",
		"value": 968
	},
	{
		"label": "Производство тепловой энергии самостоятельными котельными",
		"value": 969
	},
	{
		"label": "Кондиционирование воздуха",
		"value": 971
	},
	{
		"label": "Пуск и наладка смонтированного оборудования",
		"value": 1056
	},
	{
		"label": "Распределение тепловой энергии",
		"value": 973
	},
	{
		"label": "Сбор и обработка сточных вод",
		"value": 979
	},
	{
		"label": "Сбор и обработка сточных вод",
		"value": 980
	},
	{
		"label": "Сбор и обработка сточных вод",
		"value": 981
	},
	{
		"label": "Сбор и обработка сточных вод",
		"value": 982
	},
	{
		"label": "Сбор, обработка и удаление отходов; утилизация (восстановление) материалов",
		"value": 983
	},
	{
		"label": "Сбор неопасных отходов",
		"value": 985
	},
	{
		"label": "Сбор неопасных отходов",
		"value": 986
	},
	{
		"label": "Сбор опасных отходов",
		"value": 987
	},
	{
		"label": "Сбор опасных отходов",
		"value": 988
	},
	{
		"label": "Обработка и удаление неопасных отходов",
		"value": 990
	},
	{
		"label": "Обработка и удаление неопасных отходов",
		"value": 991
	},
	{
		"label": "Обработка и удаление опасных отходов",
		"value": 992
	},
	{
		"label": "Обработка и удаление опасных отходов",
		"value": 993
	},
	{
		"label": "Разборка машин и оборудования, не подлежащих восстановлению",
		"value": 995
	},
	{
		"label": "Разборка машин и оборудования, не подлежащих восстановлению",
		"value": 996
	},
	{
		"label": "Переработка отсортированных материалов",
		"value": 997
	},
	{
		"label": "Переработка отходов и лома черных металлов",
		"value": 998
	},
	{
		"label": "Переработка отходов и лома цветных металлов",
		"value": 999
	},
	{
		"label": "Переработка неметаллических отходов",
		"value": 1000
	},
	{
		"label": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
		"value": 1003
	},
	{
		"label": "Деятельность по ликвидации загрязнений и прочие услуги в области удаления отходов",
		"value": 1004
	},
	{
		"label": "Строительство зданий",
		"value": 1006
	},
	{
		"label": "Разработка строительных проектов",
		"value": 1007
	},
	{
		"label": "Разработка строительных проектов",
		"value": 1008
	},
	{
		"label": "Разработка строительных проектов",
		"value": 1009
	},
	{
		"label": "Строительство жилых и нежилых зданий",
		"value": 1011
	},
	{
		"label": "Строительство жилых зданий",
		"value": 1012
	},
	{
		"label": "Строительство нежилых зданий, за исключением стационарных торговых объектов категорий 1, 2",
		"value": 1013
	},
	{
		"label": "Строительство стационарных торговых объектов категории 1",
		"value": 1014
	},
	{
		"label": "Строительство стационарных торговых объектов категории 2",
		"value": 1015
	},
	{
		"label": "Гражданское строительство",
		"value": 1016
	},
	{
		"label": "Строительство автомобильных и железных дорог",
		"value": 1017
	},
	{
		"label": "Строительство автомобильных дорог",
		"value": 1018
	},
	{
		"label": "Строительство дорог и автомагистралей",
		"value": 1019
	},
	{
		"label": "Деятельность по организации строительства, реконструкции, ремонта, платного движения и содержания автомобильных дорог (участков) общего пользования международного и республиканского значения",
		"value": 1020
	},
	{
		"label": "Строительство железных дорог и метро",
		"value": 1021
	},
	{
		"label": "Строительство железных дорог и метро",
		"value": 1022
	},
	{
		"label": "Строительство мостов и туннелей",
		"value": 1023
	},
	{
		"label": "Строительство мостов и туннелей",
		"value": 1024
	},
	{
		"label": "Строительство трубопроводов",
		"value": 1026
	},
	{
		"label": "Строительство нефтяных и газовых магистральных трубопроводов",
		"value": 1027
	},
	{
		"label": "Строительство трубопроводов для систем водоснабжения и канализации",
		"value": 1028
	},
	{
		"label": "Строительство прочих трубопроводов",
		"value": 1029
	},
	{
		"label": "Строительство линий электропередач и телекоммуникаций",
		"value": 1030
	},
	{
		"label": "Строительство линий электропередач и телекоммуникаций",
		"value": 1031
	},
	{
		"label": "Строительство водных сооружений",
		"value": 1033
	},
	{
		"label": "Строительство водных сооружений",
		"value": 1034
	},
	{
		"label": "Строительство прочих инженерных сооружений, не включенных в другие группировки",
		"value": 1035
	},
	{
		"label": "Строительство прочих инженерных сооружений, не включенных в другие группировки",
		"value": 1036
	},
	{
		"label": "Специализированные строительные работы",
		"value": 1037
	},
	{
		"label": "Снос  зданий и сооружений, подготовка строительного участка",
		"value": 1038
	},
	{
		"label": "Разборка и снос зданий и сооружений",
		"value": 1039
	},
	{
		"label": "Разборка и снос зданий и сооружений",
		"value": 1040
	},
	{
		"label": "Подготовка строительного участка",
		"value": 1041
	},
	{
		"label": "Земляные работы",
		"value": 1042
	},
	{
		"label": "Взрывные работы",
		"value": 1043
	},
	{
		"label": "Специальные работы в грунтах",
		"value": 1044
	},
	{
		"label": "Монтаж систем водоснабжения, отопления и кондиционирования воздуха",
		"value": 1051
	},
	{
		"label": "Монтаж систем водоснабжения, отопления и кондиционирования воздуха",
		"value": 1052
	},
	{
		"label": "Изоляционные работы",
		"value": 1054
	},
	{
		"label": "Прочие строительно-монтажные работы, не включенные в другие группировки",
		"value": 1055
	},
	{
		"label": "Штукатурные работы",
		"value": 1058
	},
	{
		"label": "Штукатурные работы",
		"value": 1059
	},
	{
		"label": "Столярные и плотницкие работы",
		"value": 1060
	},
	{
		"label": "Столярные и плотницкие работы",
		"value": 1061
	},
	{
		"label": "Покрытие полов и облицовка стен",
		"value": 1062
	},
	{
		"label": "Покрытие полов и облицовка стен",
		"value": 1063
	},
	{
		"label": "Малярные и стекольные работы",
		"value": 1064
	},
	{
		"label": "Малярные и стекольные работы",
		"value": 1065
	},
	{
		"label": "Прочие отделочные работы",
		"value": 1066
	},
	{
		"label": "Прочие отделочные работы",
		"value": 1067
	},
	{
		"label": "Кровельные работы",
		"value": 1069
	},
	{
		"label": "Кровельные работы",
		"value": 1070
	},
	{
		"label": "Прочие специализированные строительные работы, не включенные в другие группировки",
		"value": 1071
	},
	{
		"label": "Строительство шахт",
		"value": 1072
	},
	{
		"label": "Гидроизоляционные работы",
		"value": 1073
	},
	{
		"label": "Аренда строительного оборудования с оператором",
		"value": 1074
	},
	{
		"label": "Прочие строительные работы, требующие специальной квалификации",
		"value": 1075
	},
	{
		"label": "Оптовая и розничная торговля; ремонт автомобилей и мотоциклов",
		"value": 1076
	},
	{
		"label": "Оптовая и розничная торговля автомобилями и мотоциклами и их ремонт",
		"value": 1077
	},
	{
		"label": "Торговля автомобилями",
		"value": 1078
	},
	{
		"label": "Торговля автомобилями и легкими автотранспортными средствами",
		"value": 1079
	},
	{
		"label": "Оптовая торговля автомобилями и легкими автотранспортными средствами",
		"value": 1080
	},
	{
		"label": "Розничная торговля автомобилями и легкими автотранспортными средствами в торговых объектах с торговой площадью менее 2000 кв.м",
		"value": 1081
	},
	{
		"label": "Розничная торговля автомобилями и легкими автотранспортными средствами в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1082
	},
	{
		"label": "Торговля прочими автотранспортными средствами",
		"value": 1083
	},
	{
		"label": "Оптовая торговля прочими автотранспортными средствами",
		"value": 1084
	},
	{
		"label": "Розничная торговля прочими автотранспортными средствами в торговых объектах с торговой площадью менее 2000 кв.м",
		"value": 1085
	},
	{
		"label": "Розничная торговля прочими автотранспортными средствами в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1086
	},
	{
		"label": "Техническое обслуживание и ремонт автомобилей",
		"value": 1088
	},
	{
		"label": "Оптовая торговля обувью",
		"value": 1160
	},
	{
		"label": "Розничная торговля автомобильными деталями, узлами и  принадлежностями ",
		"value": 1094
	},
	{
		"label": "Розничная торговля автомобильными деталями, узлами и  принадлежностями в торговых объектах с торговой площадью менее 2000 кв.м",
		"value": 1095
	},
	{
		"label": "Розничная торговля автомобильными деталями, узлами и  принадлежностями  в торговых объектах с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1096
	},
	{
		"label": "Торговля мотоциклами, их деталями, узлами и принадлежностями; техническое обслуживание и ремонт мотоциклов",
		"value": 1098
	},
	{
		"label": "Оптовая торговля мотоциклами, мотороллерами, деталями и принадлежностями к ним",
		"value": 1099
	},
	{
		"label": "Розничная торговля мотоциклами, мотороллерами, деталями и принадлежностями к ним",
		"value": 1100
	},
	{
		"label": "Оптовая торговля, за исключением торговли автомобилями и мотоциклами",
		"value": 1102
	},
	{
		"label": "Оптовая торговля за вознаграждение или на договорной основе",
		"value": 1103
	},
	{
		"label": "Деятельность агентов по оптовой  торговле сельскохозяйственным сырьем, живыми животными, текстильным сырьем и полуфабрикатами",
		"value": 1104
	},
	{
		"label": "Деятельность агентов по оптовой  торговле сельскохозяйственным сырьем, живыми животными, текстильным сырьем и полуфабрикатами",
		"value": 1105
	},
	{
		"label": "Лизинг грузовых автомобилей",
		"value": 1779
	},
	{
		"label": "Деятельность агентов по оптовой торговле топливом, рудами, металлами и химическими веществами",
		"value": 1106
	},
	{
		"label": "Деятельность агентов по оптовой торговле топливом, рудами, металлами и химическими веществами",
		"value": 1107
	},
	{
		"label": "Деятельность агентов по оптовой торговле древесиной и строительными материалами",
		"value": 1108
	},
	{
		"label": "Деятельность агентов по оптовой торговле древесиной и строительными материалами",
		"value": 1109
	},
	{
		"label": "Деятельность агентов по оптовой  торговле машинами, оборудованием, судами и летательными аппаратами",
		"value": 1110
	},
	{
		"label": "Деятельность агентов по оптовой  торговле машинами, оборудованием, судами и летательными аппаратами",
		"value": 1111
	},
	{
		"label": "Деятельность агентов по оптовой торговле мебелью, бытовыми товарами, скобяными и прочими металлическими изделиями",
		"value": 1112
	},
	{
		"label": "Деятельность агентов по оптовой торговле мебелью, бытовыми товарами, скобяными и прочими металлическими изделиями",
		"value": 1113
	},
	{
		"label": "Деятельность агентов по оптовой торговле текстильными изделиями, одеждой, обувью, изделиями из кожи и меха",
		"value": 1114
	},
	{
		"label": "Деятельность агентов по оптовой торговле пищевыми продуктами, напитками и табачными изделиями",
		"value": 1116
	},
	{
		"label": "Деятельность агентов по оптовой торговле пищевыми продуктами, напитками и табачными изделиями",
		"value": 1117
	},
	{
		"label": "Деятельность агентов, специализирующихся на оптовой торговле отдельными видами товаров или группами товаров, не включенными в другие группировки",
		"value": 1118
	},
	{
		"label": "Деятельность агентов, специализирующихся на оптовой торговле отдельными видами товаров или группами товаров, не включенными в другие группировки",
		"value": 1119
	},
	{
		"label": "Оптовая торговля зерном, семенами и кормами для животных",
		"value": 1124
	},
	{
		"label": "Оптовая торговля необработанным табаком",
		"value": 1125
	},
	{
		"label": "Оптовая торговля масличными культурами",
		"value": 1126
	},
	{
		"label": "Оптовая торговля цветами и другими растениями",
		"value": 1127
	},
	{
		"label": "Оптовая торговля цветами и другими растениями",
		"value": 1128
	},
	{
		"label": "Оптовая торговля живыми животными",
		"value": 1129
	},
	{
		"label": "Оптовая торговля живыми животными",
		"value": 1130
	},
	{
		"label": "Оптовая торговля шкурами и кожей",
		"value": 1131
	},
	{
		"label": "Оптовая торговля шкурами и кожей",
		"value": 1132
	},
	{
		"label": "Оптовая торговля фруктами и овощами",
		"value": 1134
	},
	{
		"label": "Оптовая торговля мясом и мясными продуктами",
		"value": 1136
	},
	{
		"label": "Оптовая торговля мясом и мясными продуктами",
		"value": 1137
	},
	{
		"label": "Оптовая торговля молочными продуктами, яйцами и пищевыми маслами и жирами",
		"value": 1138
	},
	{
		"label": "Оптовая торговля молочными продуктами, яйцами и пищевыми маслами и жирами",
		"value": 1139
	},
	{
		"label": "Оптовая торговля напитками",
		"value": 1140
	},
	{
		"label": "Оптовая торговля напитками в магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1141
	},
	{
		"label": "Оптовая торговля изделиями из керамики и стекла, чистящими средствами",
		"value": 1165
	},
	{
		"label": "Оптовая торговля напитками в магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше), включая оптово-продовольственные распределительные центры",
		"value": 1142
	},
	{
		"label": "Оптовая торговля табачными изделиями",
		"value": 1143
	},
	{
		"label": "Оптовая торговля табачными изделиями",
		"value": 1144
	},
	{
		"label": "Оптовая торговля сахаром, шоколадом и сахаристыми кондитерскими изделиями",
		"value": 1145
	},
	{
		"label": "Деятельность туристских агентств",
		"value": 1826
	},
	{
		"label": "Оптовая торговля сахаром, шоколадом и сахаристыми кондитерскими изделиями",
		"value": 1146
	},
	{
		"label": "Оптовая торговля кофе, чаем, какао и специями",
		"value": 1147
	},
	{
		"label": "Оптовая торговля кофе, чаем, какао и специями",
		"value": 1148
	},
	{
		"label": "Оптовая торговля прочими продуктами питания, включая рыбу, ракообразных и моллюсков",
		"value": 1149
	},
	{
		"label": "Оптовая торговля рыбой и рыбными продуктами",
		"value": 1150
	},
	{
		"label": "Оптовая торговля прочими продуктами питания",
		"value": 1151
	},
	{
		"label": "Неспециализированная оптовая торговля продуктами питания, напитками и табачными изделиями",
		"value": 1152
	},
	{
		"label": "Неспециализированная оптовая торговля продуктами питания, напитками и табачными изделиями",
		"value": 1153
	},
	{
		"label": "Оптовая торговля текстильными изделиями",
		"value": 1155
	},
	{
		"label": "Оптовая торговля текстильными изделиями",
		"value": 1156
	},
	{
		"label": "Оптовая торговля одеждой и обувью",
		"value": 1157
	},
	{
		"label": "Оптовая торговля трикотажными и чулочно-носочными изделиями",
		"value": 1158
	},
	{
		"label": "Оптовая торговля одеждой, кроме трикотажных и чулочно-носочных изделий",
		"value": 1159
	},
	{
		"label": "Оптовая торговля радио- и телевизионным оборудованием",
		"value": 1163
	},
	{
		"label": "Оптовая торговля изделиями из керамики и стекла, чистящими средствами",
		"value": 1164
	},
	{
		"label": "Оптовая торговля парфюмерными и косметическими средствами",
		"value": 1166
	},
	{
		"label": "Оптовая торговля парфюмерными и косметическими средствами",
		"value": 1167
	},
	{
		"label": "Оптовая торговля фармацевтическими товарами",
		"value": 1168
	},
	{
		"label": "Оптовая торговля фармацевтическими товарами, кроме торговли медицинской техникой и ортопедическими изделиями",
		"value": 1169
	},
	{
		"label": "Оптовая реализация фармацевтических и медицинских товаров в рамках гарантированного объема бесплатной медицинской помощи",
		"value": 1170
	},
	{
		"label": "Оптовая торговля медицинской техникой и ортопедическими  изделиями",
		"value": 1171
	},
	{
		"label": "Оптовая торговля мебелью, коврами и осветительным оборудованием",
		"value": 1172
	},
	{
		"label": "Оптовая торговля мебелью, коврами и осветительным оборудованием",
		"value": 1173
	},
	{
		"label": "Оптовая торговля часами и ювелирными изделиями",
		"value": 1174
	},
	{
		"label": "Оптовая торговля часами и ювелирными изделиями",
		"value": 1175
	},
	{
		"label": "Оптовая торговля прочими непродовольственными товарами потребительского назначения",
		"value": 1176
	},
	{
		"label": "Оптовая торговля канцелярскими принадлежностями",
		"value": 1177
	},
	{
		"label": "Оптовая торговля музыкальными инструментами ",
		"value": 1178
	},
	{
		"label": "Оптовая торговля прочими непродовольственными товарами потребительского назначения, не включенными в другие группировки",
		"value": 1179
	},
	{
		"label": "Оптовая торговля компьютерами, периферийным компьютерным оборудованием и программным обеспечением",
		"value": 1181
	},
	{
		"label": "Оптовая торговля компьютерами, периферийным компьютерным оборудованием и программным обеспечением",
		"value": 1182
	},
	{
		"label": "Оптовая торговля электронным и телекоммуникационным оборудованием и их частями ",
		"value": 1183
	},
	{
		"label": "Оптовая торговля электронным и телекоммуникационным оборудованием и их частями ",
		"value": 1184
	},
	{
		"label": "Химическая чистка и крашение",
		"value": 2079
	},
	{
		"label": "Оптовая торговля сельскохозяйственной техникой, оборудованием, деталями и принадлежностями к ним",
		"value": 1186
	},
	{
		"label": "Оптовая торговля сельскохозяйственной техникой, оборудованием, деталями и принадлежностями к ним",
		"value": 1187
	},
	{
		"label": "Оптовая торговля  станками",
		"value": 1188
	},
	{
		"label": "Оптовая торговля  станками",
		"value": 1189
	},
	{
		"label": "Оптовая торговля машинами и оборудованием для горнодобывающей промышленности и  строительства",
		"value": 1190
	},
	{
		"label": "Оптовая торговля машинами и оборудованием для горнодобывающей промышленности и  строительства",
		"value": 1191
	},
	{
		"label": "Оптовая торговля машинами и оборудованием для текстильной промышленности и швейными и вязальными машинами",
		"value": 1192
	},
	{
		"label": "Оптовая торговля машинами и оборудованием для текстильной промышленности и швейными и вязальными машинами",
		"value": 1193
	},
	{
		"label": "Оптовая торговля офисной мебелью",
		"value": 1194
	},
	{
		"label": "Оптовая торговля офисной мебелью",
		"value": 1195
	},
	{
		"label": "Оптовая торговля прочими офисными машинами и оборудованием",
		"value": 1196
	},
	{
		"label": "Ассенизаторская деятельность",
		"value": 2107
	},
	{
		"label": "Оптовая торговля прочими машинами и оборудованием",
		"value": 1198
	},
	{
		"label": "Оптовая торговля прочими машинами и оборудованием",
		"value": 1199
	},
	{
		"label": "Оптовая торговля твердым, жидким и газообразным топливом и подобными продуктами",
		"value": 1201
	},
	{
		"label": "Оптовая торговля сырой нефтью и попутным газом",
		"value": 1202
	},
	{
		"label": "Оптовая торговля природным (горючим) газом",
		"value": 1203
	},
	{
		"label": "Оптовая торговля каменным углем",
		"value": 1204
	},
	{
		"label": "Оптовая торговля лигнитом (бурым углем)",
		"value": 1205
	},
	{
		"label": "Оптовая торговля авиационным бензином и керосином",
		"value": 1206
	},
	{
		"label": "Оптовая торговля автомобильным бензином",
		"value": 1207
	},
	{
		"label": "Оптовая торговля дизельным топливом",
		"value": 1208
	},
	{
		"label": "Оптовая торговля мазутом топочным",
		"value": 1209
	},
	{
		"label": "Оптовая торговля металлами и металлическими рудами",
		"value": 1211
	},
	{
		"label": "Оптовая торговля рудами черных и цветных металлов",
		"value": 1212
	},
	{
		"label": "Оптовая торговля чугуном, сталью и их литьем",
		"value": 1213
	},
	{
		"label": "Оптовая торговля редкими, редкоземельными и цветными металлами и их литьем",
		"value": 1214
	},
	{
		"label": "Оптовая торговля драгоценными металлами",
		"value": 1215
	},
	{
		"label": "Оптовая торговля лесоматериалами, строительными материалами и санитарно-техническим оборудованием",
		"value": 1216
	},
	{
		"label": "Оптовая торговля листовым стеклом ",
		"value": 1217
	},
	{
		"label": "Оптовая торговля цементом, песком и гравием",
		"value": 1218
	},
	{
		"label": "Оптовая торговля изделиями из бетона, цемента, гипса и аналогичных материалов",
		"value": 1219
	},
	{
		"label": "Оптовая торговля лакокрасочной продукцией, обоями и напольными покрытиями",
		"value": 1220
	},
	{
		"label": "Оптовая торговля древесиной и продукцией обработки древесины",
		"value": 1221
	},
	{
		"label": "Оптовая торговля строительными металлическими конструкциями",
		"value": 1222
	},
	{
		"label": "Оптовая торговля санитарно-техническим оборудованием",
		"value": 1223
	},
	{
		"label": "Оптовая торговля стеновыми блоками",
		"value": 1224
	},
	{
		"label": "Оптовая торговля скобяными изделиями, водопроводным и отопительным оборудованием и  инвентарем",
		"value": 1225
	},
	{
		"label": "Оптовая торговля скобяными изделиями, водопроводным и отопительным оборудованием и  инвентарем",
		"value": 1226
	},
	{
		"label": "Оптовая торговля химической продукцией",
		"value": 1227
	},
	{
		"label": "Оптовая торговля химическими веществами и химическими продуктами",
		"value": 1228
	},
	{
		"label": "Оптовая торговля резинотехническими изделиями",
		"value": 1229
	},
	{
		"label": "Оптовая торговля минеральными удобрениями",
		"value": 1230
	},
	{
		"label": "Оптовая торговля пестицидами и прочими агрохимическими продуктами",
		"value": 1231
	},
	{
		"label": "Оптовая торговля прочими промежуточными продуктами",
		"value": 1232
	},
	{
		"label": "Оптовая торговля пластмассами в первичных формах и синтетическим каучуком",
		"value": 1233
	},
	{
		"label": "Оптовая торговля прочими промежуточными продуктами",
		"value": 1234
	},
	{
		"label": "Оптовая торговля драгоценными камнями",
		"value": 1235
	},
	{
		"label": "Оптовая торговля отходами и ломом",
		"value": 1236
	},
	{
		"label": "Оптовая торговля ломом и отходами черных и цветных металлов",
		"value": 1237
	},
	{
		"label": "Оптовая торговля ломом драгоценных металлов и драгоценных камней",
		"value": 1238
	},
	{
		"label": "Деятельность грузового воздушного транспорта",
		"value": 1435
	},
	{
		"label": "Специализированная оптовая торговля товарами, не включенными в другие группировки",
		"value": 1246
	},
	{
		"label": "Розничная торговля, кроме торговли автомобилями и мотоциклами",
		"value": 1248
	},
	{
		"label": "Розничная торговля в неспециализированных магазинах",
		"value": 1249
	},
	{
		"label": "Розничная торговля в неспециализированных магазинах преимущественно продуктами питания, напитками и табачными изделиями",
		"value": 1250
	},
	{
		"label": "Розничная торговля преимущественно продуктами питания,  напитками и табачными изделиями, в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1252
	},
	{
		"label": "Прочая розничная торговля в неспециализированных магазинах",
		"value": 1253
	},
	{
		"label": "Розничная торговля фруктами и овощами в специализированных магазинах",
		"value": 1257
	},
	{
		"label": "Розничная торговля фруктами и овощами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1259
	},
	{
		"label": "Розничная торговля мясом и мясными продуктами в специализированных магазинах",
		"value": 1260
	},
	{
		"label": "Розничная торговля домашней птицей, дичью и изделиями из них в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1261
	},
	{
		"label": "Розничная торговля домашней птицей, дичью и изделиями из них в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1262
	},
	{
		"label": "Прочая розничная торговля мясом и мясными продуктами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1263
	},
	{
		"label": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах",
		"value": 1265
	},
	{
		"label": "Розничная торговля обувью и кожаными изделиями в специализированных магазинах",
		"value": 1337
	},
	{
		"label": "Розничная торговля рыбой, ракообразными и моллюсками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1267
	},
	{
		"label": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах",
		"value": 1268
	},
	{
		"label": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1269
	},
	{
		"label": "Розничная торговля хлебобулочными, мучными и сахаристыми кондитерскими изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1270
	},
	{
		"label": "Розничная торговля напитками в специализированных магазинах",
		"value": 1271
	},
	{
		"label": "Розничная торговля напитками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1272
	},
	{
		"label": "Деятельность туристских операторов",
		"value": 1827
	},
	{
		"label": "Розничная торговля напитками в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1273
	},
	{
		"label": "Розничная торговля табачными изделиями в специализированных магазинах",
		"value": 1274
	},
	{
		"label": "Розничная торговля табачными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1275
	},
	{
		"label": "Розничная торговля табачными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1276
	},
	{
		"label": "Прочая розничная торговля продуктами питания в специализированных магазинах",
		"value": 1277
	},
	{
		"label": "Розничная торговля топливом в специализированных магазинах",
		"value": 1281
	},
	{
		"label": "Розничная торговля моторным топливом в специализированных магазинах, за исключением находящихся на придорожной полосе",
		"value": 1282
	},
	{
		"label": "Розничная торговля смазочными материалами в специализированных магазинах",
		"value": 1283
	},
	{
		"label": "Розничная торговля моторным топливом в специализированных магазинах, находящихся на придорожной полосе",
		"value": 1284
	},
	{
		"label": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах",
		"value": 1286
	},
	{
		"label": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1287
	},
	{
		"label": "Розничная торговля компьютерами, периферийным оборудованием и программным обеспечением в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1288
	},
	{
		"label": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах",
		"value": 1289
	},
	{
		"label": "Розничная торговля телекоммуникационным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1290
	},
	{
		"label": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах",
		"value": 1292
	},
	{
		"label": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1293
	},
	{
		"label": "Розничная торговля аудио- и видеоаппаратурой в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1294
	},
	{
		"label": "Розничная торговля текстильными изделиями в специализированных магазинах",
		"value": 1296
	},
	{
		"label": "Розничная торговля текстильными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1297
	},
	{
		"label": "Розничная торговля текстильными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1298
	},
	{
		"label": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах",
		"value": 1299
	},
	{
		"label": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1301
	},
	{
		"label": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах",
		"value": 1302
	},
	{
		"label": "Деятельность  прочего пассажирского сухопутного транспорта, не включенного в другие группировки",
		"value": 1405
	},
	{
		"label": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1303
	},
	{
		"label": "Розничная торговля коврами, ковровыми изделиями, настенными и напольными покрытиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше) ",
		"value": 1304
	},
	{
		"label": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах",
		"value": 1305
	},
	{
		"label": "Розничная торговля одеждой в специализированных магазинах",
		"value": 1332
	},
	{
		"label": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м  ",
		"value": 1306
	},
	{
		"label": "Розничная торговля электрическими бытовыми приборами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1307
	},
	{
		"label": "Розничная торговля мебелью, осветительным оборудованием и прочими бытовыми товарами в специализированных магазинах",
		"value": 1308
	},
	{
		"label": "Розничная торговля мебелью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1309
	},
	{
		"label": "Розничная торговля музыкальными инструментами и партитурами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1310
	},
	{
		"label": "Розничная торговля мебелью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1311
	},
	{
		"label": "Розничная торговля музыкальными инструментами и партитурами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1312
	},
	{
		"label": "Розничная торговля осветительными приборами и бытовыми товарами, не включенными в другие группировки, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1313
	},
	{
		"label": "Розничная торговля книгами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1317
	},
	{
		"label": "Розничная торговля книгами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1318
	},
	{
		"label": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах",
		"value": 1319
	},
	{
		"label": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1320
	},
	{
		"label": "Розничная торговля газетами, журналами и канцелярскими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1321
	},
	{
		"label": "Розничная торговля аудио и видеозаписями в специализированных магазинах",
		"value": 1322
	},
	{
		"label": "Розничная торговля аудио и видеозаписями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1323
	},
	{
		"label": "Розничная торговля аудио и видеозаписями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1324
	},
	{
		"label": "Розничная торговля спортивным оборудованием в специализированных магазинах",
		"value": 1325
	},
	{
		"label": "Розничная торговля спортивным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1326
	},
	{
		"label": "Перестрахование",
		"value": 1634
	},
	{
		"label": "Пенсионное обеспечение",
		"value": 1636
	},
	{
		"label": "Розничная торговля спортивным оборудованием в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1327
	},
	{
		"label": "Розничная торговля играми и игрушками в специализированных магазинах",
		"value": 1328
	},
	{
		"label": "Розничная торговля играми и игрушками в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1330
	},
	{
		"label": "Деятельность водного транспорта",
		"value": 1415
	},
	{
		"label": "Розничная торговля трикотажными и чулочно-носочными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1333
	},
	{
		"label": "Розничная торговля одеждой, кроме трикотажных и чулочно-носочных изделий, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1334
	},
	{
		"label": "Розничная торговля трикотажными и чулочно-носочными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1335
	},
	{
		"label": "Розничная торговля одеждой, кроме трикотажных и чулочно-носочных изделий, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1336
	},
	{
		"label": "Розничная торговля обувью в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1340
	},
	{
		"label": "Розничная торговля кожаными изделиями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1341
	},
	{
		"label": "Розничная торговля фармацевтическими товарами в специализированных магазинах",
		"value": 1342
	},
	{
		"label": "Розничная торговля фармацевтическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1343
	},
	{
		"label": "Розничная торговля фармацевтическими товарами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1344
	},
	{
		"label": "Складирование и хранение грузов",
		"value": 1442
	},
	{
		"label": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах",
		"value": 1345
	},
	{
		"label": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1346
	},
	{
		"label": "Розничная торговля медицинскими и ортопедическими товарами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1347
	},
	{
		"label": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах",
		"value": 1348
	},
	{
		"label": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1349
	},
	{
		"label": "Розничная торговля косметическими товарами и туалетными принадлежностями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1350
	},
	{
		"label": "Розничная торговля цветами, комнатными растениями, семенами, удобрениями, домашними животными и кормами для домашних животных в специализированных магазинах",
		"value": 1351
	},
	{
		"label": "Розничная торговля семенами и удобрениями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1353
	},
	{
		"label": "Государственное пенсионное обеспечение",
		"value": 1637
	},
	{
		"label": "Розничная торговля домашними животными и кормами для домашних животных в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1354
	},
	{
		"label": "Розничная торговля семенами и удобрениями в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1356
	},
	{
		"label": "Розничная торговля домашними животными и кормами для домашних животных в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1357
	},
	{
		"label": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах",
		"value": 1358
	},
	{
		"label": "Розничная торговля часами и ювелирными изделиями в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1359
	},
	{
		"label": "Издание газет",
		"value": 1519
	},
	{
		"label": "Розничная торговля фотографическим, оптическим и точным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1364
	},
	{
		"label": "Розничная торговля велосипедами в специализированных магазинах, являющихся  торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1365
	},
	{
		"label": "Прочая розничная торговля в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1366
	},
	{
		"label": "Розничная торговля подержанными товарами в магазинах",
		"value": 1368
	},
	{
		"label": "Розничная торговля подержанными товарами в магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1369
	},
	{
		"label": "Розничная торговля подержанными товарами в  магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1370
	},
	{
		"label": "Розничная торговля продуктами питания, напитками и табачными изделиями в торговых палатках, киосках и на рынках",
		"value": 1372
	},
	{
		"label": "Розничная торговля продуктами питания, напитками и табачными изделиями на рынках",
		"value": 1374
	},
	{
		"label": "Розничная торговля одеждой, обувью и текстильными изделиями  в торговых палатках, киосках и на рынках",
		"value": 1375
	},
	{
		"label": "Розничная торговля одеждой, обувью и текстильными изделиями в торговых палатках, ларьках и киосках",
		"value": 1376
	},
	{
		"label": "Розничная торговля одеждой, обувью и текстильными изделиями на рынках",
		"value": 1377
	},
	{
		"label": "Розничная торговля прочими товарами в палатках, киосках и  на рынках",
		"value": 1378
	},
	{
		"label": "Розничная торговля прочими товарами в торговых палатках, ларьках и киосках",
		"value": 1379
	},
	{
		"label": "Розничная торговля путем заказа товаров по почте или через сеть Интернет",
		"value": 1382
	},
	{
		"label": "Прочая розничная торговля вне магазинов, палаток, киосков или рынков",
		"value": 1384
	},
	{
		"label": "Развозная и разносная розничная торговля",
		"value": 1385
	},
	{
		"label": "Деятельность сухопутного и трубопроводного транспорта",
		"value": 1389
	},
	{
		"label": "Деятельность морского и прибрежного пассажирского транспорта",
		"value": 1416
	},
	{
		"label": "Управление финансовыми рынками",
		"value": 1641
	},
	{
		"label": "Перевозки трамваями",
		"value": 1399
	},
	{
		"label": "Перевозки троллейбусами",
		"value": 1400
	},
	{
		"label": "Перевозки метрополитеном",
		"value": 1401
	},
	{
		"label": "Перевозки прочими видами транспорта, подчиняющегося расписанию",
		"value": 1402
	},
	{
		"label": "Деятельность такси",
		"value": 1403
	},
	{
		"label": "Деятельность такси",
		"value": 1404
	},
	{
		"label": "Деятельность  прочего пассажирского сухопутного транспорта, не включенного в другие группировки",
		"value": 1406
	},
	{
		"label": "Деятельность грузового автомобильного транспорта",
		"value": 1408
	},
	{
		"label": "Деятельность грузового автомобильного транспорта",
		"value": 1409
	},
	{
		"label": "Предоставление услуг по переезду",
		"value": 1410
	},
	{
		"label": "Предоставление услуг по переезду",
		"value": 1411
	},
	{
		"label": "Деятельность трубопроводного транспорта",
		"value": 1413
	},
	{
		"label": "Деятельность морского и прибрежного пассажирского транспорта",
		"value": 1417
	},
	{
		"label": "Деятельность морского и прибрежного пассажирского транспорта",
		"value": 1418
	},
	{
		"label": "Деятельность морского и прибрежного грузового транспорта",
		"value": 1420
	},
	{
		"label": "Деятельность морского и прибрежного грузового транспорта",
		"value": 1421
	},
	{
		"label": "Деятельность речного пассажирского транспорта",
		"value": 1423
	},
	{
		"label": "Деятельность речного пассажирского транспорта",
		"value": 1424
	},
	{
		"label": "Деятельность речного  грузового транспорта",
		"value": 1426
	},
	{
		"label": "Деятельность речного  грузового транспорта, кроме лесосплава",
		"value": 1427
	},
	{
		"label": "Лесосплав",
		"value": 1428
	},
	{
		"label": "Деятельность воздушного транспорта",
		"value": 1429
	},
	{
		"label": "Деятельность воздушного пассажирского транспорта",
		"value": 1430
	},
	{
		"label": "Деятельность воздушного пассажирского транспорта",
		"value": 1431
	},
	{
		"label": "Деятельность  воздушного пассажирского транспорта, подчиняющегося расписанию",
		"value": 1432
	},
	{
		"label": "Деятельность  воздушного пассажирского транспорта, не подчиняющегося расписанию",
		"value": 1433
	},
	{
		"label": "Деятельность товарных бирж",
		"value": 1642
	},
	{
		"label": "Деятельность грузового воздушного транспорта, подчиняющего расписанию",
		"value": 1436
	},
	{
		"label": "Деятельность грузового воздушного транспорта, не подчиняющего расписанию",
		"value": 1437
	},
	{
		"label": "Деятельность космического транспорта",
		"value": 1438
	},
	{
		"label": "Деятельность космического транспорта",
		"value": 1439
	},
	{
		"label": "Складирование грузов и вспомогательная транспортная деятельность",
		"value": 1440
	},
	{
		"label": "Складирование и хранение грузов",
		"value": 1441
	},
	{
		"label": "Складирование и хранение зерна",
		"value": 1443
	},
	{
		"label": "Складирование и хранение продовольственных товаров, кроме овощей и фруктов",
		"value": 1445
	},
	{
		"label": "Хранение нефти",
		"value": 1446
	},
	{
		"label": "Складирование и хранение овощей и фруктов",
		"value": 1447
	},
	{
		"label": "Вспомогательная деятельность сухопутного транспорта",
		"value": 1449
	},
	{
		"label": "Эксплуатация железных дорог",
		"value": 1450
	},
	{
		"label": "Эксплуатация автомобильных дорог",
		"value": 1451
	},
	{
		"label": "Деятельность терминалов",
		"value": 1452
	},
	{
		"label": "Деятельность автомобильных стоянок",
		"value": 1453
	},
	{
		"label": "Эксплуатация магистральных и иных трубопроводов, в том числе водоводов",
		"value": 1454
	},
	{
		"label": "Деятельность по подсоединению (подключению) трубопровода к действующим магистральным и иным трубопроводам",
		"value": 1455
	},
	{
		"label": "Прочая вспомогательная деятельность  сухопутного транспорта",
		"value": 1456
	},
	{
		"label": "Вспомогательная деятельность водного транспорта",
		"value": 1457
	},
	{
		"label": "Вспомогательная деятельность водного транспорта",
		"value": 1458
	},
	{
		"label": "Вспомогательная деятельность воздушного транспорта",
		"value": 1459
	},
	{
		"label": "Регулирование использования воздушного пространства",
		"value": 1460
	},
	{
		"label": "Деятельность аэропортов",
		"value": 1461
	},
	{
		"label": "Прочая деятельность, относящаяся к пассажирским и грузовым перевозкам воздушным транспортом",
		"value": 1462
	},
	{
		"label": "Транспортная обработка грузов",
		"value": 1463
	},
	{
		"label": "Транспортная обработка грузов",
		"value": 1464
	},
	{
		"label": "Прочая вспомогательная транспортная деятельность",
		"value": 1465
	},
	{
		"label": "Транспортно-экспедиционные  услуги",
		"value": 1466
	},
	{
		"label": "Технический надзор на транспорте",
		"value": 1467
	},
	{
		"label": "Прочая транспортно-экспедиционная деятельность",
		"value": 1468
	},
	{
		"label": "Почтовая и курьерская деятельность",
		"value": 1469
	},
	{
		"label": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
		"value": 1470
	},
	{
		"label": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
		"value": 1471
	},
	{
		"label": "Почтовая деятельность  в рамках предоставления услуг общего пользования",
		"value": 1472
	},
	{
		"label": "Прочая почтовая и курьерская деятельность",
		"value": 1474
	},
	{
		"label": "Прочая почтовая и курьерская деятельность",
		"value": 1475
	},
	{
		"label": "Предоставление услуг по проживанию и питанию",
		"value": 1476
	},
	{
		"label": "Предоставление услуг по временному проживанию",
		"value": 1477
	},
	{
		"label": "Предоставление услуг гостиницами и аналогичными местами для проживания",
		"value": 1478
	},
	{
		"label": "Предоставление услуг гостиницами и аналогичными местами для проживания",
		"value": 1479
	},
	{
		"label": "Предоставление услуг общежитиями при школах-интернатах",
		"value": 1493
	},
	{
		"label": "Предоставление услуг студенческими общежитиями ",
		"value": 1494
	},
	{
		"label": "Предоставление услуг прочими местами для проживания, не включенными в другие категории",
		"value": 1495
	},
	{
		"label": "Предоставление услуг по обеспечению  питанием и напитками",
		"value": 1496
	},
	{
		"label": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
		"value": 1497
	},
	{
		"label": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания",
		"value": 1498
	},
	{
		"label": "Доставка готовой пищи на заказ",
		"value": 1502
	},
	{
		"label": "Прочая деятельность по обеспечению питанием",
		"value": 1504
	},
	{
		"label": "Прочие виды организации питания вне населенных пунктов",
		"value": 1505
	},
	{
		"label": "Прочие виды организации питания в пассажирских поездах",
		"value": 1506
	},
	{
		"label": "Подача напитков",
		"value": 1509
	},
	{
		"label": "Подача напитков",
		"value": 1510
	},
	{
		"label": "Информация и связь",
		"value": 1511
	},
	{
		"label": "Издание книг, периодических публикаций и другие виды издательской деятельности",
		"value": 1513
	},
	{
		"label": "Издание книг",
		"value": 1514
	},
	{
		"label": "Издание книг",
		"value": 1515
	},
	{
		"label": "Издание газет",
		"value": 1518
	},
	{
		"label": "Издание компьютерных игр",
		"value": 1526
	},
	{
		"label": "Издание прочего программного обеспечения",
		"value": 1527
	},
	{
		"label": "Издание прочего программного обеспечения",
		"value": 1528
	},
	{
		"label": "Негосударственное пенсионное обеспечение",
		"value": 1638
	},
	{
		"label": "Производство кино-, видеофильмов и телевизионных программ, деятельность в сфере звукозаписи и издания музыкальных произведений ",
		"value": 1529
	},
	{
		"label": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
		"value": 1530
	},
	{
		"label": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
		"value": 1531
	},
	{
		"label": "Деятельность по производству кино-, видеофильмов и телевизионных программ",
		"value": 1532
	},
	{
		"label": "Деятельность по завершению создания кино-, видеофильмов  и телевизионных программ",
		"value": 1533
	},
	{
		"label": "Деятельность по завершению создания кино-, видеофильмов  и телевизионных программ",
		"value": 1534
	},
	{
		"label": "Деятельность по распространению кино-, видеофильмов и телевизионных программ",
		"value": 1535
	},
	{
		"label": "Деятельность по распространению кино-, видеофильмов и телевизионных программ",
		"value": 1536
	},
	{
		"label": "Деятельность по показу кинофильмов",
		"value": 1537
	},
	{
		"label": "Деятельность по показу кинофильмов",
		"value": 1538
	},
	{
		"label": "Деятельность в сфере звукозаписи и издания музыкальных произведений ",
		"value": 1540
	},
	{
		"label": "Деятельность в сфере звукозаписи и издания музыкальных произведений ",
		"value": 1541
	},
	{
		"label": "Деятельность по созданию программ и телерадиовещание",
		"value": 1542
	},
	{
		"label": "Радиовещание",
		"value": 1543
	},
	{
		"label": "Радиовещание",
		"value": 1544
	},
	{
		"label": "Радиовещание",
		"value": 1545
	},
	{
		"label": "Деятельность по созданию и трансляции телевизионных программ",
		"value": 1547
	},
	{
		"label": "Деятельность по созданию и трансляции телевизионных программ",
		"value": 1548
	},
	{
		"label": "Телекоммуникации",
		"value": 1549
	},
	{
		"label": "Проводная телекоммуникационная связь",
		"value": 1550
	},
	{
		"label": "Проводная телекоммуникационная связь",
		"value": 1551
	},
	{
		"label": "Проводная телекоммуникационная связь для государственных органов посредством единой транспортной среды",
		"value": 1552
	},
	{
		"label": "Прочая проводная телекоммуникационная связь",
		"value": 1553
	},
	{
		"label": "Беспроводная телекоммуникационная связь",
		"value": 1555
	},
	{
		"label": "Беспроводная телекоммуникационная связь посредством единой транспортной среды",
		"value": 1556
	},
	{
		"label": "Организация вещания теле-, радиопрограмм посредством сети национального оператора телерадиовещания",
		"value": 1557
	},
	{
		"label": "Прочая беспроводная телекоммуникационная связь",
		"value": 1558
	},
	{
		"label": "Деятельность в области спутниковых телекоммуникаций",
		"value": 1560
	},
	{
		"label": "Деятельность в области спутниковых телекоммуникаций для государственных органов посредством единой транспортной среды",
		"value": 1561
	},
	{
		"label": "Консультационные  и практические услуги в области информационных технологий",
		"value": 1574
	},
	{
		"label": "Планирование и проектирование коммерческих информационных систем",
		"value": 1575
	},
	{
		"label": "Деятельность по управлению компьютерным оборудованием",
		"value": 1576
	},
	{
		"label": "Другие виды деятельности в области информационных технологий и информационных систем",
		"value": 1579
	},
	{
		"label": "Другие виды деятельности в области информационных технологий и информационных систем в рамках автоматизации функций государственных органов, учреждений и организаций",
		"value": 1580
	},
	{
		"label": "Деятельность в области кибербезопасности",
		"value": 1581
	},
	{
		"label": "Деятельность в области информационного обслуживания",
		"value": 1583
	},
	{
		"label": "Обработка данных, размещение приложений (прикладных программ) и связанная с этим деятельность; деятельность веб-порталов",
		"value": 1584
	},
	{
		"label": "Вспомогательная деятельность в сфере финансовых услуг и страхования",
		"value": 1639
	},
	{
		"label": "Обработка данных, размещение приложений (прикладных программ) и связанная с этим деятельность",
		"value": 1585
	},
	{
		"label": "Информационно-методологическое обеспечение с сопровождением информационных систем и баз данных",
		"value": 1587
	},
	{
		"label": "Деятельность веб-порталов",
		"value": 1588
	},
	{
		"label": "Деятельность веб-порталов",
		"value": 1589
	},
	{
		"label": "Деятельность информационных агентств",
		"value": 1591
	},
	{
		"label": "Деятельность информационных агентств",
		"value": 1592
	},
	{
		"label": "Прочая деятельность в области информационного обслуживания, не включенная в другие группировки",
		"value": 1593
	},
	{
		"label": "Прочие виды деятельности, связанные с предоставлением компьютеризированных услуг телефонной связи",
		"value": 1594
	},
	{
		"label": "Прочие виды деятельности в области информационного обслуживания",
		"value": 1595
	},
	{
		"label": "Финансовая и страховая деятельность",
		"value": 1596
	},
	{
		"label": "Деятельность национального банка",
		"value": 1599
	},
	{
		"label": "Деятельность национального банка",
		"value": 1600
	},
	{
		"label": "Прочее денежное посредничество",
		"value": 1601
	},
	{
		"label": "Деятельность банков, за исключением, банка, являющегося национальным институтом развития, и его дочерней организации-лизингодателя",
		"value": 1602
	},
	{
		"label": "Деятельность сберегательных банков",
		"value": 1603
	},
	{
		"label": "Деятельность банка, являющегося национальным институтом развития, и его дочерней организации-лизингодателя",
		"value": 1604
	},
	{
		"label": "Денежное посредничество прочих финансовых учреждений",
		"value": 1605
	},
	{
		"label": "Деятельность холдинговых компаний",
		"value": 1607
	},
	{
		"label": "Деятельность холдинговых компаний",
		"value": 1608
	},
	{
		"label": "Деятельность трастовых компаний, инвестиционных фондов и аналогичных финансовых организаций",
		"value": 1610
	},
	{
		"label": "Деятельность трастовых компаний, инвестиционных фондов и аналогичных финансовых организаций",
		"value": 1611
	},
	{
		"label": "Финансовый лизинг, кроме финансового лизинга медицинского оборудования и техники",
		"value": 1614
	},
	{
		"label": "Финансовый лизинг медицинского оборудования и техники",
		"value": 1615
	},
	{
		"label": "Прочие виды кредитования",
		"value": 1616
	},
	{
		"label": "Деятельность ломбардов",
		"value": 1617
	},
	{
		"label": "Деятельность специальных фондов финансовой поддержки субъектов частного предпринимательства",
		"value": 1618
	},
	{
		"label": "Прочие виды кредитования, не включенные в другие группировки",
		"value": 1619
	},
	{
		"label": "Прочее финансовое посредничество, кроме страхования и пенсионного обеспечения, не включенное в другие группировки",
		"value": 1620
	},
	{
		"label": "Финансово-экономические услуги, оказываемые субъектам индустриально-инновационной деятельности и агропромышленного комплекса",
		"value": 1621
	},
	{
		"label": "Другие финансовые услуги, финансирование в различных отраслях экономики, инвестиционная деятельность",
		"value": 1622
	},
	{
		"label": "Другие виды финансовых услуг, кроме страхования и пенсионного обеспечения, не включенные в другие группировки",
		"value": 1623
	},
	{
		"label": "Страхование, перестрахование и пенсионное обеспечение, кроме обязательного социального обеспечения",
		"value": 1624
	},
	{
		"label": "Страхование",
		"value": 1625
	},
	{
		"label": "Страхование жизни",
		"value": 1626
	},
	{
		"label": "Государственное страхование жизни",
		"value": 1627
	},
	{
		"label": "Негосударственное страхование жизни",
		"value": 1628
	},
	{
		"label": "Страхование ущерба",
		"value": 1629
	},
	{
		"label": "Государственное страхование ущерба",
		"value": 1630
	},
	{
		"label": "Негосударственное страхование ущерба",
		"value": 1631
	},
	{
		"label": "Перестрахование",
		"value": 1633
	},
	{
		"label": "Вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
		"value": 1640
	},
	{
		"label": "Брокерская деятельность по сделкам с ценными бумагами и товарами, за исключением деятельности, связанной с управлением активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
		"value": 1647
	},
	{
		"label": "Прочая вспомогательная деятельность в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
		"value": 1649
	},
	{
		"label": "Оценка страховых рисков и убытков",
		"value": 1651
	},
	{
		"label": "Оценка страховых рисков и убытков",
		"value": 1652
	},
	{
		"label": "Деятельность страховых агентов и брокеров",
		"value": 1653
	},
	{
		"label": "Деятельность страховых агентов и брокеров",
		"value": 1654
	},
	{
		"label": "Прочая вспомогательная деятельность по страхованию и пенсионному обеспечению",
		"value": 1655
	},
	{
		"label": "Прочая вспомогательная деятельность по страхованию и пенсионному обеспечению",
		"value": 1656
	},
	{
		"label": "Деятельность по управлению фондами",
		"value": 1658
	},
	{
		"label": "Деятельность по управлению активами Национального фонда, золотовалютными активами Национального Банка, пенсионными активами ",
		"value": 1659
	},
	{
		"label": "Деятельность по доверительному управлению портфелем активов фондов",
		"value": 1660
	},
	{
		"label": "Операции с недвижимым имуществом",
		"value": 1661
	},
	{
		"label": "Операции с недвижимым имуществом",
		"value": 1662
	},
	{
		"label": "Покупка и продажа собственного недвижимого имущества",
		"value": 1663
	},
	{
		"label": "Покупка и продажа собственного недвижимого имущества",
		"value": 1664
	},
	{
		"label": "Покупка и продажа многоквартирных и жилых домов (особняков)",
		"value": 1665
	},
	{
		"label": "Покупка и продажа прочей недвижимости",
		"value": 1666
	},
	{
		"label": "Аренда и управление собственной или арендуемой недвижимостью",
		"value": 1668
	},
	{
		"label": "Деятельность агентств по операциям с недвижимым имуществом",
		"value": 1673
	},
	{
		"label": "Посреднические услуги при купле-продаже и сдаче внаем недвижимого имущества производственно-технического назначения",
		"value": 1674
	},
	{
		"label": "Деятельность по взаимоотношениям и связью с общественностью",
		"value": 1699
	},
	{
		"label": "Посреднические услуги при купле-продаже и сдаче внаем жилья и другого недвижимого имущества непроизводственного назначения",
		"value": 1675
	},
	{
		"label": "Управление недвижимым имуществом за вознаграждение или на договорной основе",
		"value": 1676
	},
	{
		"label": "Основное и общее среднее образование",
		"value": 1915
	},
	{
		"label": "Управление недвижимостью за вознаграждение или на договорной основе",
		"value": 1677
	},
	{
		"label": "Обеспечение мер при эксплуатаций «Эталонного центра»",
		"value": 1678
	},
	{
		"label": "Профессиональная, научная и техническая деятельность",
		"value": 1679
	},
	{
		"label": "Деятельность в области права и бухгалтерского учета",
		"value": 1680
	},
	{
		"label": "Деятельность в области права",
		"value": 1681
	},
	{
		"label": "Деятельность в области права",
		"value": 1682
	},
	{
		"label": "Адвокатская деятельность",
		"value": 1683
	},
	{
		"label": "Нотариальная деятельность",
		"value": 1684
	},
	{
		"label": "Прочая деятельность в области права",
		"value": 1685
	},
	{
		"label": "Деятельность в области бухгалтерского учета и аудита; консультирование по налогообложению",
		"value": 1687
	},
	{
		"label": "Деятельность по проведению финансовой ревизии (аудита)",
		"value": 1688
	},
	{
		"label": "Деятельность в области составления счетов и бухгалтерского учета",
		"value": 1689
	},
	{
		"label": "Консультрование в области налогообложения",
		"value": 1690
	},
	{
		"label": "Деятельность, связанная с неплатежеспособностью и взысканием задолженности",
		"value": 1691
	},
	{
		"label": "Деятельность в области бухгалтерского, налогового учета и казначейских операций для национального управляющего холдинга",
		"value": 1692
	},
	{
		"label": "Деятельность головных компаний; консультирование по вопросам управления",
		"value": 1693
	},
	{
		"label": "Деятельность головных компаний",
		"value": 1694
	},
	{
		"label": "Деятельность головных компаний",
		"value": 1695
	},
	{
		"label": "Деятельность головных компаний, связанная с реализацией государственной политики индустриально-инновационного развития",
		"value": 1696
	},
	{
		"label": "Деятельность прочих головных компаний",
		"value": 1697
	},
	{
		"label": "Деятельность по взаимоотношениям и связью с общественностью",
		"value": 1700
	},
	{
		"label": "Консультирование по вопросам коммерческой деятельности и прочее консультирование по вопросам управления",
		"value": 1701
	},
	{
		"label": "Консультирование по вопросам коммерческой деятельности и управления",
		"value": 1702
	},
	{
		"label": "Консультативное сопровождение концессионных проектов и проектов государственно-частного партнерства",
		"value": 1703
	},
	{
		"label": "Деятельность в области архитектуры, инженерных изысканий, технических испытаний и анализа",
		"value": 1704
	},
	{
		"label": "Деятельность в области архитектуры, инженерных изысканий и предоставление технических консультаций в этих областях",
		"value": 1705
	},
	{
		"label": "Деятельность в области архитектуры",
		"value": 1706
	},
	{
		"label": "Деятельность в области архитектуры для объектов атомной промышленности и атомной энергетики",
		"value": 1707
	},
	{
		"label": "Деятельность в области архитектуры, за исключением объектов атомной промышленности и атомной энергетики  ",
		"value": 1708
	},
	{
		"label": "Деятельность в области инженерных изысканий и предоставление технических консультаций в этой области",
		"value": 1709
	},
	{
		"label": "Деятельность в области инженерно-технического проектирования, за исключением объектов атомной промышленности и атомной энергетики",
		"value": 1710
	},
	{
		"label": "Деятельность по проведению геологической разведки и изысканий (без научных исследований и разработок)",
		"value": 1711
	},
	{
		"label": "Геодезическая деятельность",
		"value": 1712
	},
	{
		"label": "Землеустройство",
		"value": 1713
	},
	{
		"label": "Деятельность в области картографии",
		"value": 1714
	},
	{
		"label": "Исследование конъюнктуры рынка и изучение общественного мнения",
		"value": 1749
	},
	{
		"label": "Технические испытания и анализы",
		"value": 1718
	},
	{
		"label": "Деятельность санитарно-эпидемиологических организаций",
		"value": 1719
	},
	{
		"label": "Деятельность организаций санитарного просвещения",
		"value": 1720
	},
	{
		"label": "Деятельность нефтеперерабатывающих заводов по осуществлению лабораторных испытаний и анализов",
		"value": 1721
	},
	{
		"label": "Лабораторно-аналитические исследования в геологической отрасли",
		"value": 1722
	},
	{
		"label": "Деятельность прочих учреждений, осуществляющих технические испытания и анализы",
		"value": 1723
	},
	{
		"label": "Научные исследования и разработки",
		"value": 1724
	},
	{
		"label": "Научные исследования и экспериментальные разработки в области естественных и технических наук ",
		"value": 1725
	},
	{
		"label": "Научные исследования и экспериментальные разработки в области биотехнологий",
		"value": 1726
	},
	{
		"label": "Научные исследования и экспериментальные разработки в области биотехнологий",
		"value": 1727
	},
	{
		"label": "Прочие научные исследования и экспериментальные разработки в области естественных и технических наук ",
		"value": 1728
	},
	{
		"label": "Научные исследования и экспериментальные разработки в области проектирования, строительства, ремонта, содержания и диагностики автомобильных дорог и мостовых сооружений",
		"value": 1729
	},
	{
		"label": "Аренда грузовых автомобилей",
		"value": 1778
	},
	{
		"label": "Научно-исследовательские, опытно-методические и опытно-конструкторские работы в геологической отрасли",
		"value": 1730
	},
	{
		"label": "Научные исследования и разработки в области космической деятельности",
		"value": 1731
	},
	{
		"label": "Исследования и экспериментальные разработки в области мирного использования атомной энергии",
		"value": 1732
	},
	{
		"label": "Исследования и разработки в области противоинфекционных препаратов",
		"value": 1733
	},
	{
		"label": "Прочие исследования и разработки в области естественных и технических наук ",
		"value": 1734
	},
	{
		"label": "Исследования и экспериментальные разработки в области общественных и гуманитарных наук",
		"value": 1736
	},
	{
		"label": "Прикладные исследования в области общественных и гуманитарных наук, направленные на содействие развитию отечественных производств",
		"value": 1737
	},
	{
		"label": "Прикладные исследования в области общественных и гуманитарных наук, направленных на развитие индустрии",
		"value": 1738
	},
	{
		"label": "Исследования и экспериментальные разработки в области обороны и национальной безопасности",
		"value": 1739
	},
	{
		"label": "Междисциплинарные исследования и разработки в области экономического, бюджетного и стратегического планирования",
		"value": 1740
	},
	{
		"label": "Исследования и экспериментальные разработки в области общественных и гуманитарных наук, не включенные в другие группировки",
		"value": 1741
	},
	{
		"label": "Рекламная деятельность и исследование конъюнктуры рынка",
		"value": 1742
	},
	{
		"label": "Рекламная деятельность",
		"value": 1743
	},
	{
		"label": "Деятельность рекламных агентств",
		"value": 1744
	},
	{
		"label": "Деятельность рекламных агентств",
		"value": 1745
	},
	{
		"label": "Размещение рекламы в средствах массовой информации",
		"value": 1746
	},
	{
		"label": "Размещение рекламы в средствах массовой информации",
		"value": 1747
	},
	{
		"label": "Специализированная дизайнерская деятельность",
		"value": 1754
	},
	{
		"label": "Деятельность в области фотографии",
		"value": 1756
	},
	{
		"label": "Деятельность в области фотографии",
		"value": 1757
	},
	{
		"label": "Деятельность по устному и письменному переводу",
		"value": 1759
	},
	{
		"label": "Деятельность фильмо- и фонотек",
		"value": 1998
	},
	{
		"label": "Деятельность по устному и письменному переводу",
		"value": 1760
	},
	{
		"label": "Прочая профессиональная, научная и техническая деятельность, не включенная в другие группировки",
		"value": 1762
	},
	{
		"label": "Деятельность гидрометеорологической службы",
		"value": 1763
	},
	{
		"label": "Аккредитация в области оценки соответствия",
		"value": 1764
	},
	{
		"label": "Деятельность ведомственных служб, занимающихся инновационными технологиями (медицинские, образовательные, консультационные и др.)",
		"value": 1765
	},
	{
		"label": "Иная профессиональная, научная и техническая деятельность, не включенная в другие группировки",
		"value": 1766
	},
	{
		"label": "Ветеринарная деятельность",
		"value": 1767
	},
	{
		"label": "Ветеринарная деятельность",
		"value": 1768
	},
	{
		"label": "Ветеринарная деятельность",
		"value": 1769
	},
	{
		"label": "Ветеринарная деятельность",
		"value": 1770
	},
	{
		"label": "Деятельность в области административного и вспомогательного обслуживания",
		"value": 1771
	},
	{
		"label": "Аренда, прокат и лизинг",
		"value": 1772
	},
	{
		"label": "Аренда и лизинг автомобилей",
		"value": 1773
	},
	{
		"label": "Аренда и лизинг легковых автомобилей и легких автотранспортных средств",
		"value": 1774
	},
	{
		"label": "Аренда легковых автомобилей и легких автотранспортных средств",
		"value": 1775
	},
	{
		"label": "Лизинг легковых автомобилей и легких автотранспортных средств",
		"value": 1776
	},
	{
		"label": "Аренда и лизинг грузовых автомобилей",
		"value": 1777
	},
	{
		"label": "Аренда и лизинг развлекательного и спортивного оборудования",
		"value": 1781
	},
	{
		"label": "Аренда и лизинг развлекательного и спортивного оборудования",
		"value": 1782
	},
	{
		"label": "Прокат видеокассет и дисков",
		"value": 1783
	},
	{
		"label": "Прокат видеокассет и дисков",
		"value": 1784
	},
	{
		"label": "Аренда и лизинг прочих предметов личного потребления и бытовых товаров",
		"value": 1785
	},
	{
		"label": "Аренда и лизинг прочих предметов личного потребления и бытовых товаров",
		"value": 1786
	},
	{
		"label": "Аренда и лизинг сельскохозяйственных машин и оборудования",
		"value": 1788
	},
	{
		"label": "Аренда сельскохозяйственных машин и оборудования",
		"value": 1789
	},
	{
		"label": "Лизинг сельскохозяйственных машин  и оборудования",
		"value": 1790
	},
	{
		"label": "Аренда и лизинг строительных машин и оборудования ",
		"value": 1791
	},
	{
		"label": "Аренда строительных машин и оборудования ",
		"value": 1792
	},
	{
		"label": "Лизинг строительных машин и оборудования ",
		"value": 1793
	},
	{
		"label": "Лизинг компьютерного и периферийного оборудования для инфотелекоммуникационной системы",
		"value": 1796
	},
	{
		"label": "Лизинг прочих офисных машин и оборудования",
		"value": 1798
	},
	{
		"label": "Аренда и лизинг водных транспортных средств и оборудования",
		"value": 1799
	},
	{
		"label": "Аренда водных транспортных средств и оборудования",
		"value": 1800
	},
	{
		"label": "Лизинг водных транспортных средств и оборудования",
		"value": 1801
	},
	{
		"label": "Аренда и лизинг воздушных транспортных средств и оборудования",
		"value": 1802
	},
	{
		"label": "Аренда воздушных транспортных средств и оборудования",
		"value": 1803
	},
	{
		"label": "Лизинг воздушных транспортных средств и оборудования",
		"value": 1804
	},
	{
		"label": "Аренда и лизинг прочих машин, оборудования и материальных активов, не включенных в другие группировки",
		"value": 1805
	},
	{
		"label": "Аренда прочих машин, оборудования и материальных активов, не включенных в другие группировки",
		"value": 1806
	},
	{
		"label": "Лизинг прочих машин, оборудования и материальных активов, не включенных в другие группировки",
		"value": 1807
	},
	{
		"label": "Лизинг продуктов интеллектуальной собственности и аналогичных  продуктов, кроме работ, защищенным авторским правом",
		"value": 1809
	},
	{
		"label": "Лизинг продуктов интеллектуальной собственности и аналогичных  продуктов, кроме работ, защищенным авторским правом",
		"value": 1810
	},
	{
		"label": "Деятельность в области трудоустройства",
		"value": 1811
	},
	{
		"label": "Деятельность агентств по трудоустройству",
		"value": 1812
	},
	{
		"label": "Деятельность агентств по трудоустройству",
		"value": 1813
	},
	{
		"label": "Деятельность агентств по трудоустройству",
		"value": 1814
	},
	{
		"label": "Деятельность агентств по временному трудоустройству",
		"value": 1816
	},
	{
		"label": "Деятельность агентств по временному трудоустройству",
		"value": 1817
	},
	{
		"label": "Прочая деятельность по обеспечению трудовыми ресурсами (персоналом)",
		"value": 1819
	},
	{
		"label": "Прочая деятельность по обеспечению трудовыми ресурсами (персоналом), кроме деятельности организаций, учрежденных национальными компаниями",
		"value": 1820
	},
	{
		"label": "Деятельность организаций, учрежденных национальными компаниями, по предоставлению технического и вспомогательного персонала для обслуживания стратегических объектов",
		"value": 1821
	},
	{
		"label": "Деятельность по кадровому делопроизводству для национального управляющего холдинга",
		"value": 1822
	},
	{
		"label": "Деятельность туристских агентств и операторов, бронирование и сопутствующая деятельность",
		"value": 1823
	},
	{
		"label": "Деятельность туристских агентств и операторов",
		"value": 1824
	},
	{
		"label": "Деятельность туристских агентств",
		"value": 1825
	},
	{
		"label": "Деятельность туристских операторов",
		"value": 1828
	},
	{
		"label": "Прочие услуги по бронированию и сопутствующая деятельность",
		"value": 1830
	},
	{
		"label": "Прочие услуги по бронированию и сопутствующая деятельность",
		"value": 1831
	},
	{
		"label": "Деятельность по проведению расследований",
		"value": 1841
	},
	{
		"label": "Деятельность по проведению расследований",
		"value": 1842
	},
	{
		"label": "Деятельность по обслуживанию зданий и благоустройству территорий",
		"value": 1843
	},
	{
		"label": "Комплексное обслуживание объектов",
		"value": 1844
	},
	{
		"label": "Комплексное обслуживание объектов",
		"value": 1845
	},
	{
		"label": "Комплексное обслуживание объектов",
		"value": 1846
	},
	{
		"label": "Общая уборка зданий",
		"value": 1848
	},
	{
		"label": "Общая уборка зданий",
		"value": 1849
	},
	{
		"label": "Прочая деятельность (специализированная) по уборке зданий, чистке промышленных машин и оборудования",
		"value": 1850
	},
	{
		"label": "Прочая деятельность (специализированная) по уборке зданий, чистке промышленных машин и оборудования",
		"value": 1851
	},
	{
		"label": "Прочая деятельность по уборке",
		"value": 1852
	},
	{
		"label": "Прочая деятельность по уборке",
		"value": 1853
	},
	{
		"label": "Деятельность по благоустройству территорий",
		"value": 1855
	},
	{
		"label": "Деятельность по благоустройству территорий",
		"value": 1856
	},
	{
		"label": "Деятельность в области офисного административного и вспомогательного обслуживания, направленная на поддержание коммерческой деятельности",
		"value": 1857
	},
	{
		"label": "Деятельность в области офисного административного и вспомогательного обслуживания",
		"value": 1858
	},
	{
		"label": "Деятельность по предоставлению комплексных офисных административных услуг",
		"value": 1859
	},
	{
		"label": "Деятельность по предоставлению комплексных офисных административных услуг",
		"value": 1860
	},
	{
		"label": "Деятельность по копированию, подготовке документов и прочая специализированная офисная вспомогательная деятельность",
		"value": 1861
	},
	{
		"label": "Деятельность по копированию, подготовке документов и прочая специализированная офисная вспомогательная деятельность",
		"value": 1862
	},
	{
		"label": "Деятельность телефонных справочно-информационных служб",
		"value": 1864
	},
	{
		"label": "Деятельность телефонных справочно-информационных служб",
		"value": 1865
	},
	{
		"label": "Организация конференций и торговых выставок",
		"value": 1867
	},
	{
		"label": "Организация конференций и торговых выставок",
		"value": 1868
	},
	{
		"label": "Деятельность агентств по сбору платежей и кредитных бюро",
		"value": 1870
	},
	{
		"label": "Деятельность государственного кредитного бюро",
		"value": 1871
	},
	{
		"label": "Прочая деятельность по предоставлению вспомогательных коммерческих услуг, не включенная в другие группировки",
		"value": 1875
	},
	{
		"label": "Прочая деятельность по предоставлению вспомогательных коммерческих услуг, не включенная в другие группировки",
		"value": 1876
	},
	{
		"label": "Государственное управление и оборона; обязательное  социальное обеспечение",
		"value": 1877
	},
	{
		"label": "Государственное управление и оборона; обязательное социальное обеспечение",
		"value": 1878
	},
	{
		"label": "Государственное управление общего характера и управление социально-экономической политикой общества",
		"value": 1879
	},
	{
		"label": "Деятельность республиканских органов управления",
		"value": 1881
	},
	{
		"label": "Деятельность региональных органов управления",
		"value": 1882
	},
	{
		"label": "Деятельность местных органов управления",
		"value": 1883
	},
	{
		"label": "Деятельность сельских и поселковых органов управления",
		"value": 1884
	},
	{
		"label": "Деятельность, связанная с налогообложением",
		"value": 1885
	},
	{
		"label": "Деятельность в области статистики и социологии",
		"value": 1887
	},
	{
		"label": "Регулирование деятельности учреждений здравоохранения, образования, культуры и других социальных услуг, кроме социального обеспечения",
		"value": 1888
	},
	{
		"label": "Регулирование деятельности учреждений здравоохранения, образования, культуры и других социальных услуг, кроме социального обеспечения",
		"value": 1889
	},
	{
		"label": "Регулирование и содействие эффективному ведению экономической деятельности",
		"value": 1890
	},
	{
		"label": "Регулирование и содействие эффективному ведению экономической деятельности",
		"value": 1891
	},
	{
		"label": "Международная деятельность",
		"value": 1893
	},
	{
		"label": "Международная деятельность",
		"value": 1894
	},
	{
		"label": "Оборонная деятельность",
		"value": 1895
	},
	{
		"label": "Оборонная деятельность",
		"value": 1896
	},
	{
		"label": "Деятельность в области юстиции и правосудия",
		"value": 1897
	},
	{
		"label": "Деятельность в области юстиции и правосудия",
		"value": 1898
	},
	{
		"label": "Деятельность по обеспечению общественного порядка и безопасности",
		"value": 1899
	},
	{
		"label": "Деятельность по обеспечению общественного порядка и безопасности",
		"value": 1900
	},
	{
		"label": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях",
		"value": 1901
	},
	{
		"label": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях",
		"value": 1902
	},
	{
		"label": "Деятельность в области обязательного социального страхования",
		"value": 1904
	},
	{
		"label": "Деятельность в области обязательного социального страхования",
		"value": 1905
	},
	{
		"label": "Образование",
		"value": 1906
	},
	{
		"label": "Образование",
		"value": 1907
	},
	{
		"label": "Дошкольное образование",
		"value": 1908
	},
	{
		"label": "Дошкольное образование",
		"value": 1909
	},
	{
		"label": "Дошкольное образование",
		"value": 1910
	},
	{
		"label": "Начальное образование (1-й уровень)",
		"value": 1912
	},
	{
		"label": "Начальное образование (1-й уровень)",
		"value": 1913
	},
	{
		"label": "Высшее образование",
		"value": 1924
	},
	{
		"label": "Послевузовское образование",
		"value": 1925
	},
	{
		"label": "Образование в области спорта и отдыха",
		"value": 1927
	},
	{
		"label": "Образование в области спорта и отдыха",
		"value": 1928
	},
	{
		"label": "Образование в области культуры",
		"value": 1929
	},
	{
		"label": "Образование в области культуры",
		"value": 1930
	},
	{
		"label": "Деятельность школ подготовки водителей транспортных средств",
		"value": 1931
	},
	{
		"label": "Деятельность школ подготовки водителей транспортных средств",
		"value": 1932
	},
	{
		"label": "Прочие виды образования, не включенные в другие группировки",
		"value": 1933
	},
	{
		"label": "Деятельность вечерних общеобразовательных школ",
		"value": 1934
	},
	{
		"label": "Виды образования, предоставляемые национальными компаниями и их дочерними организациями",
		"value": 1935
	},
	{
		"label": "Прочая деятельность в области образования, не включенная в другие группировки",
		"value": 1936
	},
	{
		"label": "Вспомогательная деятельность в области образования",
		"value": 1938
	},
	{
		"label": "Вспомогательная деятельность в области образования, предоставляемая национальными компаниями и их дочерними организациями",
		"value": 1939
	},
	{
		"label": "Прочая вспомогательная деятельность в области образования",
		"value": 1940
	},
	{
		"label": "Здравоохранение и социальное обслуживание населения",
		"value": 1941
	},
	{
		"label": "Деятельность в области здравоохранения",
		"value": 1942
	},
	{
		"label": "Деятельность больничных организаций",
		"value": 1943
	},
	{
		"label": "Деятельность больничных организаций",
		"value": 1944
	},
	{
		"label": "Деятельность больниц широкого профиля и специализированных больниц",
		"value": 1945
	},
	{
		"label": "Деятельность родильных домов",
		"value": 1946
	},
	{
		"label": "Деятельность санаторно-курортных организаций",
		"value": 1947
	},
	{
		"label": "Деятельность лепрозориев",
		"value": 1948
	},
	{
		"label": "Деятельность других лечебных учреждений, имеющих стационары",
		"value": 1949
	},
	{
		"label": "Общая врачебная практика",
		"value": 1951
	},
	{
		"label": "Общая врачебная практика",
		"value": 1952
	},
	{
		"label": "Специальная врачебная практика",
		"value": 1953
	},
	{
		"label": "Специальная врачебная практика",
		"value": 1954
	},
	{
		"label": "Стоматологическая деятельность",
		"value": 1955
	},
	{
		"label": "Стоматологическая деятельность",
		"value": 1956
	},
	{
		"label": "Прочая деятельность в области здравоохранения",
		"value": 1958
	},
	{
		"label": "Прочая деятельность в области здравоохранения",
		"value": 1959
	},
	{
		"label": "Библиотечная деятельность, включая деятельность читальных залов, лекториев, демонстрационных залов",
		"value": 1999
	},
	{
		"label": "Деятельность архивов",
		"value": 2000
	},
	{
		"label": "Деятельность книжных палат",
		"value": 2001
	},
	{
		"label": "Деятельность музеев",
		"value": 2002
	},
	{
		"label": "Деятельность музеев",
		"value": 2003
	},
	{
		"label": "Деятельность исторических мест и зданий и аналогичных туристических достопримечательностей",
		"value": 2004
	},
	{
		"label": "Деятельность исторических мест и зданий и аналогичных туристических достопримечательностей",
		"value": 2005
	},
	{
		"label": "Деятельность ботанических садов, зоопарков и природных заповедников",
		"value": 2006
	},
	{
		"label": "Деятельность ботанических садов и зоопарков",
		"value": 2007
	},
	{
		"label": "Деятельность природных заповедников, охрана дикой природы",
		"value": 2008
	},
	{
		"label": "Деятельность по организации азартных игр и заключению пари",
		"value": 2009
	},
	{
		"label": "Деятельность по организации азартных игр и заключению пари",
		"value": 2010
	},
	{
		"label": "Деятельность по организации азартных игр и заключению пари",
		"value": 2011
	},
	{
		"label": "Деятельность по организации азартных игр и заключению пари",
		"value": 2012
	},
	{
		"label": "Деятельность в области спорта, организации отдыха и развлечений",
		"value": 2013
	},
	{
		"label": "Деятельность в области спорта",
		"value": 2014
	},
	{
		"label": "Эксплуатация спортивных сооружений",
		"value": 2015
	},
	{
		"label": "Эксплуатация спортивных сооружений",
		"value": 2016
	},
	{
		"label": "Деятельность спортивных клубов",
		"value": 2017
	},
	{
		"label": "Деятельность спортивных клубов",
		"value": 2018
	},
	{
		"label": "Деятельность фитнес-клубов",
		"value": 2019
	},
	{
		"label": "Деятельность фитнес-клубов",
		"value": 2020
	},
	{
		"label": "Прочая деятельность в области спорта",
		"value": 2021
	},
	{
		"label": "Прочая деятельность в области спорта",
		"value": 2022
	},
	{
		"label": "Деятельность развлекательных и тематических парков",
		"value": 2024
	},
	{
		"label": "Деятельность развлекательных и тематических парков",
		"value": 2025
	},
	{
		"label": "Деятельность танцевальных залов, дискотек",
		"value": 2027
	},
	{
		"label": "Деятельность родео, тиров",
		"value": 2028
	},
	{
		"label": "Деятельность кукольных театров",
		"value": 2029
	},
	{
		"label": "Прочие виды деятельности по организации отдыха и развлечений",
		"value": 2030
	},
	{
		"label": "Предоставление прочих видов услуг",
		"value": 2031
	},
	{
		"label": "Деятельность общественных объединений (организаций)",
		"value": 2032
	},
	{
		"label": "Отделка тканей и текстильных изделий",
		"value": 304
	},
	{
		"label": "Деятельность коммерческих, предпринимательских и профессиональных общественных организаций",
		"value": 2033
	},
	{
		"label": "Деятельность коммерческих и предпринимательских общественных организаций",
		"value": 2034
	},
	{
		"label": "Деятельность коммерческих и предпринимательских общественных организаций",
		"value": 2035
	},
	{
		"label": "Деятельность профессиональных общественных организаций",
		"value": 2036
	},
	{
		"label": "Деятельность профессиональных общественных организаций",
		"value": 2037
	},
	{
		"label": "Деятельность профессиональных союзов",
		"value": 2039
	},
	{
		"label": "Деятельность профессиональных союзов",
		"value": 2040
	},
	{
		"label": "Деятельность политических организаций",
		"value": 2045
	},
	{
		"label": "Деятельность прочих общественных организаций, не включенных в другие группировки",
		"value": 2046
	},
	{
		"label": "Деятельность прочих общественных организаций, не включенных в другие группировки",
		"value": 2047
	},
	{
		"label": "Ремонт компьютеров, предметов личного потребления и бытовых товаров",
		"value": 2048
	},
	{
		"label": "Ремонт компьютеров и коммуникационного оборудования",
		"value": 2049
	},
	{
		"label": "Ремонт компьютеров и периферийного оборудования",
		"value": 2050
	},
	{
		"label": "Ремонт компьютеров и периферийного оборудования",
		"value": 2051
	},
	{
		"label": "Ремонт коммуникационного оборудования",
		"value": 2052
	},
	{
		"label": "Ремонт коммуникационного оборудования",
		"value": 2053
	},
	{
		"label": "Ремонт  электронной бытовой техники",
		"value": 2055
	},
	{
		"label": "Ремонт  электронной бытовой техники",
		"value": 2056
	},
	{
		"label": "Ремонт бытовых приборов, домашнего и садового оборудования",
		"value": 2057
	},
	{
		"label": "Ремонт бытовых приборов, домашнего и садового оборудования",
		"value": 2058
	},
	{
		"label": "Ремонт обуви и изделий из кожи",
		"value": 2059
	},
	{
		"label": "Ремонт дорожных и галантерейных изделий из натуральной и искусственной кожи",
		"value": 2061
	},
	{
		"label": "Ремонт мебели и предметов интерьера",
		"value": 2062
	},
	{
		"label": "Ремонт мебели и предметов интерьера",
		"value": 2063
	},
	{
		"label": "Ремонт часов и ювелирных изделий",
		"value": 2064
	},
	{
		"label": "Ремонт наручных и прочих часов",
		"value": 2065
	},
	{
		"label": "Ремонт ювелирных изделий",
		"value": 2066
	},
	{
		"label": "Ремонт прочих предметов личного потребления и бытовых товаров",
		"value": 2067
	},
	{
		"label": "Ремонт трикотажных и вязаных изделий",
		"value": 2068
	},
	{
		"label": "Ремонт меховых и кожаных изделий и головных уборов",
		"value": 2070
	},
	{
		"label": "Ремонт музыкальных инструментов",
		"value": 2071
	},
	{
		"label": "Ремонт ковров и ковровых изделий",
		"value": 2072
	},
	{
		"label": "Ремонт велосипедов",
		"value": 2073
	},
	{
		"label": "Ремонт прочих предметов личного потребления и бытовых товаров, не включенных в другие группировки",
		"value": 2074
	},
	{
		"label": "Предоставление прочих индивидуальных услуг",
		"value": 2075
	},
	{
		"label": "Предоставление прочих индивидуальных услуг",
		"value": 2076
	},
	{
		"label": "Стирка и (химическая) чистка текстильных и меховых изделий ",
		"value": 2077
	},
	{
		"label": "Стирка и обработка белья",
		"value": 2078
	},
	{
		"label": "Предоставление услуг парикмахерскими и салонами красоты",
		"value": 2080
	},
	{
		"label": "Организация похорон и связанная с этим деятельность",
		"value": 2082
	},
	{
		"label": "Организация похорон и связанная с этим деятельность",
		"value": 2083
	},
	{
		"label": "Деятельность по обеспечению физического комфорта",
		"value": 2084
	},
	{
		"label": "Деятельность по обеспечению физического комфорта",
		"value": 2085
	},
	{
		"label": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу; деятельность домашних хозяйств по производству товаров и услуг для собственного потребления",
		"value": 2088
	},
	{
		"label": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
		"value": 2090
	},
	{
		"label": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
		"value": 2091
	},
	{
		"label": "Деятельность домашних хозяйств, нанимающих домашнюю прислугу",
		"value": 2092
	},
	{
		"label": "Деятельность домашних хозяйств по производству товаров и услуг для собственного потребления",
		"value": 2093
	},
	{
		"label": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
		"value": 2094
	},
	{
		"label": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
		"value": 2095
	},
	{
		"label": "Деятельность домашних хозяйств по производству товаров для собственного потребления",
		"value": 2096
	},
	{
		"label": "Деятельность домашних хозяйств по предоставлению услуг для собственного потребления",
		"value": 2098
	},
	{
		"label": "Деятельность домашних хозяйств по предоставлению услуг для собственного потребления",
		"value": 2099
	},
	{
		"label": "Деятельность экстерриториальных организаций и органов",
		"value": 2100
	},
	{
		"label": "Деятельность экстерриториальных организаций и органов",
		"value": 2101
	},
	{
		"label": "Деятельность экстерриториальных организаций и органов",
		"value": 2102
	},
	{
		"label": "Деятельность экстерриториальных организаций и органов",
		"value": 2103
	},
	{
		"label": "Деятельность экстерриториальных организаций и органов",
		"value": 2104
	},
	{
		"label": "Деятельность по зарядке аккумуляторных батарей транспортных средств с электродвигателями",
		"value": 2105
	},
	{
		"label": "Деятельность в области технического регулирования, стандартизации, метрологии и оценки соответствия",
		"value": 2108
	},
	{
		"label": "Деятельность по осуществлению цифрового майнинга",
		"value": 2109
	},
	{
		"label": "Комплексное обслуживание жилых объектов",
		"value": 2110
	},
	{
		"label": "Комплексное обслуживание коммерческих объектов",
		"value": 2111
	},
	{
		"label": "Общая уборка жилых домов",
		"value": 2112
	},
	{
		"label": "Общая уборка коммерческих  зданий",
		"value": 2113
	},
	{
		"label": "Производство асфальтобетона",
		"value": 2114
	},
	{
		"label": "Производство гаражного и автозаправочного оборудования",
		"value": 2115
	},
	{
		"label": "Производство прочих частей и принадлежностей автотранспортных средств и их двигателей",
		"value": 2116
	},
	{
		"label": "Производство тепловой энергии возобновляемыми и альтернативными источниками энергии",
		"value": 2117
	},
	{
		"label": "Ремонт металлических бочек и аналогичных емкостей",
		"value": 2118
	},
	{
		"label": "Эксплуатация сетей и сооружений систем водоотведения (канализации)",
		"value": 2119
	},
	{
		"label": "Производство  и обработка вязаного и трикотажного полотна",
		"value": 307
	},
	{
		"label": "Производство прочих текстильных изделий технического и производственного назначения",
		"value": 319
	},
	{
		"label": "Производство одежды из кожи",
		"value": 327
	},
	{
		"label": "Техническое обслуживание и ремонт автомобилей, за исключением произведенных станциями технического обслуживания, находящимися на придорожной полосе",
		"value": 1089
	},
	{
		"label": "Техническое обслуживание и ремонт автомобилей станциями технического обслуживания, находящимися на придорожной полосе",
		"value": 1090
	},
	{
		"label": "Деятельность агентов по оптовой торговле текстильными изделиями, одеждой, обувью, изделиями из кожи и меха",
		"value": 1115
	},
	{
		"label": "Оптовая торговля широким ассортиментом товаров без какой-либо конкретизации",
		"value": 1247
	},
	{
		"label": "Розничная торговля преимущественно продуктами питания, напитками и табачными изделиями в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1251
	},
	{
		"label": "Прочая розничная торговля в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1254
	},
	{
		"label": "Прочая розничная торговля в неспециализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1255
	},
	{
		"label": "Розничная торговля фруктами и овощами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1258
	},
	{
		"label": "Прочая розничная торговля мясом и мясными продуктами в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1264
	},
	{
		"label": "Прочая розничная торговля  продуктами питания в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1278
	},
	{
		"label": "Прочая розничная торговля продуктами питания в специализированных магазинах, являющихся торговыми объектами, с торговой площадью более 2000 кв.м (2000 кв.м и выше)",
		"value": 1279
	},
	{
		"label": "Розничная торговля скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м ",
		"value": 1300
	},
	{
		"label": "Розничная торговля осветительными приборами и бытовыми товарами, не включенными в другие группировки, в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1314
	},
	{
		"label": "Розничная торговля играми и игрушками в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1329
	},
	{
		"label": "Розничная торговля фотографическим, оптическим и точным оборудованием в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1362
	},
	{
		"label": "Прочая розничная торговля в специализированных магазинах, являющихся торговыми объектами, с торговой площадью менее 2000 кв.м",
		"value": 1367
	},
	{
		"label": "Розничная торговля продуктами питания, напитками и табачными изделиями в торговых палатках, ларьках и киосках",
		"value": 1373
	},
	{
		"label": "Розничная торговля прочими товарами на рынках",
		"value": 1380
	},
	{
		"label": "Розничная торговля путем заказа товаров по почте или через сеть Интернет",
		"value": 1383
	},
	{
		"label": "Розничная торговля через сетевой маркетинг",
		"value": 1386
	},
	{
		"label": "Прочая розничная торговля вне магазинов",
		"value": 1387
	},
	{
		"label": "Предоставление услуг гостиницами с ресторанами, за исключением гостиниц, находящихся на придорожной полосе",
		"value": 1480
	},
	{
		"label": "Предоставление услуг гостиницами без ресторанов, за исключением гостиниц, находящихся на придорожной полосе",
		"value": 1481
	},
	{
		"label": "Предоставление жилья на выходные дни и прочие периоды краткосрочного проживания",
		"value": 1486
	},
	{
		"label": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания, за исключением деятельности объектов, находящихся на придорожной полосе",
		"value": 1499
	},
	{
		"label": "Деятельность ресторанов и предоставление услуг по доставке продуктов питания объектами, находящимися на придорожной полосе",
		"value": 1500
	},
	{
		"label": "Доставка готовой пищи на заказ",
		"value": 1503
	},
	{
		"label": "Прочая деятельность по обеспечению питанием, не включенная в другие группировки",
		"value": 1507
	},
	{
		"label": "Ремонт швейных изделий, головных уборов и изделий текстильной галантереи",
		"value": 2069
	},
	{
		"label": "Подготовка хлопчатобумажного волокна, хлопка-волокна",
		"value": 286
	},
	{
		"label": "Подготовка шерстяного волокна",
		"value": 288
	},
	{
		"label": "Подготовка льняного и прочих растительных волокон",
		"value": 290
	},
	{
		"label": "Производство прочих тканей",
		"value": 301
	},
	{
		"label": "Производство готовых текстильных изделий, кроме одежды",
		"value": 310
	},
	{
		"label": "Производство текстильной галантереи",
		"value": 321
	},
	{
		"label": "Предоставление гостиничных услуг с ресторанами для официальных мероприятий",
		"value": 1482
	},
	{
		"label": "Предоставление услуг парикмахерскими и салонами красоты",
		"value": 2081
	},
	{
		"label": "Консультационные услуги в области информационных технологий",
		"value": 2124
	},
	{
		"label": "Прочая деятельность в области здравоохранения, не включенная в другие группировки",
		"value": 2127
	},
	{
		"label": "Отделочные работы",
		"value": 1057
	},
	{
		"label": "Деятельность в области социального обслуживания населения с обеспечением проживания",
		"value": 1960
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания и ухода за пациентами средним медицинским персоналом",
		"value": 1961
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания и ухода за пациентами средним медицинским персоналом",
		"value": 1962
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания и ухода за пациентами средним медицинским персоналом",
		"value": 1963
	},
	{
		"label": "Предоставление специальных социальных услуг с обеспечением проживания пожилым гражданам и лицам с инвалидностью",
		"value": 1967
	},
	{
		"label": "Деятельность по организации отдыха и развлечений",
		"value": 2023
	}
]
