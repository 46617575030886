import locale from 'antd/locale/ru_RU';
import {
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEvent, useStore } from 'effector-react';
import { AreaChartOutlined, RollbackOutlined } from '@ant-design/icons';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';
import MapWork from '../MapWork/MapWork.jsx';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $activeFilters,
  resetActiveFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { selectDotEv } from '../../models/dotsLayerModel/index.js';
import BarChart from '../../components/BarChart/BarChart.jsx';
import SearchSelectOpenable from '../../components/SearchSelect/SearchSelectOpenable.jsx';
import { $binSelectOptions } from '../../models/globalModel/index.js';
import { $projectsMapStore } from '../../models/projectsMapModel/index.js';

function ProjectsMap() {
  const {
    projects_table_data,
    activity_select,
    organizations_select,
    region_widget_data,
    district_widget_data,
  } = useStore($projectsMapStore);
  // const { projects_table_data, activity_select, organizations_select } =
  //   useStore($projRegistryStore);
  // const { region_widget_data, district_widget_data } =
  //   useStore($investmentsStore);
  const bin_select = useStore($binSelectOptions);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (record) => {
    setSelectedRow(record.key);
  };

  const selectDot = useEvent(selectDotEv);

  const dropdownItems = useCallback((proj_id, subj_id) => {
    return [
      {
        key: 'subject',
        label: (
          <Link to={`/view-subject/${subj_id}`} target="_blank">
            Просмотр предприятия
          </Link>
        ),
      },
      {
        key: 'project',
        label: (
          <Link to={`/view-project/${proj_id}`} target="_blank">
            Просмотр проекта
          </Link>
        ),
      },
    ];
  }, []);

  const DropDown = useCallback(({ field, record }) => {
    return (
      <Dropdown
        menu={{ items: dropdownItems(record.proj_id, record.subj_id) }}
        placement="topLeft"
        arrow
        // trigger={['click']}
        trigger={['']} // dont trigger dropdown
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => selectDot(record.proj_id)}
        >
          {field}
        </div>
        {/* <Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip> */}
      </Dropdown>
    );
  }, []);

  const columns = [
    {
      title: '№',
      key: 'order',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      key: 'name',
      width: '40%',
      title: 'Наименование проекта',
      dataIndex: 'projectName',
      render: (name, record) => <DropDown field={name} record={record} />,
    },
    {
      key: 'subject',
      width: '20%',
      title: 'Предприятие',
      dataIndex: 'subject',
      render: (manufact, record) => (
        <DropDown field={manufact} record={record} />
      ),
    },
    {
      title: 'Регион',
      key: 'region',
      dataIndex: 'region_id',
      render: (region) => regions_dictionary[region],
    },
    // {
    //   key: 'completion',
    //   width: '10%',
    //   title: '% завершения',
    //   dataIndex: 'completion',
    // },
    {
      key: 'investments',
      width: '20%',
      title: 'Сумма инвестиций (млн. тенге)',
      dataIndex: 'investments_total',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.investments_total - b.investments_total,
      render: (record) =>
        record ? new Intl.NumberFormat('ru-RU').format(record) : 0,
    },
    {
      key: 'delay',
      width: '20%',
      title: 'Просрочка',
      dataIndex: 'delay',
      sorter: (a, b) => {
        if (a.delay > b.delay) {
          return 1;
        }
        if (a.delay < b.delay) {
          return -1;
        }
        return 0;
      },
      render: (record) => (record == null ? 0 : record),
    },
    {
      key: 'actions',
      width: '15%',
      title: 'Действия',
      align: 'center',
      render: (record) => (
        <div
          id="buttons_container"
          style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
        >
          {/* <Tooltip title="Просмотр предприятия"> */}
          {/*  <Link to={`/view-subject/${record.subj_id}`} target="_blank"> */}
          {/*    <Button className="info-btn" icon={<ProjectOutlined />} /> */}
          {/*  </Link> */}
          {/* </Tooltip> */}
          <Tooltip title="Просмотр проекта">
            <Link to={`/view-project/${record.proj_id}`} target="_blank">
              <Button className="info-btn" icon={<AreaChartOutlined />} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const activeFilters = useStore($activeFilters);
  const resetActiveFilters = useEvent(resetActiveFiltersEv);

  useEffect(() => {
    if (hasActiveFilters(activeFilters)) {
      setTimeout(() => {
        resetActiveFilters();
      }, 500);
    }
  }, []);

  const clearFilters = () => {
    resetActiveFilters();
  };

  return (
    <ContainerInner showTitle={false}>
      {/* <SearchSelectOpenable */}
      {/*  select_items={{ */}
      {/*    activity_select, */}
      {/*    organizations_select, */}
      {/*    bin_select, */}
      {/*  }} */}
      {/* /> */}
      {/* <Tooltip title="Очистить фильтры" placement="right"> */}
      {/*  <Button */}
      {/*    icon={<RollbackOutlined />} */}
      {/*    className="clear_filters_button" */}
      {/*    onClick={clearFilters} */}
      {/*  /> */}
      {/* </Tooltip> */}
      <Row justify="space-between" id="projectsMapContainer">
        <Col
          style={{
            width: '62%',
            height: '57vh',
            boxShadow: '5px 5px 10px rgba(0,0,0,.4)',
          }}
          className="db-col-map"
        >
          <MapWork />
        </Col>
        <Col
          style={{ width: '35%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)' }}
          className="db-col1-top"
        >
          <BarChart
            title="Сумма инвестиций по регионам (млн. тг.)"
            data={region_widget_data}
            width="100%"
            xAlias="Сумма"
            yAlias="Область"
            filter="region_id"
            height="20vh"
            headStyle={{ minHeight: 'unset' }}
          />
          <BarChart
            title="Сумма инвестиций по районам (млн. тг.)"
            data={district_widget_data}
            width="100%"
            xAlias="Сумма"
            yAlias="Область"
            filter="district_id"
            height="20vh"
            headStyle={{ minHeight: 'unset' }}
          />
        </Col>
      </Row>

      <Row
        style={{ height: '62vh', maxHeight: '62vh' }}
        className="db-row-bottom"
      >
        <ConfigProvider locale={locale}>
          <Table
            columns={columns}
            dataSource={projects_table_data}
            size="small"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === selectedRow ? 'selectedRow' : '',
            })}
            pagination={false}
            style={{ width: '100%' }}
          />
        </ConfigProvider>
      </Row>
    </ContainerInner>
  );
}

export default ProjectsMap;
