import ContainerInner from "../../components/ContentContainer/ContainerInner.jsx";
import ProjectCreateEditForm from "../../components/ProjectCreateEditForm/ProjectCreateEditForm.jsx";

const CreateProjectPublic = () => {
    return <ContainerInner title={'Создание проекта'}>
        <ProjectCreateEditForm />
    </ContainerInner>
}

export default CreateProjectPublic
