import axios from 'axios';
import notification from 'antd/es/notification';
import { Cookies } from 'react-cookie';

// const apiRoot = 'http://192.168.199.3/api';
// const apiRoot = 'https://invest.eoz.kz/api';
const apiRoot = __API_URL__;
export const cookies = new Cookies();

export const api = (auth = true) => {
  const axiosInstance = axios.create({
    baseURL: apiRoot,
  });

  if (auth) {
    const access_token = cookies.get('access_token');
    axiosInstance.defaults.headers = {
      Authorization: `Bearer ${access_token}`,
      'Access-Control-Expose-Headers': 'Content-Disposition',
    };
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.request.status === 0) {
        notification.error({
          message: 'Ошибка',
          description: 'Ошибка сети',
          placement: 'topRight',
        });
      } else if (error.response.status === 401) {
        cookies.remove('access_token', { path: '/' });
        if (!window.location.pathname.includes('public')) {
          if (!window.location.pathname.includes('foreign-investments')) {
            window.location.href = '/login';
          }
        }
      }
      if (!window.location.pathname.includes('public')) {
        notification.error({
          message: error.response.statusText,
          description: error.response.data.message,
          placement: 'topRight',
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
