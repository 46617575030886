import { createEffect } from 'effector';
import { api } from '../../api/axiosInstance.js';

const isYear = !!window.location.href.includes('polls-year');

export const getBizPollOptsFx = createEffect().use(
  async (type) =>
    (
      await api().get(
        `/business-climate-form${isYear ? '-year' : ''}/?business_type=${type}`
      )
    ).data
);

export const getBinInfoFx = createEffect().use(
  async ({ bin, type }) =>
    (
      await api().get(
        `/business-climate-form${
          isYear ? '-year' : ''
        }/gov/${bin}?business_type=${type}`
      )
    ).data
);

export const submitFormFx = createEffect().use(
  async (payload) =>
    (
      await api().post(
        `/business-climate-form${isYear ? '-year' : ''}/`,
        payload
      )
    ).data
);

export const getResultsListFx = createEffect().use(
  async (payload) =>
    (
      await api().get(
        `/business-climate-form${payload.isYear ? '-year' : ''}/list?${
          payload.query
        }`
      )
    ).data
);

export const getResultsListPlanFx = createEffect().use(
  async (payload) =>
    (await api().get(`/business-climate-form-year/list-plan?${payload}`)).data
);

export const downloadResultsFx = createEffect().use(async (payload) => {
  return (
    await api().get(
      `/business-climate-form${payload.isYear ? '-year' : ''}/export?${
        payload.query
      }`
    )
  ).data;
});

export const downloadYearPlanFx = createEffect().use(
  async (payload) =>
    (await api().get(`/business-climate-form-year/export-plan`)).data
);
