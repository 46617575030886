import { combine, createStore } from 'effector';
import { $globalStore } from '../globalModel/index.js';
import { $userInfo } from '../authModel/index.js';

export const $okedsData = createStore([]);

export const $okedsModalState = createStore({
  open: false,
  type: null,
  id: null,
});

export const $selectedOked = createStore(null);

export const $okedsSelect = combine($globalStore, ({ activities }) => {
  return activities?.map((i) => ({
    label: `${i?.name_ru} (${i?.code})`,
    value: i.id,
  }));
});

export const $regionsSelectByUser = combine(
  $globalStore,
  $userInfo,
  ({ administrative_territory_units: data }, { region_id }) => {
    if (region_id === 2 || region_id === null) {
      return data
        ?.filter(
          (i) =>
            i.type_name_ru === 'Область' ||
            i.type_name_ru === 'Город республиканского значения'
        )
        ?.map((i) => ({ label: i.name_ru, value: i.id }));
    }
    return data
      ?.filter(
        (i) =>
          (i.type_name_ru === 'Область' ||
            i.type_name_ru === 'Город республиканского значения') &&
          i.id === region_id
      )
      ?.map((i) => ({ label: i.name_ru, value: i.id }));
  }
);

export const $republicanCities = combine(
  $globalStore,
  ({ administrative_territory_units: data }) => {
    return data
      ?.filter((i) => i.type_name_ru === 'Город республиканского значения')
      ?.map((i) => i.id);
  }
);

export const $districtsSelectByUser = combine(
  $globalStore,
  $userInfo,
  ({ administrative_territory_units: data }, { region_id }) => {
    if (region_id === 2 || region_id === null) {
      return data
        ?.filter(
          (i) => i.type_name_ru === 'Район' || i.type_name_ru === 'Город'
        )
        ?.map((i) => ({
          label: i.name_ru,
          value: i.id,
          region_id: i.region_id,
        }));
    }
    return data
      ?.filter(
        (i) =>
          (i.type_name_ru === 'Район' || i.type_name_ru === 'Город') &&
          i.region_id === region_id
      )
      ?.map((i) => ({
        label: i.name_ru,
        value: i.id,
        region_id: i.region_id,
      }));
  }
);

export const $okdesPriorityFilters = createStore({
  region_id: null,
  district_id: null,
});
