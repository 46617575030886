import { createEffect } from 'effector';
import { api } from '../../api/axiosInstance.js';

export const getMGPsListFx = createEffect().use(
  async () => (await api().post('/mgp/list', {})).data
);

export const getMGPPageListFx = createEffect().use(async (filters) => {
  const payload = { ...filters };
  if (window.location.pathname.includes('public')) {
    payload.region_ids = [24];
  }
  return (await api().post('/mgp/list', payload)).data;
});

export const getMGPByIdFx = createEffect().use(
  async (id) => (await api().get(`/mgp/${id}`)).data
);

export const createMGPFx = createEffect().use(
  async (payload) => (await api().post('/mgp/', payload)).data
);

export const editMGPFx = createEffect().use(
  async ({ id, payload }) => (await api().put(`/mgp/${id}`, payload)).data
);

export const deleteMGPFx = createEffect().use(
  async (id) => (await api().delete(`/mgp/${id}`)).data
);

export const getNMPDataFx = createEffect().use(async (payload) => {
  return (
    await api().get(
      `/mgp/recipient${Object.keys(payload).length > 0 ? `?${payload}` : ''}`
    )
  ).data;
  // return new Promise((resolve, reject) =>
  //   setTimeout(() => {
  //     console.log('payload', payload);
  //     return resolve(mockNMPData);
  //   }, 1000)
  // );
});
