import { combine, createStore } from 'effector';
import {
  districts_dictionary,
  regions_districts_id_dictionary,
} from '../../dictionaries/regions_districts_dictionary.js';
import { prepareTreeSelectData } from '../../counters/utils/activityTreeSelectPreparator.js';

const unnecessary_names = [
  'г.Аягоз',
  'г.Шар',
  'Риддер',
  'г.Акколь',
  'г.Атбасар',
  'г.Макинск',
  'г.Степняк',
  'г.Ерейментау',
  'г.Есиль',
  'г.Державинск',
  'г.Щучинск',
  'Актобе',
  'г.Алга',
  'г.Кандыагаш',
  'г.Эмба',
  'г.Жем',
  'г.Темир',
  'г.Хромтау',
  'г.Шалкар',
  'г.Есик',
  'г.Каскелен',
  'г.Талгар',
  'г.Кульсары',
  'г.Зайсан',
  'г.Алтай',
  'г.Серебрянск',
  'г.Шемонаиха',
  'Семипалатинский район',
  'г.Жанатас',
  'г.Каратау',
  'г.Шу',
  'г.Ушарал',
  'г.Уштобе',
  'г.Жаркент',
  'г.Сарканд',
  'г.Аксай',
  'г.Караганда',
  'г.Абай',
  'г.Каркаралинск',
  'Костанайский район',
  'г.Житикара',
  'г.Тобыл',
  'Аркалыкский район',
  'г.Аральск',
  'г.Казалинск',
  'Екибастузский район',
  'Аксуский район',
  'г.Булаево',
  'г.Мамлютка',
  'г.Сергеевка',
  'г.Тайынша',
  'г.Сарыагаш',
  'г.Ленгер',
  'г.Шардара',
];

export const $globalStore = createStore({});

export const $mapLoaded = createStore(false);
$mapLoaded.watch((state) => console.log('$mapLoaded', state));

export const $binDictionary = createStore({});

export const $binSelectOptions = createStore([]);

export const $projectsStore = createStore([]);

export const $regionsSelect = combine(
  $globalStore,
  ({ administrative_territory_units: data }) => {
    return data
      ?.filter(
        (i) =>
          i.type_name_ru === 'Область' ||
          i.type_name_ru === 'Город республиканского значения'
      )
      ?.map((i) => ({ label: i.name_ru, value: i.id }));
  }
);

export const $districtsSelect = combine(
  $globalStore,
  ({ administrative_territory_units: data }) => {
    const unnecessary_ids = [452];

    return data
      ?.filter(
        (i) =>
          (i.type_name_ru === 'Район' || i.type_name_ru === 'Город') &&
          !unnecessary_ids.includes(i.id) &&
          !unnecessary_names.includes(i.name_ru)
      )
      ?.map((i) => {
        // let region_id;
        // Object.keys(regions_districts_id_dictionary).forEach((key) => {
        //   if (regions_districts_id_dictionary[key][i.id]) {
        //     region_id = +key;
        //   }
        // });

        return {
          label: i.name_ru,
          value: i.id,
          region_id: i.region_id,
        };
      });
  }
);

export const $regionsMap = combine(
  $globalStore,
  ({ administrative_territory_units: data }) => {
    return Object.fromEntries(
      data
        ?.filter(
          (i) =>
            i.type_name_ru === 'Область' ||
            i.type_name_ru === 'Город республиканского значения'
        )
        ?.map((i) => [i.id, i.name_ru]) ?? []
    );
  }
);

export const $districtsMap = combine(
  $globalStore,
  ({ administrative_territory_units: data }) => {
    return Object.fromEntries(
      data
        ?.filter(
          (i) => i.type_name_ru === 'Район' || i.type_name_ru === 'Город'
        )
        ?.map((i) => [i.id, i.name_ru /* districts_dictionary[i.id] */]) ?? []
    );
  }
);

export const $activitiesMap = combine($globalStore, (info) =>
  Object.fromEntries(info?.activities?.map((i) => [i.id, i.name_ru]) ?? [])
);

export const $activitiesTreeSelect = combine($globalStore, ({ activities }) =>
  activities && activities.length > 0 ? prepareTreeSelectData(activities) : []
);
