import { sample } from 'effector';
import notification from 'antd/es/notification';
import queryString from 'query-string';
import { condition } from 'patronum';
import {
  $binInfo,
  $govCantVoteModalOpen,
  $pollOptions,
  $pollsResults,
  $submitStatus,
  $wrongTypeModalOpen,
} from './stores.js';
import {
  downloadResultsFx,
  downloadYearPlanFx,
  getBinInfoFx,
  getBizPollOptsFx,
  getResultsListFx,
  getResultsListPlanFx,
  submitFormFx,
} from './effects.js';
import {
  BizPollGate,
  BizPollResultsGate,
  downloadResultsEv,
  downloadYearPlanEv,
  getBinInfoEv,
  getPlanResultsListEv,
  getResultsListEv,
  resetGovCantVoteModalEv,
  resetWrongTypeModalEv,
  submitFormEv,
} from './events.js';
import i18 from '../../i18n';
import {
  checkValue,
  formQuery,
  preparePlanResults,
  prepareResults,
} from './utils.js';

$pollOptions
  .on(getBizPollOptsFx.doneData, (_, payload) => payload)
  .reset(BizPollGate.close);

$binInfo
  .on(getBinInfoFx.doneData, (_, payload) => payload)
  .reset(BizPollGate.close);

$submitStatus.on(submitFormFx.doneData, () => true).reset(BizPollGate.close);

$pollsResults
  .on(getResultsListFx.doneData, (_, payload) => prepareResults(payload))
  .on(getResultsListPlanFx.doneData, (_, payload) =>
    preparePlanResults(payload)
  )
  .reset(BizPollResultsGate.close);

$wrongTypeModalOpen.reset(resetWrongTypeModalEv);

$govCantVoteModalOpen.reset(resetGovCantVoteModalEv);

sample({
  clock: [getBinInfoFx.failData, submitFormFx.failData],
  filter: (err) =>
    !!(
      err?.response?.status === 422 &&
      err.response.data.message === 'krp not match'
    ),
  fn: () => true,
  target: $wrongTypeModalOpen,
});

sample({
  clock: [getBinInfoFx.failData, submitFormFx.failData],
  filter: (err) =>
    !!(
      err?.response?.status === 422 &&
      err.response.data.message === 'gov company cannot vote'
    ),
  fn: () => true,
  target: $govCantVoteModalOpen,
});

sample({
  clock: BizPollGate.state.updates,
  filter: (gate) => !!gate.type,
  fn: (gate) => (gate.type === 'small' ? 'малый' : 'средний'),
  target: getBizPollOptsFx,
});

sample({
  clock: submitFormEv,
  fn: (payload) => {
    return {
      gov_id: payload.gov_id,
      business_type:
        window.location.pathname.split('/').at(-1) === 'small'
          ? 'малый'
          : 'средний',
      answers: Object.entries(payload)
        .filter(([key]) => key !== 'gov_id' && payload[key].value !== undefined)
        .map(([key, value]) => ({
          question_id: +key,
          answers: Array.isArray(value.value)
            ? checkValue(value)
            : [value.value === 'custom' ? value.custom : value.value],
        })),
    };
  },
  target: submitFormFx,
});

sample({
  clock: getBinInfoEv,
  fn: (bin) => ({
    bin,
    type:
      window.location.pathname.split('/').at(-1) === 'small'
        ? 'малый'
        : 'средний',
  }),
  target: getBinInfoFx,
});

getBinInfoFx.failData.watch((err) => {
  if (err?.response?.status === 404) {
    notification.error({ message: i18.t('biz-polls.errors.404') });
  } else if (err?.response?.status === 409) {
    notification.error({ message: i18.t('biz-polls.errors.409') });
  }
});

condition({
  source: BizPollResultsGate.state.updates,
  if: (source) => source.business_type === 'plan',
  then: getPlanResultsListEv,
  else: getResultsListEv,
});

sample({
  clock: getResultsListEv,
  filter: (gate) => !!gate.business_type,
  fn: (gate) => {
    if (gate.activeQuarter && /^\d{4}$/g.test(gate.activeQuarter)) {
      return {
        isYear: true,
        query: formQuery(gate),
      };
    }
    return {
      isYear: false,
      query: formQuery(gate),
    };
  },
  target: getResultsListFx,
});

sample({
  clock: getPlanResultsListEv,
  filter: (gate) => !!gate.business_type,
  fn: formQuery,
  target: getResultsListPlanFx,
});

sample({
  source: BizPollResultsGate.state,
  clock: downloadResultsEv,
  fn: (gate) => {
    const business_type =
      gate.business_type === 'plan' ? 'small' : gate.business_type;
    return {
      isYear:
        gate.business_type === 'plan' ||
        (gate.activeQuarter && /^\d{4}$/g.test(gate.activeQuarter)),
      query: formQuery({
        ...gate,
        business_type,
      }),
    };
  },
  target: downloadResultsFx,
});

downloadResultsFx.doneData.watch(() =>
  notification.info({ message: 'Отчет принят в работу' })
);

sample({
  clock: downloadYearPlanEv,
  target: downloadYearPlanFx,
});

downloadYearPlanFx.doneData.watch(() =>
  notification.info({ message: 'Отчет принят в работу' })
);
