import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useEvent, useUnit } from 'effector-react';
import ContentContainer from './components/ContentContainer/ContentContainer.jsx';
import { $globalStore, getRawDataFx } from './models/globalModel/index.js';
import {
  $authStatus,
  $userConfirmed,
  $userInfo,
  getCurrentUserFx,
  setAuthStatusEv,
} from './models/authModel/index.js';
import { cookies } from './api/axiosInstance.js';
import { getProjectFx } from './models/viewProjectModel/index.js';
import { getOrganizationFx } from './models/viewSubjectModel/index.js';
import { getDictionariesFx } from './models/dictionariesModel/effects.js';
import { getOverviewDataFx } from './models/overviewModel/index.js';
import { getNMPDataFx } from './models/mgpModel/index.js';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const globalStore = useUnit($globalStore);
  const isGlobalLoading = useUnit(getRawDataFx.pending);
  const isProjectLoading = useUnit(getProjectFx.pending);
  const isOrganizationLoading = useUnit(getOrganizationFx.pending);
  const isOverviewLoading = useUnit(getOverviewDataFx.pending);
  const isNMPLoading = useUnit(getNMPDataFx.pending);

  const isLoading =
    isGlobalLoading ||
    isProjectLoading ||
    isOrganizationLoading ||
    isOverviewLoading ||
    isNMPLoading;

  const authStatus = useUnit($authStatus);
  const curUser = useUnit($userInfo);
  const userConfirmed = useUnit($userConfirmed);
  const setAuth = useEvent(setAuthStatusEv);

  useEffect(() => {
    if (
      location.pathname === '/' &&
      authStatus &&
      curUser.role &&
      !['potential_investor', 'vtp', 'fundraiser', 'investor'].includes(
        curUser.role
      )
    ) {
      navigate('/dashboard');
    } else if (
      ['/', '/dashboard'].includes(location.pathname) &&
      authStatus &&
      curUser.role &&
      (curUser.role === 'potential_investor' ||
        curUser.role === 'vtp' ||
        curUser.role === 'fundraiser')
    ) {
      navigate('/invest-requests');
    } else if (
      ['/', '/dashboard'].includes(location.pathname) &&
      authStatus &&
      curUser.role === 'investor'
    ) {
      navigate('/edit-project');
    }
  }, [location, curUser, authStatus]);

  useEffect(() => {
    if (
      !location.pathname.includes('foreign-investments') &&
      Object.keys(globalStore).length === 0
    ) {
      getRawDataFx();
      getCurrentUserFx();
      getDictionariesFx();
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes('public')) {
      if (!authStatus) {
        const token = cookies.get('access_token');
        if (!token && !location.pathname.includes('foreign-investments')) {
          navigate('/login');
        } else {
          setAuth(true);
        }
      }
    }
  }, [authStatus, location.pathname]);

  return (
    <Spin spinning={isLoading} tip="Загружаем данные..." size="large">
      {(curUser.id && userConfirmed) || location.pathname.includes('public') ? (
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      ) : curUser.id && !userConfirmed ? (
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <h2>Подтвердите регистрацию на почте</h2>
        </div>
      ) : null}
    </Spin>
  );
}

export default App;
