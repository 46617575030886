import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
} from 'antd';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { $createEditProjectUtils } from '../../../models/dictionariesModel/index.js';
import { prepareActivitiesSelectData } from '../../../counters/viewProject/index.js';
import {
  $districtsSelect,
  $globalStore,
  $regionsSelect,
} from '../../../models/globalModel/index.js';
import Map from '../../Map/Map.jsx';
import EditBottomBar from '../../BottomBar/EditBottomBar.jsx';
import { public_activity_select } from '../../../dictionaries/public_dictionaries.js';

function CommonInfoForm({ form }) {
  const { pathname } = useLocation();

  const { truOptions, unitOptions, projTypeOptions } = useStore(
    $createEditProjectUtils
  );
  const activities = useStore($globalStore)?.activities || [];
  let { activity_select } = prepareActivitiesSelectData(activities);

  if (pathname.includes('public')) {
    activity_select = public_activity_select;
  }

  const regions = useStore($regionsSelect);
  const districts = useStore($districtsSelect);

  const selectedRegion = Form.useWatch('region_id', form);

  const [districtsSelect, setDistrictsSelect] = useState(districts);

  useEffect(() => {
    if (selectedRegion && districts) {
      setDistrictsSelect(
        districts.filter((i) => i.region_id === selectedRegion)
      );
    } else {
      setDistrictsSelect(districts);
    }
  }, [selectedRegion, districts]);

  return (
    <>
      <Form.Item
        name="name"
        label="Наименование проекта"
        rules={[{ required: true, message: 'Обязательное поле' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="main_activity_id"
        label="Вид деятельности по проекту"
        rules={[{ required: true, message: 'Обязательное поле' }]}
      >
        <Select options={activity_select} showSearch optionFilterProp="label" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Описание проекта"
        rules={[{ required: true, message: 'Обязательное поле' }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item
        label="Тип проекта"
        name="project_type_id"
        rules={[{ required: true, message: 'Обязательное поле' }]}
      >
        <Select options={projTypeOptions} />
      </Form.Item>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            name="region_id"
            label="Область"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={regions}
              showSearch
              optionFilterProp="label"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="district_id"
            label="Район/Город"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={districtsSelect}
              showSearch
              optionFilterProp="label"
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item
          name="start_date_plan"
          label="Дата начала проекта"
          rules={[{ required: true, message: 'Обязательное поле' }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
      </Row>
      <Divider orientationMargin={0} orientation="left">
        Координаты проекта
      </Divider>
      <div
        style={{
          height: '500px',
          position: 'relative',
        }}
      >
        <Map isEditPage />
        <EditBottomBar />
      </div>
      <Divider orientationMargin={0} orientation="left">
        Проектная мощность в год
      </Divider>
      <Form.List name="capacities" initialValue={[{ tru_type_id: null }]}>
        {(fields, { add, remove }) => (
          <Col span={24}>
            {fields.map((field, index) => (
              <>
                {index !== 0 && (
                  <Divider orientationMargin={0} orientation="left" />
                )}
                <Row style={{ gap: 16 }}>
                  <Col span={7}>
                    <Form.Item
                      name={[field.name, 'tru_type_id']}
                      label="Вид ТРУ"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                      <Select options={truOptions} showSearch />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name={[field.name, 'tnved']}
                      label="Наименование ТНВЭД"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                      {/* <Select options={tnvedOptions} showSearch/> */}
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ gap: 16 }}>
                  <Col span={7}>
                    <Form.Item
                      name={[field.name, 'value']}
                      label="Количество"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name={[field.name, 'unit_id']}
                      label="Единица измерения"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                      <Select options={unitOptions} showSearch />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name={[field.name, 'description']}
                  label="Описание"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'exported']}
                  label="Экспорт продукции за границу РК"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Row style={{ width: '20%' }} justify="space-between">
                  {index > 0 && (
                    <Button danger onClick={() => remove(field.name)}>
                      Отменить
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Row>
              </>
            ))}
          </Col>
        )}
      </Form.List>
    </>
  );
}

export default CommonInfoForm;
