import { Button, ConfigProvider, Dropdown, Layout, Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './ContentContainer.css';
import Icon, {
  AimOutlined,
  AppstoreAddOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BarChartOutlined,
  DownOutlined,
  IdcardOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useEvent, useUnit } from 'effector-react';
import { useMemo } from 'react';
import localeRu from 'antd/locale/ru_RU';
import localeKz from 'antd/locale/kk_KZ';
import localeEng from 'antd/locale/en_US';
import localeCn from 'antd/locale/zh_CN';
import { useTranslation } from 'react-i18next';
import {
  $authStatus,
  $language,
  $userInfo,
  changeLanguageEv,
  logoutEv,
} from '../../models/authModel/index.js';
import logo from '../../assets/new_logo.svg';
import LanguageSelector from '../LanguageSelector/LanguageSelector.jsx';

const localeDict = {
  ru: localeRu,
  kz: localeKz,
  en: localeEng,
  cn: localeCn,
};

const { Header } = Layout;

function MoonSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-2 1 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02103 12.3377C4.29982 16.4567 7.684 19.8078 11.7342 19.9918C14.5917 20.1197 17.1473 18.7441 18.6806 16.5767C19.3156 15.6889 18.9749 15.097 17.914 15.297C17.3951 15.393 16.8608 15.4329 16.3032 15.409C12.5163 15.249 9.41868 11.9778 9.40319 8.11482C9.39544 7.07509 9.60454 6.09134 9.984 5.19557C10.4022 4.20382 9.89881 3.73194 8.9308 4.15584C5.86413 5.49149 3.76548 8.68267 4.02103 12.3377Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

function NotificationSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-4 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.29 15.29L14 14V9C14 5.93 12.36 3.36 9.49999 2.68V2C9.49999 1.17 8.82999 0.5 7.99999 0.5C7.16999 0.5 6.49999 1.17 6.49999 2V2.68C3.62999 3.36 1.99999 5.92 1.99999 9V14L0.70999 15.29C0.0799904 15.92 0.51999 17 1.40999 17H14.58C15.48 17 15.92 15.92 15.29 15.29ZM12 15H3.99999V9C3.99999 6.52 5.50999 4.5 7.99999 4.5C10.49 4.5 12 6.52 12 9V15ZM7.99999 20C9.09999 20 9.99999 19.1 9.99999 18H5.99999C5.99999 19.1 6.88999 20 7.99999 20Z"
        fill="currentColor"
      />
    </svg>
  );
}

function MoonIcon() {
  return <Icon component={MoonSvg} />;
}

function NotificationIcon() {
  return <Icon component={NotificationSvg} />;
}

function ContentContainer({ children }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const authStatus = useUnit($authStatus);
  const curUsr = useUnit($userInfo);
  const language = useUnit($language);

  const ddItems = [
    {
      key: 'edit-project',
      label: (
        <>
          <ProjectOutlined /> Мои проекты
        </>
      ),
    },
    {
      key: 'edit-subject',
      label: (
        <>
          <AreaChartOutlined /> Мои предприятия
        </>
      ),
    },
    {
      key: 'create-project',
      label: (
        <>
          <AppstoreAddOutlined /> Создать проект
        </>
      ),
    },
    {
      key: 'okeds-priority',
      label: (
        <>
          <OrderedListOutlined /> Приоритеты ОКЭД
        </>
      ),
    },
    {
      key: 'mgp',
      label: (
        <>
          <AimOutlined /> МГП
        </>
      ),
    },
    {
      key: 'polls-results',
      label: (
        <>
          <BarChartOutlined /> Результаты опросов
        </>
      ),
    },
    {
      key: 'project-requests',
      label: (
        <>
          <AuditOutlined /> Проекты на рассмотрении
        </>
      ),
    },
    {
      key: 'my-profile',
      label: (
        <>
          <IdcardOutlined /> {t('Мой профиль')}
        </>
      ),
    },
    {
      key: 'logout',
      label: (
        <>
          <LogoutOutlined /> {t('Выход')}
        </>
      ),
    },
  ];

  const dropdownItems = useMemo(() => {
    if (curUsr.id && curUsr.role !== 'editor') {
      const result = [...ddItems];
      if (
        curUsr.id &&
        !['admin', 'chamber', 'investor', 'guest'].includes(curUsr.role)
      ) {
        const idx = result.findIndex((item) => item.key === 'create-project');
        if (idx !== -1) {
          result.splice(idx, 1);
        }
      }
      if (curUsr.id && !['admin', 'chamber'].includes(curUsr.role)) {
        const idx = result.findIndex((item) => item.key === 'okeds-priority');
        if (idx !== -1) {
          result.splice(idx, 1);
        }
      }
      if (!['admin', 'chamber', 'mio'].includes(curUsr.role)) {
        const idx = result.findIndex((item) => item.key === 'project-requests');
        if (idx !== -1) {
          result.splice(idx, 1);
        }
      }
      if (
        curUsr.id &&
        (curUsr.role === 'potential_investor' ||
          curUsr.role === 'vtp' ||
          curUsr.role === 'fundraiser')
      ) {
        const idxProj = result.findIndex((item) => item.key === 'edit-project');
        if (idxProj !== -1) {
          result.splice(idxProj, 1);
        }
        const idxSubj = result.findIndex((item) => item.key === 'edit-subject');
        if (idxSubj !== -1) {
          result.splice(idxSubj, 1);
        }
      }
      if (curUsr.id && curUsr.role === 'investor') {
        const idxSubj = result.findIndex((item) => item.key === 'edit-subject');
        if (idxSubj !== -1) {
          result.splice(idxSubj, 1);
        }
      }
      if (
        curUsr.id &&
        !['admin', 'survey_admin', 'guest'].includes(curUsr.role)
      ) {
        const idx = result.findIndex((item) => item.key === 'mgp');
        if (idx !== -1) {
          result.splice(idx, 1);
        }
      }
      if (curUsr.id && !['admin', 'survey_admin'].includes(curUsr.role)) {
        const idx = result.findIndex((item) => item.key === 'polls-results');
        if (idx !== -1) {
          result.splice(idx, 1);
        }
      }
      return result;
    }
    return [
      {
        key: 'my-profile',
        label: (
          <>
            <IdcardOutlined /> {t('Мой профиль')}
          </>
        ),
      },
      {
        key: 'logout',
        label: (
          <>
            <LogoutOutlined /> {t('Выход')}
          </>
        ),
      },
    ];
  }, [authStatus, curUsr]);

  const itemsNoAuth = [
    {
      key: '/foreign-investments',
      label: (
        <Link
          to="/foreign-investments"
          // className="white_font_color white_font_color:active"
        >
          Иностранные инвестиции
        </Link>
      ),
    },
  ];

  let items;

  if (curUsr.role === 'investor') {
    items = [
      // {
      //   key: '/projects-map',
      //   label: (
      //     <Link
      //       // className="white_font_color white_font_color:active"
      //       to="/projects-map"
      //     >
      //       Интерактивная карта
      //     </Link>
      //   ),
      // },
    ];
  } else if (
    curUsr.role === 'potential_investor' ||
    curUsr.role === 'vtp' ||
    curUsr.role === 'fundraiser'
  ) {
    items = [
      {
        key: '/invest-requests',
        label: (
          <Link to="/invest-requests">{t('Инвестиционные предложения')}</Link>
        ),
      },
    ];
  } else {
    items = [
      {
        key: '/dashboard',
        label: (
          <Link
            // className="white_font_color white_font_color:active"
            to="/dashboard"
          >
            Дэшборд
          </Link>
        ),
      },
      {
        key: '/projects-map',
        label: (
          <Link
            // className="white_font_color white_font_color:active"
            to="/projects-map"
          >
            Интерактивная карта
          </Link>
        ),
      },
      {
        key: '/organizations',
        label: (
          <Link
            // className="white_font_color white_font_color:active"
            to="/organizations"
          >
            Реестр предприятий
          </Link>
        ),
      },
      {
        key: '/mgp-registry',
        label: (
          <Link
            // className="white_font_color white_font_color:active"
            to="/mgp-registry"
          >
            МГП
          </Link>
        ),
      },
      {
        key: '/sectors',
        label: <span style={{ color: '#2b3674' }}>Товары ОТП</span>,
        children: [
          {
            key: 'sectors-construction',
            label: <Link to="/sectors/construction">Сектор строительства</Link>,
          },
          {
            key: 'sectors-oil-and-gas',
            label: <Link to="/sectors/oil-and-gas">Нефтегазовый сектор</Link>,
          },
          {
            key: 'sectors-public-utilities',
            label: (
              <Link to="/sectors/public-utilities">
                Сектор энергетики и жилищнокоммунального хозяйства
              </Link>
            ),
          },
        ],
      },
    ];
  }

  const onLogout = useEvent(logoutEv);

  const onDropDownClick = ({ key }) => {
    if (key === 'logout') {
      onLogout();
    } else {
      navigate(`/${key}`);
    }
  };

  return (
    <ConfigProvider locale={localeDict[language]}>
      <Layout className="layout_container">
        {/* <Layout.Sider theme={'dark'} width={'10%'}> */}
        {/*    <Menu theme={'dark'} items={items} mode={'inline'} /> */}
        {/* </Layout.Sider> */}
        {/* {children} */}

        {location.pathname.includes('public') &&
        !location.pathname.includes('sectors') ? (
          ''
        ) : (
          <Header
            id="header"
            style={{
              position: 'fixed',
              top: 0,
              zIndex: 25,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              // backgroundColor: '#4328af',
              backgroundColor: '#fff',
              border: '1px solid rgba(5, 5, 5, 0.06)',
              minHeight: '80px',
            }}
          >
            <div
              className="container"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img style={{ marginRight: '25px' }} alt="logo" src={logo} />
              <Menu
                id="header-dropdown"
                theme="dark"
                items={authStatus ? items : itemsNoAuth}
                mode="horizontal"
                selectedKeys={[location.pathname]}
                style={{
                  width: 'calc(100% - 526px)',
                  // backgroundColor: '#4328af',
                  backgroundColor: '#fff',
                  color: '#1b2559',
                  fontWeight: 700,
                  height: '40px',
                  lineHeight: '42px',
                }}
              />
              {/* <Dropdown */}
              {/*  placement="bottom" */}
              {/*  menu={{ items: dropdownItems, onClick: onDropDownClick }} */}
              {/* > */}
              {/*  <Button */}
              {/*    className="profile_button" */}
              {/*    icon={<UserOutlined />} */}
              {/*    shape="circle" */}
              {/*  /> */}
              {/* </Dropdown> */}

              <div className="menu_controls_wrapper">
                <Button
                  shape="circle"
                  icon={<NotificationIcon />}
                  className="notification_button"
                />
                <Button
                  shape="circle"
                  icon={<MoonIcon />}
                  className="theme_button"
                />
                <LanguageSelector
                  allLangs={[
                    'vtp',
                    'potential_investor',
                    'fundraiser',
                  ].includes(curUsr.role)}
                />
                <Dropdown
                  placement="bottom"
                  menu={{ items: dropdownItems, onClick: onDropDownClick }}
                >
                  <div className="profile_wrapper">
                    {/* <img /> */}
                    <div className="avatar_wrapper">
                      <UserOutlined />
                    </div>
                    <div style={{ margin: '0 10px' }}>
                      {curUsr?.username ?? 'Профиль'}
                    </div>
                    <DownOutlined
                      style={{
                        color: '#A3AED0',
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Header>
        )}
        <Layout.Content
          style={{
            marginTop: '80px',
            height: '100%',
            paddingBottom: '96px',
          }}
          className="content"
        >
          {children}
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
}

export default ContentContainer;
