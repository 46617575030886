import React from 'react';
import { Button, Col, Form, Row, Select, Tooltip } from 'antd';
import { useUnit } from 'effector-react/effector-react.mjs';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import {
  $districtsSelect,
  $regionsSelect,
} from '../../models/globalModel/index.js';
import {
  applyOkedsFiltersEv,
  resetOkedsFiltersEv,
} from '../../models/okedsPriorityModel/index.js';

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

function OkedsPriorityFilters() {
  const [filterForm] = Form.useForm();
  const regionsSelect = useUnit($regionsSelect);
  const districtsSelect = useUnit($districtsSelect);
  const apply = useUnit(applyOkedsFiltersEv);
  const reset = useUnit(resetOkedsFiltersEv);

  const onFinish = (values) => {
    apply(values);
  };

  const onCancel = () => {
    filterForm.resetFields();
    reset();
  };

  return (
    <Form form={filterForm} onFinish={onFinish} layout="vertical">
      <Row style={{ alignItems: 'center' }} gutter={16}>
        <Col span={4}>
          <Form.Item name="region_id" label="Регион">
            <Select
              options={regionsSelect}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="district_id" label="Район">
            <Select
              options={districtsSelect}
              dropdownStyle={{ width: 300 }}
              allowClear
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
        </Col>
        <Button.Group>
          <Tooltip title="Применить фильтры">
            <Button
              onClick={() => filterForm.submit()}
              icon={<FilterOutlined />}
              type="primary"
              style={{ boxShadow: 'none' }}
            />
          </Tooltip>
          <Tooltip title="Сбросить фильтры">
            <Button onClick={onCancel} icon={<ClearOutlined />} danger />
          </Tooltip>
        </Button.Group>
      </Row>
    </Form>
  );
}

export default OkedsPriorityFilters;
