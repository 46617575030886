import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useStore, useUnit } from 'effector-react';
import { downloadSectorsDataFx } from '../../models/sectorsModel/index.js';

function DownloadFilteredButton({ onDownload }) {
  const sectorsLoading = useUnit(downloadSectorsDataFx.pending);
  const loading = sectorsLoading;

  return (
    <Tooltip title="Скачать выгрузку по примененным фильтрам" placement="right">
      <Button
        className="btn-primary"
        icon={<DownloadOutlined />}
        onClick={() => onDownload()}
        loading={loading}
      />
    </Tooltip>
  );
}

export default DownloadFilteredButton;
