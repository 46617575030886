import { sample } from 'effector';
import { splitMap } from 'patronum';
import {
  downloadProjectsFx,
  getBizModalPollsStatsFx,
  getDashboardModalDataFx,
  getMBizModalDataFx,
  getOtpActivitiesDataFx,
  getOtpDataFx,
  getOtpOrgsDataFx,
  getOverviewDataFx,
  getProblemProjectsFx,
  getSmBizModalDataFx,
} from './effects.js';
import {
  $activeProblemResp,
  $bizRegionModalData,
  $bizModalRawData,
  $bizRegionModalState,
  $dashboardModalData,
  $dashboardModalOpen,
  $isModalOpen,
  $otpModalData,
  $otpModalState,
  $otpStore,
  $overviewStore,
  $problemProjects,
  $showProjectDots,
  $bizDistrictModalState,
  $bizDistrictModalData,
  $bizPollsStatsModalState,
  $bizPollsStatsModalData,
} from './stores.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../activeFiltersModel/index.js';
import {
  BizPollsStatsModalGate,
  changeActiveProblemRespEv,
  changeShowProjectDotsEv,
  downloadProjectsEv,
  getDashboardsDataEv,
  getProblemProjectsEv,
  ManufacturersGate,
  resetBizModalEv,
  resetBizPollsStatsModalEv,
  resetDistrictModalEv,
  resetOtpModalEv,
  sendOtpCallEv,
  sendOverviewCallEv,
  setBizModalEv,
  setBizPollsStatsModalEv,
  setDashboardModalOpenEv,
  setDistrictModalEv,
  setIsModalOpenEv,
  setOtpModalEv,
} from './events.js';
import {
  prepareBizDistrictModalData,
  prepareBizRegionModalData,
  prepareDashboardModalData,
} from './utils.js';

$dashboardModalOpen.on(setDashboardModalOpenEv, (_, state) => state);
$dashboardModalData
  .on(getDashboardModalDataFx.doneData, (_, data) => {
    return prepareDashboardModalData(data);
  })
  .on(setDashboardModalOpenEv, (_, state) => {
    if (!state) {
      return [];
    }
  });

$otpStore.on(getOtpDataFx.doneData, (_, payload) => payload);
$otpModalState
  .on(setOtpModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(resetOtpModalEv);

$otpModalData
  .on(
    [getOtpActivitiesDataFx.doneData, getOtpOrgsDataFx.doneData],
    (_, payload) => payload
  )
  .reset(resetOtpModalEv);

$bizRegionModalState
  .on(setBizModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(resetBizModalEv);

$bizModalRawData
  .on(
    [getSmBizModalDataFx.doneData, getMBizModalDataFx.doneData],
    (_, payload) => payload
  )
  .reset(resetBizModalEv);

$bizRegionModalData.reset(resetBizModalEv);

$bizDistrictModalState
  .on(setDistrictModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(resetDistrictModalEv);

$bizDistrictModalData.reset(resetDistrictModalEv);

$bizPollsStatsModalState
  .on(setBizPollsStatsModalEv, () => true)
  .reset(resetBizPollsStatsModalEv);

$bizPollsStatsModalData
  .on(getBizModalPollsStatsFx.doneData, (_, payload) => payload)
  .reset(BizPollsStatsModalGate.close);

sample({
  clock: getOverviewDataFx.doneData,
  target: $overviewStore,
});

sample({
  source: $activeFilters,
  clock: getOverviewDataFx.doneData,
  filter: (source) =>
    window.location.pathname.includes('public') && source.region_id !== 24,
  fn: () => {
    return {
      field: 'region_id',
      value: 24,
    };
  },
  target: changeActiveFilterEv,
});

sample({
  clock: sendOverviewCallEv,
  // filter: (clock) => isOverviewFilters(clock),
  filter: () => window.location.pathname === '/dashboard',
  fn: (clock) => {
    return {
      region_id: clock.region_id,
      district_id: clock.district_id,
      is_gov: clock.is_gov,
      business_climate_yearly: clock.business_climate_yearly,
      oked_ids: clock.main_activity_id,
      project_type_ids: clock.project_type_id,
    };
  },
  target: getOverviewDataFx,
});

sample({
  clock: ManufacturersGate.open,
  target: getOtpDataFx,
});

sample({
  clock: sendOtpCallEv,
  fn: (clock) => {
    return {
      region_id: clock.region_id,
      district_id: clock.district_id,
      activity_id: clock.activity_id,
    };
  },
  target: getOtpDataFx,
});

const { getOtpActivitiesEv, getOtpOrgsEv } = splitMap({
  source: $otpModalState.updates,
  cases: {
    getOtpActivitiesEv: (source) => {
      if (source.open && source.type === 'activities') {
        return {
          open: source.open,
          filters: {
            region_id: source.region_id,
            district_id: source.district_id,
          },
        };
      }
      return undefined;
    },
    getOtpOrgsEv: (source) => {
      if (source.open && source.type === 'organizations') {
        return {
          open: source.open,
          filters: {
            region_id: source.region_id,
            // district_id: source.district_id,
            activity_id: source.activity_id,
          },
        };
      }
      return undefined;
    },
  },
});

sample({
  clock: getOtpActivitiesEv,
  filter: (clock) => !!clock.open,
  fn: (clock) => clock.filters,
  target: getOtpActivitiesDataFx,
});

sample({
  clock: getOtpOrgsEv,
  filter: (clock) => !!clock.open,
  fn: (clock) => clock.filters,
  target: getOtpOrgsDataFx,
});

const dashboardMainTriggers = [
  'region_id',
  'district_id',
  'is_gov',
  'business_climate_yearly',
  'main_activity_id',
  'project_type_id',
];
sample({
  source: $activeFilters,
  clock: changeActiveFilterEv,
  filter: (source, clock) => {
    return (
      window.location.href.includes('dashboard') &&
      dashboardMainTriggers.includes(clock.field)
    );
  },
  fn: (source, clock) => {
    return source;
  },
  target: sendOverviewCallEv,
});

sample({
  source: $activeFilters,
  clock: changeActiveFilterEv,
  filter: (source, clock) => {
    if (
      clock.field === 'region_id' ||
      clock.field === 'district_id' ||
      clock.field === 'is_gov' ||
      clock.field === 'activity_id'
    ) {
      // if (clock.value === source[clock.field]) {
      //   return false;
      // }
      return true;
    }
    return false;
  },
  fn: (source, clock) => {
    return source;
  },
  target: sendOtpCallEv,
});

sample({
  clock: changeShowProjectDotsEv,
  target: $showProjectDots,
});

sample({
  clock: changeActiveProblemRespEv,
  target: $activeProblemResp,
});

sample({
  clock: setIsModalOpenEv,
  target: $isModalOpen,
});

sample({
  clock: getProblemProjectsEv,
  fn: (filters) => {
    const query = {};

    if (filters.region_id) {
      query.region_id = filters.region_id;
    }
    if (filters.district_id) {
      query.district_id = filters.district_id;
    }
    if (filters.activity_type_id.length > 0) {
      query.activity_type_id = filters.activity_type_id;
    }
    if (filters.project_type_id.length > 0) {
      query.project_type_id = filters.project_type_id;
    }
    if (Object.keys(query).length > 0) {
      return query;
    }
    return null;
  },
  target: getProblemProjectsFx,
});

sample({
  clock: getProblemProjectsFx.doneData,
  target: $problemProjects,
});

sample({
  clock: $activeFilters.updates,
  fn: (clock) => {
    if (
      clock.subCategory === 'projectCount' ||
      clock.subCategory === 'problemProjects'
    ) {
      return true;
    }
    return false;
  },
  target: $showProjectDots,
});

sample({
  clock: $dashboardModalOpen.updates,
  filter: (clock) => !!clock,
  target: getDashboardModalDataFx,
});

sample({
  source: $dashboardModalOpen,
  clock: getDashboardsDataEv,
  filter: (src) => !!src,
  fn: (_, ev) => {
    const query = {};

    if (ev.region_id) {
      query.region_id = ev.region_id;
    }
    if (ev.district_id) {
      query.district_id = ev.district_id;
    }
    if (Object.keys(query).length > 0) {
      return query;
    }
    return null;
  },
  target: getDashboardModalDataFx,
});

const { getSmBizDataEv, getMBizDataEv } = splitMap({
  source: $bizRegionModalState,
  cases: {
    getSmBizDataEv: (src) => !!src.open && src.type === 'business_inspections',
    getMBizDataEv: (src) => !!src.open && src.type === 'mio_performance',
  },
});

sample({
  source: $bizRegionModalState,
  clock: getSmBizDataEv,
  filter: (src) => !!src.open && src.type === 'business_inspections',
  fn: (src) => src.granularity === 'year',
  target: getSmBizModalDataFx,
});

sample({
  source: $bizRegionModalState,
  clock: getMBizDataEv,
  filter: (src) => !!src.open && src.type === 'mio_performance',
  fn: (src) => src.granularity === 'year',
  target: getMBizModalDataFx,
});

sample({
  source: { raw: $bizModalRawData, modal: $bizRegionModalState },
  clock: [$bizRegionModalState.updates, $bizModalRawData.updates],
  filter: ({ modal }) => !!modal.open,
  fn: ({ raw, modal }) => {
    return prepareBizRegionModalData(raw, modal.granularity);
  },
  target: $bizRegionModalData,
});

sample({
  source: { raw: $bizModalRawData, modal: $bizDistrictModalState },
  clock: $bizDistrictModalState.updates,
  filter: ({ modal }) => !!modal.open,
  fn: ({ raw, modal }) => {
    return prepareBizDistrictModalData(
      raw,
      modal.region_id,
      modal.granularity,
      modal.selectedQuarter,
      modal.selectedYear
    );
  },
  target: $bizDistrictModalData,
});

sample({
  clock: BizPollsStatsModalGate.state,
  filter: (gate) => Object.hasOwn(gate, 'type'),
  fn: (gate) => gate,
  target: getBizModalPollsStatsFx,
});

sample({
  clock: downloadProjectsEv,
  target: downloadProjectsFx,
});
