import {
  Divider,
  Popover,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useUnit } from 'effector-react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $mgpMaps,
  $mgpPageFiltered,
  $mgpTypeFilter,
  $mgpTypesCount,
  getMGPPageListFx,
  MGPsListGate,
  setMgpTypeFilterEv,
} from '../../models/mgpModel/index.js';
import { $activitiesMap, $regionsMap } from '../../models/globalModel/index.js';
import MGPFilters from './components/MGPFilters.jsx';
import styles from './MGPRegistry.module.css';
import NmpRegistry from './NMPRegistry.jsx';

export default function MGPRegistryPublic() {
  const listData = useUnit($mgpPageFiltered);
  const typeCounts = useUnit($mgpTypesCount);
  const { mgpMap, kspMap, typeMap, kindMap, targetMap, operatorsMap } =
    useUnit($mgpMaps);
  const { pathname } = useLocation();

  const changeType = useUnit(setMgpTypeFilterEv);

  const loading = useUnit(getMGPPageListFx.pending);

  const activitiesMap = useUnit($activitiesMap);
  const regionsMap = useUnit($regionsMap);

  const [tab, setTab] = useState(0);
  const segmentedOptions = [
    {
      label: 'Меры государственной поддержки',
      value: 0,
    },
    {
      label: 'Анализ получателей нефинансовых МГП',
      value: 1,
    },
  ];

  const columns = [
    {
      title: 'МГП',
      dataIndex: 'program_id',
      key: 'mgp_id',
      width: 200,
      render: (id, record) => (
        <Tooltip title={mgpMap[id]} placement="topLeft">
          <Link to={record.link}>{mgpMap[id]}</Link>
        </Tooltip>
      ),
    },
    {
      title: 'Регион',
      dataIndex: 'region_ids',
      key: 'region',
      width: 150,
      render: (ids) =>
        ids.length === 20 ? (
          <Tag>Весь Казахстан</Tag>
        ) : (
          <Popover
            content={
              <div style={{ maxWidth: 400 }}>
                {ids.map((id) => (
                  <Tag key={id}>{regionsMap[id]}</Tag>
                ))}
              </div>
            }
            placement="bottom"
          >
            <Tag>
              {ids.length > 1 ? `${regionsMap[ids[0]]}...` : regionsMap[ids[0]]}
            </Tag>
          </Popover>
        ),
    },
    {
      title: 'ОКЭД',
      dataIndex: 'activity_ids',
      key: 'oked',
      width: 200,
      render: (ids) => (
        <Popover
          content={
            <div
              style={{
                display: 'flex',
                gap: 4,
                maxWidth: 800,
                maxHeight: 400,
                overflow: 'auto',
                flexWrap: 'wrap',
              }}
            >
              {ids.map((id) => (
                <Tag>{activitiesMap[id]}</Tag>
              ))}
            </div>
          }
          placement="bottom"
        >
          <Tag style={{ maxWidth: 200 }}>
            <Typography.Paragraph ellipsis>
              {activitiesMap[ids[0]]}
            </Typography.Paragraph>
          </Tag>
        </Popover>
      ),
    },
    {
      title: 'КСП',
      dataIndex: 'ksp_ids',
      key: 'category',
      width: 200,
      render: (ids) => ids.map((id) => <Tag key={id}>{kspMap[id]}</Tag>),
    },
    {
      title: 'Тип поддержки',
      dataIndex: 'type_id',
      key: 'type',
      render: (id) => typeMap[id],
    },
    {
      title: 'Вид поддержки',
      dataIndex: 'kind_id',
      key: 'kind',
      render: (id) => kindMap[id],
    },
    {
      title: 'Цель',
      dataIndex: 'target_ids',
      key: 'target',
      render: (ids) => ids.map((id) => <Tag key={id}>{targetMap[id]}</Tag>),
    },
    {
      title: 'Сумма',
      width: 300,
      key: 'sum',
      children: [
        {
          title: 'От',
          dataIndex: `sum_from`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
        {
          title: 'До',
          dataIndex: `sum_to`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
      ],
    },
    {
      title: 'Ставка',
      width: 300,
      key: 'rate',
      children: [
        {
          title: 'От',
          dataIndex: `rate_from`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
        {
          title: 'До',
          dataIndex: `rate_to`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
      ],
    },
    {
      title: 'Сроки, мес.',
      key: 'deadline',
      width: 300,
      children: [
        {
          title: 'От',
          dataIndex: `deadline_from`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
        {
          title: 'До',
          dataIndex: `deadline_to`,
          render: (value) =>
            new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
              value
            ),
        },
      ],
    },
    {
      title: 'Оператор',
      dataIndex: 'operator',
      key: 'operator',
      width: 200,
      render: (id) => (
        <Tooltip title={operatorsMap[id]} placement="topLeft">
          {operatorsMap[id]}
        </Tooltip>
      ),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (text) => (
        <Tooltip title={text}>
          <Typography.Paragraph ellipsis style={{ maxWidth: 100 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: 'Ссылка',
      dataIndex: 'link',
      key: 'link',
      render: (link) => (
        <Link to={link} target="_blank">
          {link}
        </Link>
      ),
    },
  ];

  return (
    <ContainerInner
      title={segmentedOptions[tab].label}
      extra={
        !pathname.includes('public') ? (
          <Segmented options={segmentedOptions} value={tab} onChange={setTab} />
        ) : null
      }
    >
      {tab === 0 ? (
        <>
          <MGPsListGate />
          <MGPFilters />
          <Divider
            style={{ marginTop: 0 }}
            orientationMargin={0}
            orientation="left"
          >
            По виду поддержки
          </Divider>
          <Space direction="horizontal" style={{ gap: 16, marginBottom: 16 }}>
            <div onClick={() => changeType(1)} className={styles.typeSelect}>
              <span>
                <strong>Финансовая</strong>
              </span>
              <span>{typeCounts.fin}</span>
            </div>
            <div onClick={() => changeType(2)} className={styles.typeSelect}>
              <span>
                <strong>Нефинансовая</strong>
              </span>
              <span>{typeCounts.non_fin}</span>
            </div>
            <div onClick={() => changeType(null)} className={styles.typeSelect}>
              <span>
                <strong>Всего</strong>
              </span>
              <span>{typeCounts.all}</span>
            </div>
          </Space>
          <Table
            columns={columns}
            dataSource={listData}
            size="small"
            loading={loading}
            bordered
            rowClassName="table-row"
            style={{ width: '100%', overflow: 'auto' }}
          />
        </>
      ) : (
        <NmpRegistry />
      )}
    </ContainerInner>
  );
}
