export const filterOkedsData = (data, filters) => {
  if (filters.region_id && filters.district_id) {
    return data.filter(
      (item) =>
        item.region_id === filters.region_id &&
        item.district_id === filters.district_id
    );
  }
  if (filters.region_id) {
    return data.filter((item) => item.region_id === filters.region_id);
  }
  if (filters.district_id) {
    return data.filter((item) => item.district_id === filters.district_id);
  }
  return data;
};
